import React, {useState, useEffect, useContext} from 'react'
import { Routes, Route, useNavigate, Navigate as Redirect} from 'react-router-dom';
import { UserContext } from '../../App';

//CSS
import "./Dashboard.css";
import "./Widget.css";

// Icons/Imgs
import DuroSuiteIcon from "./../../styles/imgs/DuroSuiteIcon.png";

//Component
import Home from "./home/Home";
import Settings from "./settings/Settings";
import Groups from "./groups/Groups";
import Devices from "./devices/Devices";
import Audits from "./audits/Audits";
import Remediations from "./remediations/Remediations";
import Sidebar from "./Sidebar";
import Documentation from "./docs/Documentation";
import Profile from "./profile/Profile";
import Playbooks from "./playbooks/Playbooks";
import ScheduledTasks from './ScheduledTasks/ScheduledTasks';

const Dashboard = (props) => {
    const {user, theme, FetchCatch, AddNotification, UserIsAdmin} = useContext(UserContext);
    let navigate = useNavigate();
    const [deviceList, setDeviceList] = useState([]);
    const [ansibleConfig,setAnsibleConfig] = useState([]);
    const [hostList, setHostList] = useState([]);
    const [auditList, setAuditList] = useState([]);
    const [allAuditList, setAllAuditList] = useState([]);
    const [remediateList, setRemediateList] = useState([]);
    const [totalRemediateList, setTotalRemediateList] = useState([]);
    const [remediateData, setRemediateData] = useState(0);
    const [auditTotal, setAuditTotal] = useState(0);
    const [pageSelect, setPageSelect] = useState("Home");
    const [hostSelect, setHostSelect] = useState(null);
    const [allRoles, setAllRoles] = useState([]);
    const [uniqueGroupNames, setUniqueGroupNames] = useState([]);
    const [hostAuditSelect, setHostAuditSelect] = useState(null);
    const [dateAuditSelect, setDateAuditSelect] = useState(null);
    const [settings, setSettings] = useState(props.settings);

    const [inProgressAudits, setInProgressAudits] = useState(null);
    const [inProgressRemediate, setInProgressRemediate] = useState(null);
    const [inProgressQueue, setInProgressQueue] = useState([]);
    const [timeoutQueue, setTimeoutQueue] = useState(null);
    const [groupRole, setGroupRole] = useState([]);

    const url_ip = "/api/";

    useEffect(() => {
        props.setTimezone(settings.timezone)
        RefreshHandler();
    },[settings]);

    useEffect(() => {
        setGroupRole(props.groupRole);
    },[props.groupRole])

    useEffect(() => {

    },[inProgressQueue]);

    useEffect(() => {
        if(inProgressQueue.length === 0 || inProgressQueue == null) return;
        let tmp = [...inProgressQueue];
        tmp.sort((a,b) => a.time_started === b.time_started ? a.id > b.id ? 1 : -1 : a.time_started > b.time_started ? 1 : -1);
        if(!QueueEqualCheck(tmp, inProgressQueue)){
            setInProgressQueue(tmp);
        }
        console.log("queue: ", inProgressQueue);
    },[inProgressQueue]);

    const QueueEqualCheck = (a,b) => {
        if(a.length != b.length) return false;
        for(let i in a){
            if(a[i].id != b[i].id) return false;
        }
        return true;
    }

    useEffect(() => {
        const fetchData = async (audit) => {
            let data = null;
            let tmp = [...inProgressQueue];
            if(audit.remediation){
                const url_fetch = url_ip + 'remediations/' + audit.id;
                data = await fetch(url_fetch,{
                    headers:{
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                    }
                })
                .then(res=>{
                    if(res.ok) return res.json();
                    throw res;
                })
                .then(data =>{
                    data.remediation = true;
                    return data;
                }).catch(async error => {
                    return await FetchCatch(error, fetchData, [audit]);
                });  
            }else{
                const url_fetch = url_ip + 'audits/' + audit.id;
                data = await fetch(url_fetch,{
                    headers:{
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                    }
                })
                .then(res=>{
                    if(res.ok) return res.json();
                    throw res;
                })
                .then(data =>{
                    data.remediation = false;
                    return data;
                }).catch(async error => {
                    return await FetchCatch(error, fetchData, [audit]);
                });  
            }

            if(data == null) return;
            if(data.status != "in-progress"){
                clearTimeout(timeoutQueue);
                if(data.remediation){
                    try{
                        let msg = "Remediation on device: " + props.deviceList[props.deviceList.findIndex(x => x.device_id === data.device_id)].name + ", playbook: " + props.playbookList[props.playbookList.findIndex(x => x.id === data.playbook_id)].friendly_name + " is " + (data.status == "Complete" ? " successful" : data.status == "Failed" ? " unsuccessful" : " cancelled");
                        AddNotification(msg);
                    }catch{

                    }

                    updateRemediation(data);
                }else{
                    try{
                        let msg = "Auditing on device: " + props.deviceList[props.deviceList.findIndex(x => x.device_id === data.device_id)].name + ", playbook: " + props.playbookList[props.playbookList.findIndex(x => x.id === data.playbook_id)].friendly_name + " is " + (data.status == "Complete" ? " successful" : data.status == "Failed" ? " unsuccessful" : " cancelled");
                        AddNotification(msg);
                    }catch{

                    }

                    updateAudit(data);
                }

                tmp.splice(0,1);
                setInProgressQueue(tmp);
                return;
            }

            setTimeoutQueue(setTimeout(function(){
                fetchData(tmp[0]);
            }, audit.remediation ? (settings.remediateRefreshTimer * 1000) : (settings.auditsRefreshTimer * 1000)));
            
        }

        if(timeoutQueue !== null){
            clearTimeout(timeoutQueue)
            setTimeoutQueue(null);
        }
        if(inProgressQueue.length === 0 ) return;
        setTimeoutQueue(setTimeout(function(){
            fetchData(inProgressQueue[0]);
        }, inProgressQueue[0].remediation ? (settings.remediateRefreshTimer * 1000) : (settings.auditsRefreshTimer * 1000)));
    }, [inProgressQueue]);

    const updateRemediation = async (new_remediation) => {
        new_remediation.remediation = true;
        let tmp = [...totalRemediateList];
        let index = tmp[0].results.findIndex(x => x.id == new_remediation.id);
        if(index == -1){
            console.log('Could not find the remediation in total remediate list');
            return;
        }

        tmp[0].results[index] = new_remediation;
        setTotalRemediateList(tmp);
    }

    const updateAudit = async (new_audit) => {
        new_audit.remediation = false;

        let tmp = [...allAuditList];
        let index = tmp[0].results.findIndex(x => x.id == new_audit.id);
        if(index == -1){
            console.log('Could not find the audit in total audit list');
            return;
        }

        tmp[0].results[index] = new_audit;
        setAllAuditList(tmp);
    }

    useEffect(() => {
        setDeviceList(props.deviceList);
    },[props.deviceList])

    useEffect(() => {
        setHostList(props.hostList);
    },[props.hostList])

    useEffect(() => {
        setAnsibleConfig(props.ansibleConfig);
    },[props.ansibleConfig])

    useEffect(() => {
        setAuditList(props.auditsList);
    },[props.auditsList])

    useEffect(() => {
        setAllRoles(props.allRoles);
    },[props.allRoles])

    useEffect(() => {
        setInProgressAudits(props.inProgressAudits);
    },[props.inProgressAudits])

    useEffect(() => {
        setInProgressRemediate(props.inProgressRemediate);
    }, [props.inProgressRemediate])

    useEffect(() => {
        setRemediateList(props.remediateList);
    },[props.remediateList]);
    
    useEffect(() => {
        if(props.allAuditList !== null){
            setAllAuditList(props.allAuditList);
        }
        if(props.totalRemediateList !== null){
            setTotalRemediateList(props.totalRemediateList);
        }
    },[props.allAuditList, props.totalRemediatelist]);

    useEffect(() => {
        setAuditTotal(props.auditData);
    }, [props.auditData]);

    useEffect(() => {
        setRemediateData(props.remediateData);
    }, [props.remediateData]);

    useEffect(() => {
        let tmp = [];
        for(let i in hostList){
            tmp.push({"name": hostList[i].group_name, "id": hostList[i].group_id});
        }
        setUniqueGroupNames(tmp);
    },[hostList])

    const changePageSelect = (page, params=null) => {
        setHostAuditSelect(null);
        setPageSelect(page);
        if(page === "Home"){
            navigate("/");
        }else{
            navigate("/" + page.toLowerCase(), {state: {params: params}});
        }
    }

    useEffect(() => {
        if(totalRemediateList.length === 0) return;
        setRemediateList(totalRemediateList[0].results);
    },[totalRemediateList]);
    
    useEffect(() => {
        if(allAuditList.length === 0) return;
        setAuditList(allAuditList[0].results);
    },[allAuditList]);
    
    const API_get_vuln = async (audit_id) => {
        const url_vuln = "/api/vulnerabilities?audit_id=" + audit_id;
        let data = await fetch(url_vuln,{
            headers:{
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${user.token}`
            }
        })
        .then(res=>{
            if(res.ok) return res.json();
            throw res;
        })
        .then(data =>{
            return data;
        }).catch(async error => {
            return await FetchCatch(error, API_get_vuln, [audit_id]);
        });  

        return data;
    }

    const newHostHandler = async (host,group,type,os,defaultVariable=null) => {
        let hostList_tmp = [...hostList];
        let status = true;
        if(type === 0){
            let new_host = {
                "group_id": -1,
                "os_id": parseInt(os),
                "group_name": host,
                "devices" : [],
                "host_group_vars": [],
                "children": []
            }
            let new_template = {
                id: -1,
                name: "Baseline",
                os_id: null,
                playbook_id: null,
            }
            let group_data = await API_create_group(host, os);
            if(group_data == -1 || group_data == null) return;
            new_host.group_id = group_data.group_id;
            hostList_tmp.push(new_host);
            setHostList(hostList_tmp);
            let template_data = await API_create_template("Baseline", os, new_host.group_id);
            if(template_data == -1 || template_data == null) return;
            new_template.id = template_data.id;

            if(defaultVariable.length > 0){
                for(let i in defaultVariable){
                    if(!status) break;
                    let variable_data = await API_create_template_variable(new_template.id, defaultVariable[i].var_name, defaultVariable[i].var_value);
                    new_host.host_group_vars.push({"host_group_var_id": variable_data.id, "var_name": defaultVariable[i].var_name, "value": defaultVariable[i].var_value});
                }
            }
            let tmp = [...hostList];
            tmp.push(new_host);
            setHostList(tmp);
            setHostSelect({"host":new_host,"type":type});
            props.UpdateGroupRole(new_host.group_id);
        }else{
            let variables_host = [];
            let device_data = await API_create_device(group.group_id, host, os);
            if(device_data == null) return;
            let new_host = {
                "device_id": -1,
                "device_name": host,
                "device_variables" : [],
                "groups": [{"group_id": group.group_id, "group_name": group.group_name}],
                "os_id": parseInt(os)
            };
            new_host.device_id = device_data.device_id;
                for(let i in defaultVariable){
                    let device_variable_data = await API_create_device_variable(new_host.device_id, defaultVariable[i].var_name, defaultVariable[i].var_value);
                    new_host.device_variables.push({"device_var_id": device_variable_data.device_var_id, "var_name": defaultVariable[i].var_name, "value": defaultVariable[i].var_value})
                    variables_host.push({"var_id": device_variable_data.device_var_id, "var_name": defaultVariable[i].var_name, "var_value": defaultVariable[i].var_value});
                }

            let tmp = [...hostList];
            let index = hostList.indexOf(group);
            tmp[index].devices.push(new_host);
            let tmp_02 = [...deviceList];
            let new_device_host = null;
            new_device_host = {"device_id": new_host.device_id, "name": new_host.device_name, "os_id": parseInt(os), "groups": [{"group_id": group.group_id, "group_name": group.group_name}], "device_vars": variables_host};
            tmp_02.push(new_device_host);
            setDeviceList(tmp_02);
            setHostList(tmp);
            setHostSelect({"host":new_host,"type":type});
        }

        return true;
    }

    const API_create_group = async (group_name, os_id) => {
        const url_new_group = url_ip + "groups";
        let data = await fetch(url_new_group,{
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${user.token}` },
            body: JSON.stringify({ "group_name": group_name, "os_id": os_id })
        })
        .then(res=>{
            if(res.ok) return res.json();
            throw res;
        })
        .then(data =>{
            return data;
        }).catch(async error => {
            return await FetchCatch(error,  API_create_group, [group_name, os_id]);
        });  

        return data;
    }

    const API_create_template = async (template_name, os_id, group_id) => {
        let url_new_template = url_ip + "stigs/templates";
        let data = await fetch(url_new_template ,{
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${user.token}` },
            body: JSON.stringify({"name": template_name, "os_id": os_id, "group_id": group_id})
        })
        .then(res=>{
            if(res.ok) return res.json();
            throw res;
        })
        .then(data =>{
            return data;
        }).catch(async error => {
            return await FetchCatch(error,  API_create_template, [os_id, group_id]);
        });  

        return data;
    }

    const API_create_template_variable = async (template_id, var_name, var_value) => {
        if(var_name == null || template_id == null) return;
        const url_new_template_var = "/api/stigs/templates/var";
        let data = await fetch(url_new_template_var,{
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${user.token}` },
            body: JSON.stringify({ "template_id": template_id, "var_name": var_name, "var_value": var_value == null ? "" : var_value})
        })
        .then(res=>{
            if(res.ok) return res.json();
            throw res;
        })
        .then(data =>{
            return data;
        }).catch(async error => {
            return await FetchCatch(error,  API_create_template_variable, [template_id, var_name, var_value]);
        });  

        return data;
    }

    const API_create_device = async (group_id, device_name, os_id) => {
        const url_new_device = url_ip + "devices";
        let data = await fetch(url_new_device, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${user.token}` },
            body: JSON.stringify({ "group_id": group_id, "device_name": device_name, "os_id": os_id })
        })
        .then(res=>{
            if(res.ok) return res.json();
            throw res;
        })
        .then(data =>{
            return data;
        }).catch(async error => {
            return await FetchCatch(error,  API_create_device, [group_id, device_name, os_id]);
        });  

        return data;
    }

    const API_create_device_variable = async (device_id, var_name, var_value) => {
        const url_new_device_var = "/api/devices/vars";
        let data = await fetch(url_new_device_var, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${user.token}` },
            body: JSON.stringify({ "device_id": device_id, "var_name": var_name,"var_value": var_value })
        })
        .then(res=>{
            if(res.ok) return res.json();
            throw res;
        })
        .then(data =>{
            return data;
        }).catch(async error => {
            return await FetchCatch(error,  API_create_device_variable, [device_id, var_name, var_value]);
        });  

        return data;
    }

    const settingsSaveHandler = (ansibleconfig, settings_new) => {
        const url_update_ansible = url_ip + "ansible-cfg";
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${user.token}` },
            body: JSON.stringify(ansibleconfig),
        };
        fetch(url_update_ansible,requestOptions)
        .then(res=>{
            if(res.ok) return res.json();
            throw res;
        })
        .then(data =>{
            setAnsibleConfig([ansibleconfig]);
            setSettings(settings_new);
        }).catch(async error => {
            return await FetchCatch(error,  settingsSaveHandler, [ansibleconfig, settings_new]);
        });     
    }

    const getGroupDevices = (group,devices) => {
        for(let i in group.children){
            let index = hostList.findIndex(host => host.group_name === group.children[i]);
            getGroupDevices(hostList[index],devices);
        }
        for(let i in group.devices){
            devices.push(deviceList[deviceList.findIndex(x => x.device_id === group.devices[i].device_id)]);
        }
    }

    const hostAuditSelectorHandler = (host,type) => {
        if('device_id' in host){
            setHostAuditSelect(host.name);
            if(type === 0)
                navigate('/audits',{state: {host: [host]}});
            else
                navigate('/remediations',{state: {host: [host]}});
        }else{
            setHostAuditSelect(host.group_name);
            let devices = []; 
            getGroupDevices(host,devices);
            if(type === 0)
                navigate('/audits',{state: {host: devices}});
            else
                navigate('/remediations',{state: {host: devices}});
        }

    }

    const dateAuditSelectorHandler = (date) => {
        if(dateAuditSelect === date)
            setDateAuditSelect(date + " ");
        else
            setDateAuditSelect(date);
        navigate('/audits',{state:{date: date}});
    }

    const addChildHandler = (parentGroup, childGroup) => {
        const url_new_device = url_ip + "groups/child";
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${user.token}` },
            body: JSON.stringify({ "group_id": parentGroup.group_id, "child_id": childGroup.group_id})
        };
        fetch(url_new_device,requestOptions)
        .then(res=>{
            if(res.ok) return true;
            throw res;
        }).then(data => {
            let tmp = [...hostList];
            let group_index = hostList.findIndex(g => g.group_id == parentGroup.group_id);
            tmp[group_index].children.push(childGroup.group_name);
            setHostList(tmp);
        }).catch(async error => {
            return await FetchCatch(error,  addChildHandler, [parentGroup, childGroup]);
        });
    }

    const addDeviceHandler = async (group, device) => {
        const url_new_device = url_ip + "groups/device";
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${user.token}` },
            body: JSON.stringify({ "group_id": group.group_id, "device_id": device.device_id})
        };
        await fetch(url_new_device,requestOptions)
        .then(res=>{
            if(res.ok) return true;
            throw res;
        }).then(data => {
           let tmp = [...hostList];
           let group_index = hostList.findIndex(g => g.group_id == group.group_id);
           tmp[group_index].devices.push(device);
           let device_tmp = [...deviceList];
           let device_index = deviceList.findIndex(d => d.device_id == device.device_id);
           device_tmp[device_index].groups.push({"group_id": group.group_id, "group_name": group.group_name});
           setDeviceList(device_tmp);
           setHostList(tmp);
        }).catch(async error => {
            return await FetchCatch(error,  addDeviceHandler, [group, device]);
        });
    }

    const removeGroupGroupHandler = async (group, parent) => {
        let data = await API_remove_group(group.group_id, parent.group_id);
        if(data == null) return;
        let host_tmp = [...hostList];
        let index = host_tmp.findIndex(x => x.group_id == parent.group_id);
        if(index == -1){
            console.log("Can't find parent group in host list.");
            return;
        }
        let child_index = host_tmp[index].children.findIndex(y => y == group.group_name);
        if(child_index == -1){
            console.log("Can't find group in parent's children list.");
            return;
        }
        host_tmp[index].children.splice(child_index, 1);
        setHostList(host_tmp);
    }

    const API_remove_group = async (group_id, parent_group_id) => {
        const url_remove_group_Group = url_ip + "groups/child/" + parent_group_id + "/" + group_id;
        const requestOptions = {
            method: 'DELETE',
            headers: {'Authorization': `Bearer ${user.token}`}
        };
        let data = await fetch(url_remove_group_Group, requestOptions)
        .then(res=>{
            if(res.ok) return true;
            throw res;
        })
        .then(data => {
           return true;
        }).catch(async error => {
            return await FetchCatch(error,  API_remove_group, [group_id, parent_group_id]);
        });
        
        return data;
    }

    const removeDeviceGroupHandler = async (device, group) => {
        let data = await API_remove_device(group.group_id, device.device_id);
        if(data == null) return;
        let new_group_list = [...device.groups];
        new_group_list.splice(new_group_list.findIndex(x => x.group_id === group.group_id),1);
        let host_tmp = [...hostList];
        for(let i in device.groups){
            let host_index = host_tmp.findIndex(x => x.group_id === device.groups[i].group_id);
            let host_device_index = host_tmp[host_index].devices.findIndex(x => x.device_id === device.device_id);
            if(device.groups[i].group_id === group.group_id){
                host_tmp[host_index].devices.splice(host_device_index,1);
            }else{
                host_tmp[host_index].devices[host_device_index].groups = new_group_list;
            }
        }

        let device_tmp = [...deviceList];
        let device_index = device_tmp.findIndex(x => x.device_id === device.device_id);
        device_tmp[device_index].groups = new_group_list;
        setHostList(host_tmp);
        setDeviceList(device_tmp);
        RefreshHandler();
    }

    const API_remove_device = async (group_id, device_id) => {
        const url_remove_device_Group = url_ip + "groups/device/" + group_id + "/" + device_id;
        const requestOptions = {
            method: 'DELETE',
            headers: {'Authorization': `Bearer ${user.token}`}
        };
        let data = await fetch(url_remove_device_Group,requestOptions)
        .then(res=>{
            if(res.ok) return true;
            throw res;
        })
        .then(data => {
           return null;
        }).catch(async error => {
            return await FetchCatch(error,  API_remove_device, [group_id, device_id]);
        });
        
        return data;
    }

    const removeChildHandler = (parentGroup, groupSelect) => {
        const url_remove_child = url_ip + "groups/child/" + parentGroup.group_id + "/" + groupSelect.group_id;
        const requestOptions = {
            method: 'DELETE',
            headers: {'Authorization': `Bearer ${user.token}`}
        };
        fetch(url_remove_child,requestOptions)
        .then(res=>{
            if(res.ok) return true;
            throw res;
        })
        .then(data => {
            let parent_index = hostList.findIndex(host => host.group_id === parentGroup.group_id);
            let child_index = hostList[parent_index].children.findIndex(groupName => groupName === groupSelect.group_name);
            let host_tmp = [...hostList];
            host_tmp[parent_index].children.splice(child_index,1);
            setHostList(host_tmp);
            RefreshHandler();
        }).catch(async error => {
            return await FetchCatch(error,  removeChildHandler, [parentGroup, groupSelect]);
        });
    }

    const deleteHostHandler = async (hosts) => {
        let host_tmp = [...hostList];
        let device_tmp = [...deviceList];
        let status = true;
        for(let i in hosts){
            if(!status) break;
            if("device_id" in hosts[i]){
                //device deletion
                let data = await API_delete_device(hosts[i].device_id);
                if(data){
                    let index = device_tmp.findIndex(device => device.device_id === hosts[i].device_id);
                    device_tmp.splice(index,1);
                    for(let x in host_tmp){
                        for(let y in host_tmp[x].devices){
                            if(host_tmp[x].devices[y].device_id === hosts[i].device_id){
                                host_tmp[x].devices.splice(y,1);
                            }
                        }
                    }
                }
            }

            if("group_id" in hosts[i]){
                //group deletion
                let data = await API_delete_group(hosts[i].group_id);
                if(data){
                    let index = host_tmp.findIndex(group => group.group_id === hosts[i].group_id);
                    host_tmp.splice(index,1);
                    for(let z in hosts[i].devices){
                        let device_tmp_index = device_tmp.findIndex(device => device.device_id === hosts[i].devices[z].device_id);
                        if(device_tmp_index != -1){
                            let group_tmp_index = device_tmp[device_tmp_index].groups.findIndex(x => x.group_id == hosts[i].group_id);
                            if(group_tmp_index != -1){
                                device_tmp[device_tmp_index].groups.splice(group_tmp_index, 1);
                            }
                        }
                    }
                    for(let x in host_tmp){
                        for(let y in host_tmp[x].children){
                            if(host_tmp[x].children[y] === hosts[i].group_name){
                                host_tmp[x].children.splice(y,1);
                            }
                        }
                    }
                }
            }

        }
        if(status){
            setHostList(host_tmp);
            setDeviceList(device_tmp);
            RefreshHandler();
        }
    }

    const API_delete_device = async (device_id) => {
        const url_delete_host = "/api/devices/" + device_id;
        let data = await fetch(url_delete_host,{
            method: 'DELETE',
            headers: {'Authorization': `Bearer ${user.token}`}
        })
        .then(res=>{
            if(res.ok) return true;
            throw res;
        }).then(data => {
            return true;
        }).catch(async error => {
            return await FetchCatch(error,  API_delete_device, [device_id]);
        });

        return data;
    }

    const API_delete_group = async (group_id) => {
        const url_delete_host = "/api/groups/" + group_id;
        let data = await fetch(url_delete_host,{
            method: 'DELETE',
            headers: {'Authorization': `Bearer ${user.token}`}
        })
        .then(res=>{
            if(res.ok) return true;
            throw res;
        }).then(data => {
            return true;
        }).catch(async error => {
            return await FetchCatch(error,  API_delete_group, [group_id]);
        });

        return data;
    }

    const deleteAuditHandler = async (audits, page) =>{
        let tmp = [...auditList];
        let tmp_rem = [...totalRemediateList];
        let tmp_audit = [...allAuditList];
        let auditTotal_tmp = auditTotal;
        let remediateData_tmp = remediateData;
        let tmp_queue = [...inProgressQueue];

        if(timeoutQueue != null){
            clearTimeout(timeoutQueue);
            setTimeoutQueue(null);
        }

        for(let i in audits){
            let index_page = -1;
            let index = -1;

            if(audits[i].remediation === false){
                let data = await API_delete_audit(audits[i].id)
                if(data == null) continue;
                if(audits[i].status === "in-progress"){
                    let index = tmp_queue.findIndex(t => t.id === audits[i].id && t.remediation === false);
                    tmp_queue.splice(index,1);
                }

                index_page = tmp_audit.findIndex(x => x.page === page);
                index = tmp_audit[index_page].results.findIndex(x => x.id === audits[i].id);
                tmp_audit[index_page].results.splice(index,1);
                auditTotal_tmp -= 1; 
            }else{
                let data = await API_delete_remediation(audits[i].id);
                if(data == null) continue;
                if(audits[i].status === "in-progress"){
                    let index = tmp_queue.findIndex(t => t.id === audits[i].id && t.remediation === true);
                    tmp_queue.splice(index,1);
                }
                index_page = tmp_rem.findIndex(x => x.page === page);
                index = tmp_rem[index_page].results.findIndex(x => x.id === audits[i].id);
                tmp_rem[index_page].results.splice(index,1);
                remediateData_tmp -= 1;
            }
        }

        setAuditList(tmp);
        setAuditTotal(auditTotal_tmp);
        setRemediateData(remediateData_tmp);
        setTotalRemediateList(tmp_rem);
        setAllAuditList(tmp_audit);
        setInProgressQueue(tmp_queue);
    }

    const API_delete_audit = async (audit_id) => {
        const url_delete_audit = url_ip + "audits/" + audit_id;
        let data = await fetch(url_delete_audit,{
            method: 'DELETE',
            headers: {'Authorization': `Bearer ${user.token}`}
        })
        .then(res=>{
            if(res.ok) return true;
            throw res;
        }).catch(async error => {
            return await FetchCatch(error,  API_delete_audit, [audit_id]);
        });
        return data;
    }

    const API_delete_remediation = async (rem_id) => {
        const url_delete_rem = url_ip + "remediations/" + rem_id;
        let data = await fetch(url_delete_rem,{
            method: 'DELETE',
            headers: {'Authorization': `Bearer ${user.token}`}
        })
        .then(res=>{
            if(res.ok) return true;
            throw res;
        }).catch(async error => {
            return await FetchCatch(error,  API_delete_remediation, [rem_id]);
        });
        return data;
    }

    const updateDeviceVar = (group, device) => {
        props.refreshDevices();
    }

    const rerunAuditHandler = async (audits) => {
        for(let i in audits){
            if(audits[i].device_id == null || audits[i].group_id == null || audits[i].playbook_id == null || audits[i].template_id == null) continue;
            if(audits[i].remediation){
                await API_device_remediation(audits[i].device_id, audits[i].group_id, audits[i].playbook_id, audits[i].template_id);
            }else{
                await API_device_audit(audits[i].device_id, audits[i].group_id, audits[i].playbook_id, audits[i].template_id);
            }
        }
        if(audits.length > 0){
            if(audits[0].remediation){
                RefreshHandler(2);
            }else{
                RefreshHandler(1);
            }
        }
    }

    const getFriendlyTimestamp = () => {
        let friendly_timestamp = new Date().toUTCString();
        friendly_timestamp = friendly_timestamp.split(" ");
        friendly_timestamp[0] = friendly_timestamp[0].replace(/,/g, '');
        let tmp = friendly_timestamp[3];
        friendly_timestamp[3] = friendly_timestamp[4];
        friendly_timestamp[4] = tmp;
        friendly_timestamp.slice(5,1);
        friendly_timestamp = friendly_timestamp.join(" ");
        return friendly_timestamp;
    }

    const runAuditHandler = async (status,params,type) => {
        let auditMode = true;

        let new_audits = [];
        let new_remediations = [];
        if(type === 0){
            if("device_id" in params.host){
                //Device Audit
                for(let i in params.playbooks){
                    let data = await API_device_audit(params.host.device_id, params.group.group_id, params.playbooks[i].id, params.template);
                    if(data != null){
                        new_audits.push({
                            id: data.audit_id,
                            friendly_timestamp: getFriendlyTimestamp(),
                            group_id: data.group_id,
                            device_id: data.device_id,
                            job_id: data.job_id,
                            metadata: null,
                            playbook_id: data.playbook_id,
                            remediation: false,
                            status: 'in-progress',
                            time_started: Date.now(),
                            time_finished: null
                        });
                    }
                }
            }else{
                //Group Audit
                for(let i in params.playbooks){
                    let data = await API_group_audit(params.host.group_id, params.playbooks[i].id, params.template);
                    if(data != null){
                        new_audits.push({
                            id: data.remediation_id,
                            device_id: null,
                            group_id: data.group_id,
                            job_id: data.job_id,
                            friendly_timestamp: getFriendlyTimestamp(),
                            remediation: true,
                            time_finished: null,
                            time_Started: Date.now(),
                            status: "in-progress"
                        });
                    }
                }
            }
            RefreshHandler(1);
        }else{
            auditMode = false;
            if("device_id" in params.host){
                //Device Remediation
                for(let i in params.playbooks){
                    let data = await API_device_remediation(params.host.device_id, params.group.group_id, params.playbooks[i].id, params.template);
                    if(data != null){
                        new_remediations.push({
                            id: data.remediation_id,
                            device_id: data.device_id,
                            group_id: data.group_id,
                            job_id: data.job_id,
                            friendly_timestamp: getFriendlyTimestamp(),
                            remediation: true,
                            time_finished: null,
                            time_Started: Date.now(),
                            status: "in-progress"
                        });
                    }
                }
            }else{
                //Group Remediation
                for(let i in params.playbooks){
                    let data = await API_group_remediation(params.host.group_id, params.playbooks[i].id, params.template);
                    if(data != null){
                        new_remediations.push({
                            id: data.remediation_id,
                            device_id: null,
                            group_id: data.group_id,
                            job_id: data.job_id,
                            friendly_timestamp: getFriendlyTimestamp(),
                            remediation: true,
                            time_finished: null,
                            time_Started: Date.now(),
                            status: "in-progress"
                        });
                    }
                }
            }  
            RefreshHandler(2);
        }
        console.log("New Audits: ", new_audits);
        console.log("New Remediations: ", new_remediations);
        
        if(status === 1 || status === 0){
            if(auditMode)
                changePageSelect("Audits");
            else
                changePageSelect("Remediations");
        }
    }

    const API_device_audit = async (device_id, group_id, playbook_id, template_id) => {
        const url_new_audit = url_ip + "audit/device?device_id=" + device_id + "&group_id=" + group_id +"&playbook_id=" + playbook_id + "&template_id=" + template_id;
        let data = await fetch(url_new_audit,{
            headers:{
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${user.token}`
            }
        })
        .then(res=>{
            if(res.ok) return res.json();
            throw res;
        })
        .then(data =>{
            return data;
        }).catch(async error => {
            return await FetchCatch(error,  API_device_audit, [device_id, group_id, playbook_id, template_id]);
        });

        return data;
    }

    const API_group_audit = async (group_id, playbook_id, template_id) => {
        const url_new_audit = url_ip + "audit/group?group_id=" + group_id + "&playbook_id=" + playbook_id + "&template_id=" + template_id;
        let data = await fetch(url_new_audit,{
            headers:{
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${user.token}`
            }
        })
        .then(res=>{
            if(res.ok) return res.json();
            throw res;
        })
        .then(data =>{
            return data;
        }).catch(async error => {
            return await FetchCatch(error,  API_group_audit, [group_id, playbook_id, template_id]);
        })

        return data;
    }

    const API_device_remediation = async (device_id, group_id, playbook_id, template_id) => {
        const url_new_remediate = url_ip + "remediate/device?device_id=" + device_id + "&group_id=" + group_id + "&playbook_id=" + playbook_id + "&template_id=" + template_id;
        let data = await fetch(url_new_remediate,{
            headers:{
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${user.token}`
            }
        })
        .then(res=>{
            if(res.ok) return res.json();
            throw res;
        })
        .then(data =>{
            return data;
        }).catch(async error => {
            return await FetchCatch(error,  API_device_remediation, [device_id, group_id, playbook_id, template_id]);
        })

        return data;
    }

    const API_group_remediation = async (group_id, playbook_id, template_id) => {
        const url_new_remediate = url_ip + "remediate/group?group_id=" + group_id+ "&playbook_id=" + playbook_id + "&template_id=" + template_id;
        let data = await fetch(url_new_remediate,{
            headers:{
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${user.token}`
            }
        })
        .then(res=>{
            if(res.ok) return res.json();
            throw res;
        })
        .then(data =>{
            return data;
        }).catch(async error => {
            return await FetchCatch(error,  API_group_remediation , [group_id, playbook_id, template_id]);
        })
        return data;
    }

    const RefreshHandler = async (type = null) => {
        if(type == 1 || type == null){
            await refreshAuditsList();
        }

        if(type == 2 || type == null){
            await refreshRemediateList();
        }
    }

    const refreshAuditsList = async (filter = null) => {
        let data = await API_get_audits(0, settings.resultsPerPage, filter);

        if(data === null){
            setAuditList([]);
            setAuditTotal(0);
            setAllAuditList([{page: 1, results: []}]);
        } else if(Array.isArray(data)){
            setAuditList([]);
            setAuditTotal(0);
            setAllAuditList([{page: 1, results: []}]);
        }else{
            let tmp = [];
            let queue_tmp = [];
            for(let i in data._results){
                data._results[i].remediation = false;
                if(data._results[i].status === "in-progress"){
                    let record = {"id":data._results[i].id, "time_started": data._results[i].time_started, "remediation": false, "device_id": data._results[i].device_id, "playbook_id": data._results[i].playbook_id};
                    tmp.push(record);
                    let index = inProgressQueue.findIndex(x => x.id == data._results[i].id && !x.remediation);
                    if(index == -1){
                        queue_tmp.push(record);
                    }
                }
            }
            setAuditList([...data._results]);
            setAuditTotal(data._metadata.total);
            setAllAuditList([{page: 1, results: data._results}]);
            setInProgressQueue(tmp => (Distinct([...tmp, ...queue_tmp])));
        }
    }

    const refreshRemediateList = async (filter = null) => {
        let data = await API_get_remediation(0, settings.resultsPerPage, filter);

        if(data === null){
            setRemediateList([]);
            setRemediateData(0);
            setTotalRemediateList([{page: 1, results: []}]);
        }else  if(Array.isArray(data)){
            setRemediateData([]);
            setRemediateData(0);
            setTotalRemediateList([{page: 1, results: []}]);
        }else{

            data._results.sort((a,b) => a.time_started < b.time_started ? 1 : -1);
            let tmp = [];        
            let queue_tmp = []
            for(let i in data._results){
                data._results[i].remediation = true;
                if(data._results[i].status === "in-progress"){
                    let record = {"id":data._results[i].id, "time_started": data._results[i].time_started, "remediation": true, "device_id": data._results[i].device_id, "playbook_id": data._results[i].playbook_id}
                    tmp.push(record);
                    let index = inProgressQueue.findIndex(x => x.id == data._results[i].id && x.remediation);
                    if(index == -1){
                        queue_tmp.push(record);
                    }
                }
            }
            setRemediateList([...data._results]);
            setRemediateData(data._metadata.total);
            setTotalRemediateList([{page: 1, results: data._results}]);
            setInProgressQueue(tmp => (Distinct([...tmp, ...queue_tmp])));
        }
    }

    const updateGroupHandler = (oldGroup, newGroup) => {
        let tmp = [...hostList];
        for(let i in tmp){
            let tmp_index = tmp[i].children.indexOf(oldGroup.group_name);
            if(tmp_index !== -1){
                tmp[i].children[tmp_index] = newGroup.group_name;
            }
        }

        let device_tmp = [...deviceList];
        for(let i in newGroup.devices){
            let device_index = device_tmp.findIndex(x => x.device_id == newGroup.devices[i].device_id);
            if(device_index != -1){
                let group_index = device_tmp[device_index].groups.findIndex(x => x.group_id == newGroup.group_id);
                if(group_index != -1){
                    device_tmp[device_index].groups[group_index] = newGroup;
                }
            }
        }
        setDeviceList(device_tmp);
        let index = tmp.findIndex(t => t.group_id === newGroup.group_id);
        tmp[index] = newGroup;
        setHostList(tmp);
    }

    const getRemediatePage = async (page, filter = null) => {
        let data = await API_get_remediation(((page-1) * settings.resultsPerPage), settings.resultsPerPage, filter);
        if(data == null) return;
        setRemediateData(data._metadata === undefined ? 0 : data._metadata.total);
        let index = totalRemediateList.findIndex(x => x.page === page);
        for(let i in data._results){
            data._results[i].remediation = true;
        }
        if(index === -1){
            let tmp = [...totalRemediateList];
            if(data._results != null){
                data._results.sort((a,b) => a.time_started < b.time_started ? 1 : -1);
                tmp.push({page: page, results: data._results});
                setTotalRemediateList(tmp);
                setRemediateList([...data._results]);
            }else{
                tmp.push({page: page, results: []});
                setTotalRemediateList(tmp);
                setRemediateList([]); 
            }
        }else{
            setRemediateList(totalRemediateList[index].results);
        }
        return data._results;
    }

    const API_get_remediation = async (start, total, filter) => {
        let url_remediations = "/api/remediations?skip=" + start + "&limit=" + total;
        if(filter != null){
            if(filter.begin_time != null){
                url_remediations += "&begin_time=" + filter.begin_time;
            }
            if(filter.end_time != null){
                url_remediations+= "&end_time=" + filter.end_time;
            }
            if(filter.device_id != null){
                url_remediations += "&device_id=" + filter.device_id;
            }
        }
        let data = await fetch(url_remediations ,{
            headers:{
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${user.token}`
            }
        })
        .then(res=>{
            if(res.ok) return res.json();
            throw res;
        })
        .then(data =>{
            return data;
        }).catch(async error => {
            return await FetchCatch(error,  API_get_remediation [start, total]);
        })

        return data;
    }

    const getAuditPage = async (page, filter = null) => {
        let data = await API_get_audits(((page-1) * settings.resultsPerPage), settings.resultsPerPage, filter);
        if(data == null) return;
        if(Array.isArray(data)){
            setAuditList([]);
            setAuditTotal(0);
            setAllAuditList([{page: 1, results: []}]);
            setInProgressAudits([]);
            return [];
        }
        setAuditTotal(data._metadata?.total);
        let index = allAuditList.findIndex(x => x.page === page);
        for(let i in data._results){
            data._results[i].remediation = false;
        }
        if(index === -1){
            let tmp = [...allAuditList];
            if(data._results != null){
                data._results.sort((a,b) => a.time_started < b.time_started ? 1 : -1);
                tmp.push({page: page, results: data._results});
                setAllAuditList(tmp);
                setAuditList([...data._results]);
            }else{
                tmp.push({page: page, results: []});
                setAllAuditList(tmp);
                setAuditList([]);
            }
        }else{
            setAuditList(allAuditList[index].results);
        }
        return data._results;
    }

    const API_get_audits = async (start, total, filter) => {
        let url_audit = "/api/audits?skip=" + start + "&limit=" + total;
        if(filter != null){
            if(filter.begin_time != null){
                url_audit += "&begin_time=" + filter.begin_time;
            }
            if(filter.end_time != null){
                url_audit += "&end_time=" + filter.end_time;
            }
            if(filter.device_id != null){
                url_audit += "&device_id=" + filter.device_id;
            }
        }
        let data = await fetch(url_audit ,{
            headers:{
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${user.token}`
            }
        })
        .then(res=>{
            if(res.ok) return res.json();
            throw res;
        })
        .then(data =>{
            return data;
        }).catch(async error => {
            return await FetchCatch(error, API_get_audits, [start, total]);
        })

        return data;
    }

    const cancelAuditsHandler = async (audit_list, page) => {
        audit_list = audit_list.filter(x => x.status == "in-progress");

        for(let i in audit_list){
            await API_cancel_audit(audit_list[i].id);
        }

        RefreshHandler();
    }

    const API_cancel_audit = async (audit_id) => {
        const url_cancel = "/api/audit/cancel?audit_id=" + audit_id;
        let data = await fetch(url_cancel ,{
            headers:{
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${user.token}`,
            }
          })
          .then(res=>{
            if(res.ok) return res.json();
            throw res
          })
          .then(data =>{
            return data;
          }).catch(async error => {
            return await FetchCatch(error,  API_cancel_audit, [audit_id]);
        })

        return data;
    }

    const cancelRemediationHandler = async (remediate_list, page) => {
        remediate_list = remediate_list.filter(x => x.status == "in-progress");
        let queue_tmp = [...inProgressQueue];
        let tmp = [...totalRemediateList];
        for(let i in remediate_list){
            let queue_index = inProgressQueue.findIndex(x => x.id == remediate_list[i].id && x.remediation == true);
            if(queue_index != -1){
                queue_tmp.splice(queue_index, 1);
            }

            let page_index = tmp.findIndex(x => x.page == page);
            if(page_index != -1){
                let index = tmp[page_index].results.findIndex(x => x.id == remediate_list[i].id);
                if(index != -1){
                    let result = await API_cancel_remediation(remediate_list[i].id);
                    if(result == null) continue;
                    tmp[page_index].results[index] = result;
                }
            }
        }

        setInProgressQueue(queue_tmp);
        setTotalRemediateList(tmp);
    }

    const API_cancel_remediation = async (remediation_id) => {
        const url_cancel = "/api/remediate/cancel?rem_id=" + remediation_id;
        let new_data = await fetch(url_cancel ,{
            headers:{
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${user.token}`,
            }
          })
          .then(res=>{
            if(res.ok) return res.json();
            throw res
          })
          .then(data =>{
            return data;
          }).catch(async error => {
            return await FetchCatch(error, API_cancel_remediation, [remediation_id]);
        })

        return new_data;
    }

    return(
        <div style={{width: "100%", display: "block"}}>
            <div className="dashboard-spacer"></div>
            <img src={DuroSuiteIcon} alt="DuroSuite Icon" style={{position: "fixed", width: "30px", right: "10px", bottom: "10px"}} />
            <div className="dashboard-content" style={{backgroundColor: theme ? "#121212" : "#FDFDFD"}}>
                <Sidebar user={user} pageClick={(page) => changePageSelect(page)} pageSelect={pageSelect} inProgressQueue={inProgressQueue} auditList={auditList} remediateList={remediateList} deviceList={deviceList} playbookList={props.playbookList} theme={theme}/>
                <Routes>
                    <Route path="*" element={
                        <Redirect to="/" />
                    } />

                    <Route path="/" element={
                        <Home groupRole={groupRole} allRoles={allRoles} pageClick={changePageSelect} hostList={hostList} audits={auditList} remediations={remediateList} devices={deviceList} OS={props.OSList} playbooks={props.playbookList} auditSelect={props.auditSelect} dateAuditSelector={dateAuditSelectorHandler} auditData={auditTotal} remediateData={remediateData}/>
                    } />
                    
                    <Route path="groups" element={
                        <Groups groupRole={groupRole} hostList={hostList} newHost={newHostHandler} hostSelect={hostSelect} uniqueGroupNames={uniqueGroupNames} hostAudit={hostAuditSelectorHandler} addChild={addChildHandler} addDevice={addDeviceHandler} removeChild={removeChildHandler} removeDeviceGroup={removeDeviceGroupHandler} removeGroupGroup={removeGroupGroupHandler} OS={props.OSList} playbookList={props.playbookList} deleteHost={deleteHostHandler} updateDevice={updateDeviceVar} runAudit={runAuditHandler} deviceList={deviceList} auditList={auditList} updateGroup={updateGroupHandler} refreshGroups={props.refreshGroups}/>
                    } />

                    <Route path="devices" element={
                        <Devices groupRole={groupRole} hostList={hostList} newHost={newHostHandler} hostSelect={hostSelect} uniqueGroupNames={uniqueGroupNames} hostAudit={hostAuditSelectorHandler} addChild={addChildHandler} addDevice={addDeviceHandler} removeChild={removeChildHandler} removeDeviceGroup={removeDeviceGroupHandler} OS={props.OSList} playbookList={props.playbookList} deleteHost={deleteHostHandler} updateDevice={updateDeviceVar} runAudit={runAuditHandler} deviceList={deviceList} auditList={auditList} updateGroup={updateGroupHandler} refreshDevices={props.refreshDevices} />
                    } />

                    <Route path="audits" element={
                        <Audits groupRole={groupRole} auditList={auditList} remediateList={[]} auditData={auditTotal} getAuditPage={getAuditPage} totalAuditList={allAuditList} deviceList={deviceList} hostSelect={hostAuditSelect} dateSelect={dateAuditSelect} auditSelect={props.auditSelect} playbookList={props.playbookList} deleteAudit={deleteAuditHandler} refresh={refreshAuditsList} rerunAudits={rerunAuditHandler} inProgressQueue={inProgressQueue} viewinViewer={props.viewinViewer} resultsPerPage={settings.resultsPerPage} cancelAudits={cancelAuditsHandler}/>
                    } />

                    <Route path="remediations" element={
                        <Remediations groupRole={groupRole} auditList={[]} remediateList={remediateList} deviceList={deviceList} hostSelect={hostAuditSelect} dateSelect={dateAuditSelect} auditSelect={props.auditSelect} playbookList={props.playbookList} deleteAudit={deleteAuditHandler} rerunAudits={rerunAuditHandler} inProgressQueue={inProgressQueue} resultsPerPage={settings.resultsPerPage} getRemediatePage={getRemediatePage} remediateData={remediateData} totalRemediateList={totalRemediateList} refresh={refreshRemediateList} cancelRemediate={cancelRemediationHandler}/> 
                    }/>

                    <Route path="settings" element={
                        UserIsAdmin() ?
                        <Settings ansible_config={ansibleConfig} settings={settings} setSettings={setSettings} pageClick={(page) => changePageSelect(page)} handleSave={settingsSaveHandler} bannerConfig={props.bannerConfig} />
                        :
                        <Redirect to="/" />
                    } />

                    <Route path="documentation/*" element={
                        <Documentation pageClick={changePageSelect} auditSelect={props.auditSelect} theme={theme}/>
                    } />

                    <Route path="profile/*" element={
                        <Profile groups={hostList} notificationList={props.notificationList} setNotificationList={props.setNotificationList}/>
                    } />
                    
                    <Route path="stigs" element={
                        <Playbooks devices={deviceList} hostList={hostList} OS={props.OSList} audits={auditList} remediates={remediateList} playbooks={props.playbookList}/>} 
                    />

                    <Route path="scheduled-tasks" element={
                        <ScheduledTasks deviceList={deviceList} groupList={hostList} OSList={props.OSList} playbookList={props.playbookList} />} 
                    />
                </Routes>
            </div>
        </div>
    )
}

function Distinct(list){
    let tmp = [];
    for(let i in list){
        let index = tmp.findIndex(x => x.id == list[i].id && list[i].remediation == x.remediation);
        if(index == -1){
            tmp.push(list[i]);
        }
    }
    return tmp;
}

export default Dashboard;