import React, { useContext, useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { UserContext } from '../../../App';
import { var_password_check } from "../../Helper";


const DeviceCreate = (props) => {
    const { user, FetchCatch, theme, UserIsAdmin } = useContext(UserContext);

    const [name, setName] = useState("");
    const [OSSelect, setOSSelect] = useState(-1);
    const [groupSelect, setGroupSelect] = useState(-1);
    const [stayOpen, setStayOpen] = useState(false);
    const [defaultVariable, setDefaultVariable] = useState([]);

    const [groupList, setGroupList] = useState([])

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(OSSelect == -1){
            setGroupList([...props.hostList]);
        }else{
            setGroupList([...props.hostList.filter(x => x.os_id == OSSelect)]);
            API_default_vars(OSSelect);
        }
    }, [OSSelect])

    useEffect(() => {
        if(groupSelect != -1){
            let group = props.hostList[props.hostList.findIndex(x => x.group_id == groupSelect)];
            if(OSSelect != group.os_id){
                setOSSelect(group.os_id);
            }
        }else{
            setOSSelect(-1);
        }
    },[groupSelect])

    const cancelHost = () => {
        setName("");
        props.cancel();
    }

    const saveHost = async () => {
        if(name === "" || OSSelect == -1 || groupSelect == -1){
            alert("New Device must have a name, OS, and a parent group.");
            return;
        }
        for(let i in defaultVariable){
            if(!defaultVariable[i].var_value){
                alert("Device Variable is required.");
                return;
            }
        }
        setLoading(true);
        await props.addDevice(name, OSSelect, groupSelect, defaultVariable);
        setLoading(false);
        if(!stayOpen)
            props.cancel();
        setName("");
    }

    const changeDefaultVariable = (index, value) => {
        let tmp = [...defaultVariable];
        tmp[index].var_value = value;
        setDefaultVariable(tmp);
    }

    const API_default_vars = async (os_id) =>{
        const url_default_variable = "/api/operating-systems/defaults/" + os_id;
        let data = await fetch(url_default_variable ,{
            headers:{
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${user.token}`,
            }
            })
            .then(res=>{
            if(res.ok) return res.json();
            throw res
            })
            .then(data =>{
            data.devices.sort((a,b) => a.var_name > b.var_name ? 1 : -1);
            setDefaultVariable(data.devices);
            }).catch(async error => {
            return await FetchCatch(error, API_default_vars, [os_id]);
        })

        return data;
    }

    const device = () => {
        return(
            <>
                <div className="widget-text">
                    <span style={{marginRight: "5px"}}>Device Name: </span>
                    <input type="text" required value={name} onChange={(e) => setName(e.target.value)} style={{width: "75%"}}/>
                </div>
                <div className="widget-text">
                    <span>OS:</span>
                    <select required value={OSSelect} onChange={(e) => setOSSelect(e.target.value)} style={{width: "75%"}}>
                        <option value="-1">Select an OS</option>
                        {
                            props.OS.map((os, index) => (
                                <option key={os.id} value={os.id}>{os.operating_system}</option>
                            ))
                        }
                    </select>
                </div>
                <div className="widget-text">
                    <span>Group: </span>
                    <select required value={groupSelect} onChange={(e) => setGroupSelect(e.target.value)} style={{width: "75%"}}>
                        <option key="-1" value="-1">Select a Group</option>
                        {
                            groupList.map((group, index) => (
                                (UserIsAdmin() || props.groupRole.findIndex(x => x.group_id === group.group_id) !== -1) &&
                                <option key={index} value={group.group_id}>{group.group_name}</option>
                            ))
                        }
                    </select>
                    {(props.selected != null && props.selected.group_name != null) && props.selected.group_name}
                </div>
                <div className="widget-text" style={{gridTemplateColumns: "1fr"}}>
                    <span style={{fontWeight: "bold", textDecoration: "underline"}}>Device Variables: </span>
                </div>
                <div style={{maxHeight: "200px", overflowY: "auto"}}>
                    {
                        defaultVariable.map((item, index) => (
                            <div key={index} className="widget-text" style={{display: 'grid', gridTemplateColumns: '1fr 1fr'}}>
                                <span style={{overflowX: "hidden", textOverflow: "ellipsis"}} title={item.var_name}>{item.var_name}: </span>
                                {
                                <input type={var_password_check(item.var_name) ? "password" : "text"} className="widget-text" required value={item.var_value == null ? "" : item.var_value} onChange={(e) => changeDefaultVariable(index, e.target.value)}/>
                                }
                            </div>
                        ))
                    }
                </div>
            </>
        )
    }

  return (
    <div className="widget-container" style={{display: props.display ?  "" : "none" , backgroundColor: theme ? "#242424" : "#FFF", border: theme ? "1px solid #000" : "1px solid #EBEBEB", color: theme ? "#fff" : "#000", borderRadius: "10px", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",  width: "90%", height: "auto", margin: "10px 0px"}}>
        <h3 className="widget-header"><span>{"Adding a New Device"}</span></h3>
        <div className="widget-content">
            <div>
                {
                    device()
                }
            </div>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: "10px", color: 'gray'}}>
                <input type="checkbox" value={stayOpen} onChange={() => setStayOpen(!stayOpen)} />
                <span style={{marginLeft: "10px"}}>Add Another Device</span>
            </div>
            <div style={{display: 'flex', justifyContent:'center', alignItems:"center"}}>
                <input type="button" value="Cancel" className="widget-button" onClick={() => cancelHost()}/>
                <div style={{display: 'flex', alignItems: 'center'}}>
                <input type="button" value="Save" className="widget-button" onClick={() => saveHost()} />
                { loading && <CircularProgress style={{width: "20px", height: "20px"}} /> }
                </div>
            </div>
        </div>
    </div>
  )
}

export default DeviceCreate