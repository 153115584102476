import React, {useEffect, useState, useContext} from 'react';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { UserContext } from '../../../App';
import { var_password_check } from "../../Helper";


const PlaybookWidget = (props) => {
    const { theme } = useContext(UserContext)
    const [variables, setVariables] = useState(null);
    const [changedVar, setChangedVar] = useState([]);

    useEffect(() => {
        setChangedVar([]);
        if(props.templateVariable == null){
            setVariables(null);
        }else{
            setVariables(JSON.parse(JSON.stringify(props.templateVariable)));
        }
    }, [props.templateVariable]);

  const updateVar = (index, value, type) => {
    // type == 0 == connection_vars
    // type == 1 == stig_vars
    let tmp = {...variables};
    let variable = null;
    if(type == 0){
        tmp.connection_vars[index].var_value = value;
        variable = tmp.connection_vars[index];
    }else{
        tmp.stig_vars[index].var_value = value;
        variable = tmp.stig_vars[index];
    }

    let changed_tmp = [...changedVar];
    let changed_index = changedVar.findIndex(x => x.var_id == variable.var_id);
    if(changed_index != -1){
        changed_tmp[changed_index].var_value = value;
    }else{
        changed_tmp.push(variable);
    }

    setVariables(tmp);
    setChangedVar(changed_tmp);
  }

  const cancelEdit = () => {
    setVariables(JSON.parse(JSON.stringify(props.templateVariable)));
    setChangedVar([]);
  }

  const saveEdit = () => {
    props.save(changedVar);
    setChangedVar([]);
  }

  
  const makeBaselineHandler = () => {
    let confirmed = window.confirm("Are you sure you want '" + props.template.name + "' to be your new baseline?");
    if(confirmed) props.changeBaseline();
  }

  const validateStigVarView = (stigVar) => {
    if(stigVar.includes("_remediate") || stigVar.includes("_comment") || stigVar.includes("_audit")){
        return true;
    }
    return false;
  }

  return (
    <div className="widget-container" style={{display: props.playbook === null ? "none" : "" ,backgroundColor: theme ? "#242424" : "#FFF", border: theme ? "1px solid #000" : "1px solid #EBEBEB", color: theme ? "#fff" : "#000", borderRadius: "10px", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", width: "90%", margin: "10px 0px", height: "auto"}}>
    <h3 className="widget-header" style={{marginBottom: "5px"}}>
      <span>{props.playbook === null ? "Playbook" : props.playbook.friendly_name}</span>
    </h3>
    <div className="widget-text" style={{gridTemplateColumns: "1fr 1fr"}}>
        <span>Total Devices: </span>
        <span>{props.playbook !== null && props.devices.filter(x => x.os_id === props.playbook.os_id).length}</span>
    </div>
    <div className="widget-text" style={{gridTemplateColumns: "1fr 1fr"}}>
        <span>Vulnerabilities: </span>
        <span>{props.playbook !== null && props.vuln != null ? props.vuln.length : ""}</span>
    </div>
    <div className="widget-text" style={{gridTemplateColumns: "1fr 1fr"}}>
        <span>Templates Created: </span>
        <span>{props.playbook !== null && props.templateList != null && props.templateList.length}</span>
    </div>
    <div className="widget-text" style={{gridTemplateColumns: "1fr 1fr"}}>
        <span>Total Severity:</span>
    </div>
    <div className="widget-text" style={{gridTemplateColumns: "1fr 1fr 1fr"}}>
        <span>CAT I: {props.playbook !== null && props.vuln != null ? props.vuln.filter(x => x.severity == "high").length : ""}</span>
        <span>CAT II: {props.playbook !== null && props.vuln != null ? props.vuln.filter(x => x.severity == "medium").length : "" }</span>
        <span>CAT III: {props.playbook !== null && props.vuln != null ? props.vuln.filter(x => x.severity == "low").length : "" }</span>
    </div>
    <div className="widget-text" style={{gridTemplateColumns: "1fr 1fr", paddingTop: "10px", borderTop: "1px dotte black",}}>
        <span>Template Name: </span>
        <span>{props.template != null ? props.template.name + " #" + props.template.id: "N/A"}</span>
    </div>
    <div className="widget-text" style={{gridTemplateColumns: "1fr 1fr"}}>
        <span>Template Group: </span>
        <span>{props.template != null && props.hostList.find(x => x.group_id == props.template.group_id).group_name}</span>
    </div>
    {
        props.template != null && props.template.name != "Baseline" &&
        <div className="widget-text" style={{gridTemplateColumns: "1fr"}}>
            <div onClick={makeBaselineHandler} style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
                <AddBoxIcon /> Make this the Baseline Template 
            </div>
        </div>
    }
    <div className="widget-text" style={{gridTemplateColumns: "1fr"}}>
        <span style={{fontWeight: "bold", textDecoration: "underline"}}>STIG Variables: </span>
    </div>
    <div style={{maxHeight: "200px", overflowY: "auto"}}>
    {
        props.template == null || variables == null ? <></> :
        props.template.name == "Baseline"  ?
        variables.stig_vars.map((item, index) => (
            <div key={item.var_id} className="widget-text" style={{display: 'grid', gridTemplateColumns: '1fr 1fr', display: validateStigVarView(item.var_name) ? "none" : ""}}>
                <span style={{overflowX: "hidden", textOverflow: "ellipsis"}} title={item.var_name}>{item.var_name}: </span>
                <span style={{textAlign: "center"}}>{var_password_check(item.var_name) ? "********" : item.var_value}</span>
            </div>
        ))
        :
        variables.stig_vars.map((item, index) => (
            <div key={item.var_id} className="widget-text" style={{display: 'grid', gridTemplateColumns: '1fr 1fr', display: validateStigVarView(item.var_name) ? "none" : ""}}>
                <span style={{overflowX: "hidden", textOverflow: "ellipsis"}} title={item.var_name}>{item.var_name}: </span>
                <input type={var_password_check(item.var_name) ? "password" : "text"} value={item.var_value} className="widget-textfield" onChange={(e) => updateVar(index, e.target.value, 1)}/>
            </div>
        ))
    }
    </div>
    {
        changedVar.length > 0 &&
        <div style={{display: 'flex', justifyContent:'center', alignItems:"center", marginTop: "10px"}}>
            <input type="button" value="Cancel" className="widget-button" onClick={() => cancelEdit()}/>
            <input type="button" value="Save" className="widget-button" onClick={() => saveEdit(0)} />
        </div>
    }
  </div>
  )
}

export default PlaybookWidget