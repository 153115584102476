import React, { useState, useContext } from 'react'
import { UserContext } from '../../../App';

// Icons
import RefreshIcon from '@mui/icons-material/Refresh';
import CheckIcon from '@mui/icons-material/Check';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';


const EditProfile = (props) => {
    const { theme, user, FetchCatch, logout, refreshToken, FormatTimestamp} = useContext(UserContext);
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [savedAccessToken, setSavedAccessToken] = useState(null);

    function validatePassword(p) {
      let errors = [];
      if (p.length < 15) {
          errors.push("User password must be at least 15 characters"); 
      }
      if (p.search(/[0-9]/) < 0) {
          errors.push("User password must contain at least one digit."); 
      }
      if (p.search(/[a-z]/) < 0) {                         
        errors.push("User password must contain at least one lowercase letter.");                   
      }                       
      if (p.search(/[A-Z]/) < 0) {                         
        errors.push("User password must contain at least one uppercase letter.");                   
      }
      if (p.search(/[!@#$%^&*_-]/) < 0){
        errors.push("User password must contain at least one special character.");
      }
      if (errors.length > 0) {
        alert(errors.join("\n"));
        return false;
      }
      return true;
  }

    const changePassword = () => {
      if(currentPassword.length == 0){
        alert("Current Password is required");
        return;
      }

      if(newPassword != confirmPassword){
        alert("New password doesn't match with confirmed new password.");
        return;
      }

      if(currentPassword === newPassword){
        alert("Current password and new password should not be the same.");
        return;
      }

      let valid = validatePassword(newPassword);
      if(valid){
        let tmp = window.confirm("Are you sure you want to change password?");
        if(tmp){
          // Change password here
          const password_fetch = '/api/users/change_password';
          fetch(password_fetch,{
            method: "POST",
            headers:{
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${user.token}`,
            },
            body: JSON.stringify({'old_password': currentPassword, 'new_password': newPassword})
          })
          .then(res=>{
            if(res.ok){
              logout();
            }else{
              if(res.ok)return null;
              throw res
            }
          })
          .then(data =>{
            if(data !== null){
              alert(data.details + "\nPassword did not change.");
            }
          }).catch(async error => {
            FetchCatch(error,changePassword, []);
          })
        }else{
          return;
        }
      }else{
        return;
      }
    }

    const clearPassword = () => {
      setCurrentPassword("");
      setNewPassword("");
      setConfirmPassword("");
    }

  const accessTokenClickHandler = () => {
    navigator.clipboard.writeText(user.token);
    if(savedAccessToken != null){
      clearTimeout(savedAccessToken);
    }

    setSavedAccessToken(setTimeout(() => {
      setSavedAccessToken(null);
    }, 5000));
  }

  const refreshTokenHandler = () => {
    refreshToken();
  }

  return (
    <div className="inventory-content-wrapper" >
      <div style={{width: '90%', display: 'flex', flexDirection: 'column', fontSize: '18px'}}>
        <label>Username: </label>
        <input style={{padding: '10px 15px', borderRadius: '5px', fontWeight: 'bold', color: theme ? "white" : "gray"}} type="text" disabled value={user != null && user.email != null && user.email} />
      </div>
      <div style={{width: '90%', display: 'flex', flexDirection: 'column', fontSize: '18px', marginTop: '15px'}}>
        <label>Last Successful Login: </label>
        <input style={{padding: '10px 15px', borderRadius: '5px', fontWeight: 'bold', color: theme ? "white" : "gray"}} type="text" disabled value={FormatTimestamp(user.last_successful_login)} />
      </div>
      <div style={{width: '90%', display: 'flex', flexDirection: 'column', fontSize: '18px', marginTop: '15px'}}>
        <label>Last Failed Login: </label>
        <input style={{padding: '10px 15px', borderRadius: '5px', fontWeight: 'bold', color: theme ? "white" : "gray"}} type="text" disabled value={FormatTimestamp(user.last_failed_login)} />
      </div>
      <div style={{display: 'flex', paddingBottom: "15px", marginTop: '15px', fontSize: '18px'}}>
        <label style={{marginRight: '20px'}}>
          <p>Current Password:</p>
          <input type="password" style={{padding: '10px 15px', borderRadius: '5px', fontWeight: 'bold', color: 'gray', borderColor: 'rgba(118, 118, 118, 0.3)'}} value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} />
        </label>
        <label style={{marginRight: '20px'}}>
          <p>New Password:</p>
          <input type="password" style={{padding: '10px 15px', borderRadius: '5px', fontWeight: 'bold', color: 'gray', borderColor: 'rgba(118, 118, 118, 0.3)'}} value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
        </label>
        <label>
          <p>Confirm New Password:</p>
          <input type="password" style={{padding: '10px 15px', borderRadius: '5px', fontWeight: 'bold', color: 'gray', borderColor: 'rgba(118, 118, 118, 0.3)'}} value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
        </label>
      </div>
      <div>
        <input className="widget-button" type="button" value="Cancel" onClick={() => clearPassword()} style={{marginRight: "10px", padding: '2px'}}/> 
        <input className={currentPassword.length > 0 && newPassword.length > 0 && confirmPassword.length > 0 ? "widget-button"  : "widget-button-disable"} type="button" value="Save" disabled={currentPassword.length > 0 && newPassword.length > 0 && confirmPassword.length > 0 ? false : true} onClick={() => changePassword()} style={{padding: '2px'}}/> 
      </div>
      <div style={{width: '90%', display: 'flex', fontSize: '18px', marginTop: '15px'}}>
        <label>API Access Token: </label>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: "center"}}>
          <button style={{cursor: "pointer", marginLeft: "10px", width: "24px", height: "24px", border: 'none', background: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center', color: theme ? "#fff" : "#000"}} title="Refresh Token" onClick={() => {refreshTokenHandler()}}><RefreshIcon  /></button>
          <button style={{cursor: "pointer", marginLeft: "10px", width: "24px", height: "24px", border: 'none', background: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center', color: theme ? "#fff" : "#000"}} title="Copy to Clipboard" onClick={() => {accessTokenClickHandler()}}><ContentCopyIcon /></button>
          {
            savedAccessToken != null &&
            <span style={{fontSize: "12px", display: 'flex', alignItems: 'center', color: 'green'}}>
              <CheckIcon style={{width: "18px"}}/>
              Copied to clipboard
            </span>
          }
        </div>
      </div>
      <div style={{marginTop: "15px", fontSize: "20px", fontWeight: "bold"}}>
        Groups:
      </div>
      <div style={{width: "90%", display: 'flex', alignItems: 'center', justifyContent: 'space-between', color: "#000"}}>
        <table style={{borderCollapse: "collapse", width: "100%", color: theme ? "white" : "black"}} className="profile-admin-user-table">
          <thead>
            <tr style={{backgroundColor: theme ? "#454545" : "#dddddd"}}>
              <th style={{textAlign: 'center', width: '10%'}}>Group ID</th>
              <th style={{textAlign: 'center',padding: '5px'}}>Group Name</th>
            </tr>
          </thead>
          <tbody>
            {
              user !== null &&
              user.groups.map((group,index) => (
                <tr key={group.group_id} style={{textAlign: 'center',backgroundColor: (index % 2 !== 0) ? "#e8e8e8" : "white"}}>
                  <td style={{textAlign: 'center'}}>{group.group_id}</td>
                  <td style={{textAlign: 'center', padding: '5px'}}>{group.group_name}</td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
      <div style={{marginTop: "15px", fontSize: "20px", fontWeight: "bold"}}>
        Roles:
      </div>
      <div style={{width: "90%", display: 'flex', alignItems: 'center', justifyContent: 'space-between', color: "#000"}}>
        <table style={{borderCollapse: "collapse", width: "100%", color: theme ? "white" : "black"}} className="profile-admin-user-table">
          <thead>
            <tr style={{backgroundColor: theme ? "#454545" : "#dddddd"}}>
              <th style={{textAlign: 'center', width: '10%'}}>Role ID</th>
              <th style={{textAlign: 'center', padding: '5px'}}>Role Name</th>
            </tr>
          </thead>
          <tbody>
            {
              user !== null &&
              user.roles.map((role,index) => (
                <tr key={role.role_id} style={{textAlign: 'center', backgroundColor: (index % 2) !== 0 ? theme ? "#454545" : "#dddddd" : ""}}>
                  <td style={{textAlign: 'center'}}>{role.role_id}</td>
                  <td style={{textAlign: 'center', padding: '5px'}}>{role.role_name}</td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default EditProfile