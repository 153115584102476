import React, { useState, useEffect, Fragment, useContext } from 'react';
import { UserContext } from '../../../App';

//Icons
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import RemoveIcon from '@mui/icons-material/Remove';
import DevicesIcon from '@mui/icons-material/Devices';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
// import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import AddToQueueIcon from '@mui/icons-material/AddToQueue';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const GroupTable = (props) => {
    const { theme, FormatTimestamp } = useContext(UserContext);
    const [focus, setFocus] = useState([]);
    const [hostList, setHostList] = useState([]);
    const [filterName, setFilterName] = useState(false);
    const [filterOS, setFilterOS] = useState(false);

    const selectHandler = (event, device, parent) => {
        props.select(device, parent, event.ctrlKey, event.shiftKey);
    }

    const getCompliance = (device) => {
        let recent_audit = props.auditList.find(x => x.device_id === device.device_id && x.status === "Complete");
        if(recent_audit == null) return 'No Audit'
        if(recent_audit.metadata.Open == null || recent_audit.metadata.Open.cat_1 > 0 || recent_audit.metadata.Open.cat_2 > 0)
            return "No"
        return "Yes"
    }

    useEffect(() => {
        setHostList([...props.hostList]);
    }, [props.hostList])

    const parentCheck = (a,b) => {
        if(a.length === b.length){
            if(a.length === 0){
                return true;
            }
            let status = true;
            for(let j in a){
                if(a[j].group_id !== b[j].group_id){
                    status = false;
                    break;
                }
            }
            return status;
        }
        return false;
    }

    const sortNameHandler = () => {
        let tmp = [...hostList].sort((a,b) => a.group_name.localeCompare(b.group_name));
        setHostList(filterName ? tmp : tmp.reverse());
        setFilterName(!filterName);
    }

    const sortOSHandler = () => {
        let tmp = [...hostList].sort((a,b) => a.os_id > b.os_id ? 1 : b.os_id > a.os_id ? -1 : 0);
        setHostList(filterOS ? tmp : tmp.reverse());
        setFilterOS(!filterOS);
    }

    const focusHandler = (host,parent,e) => {
        e.stopPropagation();
        if(host.children.length === 0 && host.devices.length === 0) return;
        let tmp_focus = [...focus];
        let index = focusCheck(host,parent);
        if(index === -1){
            tmp_focus.push({host: host, parent: parent});
        }else{
            let tmp_splice = [];
            for(let i in focus){
                if(focus[i].parent.length >= (parent.length + 1)){
                    let status = true;
                    for(let j in parent){
                        if(focus[i].parent[j].group_id !== parent[j].group_id){
                            status = false;
                            break
                        }
                    }
                    if(status){
                        if(focus[i].parent[parent.length].group_id === host.group_id)
                            tmp_splice.push(i);
                    }
                }

            }
            for(let i in tmp_splice){
                tmp_focus.splice(tmp_splice[i]-i,1);
            }

            tmp_focus.splice(index-tmp_splice.length,1);
        }
        setFocus(tmp_focus);
    }

    const focusCheck = (host, parent) => {
        let index = -1;
        for(let i in focus){
            if(focus[i].host.group_id === host.group_id){
                if(parentCheck(focus[i].parent, parent)){
                    index = i;
                }
            }
            if(index !== -1)
                break;
        }
        return index;
    }

    const GetDeviceIP = (device) => {
        let index = device.device_vars.findIndex(dv => dv.var_name === "ansible_host");
        if(index === -1) return "n/a";
        return device.device_vars[index].var_value;
    }

    const getRecentAudit = (device) => {
        let recent_audit = props.auditList.find(x => x.device_id === device.device_id && x.status === "Complete");
        if(recent_audit){
            if(recent_audit.time_finished){
                return FormatTimestamp(recent_audit.time_finished);
            }else{
                return "n/a";
            }
        }else{
            return "No Audit"
        }
    }

    const getGroupRoleName = (group_id) => {
        if(props.groupRole.length === 0 || props.groupRole === undefined) return "";
        let index = props.groupRole.findIndex(x => x.group_id === group_id);
        if(index === -1) return "";
        if(props.groupRole[index].group_admin){
            return(
                <span style={{borderRadius: "15px", backgroundColor: "gold", color: "white", padding: "3px 10px", border: '1px solid #fff', fontWeight: "bold", fontSize: "13px"}} title="Admin Role">
                    Admin
                </span>
            )
        }else if(props.groupRole[index].write){
            return(
                <span style={{borderRadius: "15px", backgroundColor: "rgb(117,198,38)", color: "white", padding: "3px 10px", border: '1px solid #fff', fontWeight: "bold", fontSize: "13px"}} title="User Role">
                    User
                </span>
            )
        }else if(props.groupRole[index].read){
            return (
                <span style={{borderRadius: "15px", backgroundColor: "orange", color: "white", padding: "3px 10px", border: '1px solid #fff', fontWeight: "bold", fontSize: "13px"}} title="Read-Only Role">
                    Read-Only
                </span>
            )
        }

        return(
            <span style={{borderRadius: "15px", backgroundColor: "red", color: "white", padding: "3px 10px", border: '1px solid #fff', fontWeight: "bold", fontSize: "13px"}} title="Read-Only Role">
                Error
            </span>
        )
    }

    const getOSName = (os_id) => {
        if(os_id === null) return 'n/a';
        let index = props.OS.findIndex(x => x.id === os_id);
        if(index === -1) return 'n/a';
        return props.OS[index].operating_system;
    }

    const selectCheckHandler = (host, parent) => {
        let index = -1;
        if("device_id" in host){
            index = props.selected.findIndex(x => x.device_id === host.device_id);
        }else{
            index = props.selected.findIndex(x => x.group_id === host.group_id);
        }
        if(index === -1) return false;
        return parentCheck(props.parent[index], parent);
    }

    const addExisitingButtonHandler = (e, host) => {
        e.stopPropagation();
        props.addExisting(host);
    }

    const getRow = (host, parent) => {
        if(host === undefined) return;
        if("device_id" in host){
            return(
            <div key={host.device_id} className="inventory-table-row" style={{gridTemplateColumns: "0px 20px 2fr 1fr 1fr 1fr 1fr", backgroundColor:  selectCheckHandler(host, parent) ? "#9BCFFF" : "", border: "1px solid #d5d5d5"}} onClick={(e) => selectHandler(e, host, parent)}>
                <div style={{width: "15px", height: "45px", marginLeft: "-16px", marginTop: "-28px" ,borderLeft: "2px solid #ababab", borderBottom: "2px solid #ababab", borderBottomLeftRadius: "5px"}}>
                </div>
                <div></div>
                <div className="center-left-container" ><DevicesIcon fontSize='small' style={{paddingRight: "5px"}}/>{host.name}</div>
                <div className="center-container text-center">
                    { getRecentAudit(host) }
                </div>
                <div className="center-container text-center">
                    { props.OS != null && props.OS.length > 0 ? props.OS[props.OS.findIndex(x => x.id === host.os_id)].operating_system : "" }
                </div>
                <div className="center-container text-center" title={GetDeviceIP(host)}>
                    <span style={{overflow: 'hidden', textOverflow: 'ellipsis', width: '120px', display: 'block'}}>{GetDeviceIP(host)}</span>
                </div>
                <div className="center-container text-center">
                    { getCompliance(host) }
                </div>
            </div>  
            )
        }else{
            return(
                <Fragment key={host.group_id}>
                    <div className="inventory-table-row" style={{gridTemplateColumns: parent.length > 0 ? "0px 20px 2fr 1fr 1fr 1fr 1fr" : "15px 2fr 1fr 1fr 1fr 1fr", backgroundColor: selectCheckHandler(host, parent) ? "#9BCFFF" : "", border: parent.length > 0 ? "1px solid #d5d5d5" : "none"}} onClick={(e) => selectHandler(e, host, parent)}>
                        {
                            parent.length > 0 &&
                            <div style={{width: "15px", height: "45px", marginLeft: "-16px", marginTop: "-28px" ,borderLeft: "2px solid #ababab", borderBottom: "2px solid #ababab", borderBottomLeftRadius: "5px"}}>
                            </div>
                        }
                        <div></div>
                        <div className="center-left-container">  
                            {
                                parent.length === 0 &&
                                (
                                host.devices.length > 0 || host.children.length > 0 ?
                                // Dropdown Arrow
                                <ArrowForwardIosIcon style={{transform: focusCheck(host, parent) !== -1 ? "rotate(90deg)" : "", transition: "transform 300ms ease", zIndex: "1", fontSize: "16px", marginRight: "0px", padding: "3px", borderRadius: "5px", border: "1px solid rgba(0,0,0,0)"}} className="inventory-table-group-arrow-button" onClick={(e) => focusHandler(host,parent,e)}/>
                                :
                                // NoDropdown Arrow
                                <RemoveIcon style={{ zIndex: "1", fontSize: "16px", marginRight: "0px", padding: "3px", borderRadius: "5px", border: "1px solid rgba(0,0,0,0)"}} />
                                )
                            }
                            <AutoAwesomeMotionIcon fontSize='small' style={{margin: parent.length > 0 ? "0px 5px 0px 0px" : "0px 5px"}} />
                            {host.group_name}
                            <div title={"Add Device/Group to " + host.group_name} style={{cursor: "pointer"}} onClick={(e) => addExisitingButtonHandler(e, host)}>
                                <AddToQueueIcon style={{width: "16px", marginLeft: "10px"}}/>
                            </div>
                        </div>
                        <div className="center-container text-center"></div>
                        <div className="center-container text-center">
                            {getOSName(host.os_id)}
                        </div>
                        <div className="center-container text-center">
                            {parent.length === 0 ? host.devices.length : ""}
                        </div>
                        <div className="center-container text-center">          
                            {getGroupRoleName(host.group_id)}
                        </div>
                    </div>  
                    {
                        (host.children.length > 0 || host.devices.length > 0) && focusCheck(host,parent) !== -1 ?
                        <div className="inventory-table-animation">
                            <div className="inventory-table-header" style={{backgroundColor: theme ? "#202020" : "#D5D5D5"}}>
                                <div></div>
                                <div className="center-container text-center">
                                    Name
                                </div>
                                <div className="center-container text-center">
                                    Most Recent Audit
                                </div>
                                <div className="center-container text-center">
                                    OS
                                </div>
                                <div className="center-container text-center">
                                    IP
                                </div>
                                <div className="center-container text-center">
                                    Compliance
                                </div>
                            </div>    
                        { 
                        host.children.map((child,index) => (
                            getRow(props.hostList.find(group => group.group_name === child), [...parent, host])
                        ))
                        }
                        {
                        host.devices.map((device,index) => (
                            getRow(props.deviceList[props.deviceList.findIndex(dev => dev.device_id === device.device_id)], [...parent, host])
                        ))
                        }
                        </div>
                        :
                        <></>
                    }
                </Fragment>  
            )
        }
    }

    return (
        <div className="inventory-table" style={{backgroundColor: theme ? "#242424" : "#FFF", border: theme ? "1px solid #000" : "1px solid #d5d5d5", color: theme ? "#fff" : "#000"}}>
                <div className="inventory-table-header" style={{backgroundColor: theme ? "#202020" : "#D5D5D5"}}>
                    <div></div>
                    <div className="center-container" onClick={sortNameHandler} style={{cursor: "pointer"}}>
                        Name
                        <ArrowDropDownIcon style={{transform: !filterName ? "" : "rotate(180deg)"}}/>
                    </div>
                    <div className="center-container"></div>
                    <div className="center-container" onClick={sortOSHandler} style={{cursor: "pointer"}}>
                        OS
                        <ArrowDropDownIcon style={{transform: !filterOS ? "" : "rotate(180deg)"}}/>
                    </div>
                    <div className="center-container">Devices</div>
                    <div className="center-container">Role</div>
                </div>    
                {
                    hostList.map((group,index) => (
                        getRow(group,[])
                    ))
                }
        </div>
    )
}

export default GroupTable;