import React, {useContext, useEffect, useState} from 'react';
import { NavLink } from 'react-router-dom';
import { UserContext } from '../../App';

// Components
import ProgressBar from './ProgressBar';

// Icons
import SettingsIcon from '@mui/icons-material/Settings';
import DevicesIcon from '@mui/icons-material/Devices';
import ComputerIcon from '@mui/icons-material/Computer';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import PendingActionsIcon from '@mui/icons-material/PendingActions';

const Sidebar = (props) => {
  const { user, theme, UserIsAdmin } = useContext(UserContext);
  const [height, setHeight] = useState(window.innerHeight);

  const updateDimensions = () => {
    setHeight(window.innerHeight);
  }

  useEffect(() => {
      window.addEventListener("resize", updateDimensions);
      return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const getDeviceName = () => {
    let device= props.deviceList.find(device => device.device_id === props.inProgressQueue[0].device_id);
    if(device == null) return 'N/A';
    if(device.name != null) return device.name;
    return 'N/A';
  }
  
  const getPlaybookName = () => {
    let playbook = props.playbookList.find(playbook => playbook.id === props.inProgressQueue[0].playbook_id);
    if(playbook == null)  return 'N/A';
    if(playbook.friendly_name != null) return playbook.friendly_name;
    return 'N/A';
  }

  const getFilterRun = (type) => {
    if(props.inProgressQueue == null) return "n/a";
    let filtered = props.inProgressQueue.filter(tmp => tmp.remediation === type);
    return filtered.length;
  }

  var divFixedStyle = {
    position: "fixed", 
    bottom: "10px", 
    left: "0", 
    borderTop: "1px solid #ABABAB", 
    color: "#FFF", 
    width: "240px"
  }

  var divStyle = {
    marginBottom: "20px",
    borderTop: "1px solid #ABABAB", 
    color: "#FFF", 
    width: "240px"
  }

  return (
    <div className="sidebar-container" style={{backgroundColor: theme ? "#242424" : ""}}>
      <div className="sidebar-wrapper">
        <h3>Dashboard</h3>
        <NavLink to="/" className={({ isActive }) => isActive ? 'sidebar-navlink-active' : 'sidebar-navlink'}><DashboardIcon fontSize='small' style={{paddingRight: "5px"}} title="Home Page"/>Home</NavLink>
      </div>
      <div className="sidebar-wrapper">
          <h3>Inventory</h3>
          <NavLink to="/groups" className={({ isActive }) => isActive ? 'sidebar-navlink-active' : 'sidebar-navlink'}><DevicesIcon fontSize='small' style={{paddingRight: "5px"}}/>Groups</NavLink>
          <NavLink to="/devices" className={({ isActive }) => isActive ? 'sidebar-navlink-active' : 'sidebar-navlink'}><ComputerIcon fontSize='small' style={{paddingRight: "5px"}}/>Devices</NavLink>
          <NavLink to="/audits" className={({ isActive }) => isActive ? 'sidebar-navlink-active' : 'sidebar-navlink'}><AssignmentIcon fontSize='small' style={{paddingRight: "5px"}}/>Audits</NavLink>
          <NavLink to="/remediations" className={({ isActive }) => isActive ? 'sidebar-navlink-active' : 'sidebar-navlink'}><AddModeratorIcon fontSize='small' style={{paddingRight: "5px"}}/>Remediations</NavLink>
          <NavLink to="/stigs" className={({ isActive }) => isActive ? 'sidebar-navlink-active' : 'sidebar-navlink'}><FormatListBulletedIcon fontSize='small' style={{paddingRight: "5px"}}/>STIGs</NavLink>
          <NavLink to="/scheduled-tasks" className={({ isActive }) => isActive ? 'sidebar-navlink-active' : 'sidebar-navlink'}><PendingActionsIcon fontSize='small' style={{paddingRight: "5px"}}/>Scheduled Tasks</NavLink>
      </div>
      {
        UserIsAdmin() &&
        <div className="sidebar-wrapper">
            <h3>Settings</h3>
            <NavLink to="/settings" className={({ isActive }) => isActive ? 'sidebar-navlink-active' : 'sidebar-navlink'}><SettingsIcon fontSize='small' style={{paddingRight: "5px"}}/>Configuration</NavLink>
            <NavLink to="/profile/admin" className={({ isActive }) => isActive ? 'sidebar-navlink-active' : 'sidebar-navlink'}> <AdminPanelSettingsIcon fontSize='small' style={{paddingRight: "5px"}}/>Admin</NavLink>
        </div>
      }
      {/* <div className="sidebar-wrapper">
          <h3>Resources</h3>
          <NavLink to="/documentation" className={({ isActive }) => isActive ? 'sidebar-navlink-active' : 'sidebar-navlink'}><ArticleIcon fontSize='small' style={{paddingRight: "5px"}}/>Docs</NavLink>
      </div> */}
      <div className="sidebar-wrapper" style={height > 735 ? divFixedStyle : divStyle}>
        <h3 style={{marginTop: "16px", color: "#ABABAB"}}>Result Progress:</h3>
        {(props.inProgressQueue.length > 0 && props.playbookList.length > 0 && props.deviceList.length > 0) && (
          <div>
            <span>{props.inProgressQueue[0].remediation === true ? "Remediating" : "Auditing"}</span>
            <span>Device: {getDeviceName()}</span>
            <span>STIG: {getPlaybookName()}</span>
          </div>
        )}
        <div style={{width: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
          <ProgressBar width="85%" total={props.inProgressQueue.length} color={"#093471"}/>
        </div>
        <span style={{width: "100%"}}>Auditing: {getFilterRun(false)}</span>
        <span style={{width: "100%"}}>Remediation: {getFilterRun(true)}</span>
      </div>
    </div>
  )
}

export default Sidebar;