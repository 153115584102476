import React, { useEffect, useState, useContext } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend,CartesianGrid, ResponsiveContainer } from 'recharts';
import { UserContext } from '../../../App';

const HistoryWidget = (props) => {
    const { theme } = useContext(UserContext);
    const [data, setData] = useState([]);

    useEffect(() => {
        function formatDate(date){
            const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul","Aug", "Sep", "Oct", "Nov", "Dec"];
            let dd = date.getDate();
            let mm = date.getMonth()+1;
            let yyyy = date.getFullYear();
            // if(dd<10) {dd='0'+dd}
            date = month[mm-1] +' ' + dd + ' ' + yyyy;
            return date
         }

        const Last7Days = () => {
            let result = [];
            for (let i=0; i<7; i++) {
                let d = new Date();
                d.setDate(d.getDate() - i);
                result.push( formatDate(d) )
            }

            return(result.reverse());
        }


        if(props.auditList === null || props.remediateList === null) return;
        props.auditList.sort((a,b) => a.id > b.id ? -1 : 1);
        let tmp = [];
        const dates = Last7Days();
        for(let i in dates){
            let filtered = props.auditList.filter(function(audit){
                let audit_date = audit.friendly_timestamp != undefined ? audit.friendly_timestamp.split(" ") : null;
                if(audit_date == null) return false;
                if(audit_date[2] === ""){
                    audit_date = audit_date[1] + " " + audit_date[3] + " " + audit_date[5];
                }else{
                    audit_date = audit_date[1] + " " + audit_date[2] + " " + audit_date[4];
                }
                return audit_date === dates[i]
            });
            let filtered_rem = props.remediateList.filter(function(rem){
                if(rem.status !== "in-progress"){
                    let rem_date = rem.friendly_timestamp != undefined ? rem.friendly_timestamp.split(" ") : null;
                    if(rem_date == null) return false;
                    if(rem_date[2] === ""){
                        rem_date = rem_date[1] + " " + rem_date[3] + " " + rem_date[5];
                    }else{
                        rem_date = rem_date[1] + " " + rem_date[2] + " " + rem_date[4];
                    }
                    return rem_date === dates[i]
                }else{
                    return false;
                }
            });
            //filter for remediate here
            let date_tmp = dates[i].split(" ");
            date_tmp = date_tmp[0] + " " + date_tmp[1];

            tmp.push({"Dates": date_tmp, "Remediations": filtered_rem.length, "Audits": filtered.length});
        }
        setData(tmp);
    },[props.auditList,props.remediateList])

    return (
        <div className="widget-container" style={{width: "100%", backgroundColor: theme ? "#242424" : "#FFF", border: theme ? "1px solid #000" : "1px solid #EBEBEB", color: theme ? "#fff" : "#000", padding: "5px", borderRadius: "10px", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", height: "300px"}}>
            <h3 className="widget-header">
            <span>Recent Activity</span>
            </h3>
            <div className="widget-content" style={{justifyContent:"center", alignItems:"center", display: "flex"}}>
                <div style={{width: "100%", marginLeft: "-40px"}}>
                <ResponsiveContainer width="100%" height={250}>
                    <BarChart data={data} onClick={(e) => props.dateAuditSelector(e.activeLabel)}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="Dates" />
                        <YAxis/>
                        <Tooltip/>
                        <Legend />
                        <Bar dataKey="Remediations" fill="#EE2E24"/>
                        <Bar dataKey="Audits" fill="#093471"/>
                    </BarChart>
                </ResponsiveContainer>
                </div>
            </div>
        </div>
    )
}

export default HistoryWidget;