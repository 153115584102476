import React from 'react'
import DashboardPic from "./../../../styles/imgs/DashboardPic.png";
import ComplianceTablePic from "./../../../styles/imgs/ComplianceTablePic.png";

import FastForwardIcon from '@mui/icons-material/FastForward';

const DashboardDoc = (props) => {
  return (
    <div className="documentation-container" style={{display: props.display === true ? "flex" : "none", flexDirection: "column", justifyContent:"center" }}>
        <p style={{width: "90%"}}>The Dashboard is the first thing you will see when you log into DuroSuite's GUI. It showcases different widgets that users would like to see, ranging from: inventory's total, the device's OS count, and recent activities. The intended purpose of the GUI for DuroSuite is to put data visualization on the frontend. We want to showcase data that will be helpful to all users. We want to keep track of data from devices for audits and remediation. With this data, we can infer certain criteria like a device's compliance with your standards.</p>
        <div style={{display: "flex", width: "90%", padding: "20px 0px"}}>
            <div className='documentation-button' style={{letterSpacing: "1px", cursor: "pointer"}} onClick={() => props.pageClick("Home")}>
                <FastForwardIcon style={{marginRight: "10px", color: "#FF0000"}} /> Quick Move
            </div>
        </div>
        <div style={{border: "2px solid #A4A4A4",width: "90%"}}>
            <img alt="Dashboard Screenshot" src={DashboardPic} style={{width: "100%", height: "100%"}} />
        </div>
        <h3 className="documentation-subsection-title" id="DashboardRecentActivity" style={{color: props.theme ? "#FFF" : "#2B2B2B"}}>
            Recent Activity / Device OS
        </h3>
        <p>
            The Recent Activitiy widget allows users to view their total auditing and remediation for the last seven days. We implemented this widget to let users know how active they are. X-axis titles are based on the width of the browser. Selecting a date will result in moving to the results page with all the results for that current date. To the left of recent activity is the "Device OS" widget. The Device OS widget is a visual implementation showing the total device count per OS. This allows the user to manage and keep track of how many devices with that OS they have.
        </p>
        <h3 className="documentation-subsection-title" id="DashboardComplianceTable" style={{color: props.theme ? "#FFF" : "#2B2B2B"}}>
            Compliance Table
        </h3>
        <p>The Compliance Table keeps track of devices and sees if they are compliant with your standards. We select the latest audit and run through a function that detects whether the x rule is followed. If there is no audit for said device, it's automatically not compliant. There are no CAT I or CAT II openings. We go through all the vulnerabilities for that checklist to check if each vulnerability is open or not. In future features, we would like to add more rules and options so that users will be able to select or change rules freely.</p>
        <div style={{border: "2px solid #A4A4A4",width: "90%"}}>
            <img alt="Compliance Table Widget" src={ComplianceTablePic} style={{width: "100%", height: "100%"}} />
        </div>
    </div>
  )
}

export default DashboardDoc;