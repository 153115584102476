import React, { useState, Fragment, useContext, useRef } from 'react'
import { UserContext } from '../../../App';

// Icons
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { CircularProgress } from '@mui/material';

const GroupIngestPopup = (props) => {
  const { user, FetchCatch, UserIsAdmin } = useContext(UserContext);

  const pageOptions = ["Ingest Groups/Devices"]
  const [pageType, setPageType] = useState(props.page == null ? pageOptions[0] : props.page);

  const [ingestTemplateDownloading, setIngestTemplateDownloading] = useState(false);
  const [osSelectDL, setOSSelectDL] = useState(null);
  const [osSelectUL, setOSSelectUL] = useState(null);
  const [ingestUploading, setIngestUploading] = useState(false);

  const ingestRef = useRef(null);

  const downloadIngestTemplate = async () => {
    if (osSelectDL === null) {
      alert("Operating System must be selected.");
      return;
    }

    await API_download_template(osSelectDL.id);
    setOSSelectDL(null);
  }

  const uploadIngest = () => {
    ingestRef.current.click();
  }

  const uploadIngestRef = async (e) => {
    if (osSelectUL === null) {
      alert("Operating System must be selected.");
      return;
    }
    if (e.target.files.length === 1) {
      var form_data = new FormData();
      form_data.append('devices_file', e.target.files[0]);
      let data = await API_upload_ingest(osSelectUL.id, form_data);
      if (data) {
        props.refreshGroups();
        props.close();
      }
    }
  }

  const API_download_template = async (os_id) => {
    const download_template_api = "/api/devices/batch-upload?os_id=" + os_id;
    let filename = "";
    let data = await fetch(download_template_api, {
      method: 'GET',
      headers: { 'Authorization': `Bearer ${user.token}` },
    }).then(res => {
      if (res.ok) {
        const header = res.headers.get('content-disposition');
        if (header === null || header === undefined) {
          filename = prompt("Please enter a file name");
        } else {
          const parts = header.split(';');
          filename = parts[1].split('=')[1];
          filename = filename.substring(1, filename.length - 1);
          if (filename == null) {
            filename = prompt("Please enter a file name");
          }
          if (filename == null) return null;
          if (filename.slice(-4) !== ".csv") {
            filename += ".csv";
          }
        }
        return res.blob();
      } else {
        if (res.status == 404) {
          console.log('File Not Found.');
          return null;
        }
        throw res;
      }
    })
      .then(data => {
        if (data == null) return;
        let a = document.createElement('a');
        a.href = window.URL.createObjectURL(data);
        a.download = filename;
        a.click();
        a.remove();
      }).catch(async error => {
        return await FetchCatch(error, API_download_template, [os_id]);
      })

    return data;
  }

  const API_upload_ingest = async (os_id, file) => {
    const upload_ingest_api = "/api/devices/batch-upload?os_id=" + os_id;
    let data = await fetch(upload_ingest_api, {
      method: 'POST',
      headers: { 'Authorization': `Bearer ${user.token}` },
      body: file
    }).then(res => {
      if(res.status === 200) {
        window.alert('Success! File ingested.')
      }
      // if form was filled incorrectly resulting in failure to add record
      if (res.status === 206) {
        res.text().finally().then(text => window.alert(text))
      }
      if (res.ok) {
        return true;
      }
      throw res;
    }).then(data => {
      return data;
    }).catch(async error => {
      return await FetchCatch(error, API_upload_ingest, [os_id]);
    })

    return data;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {
          pageOptions.map((option, index) => (
            <Fragment key={option}>
              <div className={option === pageType ? "PopupPageTypeButton-Selected" : "PopupPageTypeButton"} onClick={() => setPageType(option)}>
                {option}
              </div>
              <div style={{ borderRight: "1px solid black", height: "20px", margin: "0px 5px" }}>
              </div>
            </Fragment>
          ))
        }
      </div>
      <hr style={{ marginTop: "5px" }} />
      {
        (pageType === "Ingest Groups/Devices" && UserIsAdmin()) &&
        <>
          <div className="inventory-content-header" style={{ margin: "0px" }}>
            <div style={{ display: 'flex', flexDirection: "column", marginTop: "20px" }}>
              <h2>Download Ingest Template File</h2>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <select className="widget-select" style={{ width: "auto" }} value={osSelectDL != null ? osSelectDL.id : -1} onChange={(e) => setOSSelectDL(props.OSList?.find(x => x.id == e.target.value))}>
                  <option value={-1}>Select an OS Type</option>
                  {
                    props.OSList?.map((os, index) => (
                      <option key={os.id} value={os.id}>{os.operating_system}</option>
                    ))
                  }
                </select>
                <button className={osSelectDL ? "widget-button" : "widget-button-disable"} disabled={!osSelectDL} onClick={downloadIngestTemplate} style={{ display: 'flex', alignItems: 'center', justifyContent: "center", marginTop: "10px" }} title="Download Ingest Template File">
                  {ingestTemplateDownloading ? <CircularProgress style={{ color: "white", width: "16px", height: "16px" }} /> : <><DownloadIcon />Download Template</>}
                </button>
              </div>
              <h2 style={{ marginTop: "10px" }}>Upload Ingest File</h2>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <select className="widget-select" style={{ width: "auto" }} value={osSelectUL != null ? osSelectUL.id : -1} onChange={(e) => setOSSelectUL(props.OSList.find(x => x.id == e.target.value))}>
                  <option value={-1}>Select an OS Type</option>
                  {
                    props.OSList?.map((os, index) => (
                      <option key={os.id} value={os.id}>{os.operating_system}</option>
                    ))
                  }
                </select>
                <button className={osSelectUL ? "widget-button" : "widget-button-disable"} disabled={!osSelectUL} onClick={uploadIngest} style={{ display: 'flex', alignItems: 'center', justifyContent: "center", marginTop: "10px" }} title="Upload Ingest File">
                  {ingestTemplateDownloading ? <CircularProgress style={{ color: "white", width: "16px", height: "16px" }} /> : <><UploadIcon />Upload Ingest</>}
                </button>
              </div>
            </div>
            <input ref={ingestRef} onChange={uploadIngestRef} type="file" id="input-file-upload" multiple={false} accept=".csv" />

          </div>
        </>
      }
    </div>
  )
}

export default GroupIngestPopup