import React, {useEffect, useState} from 'react';

// Components
import DeviceOSWidget from './DeviceOSWidget';
import InventoryBar from './InventoryBar';
import AuditWidget from '../audits/AuditWidget';
import HistoryWidget from './HistoryWidget';
import ComplianceTableWidget from './ComplianceTableWidget';

//props.inventory, props.audits
const Home = (props) => {
    const [latestAudit, setLatestAudit] = useState(null);

    useEffect(() => {
        if(props.audits != null && props.devices != null && props.playbooks != null){
            let tmp = props.audits.find(x => x.status == "Complete");
            if(tmp == null){
                setLatestAudit(null)
            }else{
                setLatestAudit({audit: tmp, device: props.devices.find(x => x.device_id == tmp.device_id), playbook: props.playbooks.find(x => x.id == tmp.playbook_id)});
            }
        }
    },[props.audits, props.devices, props.playbooks])

    return (
        <div className="inventory-content" style={{height: "calc(100vh - 40px)", overflowY: "auto"}}>
            <div className="inventory-content-container" >
                <div className="inventory-content-wrapper" style={{marginTop: "10px", marginLeft: "25px"}}>
                    <InventoryBar devices={props.devices} audits={props.auditData} remediate={props.remediateData} host={props.hostList} pageClick={props.pageClick}/>
                    <div style={{display: "grid", gridTemplateColumns: "35% 65%", paddingTop: "16px", paddingRight: "25px"}}>
                        <DeviceOSWidget devices={props.devices} OS={props.OS} />
                        <HistoryWidget auditList={props.audits} remediateList={props.remediations} dateAuditSelector={props.dateAuditSelector}/>
                    </div>
                    <div style={{display: "grid", gridTemplate: "1fr", paddingTop: "16px", paddingRight: "25px"}}>
                        <ComplianceTableWidget groupRole={props.groupRole} deviceList={props.devices} auditList={props.audits}/>
                    </div>
                </div>
            </div>
            <div style={{marginLeft: "16px"}}>
                <AuditWidget audit={latestAudit} moreDetail={() => props.auditSelect([latestAudit])} mostrecent={true} title="Most Recent Audit" homePage={true}/>
            </div>
        </div>
    )
}

export default Home;