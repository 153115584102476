import React from 'react'
import AuditRun from "./../../../styles/imgs/AuditRun.png";
import DeviceSelectPic from "./../../../styles/imgs/DeviceSelectPic.png";

import FastForwardIcon from '@mui/icons-material/FastForward';


const AuditingDoc = (props) => {
  return (
    <div className="documentation-container" style={{display: props.display === true ? "flex" : "none", flexDirection: "column", justifyContent:"center" }}>
        <p>
            DuroSuite allows automative auditing of certain STIGs. Our auditing takes approximately couple of minutes to half an hour depending on the number of vulnerability and how difficult some of the vulnerability are to check. DuroSuite goals is to automate this process for users and saving them money and time. After an audit is run, you are able to export checklist for your use. To run an audit:
        </p>    
        <div style={{display: "flex", width: "90%", padding: "20px 0px"}}>
            <div className='documentation-button' style={{letterSpacing: "1px", cursor: "pointer"}} onClick={() => props.pageClick("audits")}>
                <FastForwardIcon style={{marginRight: "10px", color: "#FF0000"}} /> Quick Move
            </div>
        </div>
        <ul className="documentation-unordered-list">
            <li><p>Select a Device:</p></li>
            <li style={{marginLeft: "-25px", listStyle:"none"}}>
                <div style={{border: "2px solid #A4A4A4",width: "90%"}}>
                    <img alt="Device Select" src={DeviceSelectPic} style={{width: "100%", height: "100%"}} />
                </div>
            </li>
            <li>
                <p>Click the Audit button within the Device's Widget Selector top right.</p>
            </li>
            <li><p>Select a STIG from the dropdown. (For groups, you can select a OS depending on that group's device and checkmark what STIG you want to run)</p></li>
            <li style={{marginLeft: "-25px", listStyle:"none"}}>
                <div style={{border: "2px solid #A4A4A4",width: "30%", display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <img alt="Audits Widget" src={AuditRun} style={{width: "100%", height: "50%"}} />
                </div>
            </li>
            <li><p>Click "Run/View" or "Run" button. (Run/View will change from Device page to Result page)</p></li>
        </ul>
        <div>
        </div>
    </div>
  )
}

export default AuditingDoc;