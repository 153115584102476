import React from 'react'

export const InfoGraphHeader = (props) => {
  return (
    <table className="info-graph-table">
        <thead>
        <tr>
            <th></th>
            <th style={{width:"30px"}}></th>
            <th></th>
            <th style={{width:"30px"}}></th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td style={{paddingLeft: "9px"}}>Open: </td>
            <td>{props.statusData[2].total}</td>
            <td>Not Reviewed:</td>
            <td>{props.statusData[1].total}</td>
        </tr>
        <tr>
            <td style={{paddingLeft: "9px"}}>Not A Finding: </td>
            <td>{props.statusData[0].total}</td>
            <td>Not Applicable:</td>
            <td>{props.statusData[3].total}</td>
        </tr>
        </tbody>
    </table>
  )
}
