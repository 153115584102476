import React, {useState, useEffect, useContext} from 'react';
import { UserContext } from '../../../App';

// Components
import AuditWidget from "./AuditWidget";

// Icons
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AssignmentIcon from '@mui/icons-material/Assignment';

const AuditCompare = (props) => {
    const { user, FetchCatch, theme, FormatTimestamp} = useContext(UserContext);
    const [auditVuln, setAuditVuln] = useState([]);
    const [selectVuln, setSelectVuln] = useState([]);
    const [playbook, setPlaybook] = useState(null);

    useEffect(() => {
        if(props.audit != null && props.audit.audit != null && props.select != null && props.select.audit != null){
           getVuln();
           setPlaybook(props.playbookList.find(x => x.id == props.audit.audit.playbook_id));
        }
    },[]);

    const getVuln = async () => {
        const url_vuln = "/api/vulnerabilities?audit_id=" + props.audit.audit.id;
        await fetch(url_vuln ,{
            headers:{
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${user.token}`
            }
        })
        .then(res=>{
            if(res.ok) return res.json();
            throw res;
        })
        .then(data =>{
            setAuditVuln(data);
        }).catch(async error => {
            return await FetchCatch(error, getVuln, []);
        });

        const url_vuln2 = "/api/vulnerabilities?audit_id=" + props.select.audit.id;
        await fetch(url_vuln2 ,{
            headers:{
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${user.token}`
            }
        })
        .then(res=>{
            if(res.ok) return res.json();
            throw res;
        })
        .then(data =>{
            setSelectVuln(data);
        }).catch(async error => {
            return await FetchCatch(error, getVuln, []);
        });
    }

    const moreDetail = () => {
        props.viewinViewer([props.audit.audit, props.select.audit]);
    }

    return (
        <>
        <div className="inventory-content-container" style={{alignItems: "stretch", color: theme ? "#FFF" : "#000", marginLeft: "16px"}}>
            <div className="inventory-content-header" style={{margin: "0px", display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: "row"}}>
                <div>
                    <h2>{playbook == null ? "" : playbook.friendly_name}</h2>
                    <div onClick={() => props.undoCompare()} style={{display: "flex", color: "#0088FF", cursor:"pointer", alignItems:'center'}}><ArrowBackIcon sx={{width: "16px"}} />Go Back</div>
                    <div onClick={() => moreDetail()} style={{display: "flex", color: "#0088FF", cursor:"pointer", alignItems:'center'}}><ArrowBackIcon sx={{width: "16px"}} />More Details</div>
                </div>
                <div style={{border: "1px solid lightgray", borderRadius: "10px", padding: "10px"}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <AssignmentIcon />
                        Audit #1: {FormatTimestamp(props.audit.audit.time_started)}
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <AssignmentIcon />
                        Audit #2: {FormatTimestamp(props.select.audit.time_started)}
                    </div>
                </div>
            </div>
            <div className="inventory-content-wrapper">
                {
                    auditVuln.length > 0 && selectVuln.length > 0 &&
                    <VulnerabilityTable vuln={auditVuln} vuln2={selectVuln} theme={theme}/>
                }
            </div>
        </div>
        <div className="inventory-content-container" style={{padding: "0px 20px", height: "calc(100vh - 40px)", overflowY: "auto", overflowX:"hidden"}}>
            <AuditWidget audit={props.audit} vuln={auditVuln} moreDetail={() => props.auditSelect(props.audit)} mostrecent={false}  compare={true} title={"Audit #1: " + FormatTimestamp(props.audit.audit.time_started)}/>
            <div style={{paddingTop: "10px"}}></div>
            <AuditWidget audit={props.select} vuln={selectVuln} moreDetail={() => props.auditSelect(props.select)} mostrecent={false} compare={true} title={"Audit #2: " + FormatTimestamp(props.select.audit.time_started)}/>
        </div>
        </>
    )
}


const VulnerabilityTable = (props) => {
    const [vuln, setVuln] = useState([]);
    const [vulnSelect, setVulnSelect] = useState(null);
    
    useEffect(() => {
        let tmp = [...props.vuln];
        for(let i in tmp){
            let index = props.vuln2.findIndex(x => x.vuln_num === tmp[i].vuln_num);
            tmp[i].status2 = props.vuln2[index].status;
        }
        tmp.sort(function(a,b){
            if(a.status !== a.status2 && b.status === b.status2){
                return -1;
            }else if(a.status === a.status2 && b.status !== b.status2){
                return 1;
            }else{
                return a.vuln_num > b.vuln_num ? 1 : -1;
            }
        });
        setVuln(tmp);
    },[])

    const getSeverity = (type) => {
        if(type === "high") return "CAT I";
        else if (type === "medium") return "CAT II";
        else return "CAT III";
    }

    const getStatusColor = (status) =>{
        if(status === 'NotAFinding') return 'rgba(0,255,0,1)'
        else if(status === 'Open') return 'rgba(255,0,0,1)'
        else if(status === 'Not_Applicable') return 'NA'
        else return 'rgb(200,200,0,1)'
      }

    const getStatus = (status) => {
        if(status === 'NotAFinding') return 'NF'
        else if(status === 'Open') return 'Open'
        else if(status === 'Not_Applicable') return 'NA'
        else return 'NR'
    }

    const checkDiff = (vuln_num) => {
        let index = props.vuln.findIndex(x => x.vuln_num === vuln_num);
        let index2 = props.vuln2.findIndex(x => x.vuln_num === vuln_num);
        return(props.vuln[index].status === props.vuln2[index2].status);
    }

    const onClickVuln = (vuln) => {
        if(vulnSelect !== null && vuln.vuln_num === vulnSelect.vuln_num) setVulnSelect(null);
        else setVulnSelect(vuln);
    }

    const getDescripiton = () => {
            if(vulnSelect === null){
                return(
                    <div style={{width: "100%", height: "100%",display: "flex", justifyContent: "center", alignItems:"center"}}>
                        <h4>Select a Vulnerability</h4>
                    </div>
                )
            }else{
                return(
                        <>
                            <div style={{paddingBottom:"15px"}}>
                                <h4>Rule Title: </h4> <p>{vulnSelect.rule_title}</p>
                            </div>
                            <div style={{paddingBottom:"15px"}}>
                                <h4>Discussion: </h4>
                                <p>
                                    {
                                        vulnSelect.vuln_discuss != null &&
                                        vulnSelect.vuln_discuss.split("\n").map(function(item,idx){
                                            return (<span key={idx}>{item}<br/></span>)
                                        })
                                    }
                                </p>
                            </div>
                            <div style={{paddingBottom:"15px"}}>
                                <h4>How to Check: </h4>
                                <p>
                                    {
                                        vulnSelect.check_content != null &&
                                        vulnSelect.check_content.split("\n").map(function(item,idx){
                                            return (<span key={idx}>{item}<br/></span>)
                                        })
                                    }
                                </p>
                            </div>
                            <div style={{paddingBottom:"15px"}}>
                                <h4>How to Fix: </h4>
                                <p>
                                    {
                                        vulnSelect.fix_text != null &&
                                        vulnSelect.fix_text.split("\n").map(function(item,idx){
                                            return (<span key={idx}>{item}<br/></span>)
                                        })
                                    }
                                </p>
                            </div>
                            <div style={{paddingBottom:"15px"}}>
                                <h4>Finding Details: </h4>
                                <p>
                                    {
                                        vulnSelect.finding_details != null &&
                                        vulnSelect.finding_details.split("\n").map(function(item,idx){
                                            return (<span key={idx}>{item}<br/></span>)
                                        })
                                    }
                                </p>
                            </div>
                            <div>
                                <h4>Comments: </h4>
                                <p>
                                    {
                                        vulnSelect.comments != null &&
                                        vulnSelect.comments.split("\n").map(function(item,idx){
                                            return (<span key={idx}>{item}<br/></span>)
                                        })
                                    }
                                </p>
                            </div>
                        </>
                )
            }
    }

    return(
        <div style={{height: "calc(100vh - 142px)", display: "grid", gridTemplateColumns: "30% 70%", border: !props.theme ? "1px solid #d5d5d5" : "1px solid #fff", borderRadius: "5px", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"}}>
        <div style={{overflow: "auto", }}>
        <table style={{borderCollapse: "collapse", width: "100%"}}>
            <thead>
                <tr style={{border: "1px solid #d5d5d5", backgroundColor: !props.theme && "#d5d5d5", color: props.theme ? "#fff" : "#000"}}>
                    <th style={{padding: "10px 5px"}}>Severity</th>
                    <th>Vul. ID</th>
                    <th>Audit #1</th>
                    <th>Audit #2</th>
                </tr>
            </thead>
            <tbody>
                {
                    vuln.map((vul, index) => (
                        <tr key={vul.vuln_num} style={{textAlign: "center",backgroundColor: vulnSelect !== null && vul.vuln_num === vulnSelect.vuln_num ? "#9BCFFF" : !checkDiff(vul.vuln_num) ? "rgba(221, 70, 43, 0.6)" : "", cursor: "pointer"}} onClick={() => onClickVuln(vul)}>
                            <td style={{border: "1px solid #d5d5d5", padding: "5px"}}>{getSeverity(vul.severity)}</td>
                            <td style={{border: "1px solid #d5d5d5", }}>{vul.vuln_num}</td>
                            <td style={{border: "1px solid #d5d5d5", color: getStatusColor(vul.status)}}>{getStatus(vul.status)}</td>
                            <td style={{border: "1px solid #d5d5d5", color: getStatusColor(vul.status2)}}>{getStatus(vul.status2)}</td>
                        </tr>
                    ))
                }
            </tbody>
        </table>
        </div>
        <div className="vuln-description-content-wrapper" style={{overflow: "auto", padding: "10px", margin: "0px", height: "97%"}}>
            { getDescripiton() }
        </div>
        </div>
    )
}

export default AuditCompare;