import React, { useState, Fragment, useContext, useEffect }from 'react'
import { UserContext } from '../../../App';
import { var_password_check } from "../../Helper";

import InfoIcon from '@mui/icons-material/Info';

const GroupPopup = (props) => {
  const { user, FetchCatch, UserIsAdmin } = useContext(UserContext);
  
  const pageOptions = ['Group Creation', 'Device Creation', 'Add Existing Device/Group', 'Template Creation']
  const [pageType, setPageType] = useState(props.page == null ? pageOptions[0] : props.page);


  // Creation
  const [newName, setNewName] = useState("");
  const [newOS, setNewOS] = useState(null);
  const [newParent, setNewParent] = useState(null);
  const [newOSConnType, setNewOSConnType] = useState(null);
  const [OSConnTypeList, setOSConnTypeList] = useState([]);
  const [newConnectionVariables, setNewConnectionVariables] = useState(null);
  const [unchangedNewConnectionVariables, setUnchangedNewConnectionVariables] = useState(null);
  const [newSTIGVariables, setNewSTIGVariables] = useState(null);
  const [unchangedNewSTIGVariables, setUnchangedNewSTIGVariables] = useState(null);

  // Existing
  const [copyParent, setCopyParent] = useState(null);
  const [copyType, setCopyType] = useState('Device');
  const [copyHost, setCopyHost] = useState(null);

  useEffect(() => { 
    reset();
    if(pageType === 'Add Existing Device/Group'){
      setCopyHost(null);
      setCopyType('Device');
      if(props.select != null && props.select.length > 0){
        setCopyParent(props.select[0]);
      }else{
        setCopyParent(null);
      }
    }else if(pageType === 'Device Creation'){
      if(props.select != null && props.select.length > 0){
        newParentHandler(props.select[0].group_id);
      }
    }
  }, [pageType])

  useEffect(() => {
    setCopyHost(null);
    setCopyParent(null);
  }, [copyType])

  useEffect(() => {
    setCopyHost(null);
  }, [copyParent])

  const reset = () => {
    setNewName("");
    setNewOS(null);
    setNewParent(null);
    setNewConnectionVariables(null);
    setNewSTIGVariables(null);
    setUnchangedNewConnectionVariables(null);
    setUnchangedNewSTIGVariables(null);
    setOSConnTypeList([]);
    setNewOSConnType(null);
  }

  const newParentHandler = async (group_id) => {
    let group = props.groupList.find(x => x.group_id === group_id);
    setNewParent(group);
    
    if(group === null){
      newOSHandler(-1);
    }else{
      newOSHandler(group.os_id);
    }
  }
  
  const newOSHandler = async (os_id) => {
    let os = props.OSList.find(x => x.id === os_id);
    setNewOS(os);
    setNewOSConnType(null);
    if(os === null){
      setNewConnectionVariables(null);
      setNewSTIGVariables(null)
    }else{
      if(pageType === "Group Creation"){
        let tmp = await API_get_connection_type(os_id);
        setOSConnTypeList(tmp == null ? [] : tmp);
        setNewConnectionVariables([]);
        setUnchangedNewConnectionVariables([]);
      }

      let stig_vars = await API_get_stig_vars(os_id);
      if(stig_vars){
          if(pageType === "Device Creation"){
              //device
              stig_vars.devices.sort((a,b) => a.var_name > b.var_name ? 1 : -1);
              setNewSTIGVariables(stig_vars.devices);
              setUnchangedNewSTIGVariables(JSON.parse(JSON.stringify(stig_vars.devices)));
          }else{
              //group
              stig_vars.groups.sort((a,b) => a.var_name > b.var_name ? 1 : -1);
              setNewSTIGVariables(stig_vars.groups);
              setUnchangedNewSTIGVariables(JSON.parse(JSON.stringify(stig_vars.groups)));
          }
      }

    }
  }

  const OSConnTypeHandler = async (os_conn_type_id) => {
    let os_conn_type = OSConnTypeList.find(x => x.id === os_conn_type_id);
    setNewOSConnType(os_conn_type);
    if(newOS != null && os_conn_type != null){
      let connection_vars = await API_get_connection_vars_by_type(newOS.id, os_conn_type.id);
      if(connection_vars){
          if(Array.isArray(connection_vars)){
              setNewConnectionVariables(connection_vars);
              setUnchangedNewConnectionVariables(JSON.parse(JSON.stringify(connection_vars)));
          }else{
              setNewConnectionVariables([]);
              setUnchangedNewConnectionVariables([]);
          }
      }
    }
    if(os_conn_type == null){
      setNewConnectionVariables([]);
      setUnchangedNewConnectionVariables([]);
    }
}

  const API_get_stig_vars = async (os_id) => {
    const url_get_default = "/api/operating-systems/defaults/" + os_id;
    let data = await fetch(url_get_default,{
        method: 'GET',
        headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${user.token}` },
    })
    .then(res=>{
        if(res.ok) return res.json();
        throw res;
    })
    .then(data =>{
        return data;
    }).catch(async error => {
        return await FetchCatch(error, API_get_stig_vars, [os_id]);
    });
    return data;
  }

  const API_get_connection_vars = async (os_id) => {
    const url_get_connection = "/api/operating-systems/connection-vars/" + os_id;
    let data = await fetch(url_get_connection,{
        method: 'GET',
        headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${user.token}` },
    })
    .then(res=>{
        if(res.ok) return res.json();
        throw res;
    })
    .then(data =>{
        return data;
    }).catch(async error => {
        return await FetchCatch(error, API_get_connection_vars, [os_id]);
    });

    return data;
  }

  const API_get_connection_vars_by_type = async (os_id, conn_type) => {
    const url_get_connection = "/api/operating-systems/connection-vars/" + os_id + "/" + conn_type;
    let data = await fetch(url_get_connection,{
        method: 'GET',
        headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${user.token}` },
    })
    .then(res=>{
        if(res.ok) return res.json();
        throw res;
    })
    .then(data =>{
        return data;
    }).catch(async error => {
        return await FetchCatch(error, API_get_connection_vars_by_type, [os_id, conn_type]);
    });

    return data;
  }

  const API_get_connection_type = async (os_id) => {
    const url_get_connection_type = "/api/operating-systems/connection-vars-type?os_id=" + os_id;
    let data = await fetch(url_get_connection_type,{
        method: 'GET',
        headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${user.token}` },
    })
    .then(res=>{
        if(res.ok) return res.json();
        throw res;
    })
    .then(data =>{
        return data;
    }).catch(async error => {
        return await FetchCatch(error, API_get_connection_type, [os_id]);
    });

    return data;
  }

  const newConnectionVariablesHandler = (var_name, var_value) => { 
    let tmp = [...newConnectionVariables];
    let index = tmp.findIndex(x => x.var_name === var_name);
    tmp[index].var_value = var_value;
    setNewConnectionVariables(tmp);
  }

  const newSTIGVariablesHandler = (var_name, var_value) => {
    let tmp = [...newSTIGVariables];
    let index = tmp.findIndex(x => x.var_name === var_name);
    tmp[index].var_value = var_value;
    setNewSTIGVariables(tmp);
  }

  const groupCreationSubmitCheck = () => {
    if(newName === "" || newOS === null || newConnectionVariables === null || newOSConnType === null){
      return false;
    }

    for(let i in newConnectionVariables){
      if(newConnectionVariables[i].var_value === null){
        return false;
      }
    }

    return true;
  }

  const deviceCreationSubmitCheck = () => {
    if(newName === "" || newOS === null || newParent === null){
      return false;
    }

    return true;
  }

  const submitGroupHandler = async () => {
    await props.createGroup(newName, newOS.id, [...newConnectionVariables,...newSTIGVariables]);
    props.close();
  }

  const resetGroupHandler = () => {
    if(unchangedNewConnectionVariables != null)
      setNewConnectionVariables(JSON.parse(JSON.stringify(unchangedNewConnectionVariables)));
    if(unchangedNewSTIGVariables != null)
      setNewSTIGVariables(JSON.parse(JSON.stringify(unchangedNewSTIGVariables)));
  }

  const submitDeviceHandler = async () => {
    let stig_variables = [...newSTIGVariables];
    for(let i in stig_variables){
      if(stig_variables[i].var_value == null){
        stig_variables[i].var_value = "";
      }
    }

    await props.createDevice(newName,newOS.id, false, newParent.group_id, stig_variables);
    props.close();
  }

  const resetDeviceHandler = () => {
    if(unchangedNewSTIGVariables != null)
      setNewSTIGVariables(JSON.parse(JSON.stringify(unchangedNewSTIGVariables)));
  }

  const submitExistingHandler = async () => {
    props.addHost(copyType, copyParent, copyHost);
    props.close();
  }

  const createNewTemplateHandler = (name, group, variables) => {
    props.createTemplate(name, group, variables)
    props.close();
  }

  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
            <div style={{display: 'flex', alignItems: 'center'}}>
                {
                    pageOptions.map((option, index) => (
                        <Fragment key={option}>
                            <div className={option === pageType ? "PopupPageTypeButton-Selected" : "PopupPageTypeButton"} onClick={() => setPageType(option)}>
                                { option }
                            </div>
                            <div style={{borderRight: "1px solid black", height: "20px", margin: "0px 5px"}}>
                            </div>
                        </Fragment>
                    ))
                }
            </div>
            <hr style={{marginBottom: "20px", marginTop: "5px"}} />
            {
               (pageType === "Group Creation") &&
                <>
                <div style={{display: 'flex', flexDirection: 'column', overflowY: "auto", maxHeight: "calc(100vh - 220px)", gap: "10px",}}>
                    <h2>
                        Create Group:
                    </h2>
                    <div style={{display: 'flex', alignItems: 'center', gap: "20px", flexWrap: "wrap"}}>
                    <label>
                        <div>Group Name</div>
                        <input type="text" style={{padding: '10px 15px', borderRadius: '5px', fontWeight: 'bold', color: 'gray', borderColor: 'rgba(118, 118, 118, 0.3)'}} value={newName == null ? "" : newName} onChange={(e) => setNewName(e.target.value)} />
                    </label>
                    <div style={{display: 'flex' , flexDirection: 'column'}}>
                        Operating System:
                        <select title={newOS == null ? "" : newOS.operating_system} style={{padding: '10px 15px', borderRadius: '5px', fontWeight: 'bold', color: 'gray', borderColor: 'rgba(118, 118, 118, 0.3)', cursor: "pointer", width: "210px"}} value={newOS == null ? -1 : newOS.id} onChange={(e) => newOSHandler(parseInt(e.target.value))}>
                            <option value={-1}>Select a OS</option>
                            {
                                props.OSList?.map((os) => (
                                    <option key={os.id} value={os.id}>{os.operating_system}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div style={{display: 'flex' , flexDirection: 'column'}}>
                        Connection Type:
                        <select title={newOSConnType == null ? "" : newOSConnType.name} style={{padding: '10px 15px', borderRadius: '5px', fontWeight: 'bold', color: 'gray', borderColor: 'rgba(118, 118, 118, 0.3)', cursor: "pointer", width: "210px"}} value={newOSConnType == null ? -1 : newOSConnType.id} onChange={(e) => OSConnTypeHandler(parseInt(e.target.value))}>
                            <option value={-1}>Select a OS</option>
                            {
                                OSConnTypeList?.map((os_conn_type) => (
                                    <option key={os_conn_type.id} value={os_conn_type.id}>{os_conn_type.name}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>
                <div>
                    {
                      (newOS != null && newConnectionVariables != null) &&
                      <>
                      <h3 style={{textDecoration: "underline", marginBottom: "5px", display: 'flex', alignItems: 'center', gap: '5px'}}>
                        <div title="Some connection variables are case sensitive." style={{display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'help'}}>
                          <InfoIcon fontSize="small"/>
                        </div>
                        Connection Variables (Required):
                      </h3>
                      <div style={{display: 'flex', alignItems: 'center', gap: "20px", flexWrap: "wrap"}}>
                          {
                              newConnectionVariables.map((connection_var, index) => (
                                  <label key={index}>
                                      <div>{connection_var.var_name}</div>
                                      <input type={var_password_check(connection_var.var_name) ? "password" : "text"} style={{padding: '10px 15px', borderRadius: '5px', fontWeight: 'bold', color: 'gray', borderColor: 'rgba(118, 118, 118, 0.3)'}} value={connection_var.var_value == null ? "" : connection_var.var_value } onChange={(e) => newConnectionVariablesHandler(connection_var.var_name, e.target.value)}/>
                                  </label>
                              ))
                          }
                      </div>
                      </>
                    }
                </div>
                <div>
                    {
                      (newOS != null && newSTIGVariables != null) &&
                      <>
                      <h3 style={{textDecoration: "underline", marginBottom: "5px"}}>
                        STIG Variables:
                      </h3>
                      <div style={{display: 'flex', alignItems: 'center', gap: "20px", flexWrap: "wrap"}}>
                          {
                              newSTIGVariables.map((stig_var, index) => (
                                !stig_var.var_name.includes("_remediate") &&
                                  <label key={index}>
                                      <div>{stig_var.var_name}</div>
                                      <input type="text" style={{padding: '10px 15px', borderRadius: '5px', fontWeight: 'bold', color: 'gray', borderColor: 'rgba(118, 118, 118, 0.3)'}} value={stig_var.var_value == null ? "" : stig_var.var_value} onChange={(e) => newSTIGVariablesHandler(stig_var.var_name, e.target.value)}/>
                                  </label>
                              ))
                          }
                      </div>
                      </>
                    }
                </div>
              </div>
              <div style={{display: 'flex', alignItems: 'center', gap: "10px"}}>
              {
                newOS != null &&
                <input type="button" value="Reset" className="widget-button" style={{marginLeft: "0px", marginTop: "10px"}} onClick={resetGroupHandler}/>
              }
              <input type="button" value="Create Group" className={groupCreationSubmitCheck() && UserIsAdmin() ? "widget-button" : "widget-button-disable"} style={{marginLeft: "0px", marginTop: "10px"}} disabled={!groupCreationSubmitCheck() || !UserIsAdmin()} onClick={submitGroupHandler}/>
              </div>
              </>  
            }
            {
                pageType === "Device Creation" &&
                <>
                <div style={{display: 'flex', flexDirection: 'column', overflowY: "auto", maxHeight: "calc(100vh - 220px)", gap: "10px",}}>
                    <h2>
                        Create Device:
                    </h2>
                    <div style={{display: 'flex', alignItems: 'center', gap: "20px", flexWrap: "wrap"}}>
                    <label>
                        <div>Device Name</div>
                        <input type="text" style={{padding: '10px 15px', borderRadius: '5px', fontWeight: 'bold', color: 'gray', borderColor: 'rgba(118, 118, 118, 0.3)'}} value={newName == null ? "" : newName} onChange={(e) => setNewName(e.target.value)} />
                    </label>
                    <div className="audits_filter_datepicker_wrapper">
                        Parent Group:
                        <select style={{padding: '10px 15px', borderRadius: '5px', fontWeight: 'bold', color: 'gray', borderColor: 'rgba(118, 118, 118, 0.3)', cursor: "pointer"}} value={newParent == null ? -1 : newParent.group_id} onChange={(e) => newParentHandler(parseInt(e.target.value))}>
                            <option value={-1}>Select a Group</option>
                            {
                                props.groupList?.map((group) => (
                                    <option key={group.group_id} value={group.group_id}>{group.group_name}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>
                <div>

                </div>
                <div>
                    {
                      (newOS != null && newSTIGVariables != null) &&
                      <>
                      <h3 style={{textDecoration: "underline", marginBottom: "5px"}}>
                        Device Variables:
                      </h3>
                      <div style={{display: 'flex', alignItems: 'center', gap: "20px", flexWrap: "wrap"}}>
                          {
                              newSTIGVariables.map((stig_var, index) => (
                                  <label key={index}>
                                      <div>{stig_var.var_name}</div>
                                      <input type={var_password_check(stig_var.var_name) ? "password" : "text"} style={{padding: '10px 15px', borderRadius: '5px', fontWeight: 'bold', color: 'gray', borderColor: 'rgba(118, 118, 118, 0.3)'}} value={stig_var.var_value == null ? "" : stig_var.var_value} onChange={(e) => newSTIGVariablesHandler(stig_var.var_name, e.target.value)}/>
                                  </label>
                              ))
                          }
                      </div>
                      </>
                    }
                </div>
              </div>
              <div style={{display: 'flex', alignItems: 'center', gap: "10px"}}>
              {
                newOS != null &&
                <input type="button" value="Reset" className="widget-button" style={{marginLeft: "0px", marginTop: "10px"}} onClick={resetDeviceHandler}/>
              }
              <input type="button" value="Create Device" className={deviceCreationSubmitCheck() ? "widget-button" : "widget-button-disable"} style={{marginLeft: "0px", marginTop: "10px"}} disabled={!deviceCreationSubmitCheck()} onClick={submitDeviceHandler}/>
              </div>
              </>  
            }
            {
              pageType === "Add Existing Device/Group" &&
              <>
              <div style={{display: 'flex', flexDirection: 'column', overflowY: "auto", maxHeight: "calc(100vh - 220px)", gap: "10px"}}>
                  <h2>
                    Add Existing Device or Group:
                  </h2>
                  <div style={{display: 'flex', alignItems: 'center', gap: "20px", flexWrap: "wrap"}}>
                  <label>
                      <div>Parent Group</div>
                      <select style={{padding: '10px 15px', borderRadius: '5px', fontWeight: 'bold', color: 'gray', borderColor: 'rgba(118, 118, 118, 0.3)', cursor: "pointer"}} value={copyParent == null ? -1 : copyParent.group_id} onChange={(e) => setCopyParent(props.groupList.find(x => x.group_id == e.target.value))}>
                          <option value={-1}>Select a Group</option>
                          {
                              props.groupList?.map((group) => (
                                  <option key={group.group_id} value={group.group_id}>{group.group_name}</option>
                              ))
                          }
                      </select>
                  </label>
                    <label>
                        <div>Existing Type</div>
                        <select style={{padding: '10px 15px', borderRadius: '5px', fontWeight: 'bold', color: 'gray', borderColor: 'rgba(118, 118, 118, 0.3)', cursor: "pointer"}} value={copyType} onChange={(e) => {setCopyType(e.target.value)}}>
                          <option value="Device">Device</option>
                          <option value="Group">Group</option>
                        </select>
                    </label>
                    {
                    copyParent === null ? <></> :
                    copyType === "Group" ?
                    <label>
                        <div>Existing Group</div>
                        <select style={{padding: '10px 15px', borderRadius: '5px', fontWeight: 'bold', color: 'gray', borderColor: 'rgba(118, 118, 118, 0.3)', cursor: "pointer"}} value={copyHost == null ? -1 : copyHost.device_id} onChange={(e) => setCopyHost(props.groupList.find(x => x.group_id == e.target.value))}>
                            <option value={-1}>Select a Group</option>
                            {
                                copyParent != null &&
                                props.groupList?.map((group) => (
                                    (group.os_id == copyParent.os_id && group.group_id != copyParent.group_id && copyParent.children.find(x => x === group.name) == null) &&
                                    <option key={group.group_id} value={group.group_id}>{group.group_name}</option>
                                ))
                            }
                        </select>
                    </label>
                    :
                    <label>
                    <div>Existing Device</div>
                    <select style={{padding: '10px 15px', borderRadius: '5px', fontWeight: 'bold', color: 'gray', borderColor: 'rgba(118, 118, 118, 0.3)', cursor: "pointer"}} value={copyHost == null ? -1 : copyHost.device_id} onChange={(e) => setCopyHost(props.deviceList.find(x => x.device_id == e.target.value))}>
                        <option value={-1}>Select a Device</option>
                        {
                            copyParent != null &&
                            props.deviceList?.map((device) => (
                                (device.os_id == copyParent.os_id && device.groups.find(x => x.group_id === copyParent.group_id) == null) &&
                                <option key={device.device_id} value={device.device_id}>{device.name}</option>
                            ))
                        }
                    </select>
                </label>
                  }
                  </div>
                </div>
              <div style={{display: 'flex', alignItems: 'center', gap: "10px"}}>
              <input type="button" value={"Add " + copyType} className={copyHost && copyParent ? "widget-button" : "widget-button-disable"} style={{marginLeft: "0px", marginTop: "10px"}} disabled={!copyHost || !copyParent} onClick={submitExistingHandler}/>
              </div>
              </>  
            }
            {
              pageType === "Template Creation" &&
              <TemplateCreation groupList={props.groupList} createTemplate={createNewTemplateHandler} select={props.select}/>
            }
        </div>
  )
}

const TemplateCreation = (props) => {
  const { user, FetchCatch } = useContext(UserContext);

    //Template Creation Variables;
    const [group, setGroup] = useState(null);
    const [variables, setVariables] = useState(null);
    const [newTemplateName, setNewTemplateName] = useState("");

    useEffect(() => {
      if(props.select && props.select.length > 0){
        setGroup(props.select[0]);
      }
    },[])

    useEffect(async () => {
      if(group == null || group.group_id == null){
          setVariables(null);
          return;
      }
      let template_list = await API_get_group_templates(group.group_id);
      if(template_list == null) return;
      let baseline_tmp = template_list.find(x => x.name == "Baseline" && x.group_id == group.group_id);
      if(baseline_tmp == null) return;
      let variables = await API_get_template_variable(baseline_tmp.id);
      setVariables(variables);
    }, [group])

    const API_get_template_variable = async (template_id) => {
        const url_get_template = "/api/stigs/templates/" + template_id;
        let data = await fetch(url_get_template,{
            method: 'GET',
            headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${user.token}` },
        })
        .then(res=>{
            if(res.ok) return res.json();
            throw res;
        })
        .then(data =>{
            if(data != null){
                data.stig_vars.sort((a,b) => a.var_name > b.var_name ? 1 : -1);
                data.connection_vars.sort((a,b) => a.var_name > b.var_name ? 1 : -1);
            }
            return data;
        }).catch(async error => {
            FetchCatch(error, API_get_template_variable, [template_id]);
            return null;
        });
    
        return data;
    }

    const API_get_group_templates = async (group_id) => {
        const url_template_group = "/api/stigs/templates/group/" + group_id;
        let data = await fetch(url_template_group ,{
          headers:{
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${user.token}`,
          }
        })
        .then(res=>{
          if(res.ok) return res.json();
          throw res
        })
        .then(data =>{
          if(Array.isArray(data)){
            for(let i in data){
              data[i].group_id = group_id;
            }
            return data;
          }else{
            return [];
          }
        }).catch(async error => {
          return await FetchCatch(error, API_get_group_templates, [group_id]);
        })
        
        return data;
      }

      const variableChangeHandler = (var_id, var_value) => {
        let index = variables.stig_vars.findIndex(x => x.var_id === var_id);
        if(index === -1) return;
        let tmp = {...variables};
        tmp.stig_vars[index].var_value = var_value;
        setVariables(tmp);
      }

    const createNewTemplateHandler = async () => {
        if(newTemplateName === "" || newTemplateName.toLowerCase() === "baseline"){
            alert("Template name can not be 'baseline' or empty.");
            return;
        }

        if(group === null){
            alert("A Group must be selected.");
            return;
        }
        await props.createTemplate(newTemplateName, group, [...variables.connection_vars, ...variables.stig_vars]);
    }  

    return (
            <>
            <div style={{display: 'flex', flexDirection: 'column', overflowY: "auto", maxHeight: "calc(100vh - 220px)", gap: "10px"}}>
                <h2>
                    Create Template:
                </h2>
                <div style={{display: 'flex', alignItems: 'center', gap: "20px", flexWrap: "wrap"}}>
                    <label>
                        <div>New Template Name</div>
                        <input type="text" style={{padding: '10px 15px', borderRadius: '5px', fontWeight: 'bold', color: 'gray', borderColor: 'rgba(118, 118, 118, 0.3)'}} value={newTemplateName == null ? "" : newTemplateName} onChange={(e) => setNewTemplateName(e.target.value)}/>
                    </label>
                    <div className="audits_filter_datepicker_wrapper">
                        Group Select:
                        <select style={{padding: '10px 15px', borderRadius: '5px', fontWeight: 'bold', color: 'gray', borderColor: 'rgba(118, 118, 118, 0.3)', cursor: "pointer"}} value={group ? group.group_id : -1} onChange={(e) => setGroup(e.target.value == -1 ? null : props.groupList.find(x => x.group_id == e.target.value))}>
                            <option value={-1}>Select a group</option>
                        {
                            props.groupList?.map((group_tmp, index) => (
                                <option key={group_tmp.group_id} value={group_tmp.group_id}>{group_tmp.group_name}</option>
                            ))
                        }
                        </select>
                    </div>
                </div>
                {
                    (group != null && variables != null) &&
                    <div style={{display: 'flex', alignItems: 'center', gap: "20px", flexWrap: "wrap"}}>
                        {
                            variables.stig_vars.map((template_var, index) => (
                                !template_var.var_name.includes("_remediate") &&
                                <label key={template_var.var_id}>
                                    <div>{template_var.var_name}</div>
                                    <input type="text" style={{padding: '10px 15px', borderRadius: '5px', fontWeight: 'bold', color: 'gray', borderColor: 'rgba(118, 118, 118, 0.3)'}} value={template_var.var_value} onChange={(e) => variableChangeHandler(template_var.var_id, e.target.value)}/>
                                </label>
                            ))
                        }
                    </div>
                }
            </div>
            <div>
                <input type="button" value="Create Template" className={newTemplateName === "" || newTemplateName.toLowerCase() === "baseline" || group === null ? "widget-button-disable" : "widget-button"} disabled={newTemplateName === "" || newTemplateName.toLowerCase() === "baseline" || group === null} style={{marginLeft: "0px", marginTop: "10px"}} onClick={createNewTemplateHandler}/>
            </div>
            </>
    )
}

export default GroupPopup