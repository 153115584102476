import React, {useState, Fragment, useContext, useEffect} from 'react'
import { UserContext } from '../../../App';

const STIGPopup = (props) => {
    const { user, FetchCatch  } = useContext(UserContext);
    const pageOptions = ["Template Creation"]
    const [pageType, setPageType] = useState(props.page == null ? pageOptions[0] : props.page);

    //Template Creation Variables;
    const [group, setGroup] = useState(null);
    const [variables, setVariables] = useState(null);
    const [newTemplateName, setNewTemplateName] = useState("");

    useEffect(async () => {
        if(group == null){
            setVariables(null);
            return;
        }
        let template_list = await API_get_group_templates(group.group_id);
        if(template_list == null) return;
        let baseline_tmp = template_list.find(x => x.name == "Baseline" && x.group_id == group.group_id);
        if(baseline_tmp == null) return;
        let variables = await API_get_template_variable(baseline_tmp.id);
        setVariables(variables);
    }, [group])

    const API_get_template_variable = async (template_id) => {
        const url_get_template = "/api/stigs/templates/" + template_id;
        let data = await fetch(url_get_template,{
            method: 'GET',
            headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${user.token}` },
        })
        .then(res=>{
            if(res.ok) return res.json();
            throw res;
        })
        .then(data =>{
            if(data != null){
                data.stig_vars.sort((a,b) => a.var_name > b.var_name ? 1 : -1);
                data.connection_vars.sort((a,b) => a.var_name > b.var_name ? 1 : -1);
            }
            return data;
        }).catch(async error => {
            FetchCatch(error, API_get_template_variable, [template_id]);
            return null;
        });
    
        return data;
    }

    const API_get_group_templates = async (group_id) => {
        const url_template_group = "/api/stigs/templates/group/" + group_id;
        let data = await fetch(url_template_group ,{
          headers:{
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${user.token}`,
          }
        })
        .then(res=>{
          if(res.ok) return res.json();
          throw res
        })
        .then(data =>{
          if(Array.isArray(data)){
            for(let i in data){
              data[i].group_id = group_id;
            }
            return data;
          }else{
            return [];
          }
        }).catch(async error => {
          return await FetchCatch(error, API_get_group_templates, [group_id]);
        })
        
        return data;
      }

      const variableChangeHandler = (var_id, var_value) => {
        let index = variables.stig_vars.findIndex(x => x.var_id === var_id);
        if(index === -1) return;
        let tmp = {...variables};
        tmp.stig_vars[index].var_value = var_value;
        setVariables(tmp);
      }

    const createNewTemplateHandler = async () => {
        if(newTemplateName === "" || newTemplateName.toLowerCase() === "baseline"){
            alert("Template name can not be 'baseline' or empty.");
            return;
        }

        if(group === null){
            alert("A Group must be selected.");
            return;
        }
        await props.createTemplate(newTemplateName, group, [...variables.connection_vars, ...variables.stig_vars]);
        props.close();
    }  

    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
        <div style={{display: 'flex', alignItems: 'center'}}>
            {
                pageOptions.map((option, index) => (
                    <Fragment key={option}>
                        <div className={option === pageType ? "PopupPageTypeButton-Selected" : "PopupPageTypeButton"} onClick={() => setPageType(option)}>
                            { option }
                        </div>
                        <div style={{borderRight: "1px solid black", height: "20px", margin: "0px 5px"}}>
                        </div>
                    </Fragment>
                ))
            }
        </div>
        <hr style={{marginBottom: "20px", marginTop: "5px"}} />
        {
            pageType === "Template Creation" &&
            <>
            <div style={{display: 'flex', flexDirection: 'column', overflowY: "auto", maxHeight: "calc(100vh - 220px)", gap: "10px",}}>
                <h2>
                    Create Template:
                </h2>
                <div style={{display: 'flex', alignItems: 'center', gap: "20px", flexWrap: "wrap"}}>
                    <label>
                        <div>New Template Name</div>
                        <input type="text" style={{padding: '10px 15px', borderRadius: '5px', fontWeight: 'bold', color: 'gray', borderColor: 'rgba(118, 118, 118, 0.3)'}} value={newTemplateName} onChange={(e) => setNewTemplateName(e.target.value)}/>
                    </label>
                    <div className="audits_filter_datepicker_wrapper">
                        Group Select:
                        <select style={{padding: '10px 15px', borderRadius: '5px', fontWeight: 'bold', color: 'gray', borderColor: 'rgba(118, 118, 118, 0.3)', cursor: "pointer"}} onChange={(e) => setGroup(e.target.value == -1 ? null : props.groupList.find(x => x.group_id == e.target.value))}>
                            <option value={-1}>Select a group</option>
                        {
                            props.groupList?.map((group_tmp, index) => (
                                <option key={group_tmp.group_id} value={group_tmp.group_id}>{group_tmp.name}</option>
                            ))
                        }
                        </select>
                    </div>
                </div>
                {
                    (group != null && variables != null) &&
                    <div style={{display: 'flex', alignItems: 'center', gap: "20px", flexWrap: "wrap"}}>
                        {
                            variables.stig_vars.map((template_var, index) => (
                                !template_var.var_name.includes("_remediate") &&
                                <label key={template_var.var_id}>
                                    <div>{template_var.var_name}</div>
                                    <input type="text" style={{padding: '10px 15px', borderRadius: '5px', fontWeight: 'bold', color: 'gray', borderColor: 'rgba(118, 118, 118, 0.3)'}} value={template_var.var_value} onChange={(e) => variableChangeHandler(template_var.var_id, e.target.value)}/>
                                </label>
                            ))
                        }
                    </div>
                }
            </div>
            <div>
                <input type="button" value="Create Template" className={newTemplateName === "" || newTemplateName.toLowerCase() === "baseline" || group === null ? "widget-button-disable" : "widget-button"} disabled={newTemplateName === "" || newTemplateName.toLowerCase() === "baseline" || group === null} style={{marginLeft: "0px", marginTop: "10px"}} onClick={createNewTemplateHandler}/>
            </div>
            </>
        }
    </div>
    )
}

export default STIGPopup