import React, {useState, useEffect, useContext} from 'react'
import { UserContext } from '../../../App';
import PlaybookWidget from './PlaybookWidget';
import STIGTableRow from './STIGTableRow';

//Icons
import AddIcon from '@mui/icons-material/Add';
import DoneIcon from '@mui/icons-material/Done';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import WestIcon from '@mui/icons-material/West';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PopupPage from '../PopupPage';
import STIGPopup from './STIGPopup';

//CSS
import "./Playbooks.css";

const Playbooks = (props) => {
  const { user, FetchCatch, theme } = useContext(UserContext);
  const [width, setWidth]   = useState(window.innerWidth);
  const [filter, setFilter] = useState([false, false, false]);
  
  const updateDimensions = () => {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const [playbookSelect, setPlaybookSelect] = useState(null);
  const [playbookList, setPlaybookList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [templateList, setTemplateList] = useState([]);
  const [templateSelect, setTemplateSelect] = useState(null);
  const [templateVariable, setTemplateVariable] = useState(null);
  const [defaultVuln, setDefaultVuln] = useState([]);
  const [UnchangedVuln, setUnchangedVuln] = useState([]);
  const [changedVuln, setChangedVuln] = useState([]);
  const [saved, setSaved] = useState(false);
  const [checkAllStatus, setCheckAllStatus] = useState(false);
  const [sort, setSort] = useState(null);

  //STIGPopup Component
  const [popupagepagecheck, setPopuppagecheck] = useState(false);

  useEffect(async () => {
    if(playbookSelect === null) return;
    reset();
    let current_groups = props.hostList.filter(g => g.os_id == playbookSelect.os_id);
    setGroupList(current_groups);
    let stig_default = await API_get_stig_default(playbookSelect.id);
    if(stig_default){
      setDefaultVuln(stig_default);
      setUnchangedVuln(JSON.parse(JSON.stringify(stig_default)));
    }else{
      setPlaybookSelect(null);
    }
    getTemplates(current_groups);
  },[playbookSelect]);

  useEffect(() => {
    if(props.OS === null || props.playbooks === null) return;
    let tmp = [];
    let os_tmp = [...props.OS].sort((a, b) => a.operating_system > b.operating_system ? 1 : -1);
    for(let i in os_tmp){
      let pb_tmp = [...props.playbooks].filter(x => x.os_id == os_tmp[i].id);
      pb_tmp = pb_tmp.sort((a,b) => a.friendly_name > b.friendly_name ? 1 : -1);
      tmp = tmp.concat(pb_tmp);
    }
    setPlaybookList(tmp);
  }, [props.playbooks, props.OS]);

  useEffect(async () => {
    if(templateSelect == null){
      setTemplateVariable(null);
      if(playbookSelect != null){
        let stig_default = await API_get_stig_default(playbookSelect.id);
        if(stig_default){
          setDefaultVuln(stig_default);
          setUnchangedVuln(JSON.parse(JSON.stringify(stig_default)));
        }else{
          playbookSelect(null);
        }
        setChangedVuln([]);
      }
      return;
    }
    handleCancel();
    getTemplateVariable(templateSelect.id);
  }, [templateSelect]);

  const reset = () => {
    setSaved(false);
    setChangedVuln([]);
    setFilter([false, false, false]);
    setTemplateSelect(null);
    setTemplateList([]);
    setTemplateVariable(null);
  }

  const sortPlaybookColumn = (type) => {
    if(type == "Name"){
      if(sort === "Name"){
        setPlaybookList(([...props.playbooks].sort((a,b) => a.friendly_name.localeCompare(b.friendly_name))).reverse());
        setSort(null);
      }else{
        setPlaybookList([...props.playbooks].sort((a,b) => a.friendly_name.localeCompare(b.friendly_name)));
        setSort("Name");
      }
    }else{
      if(type == "OS"){
        if(sort === "OS"){
          let tmp = [];
          let os_tmp = [...props.OS].sort((a, b) => a.operating_system.localeCompare(b.operating_system)).reverse();
          for(let i in os_tmp){
            let pb_tmp = [...props.playbooks].filter(x => x.os_id == os_tmp[i].id);
            pb_tmp = pb_tmp.sort((a,b) => a.friendly_name.localeCompare(b.friendly_name)).reverse();
            tmp = tmp.concat(pb_tmp);
          }
          setPlaybookList(tmp);
          setSort(null);
        }else{
          let tmp = [];
          let os_tmp = [...props.OS].sort((a, b) => a.operating_system.localeCompare(b.operating_system));
          for(let i in os_tmp){
            let pb_tmp = [...props.playbooks].filter(x => x.os_id == os_tmp[i].id);
            pb_tmp = pb_tmp.sort((a,b) => a.friendly_name.localeCompare(b.friendly_name));
            tmp = tmp.concat(pb_tmp);
          }
          setPlaybookList(tmp);
          setSort("OS");
        }
      }
    }
  }

  const API_get_stig_default = async (stig_id) => {
    const url_vuln = "/api/stigs/defaults/" + stig_id;
    let data = await fetch(url_vuln ,{
          headers:{
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${user.token}`,
          }
      })
      .then(res=>{
        if(res.ok) return res.json();
        throw res
      })
      .then(data =>{
        return data;
      }).catch(async error => {
        setPlaybookSelect(null);
        return await FetchCatch(error, API_get_stig_default, [stig_id]);
    })

    return data;
  }

  const getTemplates = async (group_list) => {
    let template_list = [];
    for(let i in group_list){
      let tmp = await API_template_group(group_list[i].group_id);
      if(tmp != null){
        template_list = template_list.concat(tmp);
      }
    }
    setTemplateList(template_list);
  }

  
  const API_template_group = async (group_id) => {
    const url_template_group = "/api/stigs/templates/group/" + group_id;
    let data = await fetch(url_template_group ,{
      headers:{
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${user.token}`,
      }
    })
    .then(res=>{
      if(res.ok) return res.json();
      throw res
    })
    .then(data =>{
      if(Array.isArray(data)){
        for(let i in data){
          data[i].group_id = group_id;
        }
        return data;
      }else{
        return [];
      }
    }).catch(async error => {
      return await FetchCatch(error, API_template_group, [group_id]);
    })
    
    return data;
  }

  const getTemplateVariable = async (template_id) => {
    const url_templates = "/api/stigs/templates/" + template_id;
    await fetch(url_templates ,{
        headers:{
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${user.token}`,
        }
      })
      .then(res=>{
        if(res.ok) return res.json();
        throw res
      })
      .then(data =>{
        if(data != null){
          data.stig_vars.sort((a,b) => a.var_name > b.var_name ? 1 : -1);
          data.connection_vars.sort((a,b) => a.var_name > b.var_name ? 1 : -1);
        }
        setTemplateVariable(data);
        handleVariableVulns(data);
      }).catch(async error => {
        FetchCatch(error, getTemplateVariable, [template_id]);
    })
  }

  const API_get_template_variable = async (template_id) => {
    const url_get_template = "/api/stigs/templates/" + template_id;
    let data = await fetch(url_get_template,{
        method: 'GET',
        headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${user.token}` },
    })
    .then(res=>{
        if(res.ok) return res.json();
        throw res;
    })
    .then(data =>{
        if(data != null){
          data.stig_vars.sort((a,b) => a.var_name > b.var_name ? 1 : -1);
          data.connection_vars.sort((a,b) => a.var_name > b.var_name ? 1 : -1);
        }
        return data;
    }).catch(async error => {
        FetchCatch(error, API_get_template_variable, [template_id]);
        return null;
    });

    return data;
  }

  const handleVariableVulns = (data) => {
    let defaultVuln_tmp = JSON.parse(JSON.stringify(UnchangedVuln));
    for(let i in data.stig_vars){
      if(data.stig_vars[i].var_name.includes("_comment")){
        let tmp = data.stig_vars[i].var_name.substr(2,6);
        let index = defaultVuln_tmp.findIndex(x => x.vuln_num.includes(tmp));
        if(index != -1){
          defaultVuln_tmp[index].comments = data.stig_vars[i].var_value;
        }
      }

      if(data.stig_vars[i].var_name.includes("_remediate")){
        let tmp = data.stig_vars[i].var_name.substr(2,6);
        let index = defaultVuln_tmp.findIndex(x => x.vuln_num.includes(tmp));
        if(index != -1){
          defaultVuln_tmp[index].enabled = data.stig_vars[i].var_value == "true";
        }
      }
    }

    defaultVuln_tmp.sort((a,b) => a.enabled === b.enabled ? a.vuln_id > b.vuln_id ? -1 : 1 : a.enabled == true ? 1 : -1);
    setDefaultVuln(defaultVuln_tmp);
  }

  const filterSort = (type) => {
    let tmp_filter = [...filter];
    let tmp_vuln = [...defaultVuln];

    if(type == 1){
      tmp_vuln.sort((a,b) => a.vuln_id > b.vuln_id ? 1 : -1);
    }else if(type == 0){
      tmp_vuln.sort((a,b) => a.enabled === b.enabled ? a.vuln_id > b.vuln_id ? 1 : -1 : a.enabled == true ? 1 : -1);
    }

    tmp_filter[type] = !tmp_filter[type];
    setFilter(tmp_filter);
    setDefaultVuln(tmp_vuln);
  }

  const ChangeCommentHandler = (vuln) => {
    let index = changedVuln.findIndex(x => x.id == vuln.id);
    if(index == -1){
      let tmp = [...changedVuln];
      tmp.push(vuln);
      setChangedVuln(tmp);
    }else{
      let tmp = [...changedVuln];
      tmp[index] = vuln;
      setChangedVuln(tmp);
    }
  }

  const ChangeEnableHandler = (vuln) => {
    let index = changedVuln.findIndex(x => x.id== vuln.id);
    if(index == -1){
      let tmp = [...changedVuln];
      tmp.push(vuln);
      setChangedVuln(tmp);
    }else{
      let tmp = [...changedVuln];
      tmp[index] = vuln;
      setChangedVuln(tmp);
    }
  }

  const handleCancel = () => {
    setDefaultVuln(JSON.parse(JSON.stringify(UnchangedVuln)));
    setChangedVuln([]);
    if(templateVariable != null){
      handleVariableVulns(templateVariable);
    }
  }

  const handleSave = async () => {
    if(changedVuln.length == 0) return;
    for(let i in changedVuln){
      let tmp_num = changedVuln[i].vuln_num.substr(2);

      if(changedVuln[i].comments != null){
        let index = templateVariable.stig_vars.findIndex(x => x.var_name == ("V_" + tmp_num + "_comment"));
        if(index == -1){
          await API_create_variable(templateSelect.id, "V_" + tmp_num + "_comment", changedVuln[i].comments);
        }else{
          if(changedVuln[i].comments != templateVariable.stig_vars[index].var_value){
            await API_update_variable(templateVariable.stig_vars[index].var_id,templateSelect.id, templateVariable.stig_vars[index].var_name, changedVuln[i].comments);
          }
        }
      }

      let remediate_index = templateVariable.stig_vars.findIndex(x => x.var_name == ("V_" + tmp_num + "_remediate"));
      if(remediate_index == -1){
        if(changedVuln[i].enabled == false){
          await API_create_variable(templateSelect.id, "V_" + tmp_num + "_remediate", "false");
        }
      }else{
        if(changedVuln[i].enabled == true || changedVuln[i].enabled == "true"){
          await API_delete_variable(templateVariable.stig_vars[remediate_index].var_id);
        }
      }
    }

    getTemplateVariable(templateSelect.id);
    setChangedVuln([]);
  }

  const API_create_template = async (name, os_id, group_id, playbook_id = null) => {
    const url_create_template = "/api/stigs/templates";
    let data = await fetch(url_create_template ,{
      method: 'POST',
      headers:{
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${user.token}`,
      },
      body: JSON.stringify({"name": name, "os_id": os_id, "group_id": group_id})
    })
    .then(res=>{
      if(res.ok) return res.json();
      throw res
    })
    .then(data =>{
      return data;
    }).catch(async error => {
      FetchCatch(error, API_create_template, [name, os_id, group_id, playbook_id]);
      return null;
    });

    return data;
  }

  const API_create_variable = async (template_id, var_name, var_value) => {
    const url_add_templates_var = "/api/stigs/templates/var";
    let data = await fetch(url_add_templates_var ,{
      method: 'POST',
      headers:{
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${user.token}`,
      },
      body: JSON.stringify({"template_id": template_id, "var_name": var_name, "var_value": var_value})
    })
    .then(res=>{
      if(res.ok) return res.json();
      throw res
    })
    .then(data =>{
      return data;
    }).catch(async error => {
      FetchCatch(error, API_create_variable, [template_id, var_name, var_value]);
      return null;
    });

    return data;
  }

  const API_update_variable = async (id, template_id, var_name, var_value) => {
    const url_update_templates_var = "/api/stigs/templates/var";
    let data = await fetch(url_update_templates_var ,{
      method: 'PUT',
      headers:{
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${user.token}`,
      },
      body: JSON.stringify({"id": id, "template_id": template_id, "var_name": var_name, "var_value": var_value})
    })
    .then(res=>{
      if(res.ok) return res.json();
      throw res
    })
    .then(data =>{
      return data;
    }).catch(async error => {
      FetchCatch(error, API_update_variable, [id, template_id, var_name, var_value]);
      return null;
    });
    
    return data;
  }

  const API_delete_variable = async (var_id) => {
    const url_delete_templates_var = "/api/stigs/templates/var/" + var_id;
    let data = await fetch(url_delete_templates_var ,{
      method: 'DELETE',
      headers:{
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${user.token}`,
      }
    })
    .then(res=>{
      if(res.ok) return true;
      throw res
    })
    .then(data =>{
      return data;
    }).catch(async error => {
      FetchCatch(error, API_delete_variable, [var_id]);
      return null;
    });
    
    return data;
  }

  const ChangeTemplateNameHandler = async () => {
    if(templateSelect == null) return;
    if(templateSelect.name.toLowerCase() == "baseline"){
      alert("Can't modified 'Baseline' templates.");
      return;
    }
    let new_template_name = prompt("New Template name will be: ", templateSelect.name);
    if(new_template_name == "" || new_template_name == null) return;
    if(new_template_name == templateSelect.name){
      alert("Current Name is the same as the new name.");
      return;
    }
    
    if(new_template_name.toLowerCase().includes("baseline")){
      alert("Template name can't have 'Baseline' included.");
      return;
    }

    let data = await API_update_template(templateSelect.id, new_template_name, templateSelect.os_id, templateSelect.group_id);
    if(data != null){
      let tmp = [...templateList];
      let index = templateList.findIndex(x => x.id == data.id);
      tmp[index] = data;
      setTemplateList(tmp);
    }
  }

  const API_update_template = async (template_id, name, os_id, group_id, playbook_id) => {
    const url_update_templates_var = "/api/stigs/templates";
    let data = await fetch(url_update_templates_var ,{
      method: 'PUT',
      headers:{
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${user.token}`,
      },
      body: JSON.stringify({"id": template_id, "name": name, "os_id": os_id, "group_id": group_id})
    })
    .then(res=>{
      if(res.ok) return res.json();
      throw res
    })
    .then(data =>{
      return data;
    }).catch(async error => {
      FetchCatch(error, API_update_template, [template_id, name, os_id, group_id, playbook_id]);
      return null;
    });

    return data;
  }

  const DeleteTemplateHandler = async () => {
    if(templateSelect == null) return;
    if(templateSelect.name == "Baseline"){
      alert("Can't delete 'Baseline' templates.");
      return;
    }
    let status = window.confirm("Are you sure you want to delete template: ", templateSelect.name);
    if(status == null) return;
    let data = await API_delete_template(templateSelect.id);
    if(data != null){
      let tmp = [...templateList];
      let index = templateList.findIndex(t => t.id == templateSelect.id);
      tmp.splice(index, 1);
      setTemplateList(tmp);
    }
  }

  const API_delete_template = async (template_id) => {
    const url_templates_delete = "/api/stigs/templates/" + template_id;
    let data = await fetch(url_templates_delete ,{
      method: 'DELETE',
      headers:{
        'Accept': 'application/json',
        'Authorization': `Bearer ${user.token}`,
      }
    })
    .then(res=>{
      if(res.ok) return true;
      throw res
    })
    .then(data =>{
      return true;
    }).catch(async error => {
      return await FetchCatch(error, API_delete_template, [template_id]);
    })

    return data;
  }

  const checkOffAll = () => {
    let template_tmp = JSON.parse(JSON.stringify(defaultVuln));
    let tmp = JSON.parse(JSON.stringify(changedVuln));
    for(let i in template_tmp){
      if(template_tmp[i].enabled != checkAllStatus){
        let index = tmp.findIndex(x => x.id == template_tmp[i].id);
        if(index == -1){
          template_tmp[i].enabled = checkAllStatus;
          tmp.push(JSON.parse(JSON.stringify(template_tmp[i])));
        }else{
          tmp[index].enabled = checkAllStatus;
        }
      }
      template_tmp[i].enabled = checkAllStatus;
    }

    setChangedVuln(tmp);
    setCheckAllStatus(!checkAllStatus);
    setDefaultVuln(template_tmp);
  }

  const variableChangeHandler = async (changes) => {
    for(let i in changes){
      await API_update_variable(changes[i].var_id, templateSelect.id, changes[i].var_name, changes[i].var_value);
    }

    getTemplateVariable(templateSelect.id);
  }

  const createNewTemplateHandler = async (name, group, variables) => {
    let data = await API_create_template(name, group.os_id, group.group_id, null);
    if(data != null){
      for(let i in variables){
        await API_create_variable(data.id, variables[i].var_name, variables[i].var_value);
      }
    }

    getTemplates(groupList);
  }

  const makeBaselineHandler = async () => {
    let new_variable = [...templateVariable.connection_vars, ...templateVariable.stig_vars];
    let baseline_template = templateList.find(x => x.name == 'Baseline' && templateSelect.group_id == x.group_id);
    if(baseline_template == null){
      alert("Couldn't find the 'Baseline' template for group: " + templateSelect.group_id);
      return;
    }
    let variable_data = await API_get_template_variable(baseline_template.id);

    if(variable_data == null) return;
    let old_variable = [...variable_data.connection_vars, ...variable_data.stig_vars];
    for(let i in new_variable){
        let index = old_variable.findIndex(x => x.var_name == new_variable[i].var_name);
        if(index == -1){
            alert("Baseline Template is missing variable");
        }else{
            if(new_variable[i].var_value != old_variable[index].var_value){
                await API_update_variable(old_variable[index].var_id, baseline_template.id, new_variable[i].var_name, new_variable[i].var_value);
            }
        }
    }
  }

  const checkOSDisabled = (os_id) => {
    let os = props.OS.find(x => x.id == os_id);
    if(os){
      return os.disabled;
    }else{
      return null;
    }
  }

  return (
    <div className="inventory-content">
        <div className="inventory-content-container" style={{alignItems: "stretch", color: theme ? "#FFF" : "#000"}}>
            <div className="inventory-content-header" style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                <div >
                    <div style={{display: 'flex', alignItems: 'center'}}>
                      {
                        playbookSelect != null &&
                        <div title="Go Back" className="inventory-content-header-button" style={{cursor: "pointer", borderRadius: "50%", marginRight: "0px"}} onClick={() => setPlaybookSelect(null)}>
                          <WestIcon sx={{fontSize: "24px"}} />
                        </div>
                      }
                      <h2>{playbookSelect === null ? "STIGs" : playbookSelect.friendly_name}</h2> 
                      {
                        playbookSelect != null &&
                        <>
                          <select value={templateSelect == null ? "" : templateSelect.id} onChange={(e) => setTemplateSelect(templateList.find(t => t.id == e.target.value))} style={{marginLeft: "15px", width: "150px"}}>
                            <option value="">Select a template</option>
                            {
                              templateList.map((template, index) => (
                                <option key={template.id} value={template.id} title={template.name}>{template.name} | {props.hostList.find(g => g.group_id == template.group_id).group_name}</option>
                              ))
                            }
                          </select>
                          {
                            templateSelect != null ?
                            <>
                              <div className="inventory-content-header-button" style={{marginLeft: "5px", marginRight: "0px"}}  onClick={ChangeTemplateNameHandler} title="Rename Template">
                                <DriveFileRenameOutlineIcon style={{fontSize: "20px"}} />
                              </div>
                              <div className="inventory-content-header-button" style={{marginRight: "0px"}}  onClick={DeleteTemplateHandler} title="Delete Template">
                                <DeleteForeverIcon style={{fontSize: "20px"}} />
                              </div>
                              <div className="inventory-content-header-button" style={{marginRight: "0px"}}  onClick={() => setPopuppagecheck(true)} title="Create Template">
                                <AddIcon style={{fontSize: "20px"}} />
                              </div>
                            </>
                            :
                            <div className="inventory-content-header-button" style={{marginRight: "0px"}}  onClick={() => setPopuppagecheck(true)} title="Create Template">
                              <AddIcon style={{fontSize: "20px"}} />
                              Create Template
                            </div>
                          }
                        </>
                      }
                    </div>
                    {
                      playbookSelect != null ?
                      <div style={{paddingLeft: "34px"}}>{playbookSelect.releaseinfo}. Version: {playbookSelect.version}</div>
                      :
                      <div style={{paddingLeft: "34px", height: "18.4px", width: "2px"}}></div>
                    }
                </div>
                {
                    templateSelect != null && playbookSelect != null &&
                  <div>
                      <div style={{display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
                      </div>
                      <div style={{display: "flex", textAlign: "center", justifyContent: "end" }}>
                        <input type="button" value="Check All" className="widget-button"  onClick={() => checkOffAll()}/>
                        {
                            saved &&
                            <span style={{display: 'flex', alignItems: 'center'}}><div style={{color: "#34B233"}}><DoneIcon /></div>Saved</span>
                        }
                        <input type="button" value="Cancel" className={changedVuln.length > 0 ? "widget-button" : "widget-button-disable"} onClick={() => handleCancel()} disabled={changedVuln.length == 0}/>
                        <input type="button" value="Save" className={changedVuln.length > 0 ? "widget-button" : "widget-button-disable"} onClick={() => handleSave()} style={{marginRight: "0px"}} disabled={changedVuln.length == 0}/>
                      </div>
                  </div>
                }
            </div>
            {
              width < 1280 && playbookSelect != null &&
              <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", margin: "25px", marginRight: "35px"}}>
                  <PlaybookWidget playbook={playbookSelect} theme={theme} OS={props.OS} devices={props.devices} audits={props.audits} remediates={props.remediates} vuln={defaultVuln} templateList={templateList} template={templateSelect} templateVariable={templateVariable} save={variableChangeHandler}/>
              </div>
            }
            <div className="inventory-content-wrapper">
              <div style={{minWidth: "600px",height: "calc(100vh - 142px)", backgroundColor: theme ? "#242424" : "#FFF", border: theme ? "1px solid #000" : "1px solid #EBEBEB", color: theme ? "#fff" : "#000", borderRadius: "5px", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", margin: "0 16px", overflow: "auto"}}>
            {
                playbookSelect === null ? 
              <table style={{width: "100%", overflow: "auto", borderCollapse: "collapse", borderRadius: "15px"}}>
                <thead style={{backgroundColor: theme ? "#202020" : "#d5d5d5", position: "sticky", top: "0", borderRadius: "15px"}}>
                  <tr style={{borderRadius: "15px", position: "sticky"}}>
                    <th style={{padding: "10px 0px", borderRadius: "5px 0px 0px 0px"}}>
                      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        Name
                      <ArrowDropDownIcon style={{transform: sort === "Name" ? "rotate(180deg)" : "", cursor: "pointer"}} onClick={() => sortPlaybookColumn("Name")}/>
                      </div>
                    </th>
                    <th>
                      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        Operating System
                      <ArrowDropDownIcon style={{transform: sort === "OS" ? "rotate(180deg)" : "", cursor: "pointer"}} onClick={() => sortPlaybookColumn("OS")}/>
                      </div>
                    </th>
                    <th></th>
                    <th>Devices</th>
                    <th>Disabled</th>
                  </tr>
                </thead>
              <tbody>
              {
                playbookList.map((playbook,index) => (
                  <tr key={playbook.id} className="stig-table-row" style={{textAlign: "center", height: '32px', backgroundColor: playbookSelect !== null && playbookSelect.id === playbook.id ? "#9bcfff" : "", cursor: 'pointer'}} onClick={() => playbookSelect !== null && playbookSelect.id === playbook.id ? setPlaybookSelect(null) : setPlaybookSelect(playbook)}>
                    <td>{playbook.friendly_name}</td>
                    <td>{props.OS.length > 0 && props.OS[props.OS.findIndex(x => x.id === playbook.os_id)].operating_system}</td>
                    <td></td>
                    <td>{props.devices.filter(x => x.os_id === playbook.os_id).length}</td>
                    <td>{!checkOSDisabled(playbook.os_id) ? "False" : "True"}</td>
                  </tr>
                ))
              }
              </tbody>
              </table>
              :
            <table style={{width: "100%", maxHeight:"100%", overflow: "auto", borderCollapse: "collapse", borderRadius: "15px"}}>
                <thead style={{borderRadius: "15px", border: "1px solid #d5d5d5"}}>
                <tr style={{borderRadius: "15px", backgroundColor: theme ? "#202020" : "#d5d5d5", position: "sticky", top: "-1px", zIndex: "3"}}>
                    <th style={{padding: "5px 0px", borderRadius: "1px 0px 0px 0px"}}>
                      <div style={{display: 'flex', alignItems: 'center', justifyContent: "center", cursor: "pointer"}} onClick={() => filterSort(0)}>Enable<ArrowDropDownIcon fontSize="small" style={{transform: filter[0] && "rotate(180deg)"}}/></div>
                    </th>
                    <th style={{width: "13%"}}>
                      <div style={{display: 'flex', alignItems: 'center', justifyContent: "center", cursor: "pointer"}} onClick={() => filterSort(1)}>Vuln. ID<ArrowDropDownIcon fontSize="small" style={{transform: filter[1] && "rotate(180deg)"}}/></div>
                    </th>
                    <th style={{width: "40%"}}>Rule Title</th>
                    <th style={{width: "40%"}}>Comments</th>
                </tr>
                </thead>
            <tbody>
            {
                defaultVuln.map((vul,index) => (
                  <STIGTableRow key={vul.id} vul={vul} ChangeCommentHandler={ChangeCommentHandler} ChangeEnableHandler={ChangeEnableHandler}/>
                  ))
            }
            </tbody>
            </table>
              
            }
              </div>
            </div>
        </div>
        {
          width >= 1280 &&
        <div className="inventory-content-container" style={{display: "flex", marginRight: "20px"}}>
        {
            <PlaybookWidget playbook={playbookSelect} theme={theme} OS={props.OS} devices={props.devices} audits={props.audits} remediates={props.remediates} hostList={props.hostList} vuln={defaultVuln} templateList={templateList} template={templateSelect} templateVariable={templateVariable} save={variableChangeHandler} changeBaseline={makeBaselineHandler}/>
        }
        </div>
        }
        {
          popupagepagecheck &&
          <PopupPage close={() => setPopuppagecheck(false)} title="Templates" content={<STIGPopup page={"Template Creation"} groupList={props.hostList} templateList={templateList} createTemplate={createNewTemplateHandler} close={() => setPopuppagecheck(false)}/>}/>
        }
    </div>
    
  )
}

export default Playbooks;