import React, {Fragment, useState, useEffect, useContext} from 'react';
import { UserContext } from '../../App';

// Components
import Pagination from '../dashboard/Pagination';

const StigViewerPopup = (props) => {
    const {user, theme, FetchCatch, FormatTimestamp} = useContext(UserContext);
    const pageOptions = ['Audits']
    const [pageType, setPageType] = useState("Audits");

    const [deviceList, setDeviceList] = useState([]);
    const [deviceID, setDeviceID] = useState(-1);
    const [auditList, setAuditList] = useState([]);
    const [auditTotal, setAuditTotal] = useState(0);
    const [page, setPage] = useState(1);

    const [checkList, setCheckList] = useState([]);

    useEffect(async () => {
      if(deviceID != -1){
        if(page === 1){
          let audit_list = await API_get_audits((page - 1) * 10);
          if(audit_list && audit_list._results != null){
            setAuditTotal(audit_list._metadata.total);
            setAuditList(audit_list._results);
          }else{
            setAuditList([]);
          }
        }else{
          setPage(1);
        }
      }else{
        if(page === 1){
          let audit_list = await API_get_audits((page - 1) * 10);
          if(audit_list && audit_list._results != null){
            setAuditTotal(audit_list._metadata.total);
            setAuditList(audit_list._results);
          }else{
            setAuditList([]);
          }
        }else{
          setPage(1);
        }
      }
    }, [deviceID])

    useEffect(async () => {
      let data = await API_get_devices();
      if(data){
        setDeviceList(data);
      }else{
        setDeviceList([]);
      }
    }, [])

    useEffect(async () => {
      let audit_list = await API_get_audits((page - 1) * 10);
      if(audit_list && audit_list._results != null){
        setAuditTotal(audit_list._metadata.total);
        setAuditList(audit_list._results);
      }else{
        setAuditList([]);
      }
    }, [page])

    const API_get_audits = async (limit) => {
      const url_audit = "/api/audits?skip=" + limit + "&limit=" + 10 + (deviceID != -1 ? "&device_id=" + deviceID : "");
      let data = await fetch(url_audit, {
          headers:{
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${user.token}`
          }
      })
      .then(res=>{
          if(res.ok) return res.json();
          return null;
      })
      .then(data =>{
        // data._results = list of audits
        // data._metadata.total = total
        if(data && data._results){
          for(let i in data._results){
            data._results[i].remediation = false;
          }
        }
        return data;
      }).catch(async error => {
        return await FetchCatch(error, API_get_audits, [limit]);
      })
      
      return data;
    }

    const API_get_devices = async () => {
      const url_devices = '/api/devices';
      let data = await fetch(url_devices,{
          headers:{
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${user.token}`
          }
      })
      .then(res=>{
          if(res.ok)
              return res.json();
          throw res;
      })
      .then(data =>{
          return data;
      })
      .catch(async error => {
          return await FetchCatch(error, API_get_devices, []);
      })
  
      return data;
    }

    const getPosts = (page) => {
      setPage(page);
    }

    const checkListHandler = (audit) => {
      let tmp = [...checkList];
      let index = checkList.findIndex(x => x.id === audit.id);
      if(index === -1){
        tmp.push(JSON.parse(JSON.stringify(audit)));
      }else{
        tmp.splice(index, 1);
      }
      setCheckList(tmp);
    }

    const submitAuditsHandler = () => {
      props.viewinViewer(checkList);
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <div style={{display: 'flex', alignItems: 'center'}}>
                {
                    pageOptions.map((option, index) => (
                        <Fragment key={option}>
                            <div className={option === pageType ? "PopupPageTypeButton-Selected" : "PopupPageTypeButton"} onClick={() => setPageType(option)}>
                                { option }
                            </div>
                            <div style={{borderRight: "1px solid black", height: "20px", margin: "0px 5px"}}>
                            </div>
                        </Fragment>
                    ))
                }
            </div>
            <hr style={{marginBottom: "20px", marginTop: "5px"}} />
            {
                pageType === "Audits" &&
                <div style={{display: 'flex', flexDirection: 'column', overflowY: "auto", height: "calc(100vh - 175px)", gap: "10px",}}>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{marginRight: "5px"}}>
                      Filter by Device: 
                    </div>
                    <select value={deviceID} onChange={(e) => setDeviceID(e.target.value)}>
                      <option value="-1">Select a device</option>
                      {
                        deviceList.map((device, index) => (
                          <option key={device.device_id} value={device.device_id}>{device.name}</option>
                        ))
                      }
                    </select>
                  </div>
                  <div style={{maxHeight: "400px", overflowY: "auto", border: "1px solid #D5D5D5", borderRadius: "15px"}}>
                  <table className="inventory-table-container">
                    <thead>
                      <tr className="inventory-table-header-row" style={{position: "sticky", top: "0", backgroundColor: theme ? "#202020" : "#D5D5D5"}}>
                        <th style={{width: "50px"}}></th>
                        <th>Device</th>
                        <th>STIG</th>
                        <th>Time Started</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        auditList.map((audit,index) => (
                          <tr key={audit.id} className="inventory-table-body-row" style={{textAlign: "center", textDecoration: audit.status === "Complete" ? "" : "line-through"}}>
                            <td>
                              {
                                audit.status === "Complete" &&
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                  <input type="checkbox" checked={checkList.find(x => x.id === audit.id) != null} onChange={() => checkListHandler(audit)}/>
                                </div>
                              }
                            </td>
                            <td>{deviceList.find(x => x.device_id === audit.device_id)?.name}</td>
                            <td>{props.playbookList.find(x => x.id === audit.playbook_id)?.friendly_name}</td>
                            <td>{FormatTimestamp(audit.time_started)}</td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                  </div>
                  <div style={{overflow: "auto", display: 'flex', alignItems: 'center' ,justifyContent: 'end', marginRight: "16px", height: "70px"}}>
                    <Pagination postsPerPage={10} totalPosts={auditTotal} paginate={getPosts} page={page} theme={theme} />
                  </div>
                  <div>
                  <input type="button" value="Clear" className="widget-button" style={{marginLeft: "0px", marginTop: "10px"}} onClick={() => setCheckList([])}/>
                  <input type="button" value="Submit" className={checkList.length > 0 ? "widget-button" : "widget-button-disable"} style={{marginLeft: "0px", marginTop: "10px"}} disabled={checkList.length === 0} onClick={submitAuditsHandler}/>
                  </div>
                </div>
            }
        </div>
    )
}

export default StigViewerPopup