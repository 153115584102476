import React, {useState, useEffect, useContext} from 'react';
import { Route, Routes, NavLink, useLocation, Navigate as Redirect } from 'react-router-dom';

//Icons
import PersonIcon from '@mui/icons-material/Person';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

//CSS
import "./Profile.css";

//Components
import EditProfile from "./EditProfile";
import Notifications from './Notifications';
import AdminSettings from './AdminSettings';
import { UserContext } from '../../../App';

const Profile = (props) => {
    const { user, theme, UserIsAdmin } = useContext(UserContext);
    const [page, setPage] = useState('profile');

    const Capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    let location = useLocation();
    useEffect(() => {
        if(location.pathname.includes("/profile")){
            if(location.pathname === "/profile"){
                setPage("profile Settings");
            }else{
                if(location.pathname.includes("#")){
                    return;
                }
                setPage(location.pathname.replace('/profile/',''));
            }
        }
    },[location])

    return (
        <div className="inventory-content" style={{height: "100%", gridTemplateColumns: "240px 1fr", color: theme ? "#FFF" : "#000"}}>
            <div className="inventory-content-container" style={{alignItems: "stretch",scrollBehavior: "smooth" ,height: "calc(100vh - 40px)", overflowY: "auto"}}>
                <div style={{display: "flex", flexDirection: "column", paddingTop: "50px"}}>
                    <NavLink to="" className="profile-navlink" style={{color: theme ? "#fff" : "#000"}} onClick={() => setPage("profile")}>
                        <div style={{display: "flex", alignItems: "center", textDecoration: page === "profile Settings" && "underline"}}> 
                        <PersonIcon fontSize='small' style={{paddingRight: "5px"}}/>
                        Profile
                        </div>
                        {
                            page === "profile Settings" &&
                            <ChevronRightIcon fontSize='medium'/>
                        }
                    </NavLink>  
                    <NavLink to="notifications" className="profile-navlink" style={{color: theme ? "#fff" : "#000"}}  onClick={() => setPage("notifications")}>
                        <div style={{display: "flex", alignItems: "center", textDecoration: page === "notifications" && "underline"}}> 
                            <NotificationsActiveIcon fontSize='small' style={{paddingRight: "5px"}}/>
                            Notifications
                        </div>
                        {
                            page === "notifications" &&
                            <ChevronRightIcon fontSize='medium'/>
                        }
                    </NavLink>
                    {
                        UserIsAdmin() &&
                    <NavLink to="admin" className="profile-navlink" style={{color: theme ? "#fff" : "#000"}}  onClick={() => setPage("admin")}>
                        <div style={{display: "flex", alignItems: "center", textDecoration: page === "admin" && "underline"}}> 
                            <AdminPanelSettingsIcon fontSize='small' style={{paddingRight: "5px"}}/>
                            Admin
                        </div>
                        {
                            page === "admin" &&
                            <ChevronRightIcon fontSize='medium'/>
                        }
                    </NavLink>
                    }
                </div>
            </div>
            <div className="inventory-content-container" style={{alignItems: "stretch", padding: "0px 16px 0px 16px",scrollBehavior: "smooth" ,height: "calc(100vh - 40px)", overflowY: "auto", borderLeft: "1px solid rgba(171,171,171,0.4)"}}>
                <div className="inventory-content-header" style={{paddingLeft: "0px", paddingBottom: "20px"}}>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <h2 style={{fontSize: "32px", color: theme ? "#fff" : "#2B2B2B", lineHeight: "62px"}}>{Capitalize(page)}</h2>
                    </div>
                </div>  
                    <Routes>
                        <Route path="/" element={<EditProfile />} />
                        <Route path="/notifications" element={<Notifications notificationList={props.notificationList} setNotificationList={props.setNotificationList} />} />
                        <Route path="/admin" element={
                            UserIsAdmin() ?
                        <AdminSettings user={user} groups={props.groups}/>
                        : 
                        <Redirect to="/profile" />
                        }
                        />
                    </Routes>
            </div>
        </div>
    )
}

export default Profile