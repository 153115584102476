import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../App';

// Icons
import InfoIcon from '@mui/icons-material/Info';

const LoadingBar = (props) => {

  return (
    <div style={{border: "1px solid #D5D5D5",backgroundColor: "#F5F5F5", maxHeight: "16px", borderRadius: "15px", margin: "0px 5px"}} title={props.count}>
      <div style={{height: "100%" ,width: props.total == 0 ? "0px" : Math.floor(props.count / props.total * 100).toString() + "%", margin: "0px"}}> 
        <div className="widget-progress-bar" style={{backgroundColor: "#093471", height: "100%", borderRadius: "15px"}}>
        </div>
      </div>
    </div>
  )
}

const DeviceOSWidget = (props) => {
  const { theme } = useContext(UserContext);
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  useEffect(() => {
    if(props.OS == null || props.OS.length === 0 || props.devices == null) return; 
    let tmp = [];
    for(let i in props.OS){
      let os_devices = props.devices.filter(device => device.os_id === props.OS[i].id);
      tmp.push({"OS": props.OS[i].operating_system, "total": os_devices.length, "id": props.OS[i].id});
    }
    
    tmp.sort((a,b) => a.total > b.total ? -1 : 1);
    setData(tmp);
  },[props.devices, props.OS])

  return (
    <div className="widget-container" style={{padding: "5px", width: "90%", backgroundColor: theme ? "#242424" : "#FFF", border: theme ? "1px solid #000" : "1px solid #EBEBEB", color: theme ? "#fff" : "#000", borderRadius: "10px", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", height: "auto"}}>
      <h3 className="widget-header">
      <span>Device(s) Operating System</span>
      </h3>
      <div className="widget-content" style={{width: "100%", marginTop: "10px", display: "flex", justifyContent: 'flex-start' ,flexDirection: "column", overflowY: "auto", height: "250px"}}>
      {
        data.length > 0 &&
        data.map((os,index) => (
          <div key={index} style={{display: 'flex', flexDirection: 'column', marginBottom: "10px"}}>
            <span style={{fontSize: "1em", display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: "5px"}}>{os.OS}</span>
            <div style={{display: 'grid', gridTemplateColumns: "auto 1fr auto", marginRight: "5px"}}>
              <div title="Click for more detail" style={{cursor: "pointer", display: 'flex', alignItems: 'center', justifyContent: 'center'}} onClick={() => navigate("/devices", {state: { params: {"OS": os.id}}})}>
                <InfoIcon fontSize="small"/>
              </div>
              <LoadingBar total={props.devices.length} count={os.total}/>
              <span style={{textAlign: "center", padding: "0px 5px", fontWeight: "bold"}}>{os.total}</span>
            </div>
          </div>
        ))
      }
      </div>
    </div>
  )
}

export default DeviceOSWidget;