import React, { useState, Fragment, useContext, useEffect }from 'react'
import { UserContext } from '../../../App';


const DevicePopup = (props) => {
  const { user, FetchCatch } = useContext(UserContext);
  
  const pageOptions = ['Device Creation']
  const [pageType, setPageType] = useState(props.page == null ? pageOptions[0] : props.page);


  // Creation
  const [newName, setNewName] = useState("");
  const [newOS, setNewOS] = useState(null);
  const [newParent, setNewParent] = useState(null);
  const [newConnectionVariables, setNewConnectionVariables] = useState(null);
  const [unchangedNewConnectionVariables, setUnchangedNewConnectionVariables] = useState(null);
  const [newSTIGVariables, setNewSTIGVariables] = useState(null);
  const [unchangedNewSTIGVariables, setUnchangedNewSTIGVariables] = useState(null);

  useEffect(() => { 
    reset()
  }, [pageType])

  const reset = () => {
    setNewName("");
    setNewOS(null);
    setNewParent(null);
    setNewConnectionVariables(null);
    setNewSTIGVariables(null);
    setUnchangedNewConnectionVariables(null);
    setUnchangedNewSTIGVariables(null);
  }

  const newParentHandler = async (group_id) => {
    let group = props.groupList.find(x => x.group_id === group_id);
    setNewParent(group);
    
    if(group == null){
      newOSHandler(-1);
    }else{
      newOSHandler(group.os_id);
    }
  }
  
  const newOSHandler = async (os_id) => {
    let os = props.OSList.find(x => x.id === os_id);
    setNewOS(os);

    if(os == null){
      setNewConnectionVariables(null);
      setNewSTIGVariables(null)
    }else{
      let connection_vars = await API_get_connection_vars(os_id);
      if(connection_vars){
          if(Array.isArray(connection_vars)){
              setNewConnectionVariables(connection_vars);
              setUnchangedNewConnectionVariables(JSON.parse(JSON.stringify(connection_vars)));
          }else{
              setNewConnectionVariables([]);
              setUnchangedNewConnectionVariables([]);
          }
      }

      let stig_vars = await API_get_stig_vars(os_id);
      if(stig_vars){
          if(pageType === "Device Creation"){
              //device
              stig_vars.devices.sort((a,b) => a.var_name > b.var_name ? 1 : -1);
              setNewSTIGVariables(stig_vars.devices);
              setUnchangedNewSTIGVariables(JSON.parse(JSON.stringify(stig_vars.devices)));
          }else{
              //group
              stig_vars.groups.sort((a,b) => a.var_name > b.var_name ? 1 : -1);
              setNewSTIGVariables(stig_vars.groups);
              setUnchangedNewSTIGVariables(JSON.parse(JSON.stringify(stig_vars.groups)));
          }
      }

    }
  }

  const API_get_stig_vars = async (os_id) => {
    const url_get_default = "/api/operating-systems/defaults/" + os_id;
    let data = await fetch(url_get_default,{
        method: 'GET',
        headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${user.token}` },
    })
    .then(res=>{
        if(res.ok) return res.json();
        throw res;
    })
    .then(data =>{
        return data;
    }).catch(async error => {
        return await FetchCatch(error, API_get_stig_vars, [os_id]);
    });
    return data;
  }

  const API_get_connection_vars = async (os_id) => {
    const url_get_connection = "/api/operating-systems/connection-vars/" + os_id;
    let data = await fetch(url_get_connection,{
        method: 'GET',
        headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${user.token}` },
    })
    .then(res=>{
        if(res.ok) return res.json();
        throw res;
    })
    .then(data =>{
        return data;
    }).catch(async error => {
        return await FetchCatch(error, API_get_connection_vars, [os_id]);
    });

    return data;
  }

  const newConnectionVariablesHandler = (var_name, var_value) => { 
    let tmp = [...newConnectionVariables];
    let index = tmp.findIndex(x => x.var_name === var_name);
    tmp[index].var_value = var_value;
    setNewConnectionVariables(tmp);
  }

  const newSTIGVariablesHandler = (var_name, var_value) => {
    let tmp = [...newSTIGVariables];
    let index = tmp.findIndex(x => x.var_name === var_name);
    tmp[index].var_value = var_value;
    setNewSTIGVariables(tmp);
  }

  const deviceCreationSubmitCheck = () => {
    if(newName === "" || newOS === null || newParent === null){
      return false;
    }

    return true;
  }

  const submitDeviceHandler = async () => {
    let stig_variables = [...newSTIGVariables];
    for(let i in stig_variables){
      if(stig_variables[i].var_value == null){
        stig_variables[i].var_value = "";
      }
    }

    await props.createDevice(newName, newOS.id, newParent.group_id, stig_variables);
    props.close();
  }

  const resetDeviceHandler = () => {
    if(unchangedNewSTIGVariables != null)
      setNewSTIGVariables(JSON.parse(JSON.stringify(unchangedNewSTIGVariables)));
  }

  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
            <div style={{display: 'flex', alignItems: 'center'}}>
                {
                    pageOptions.map((option, index) => (
                        <Fragment key={option}>
                            <div className={option === pageType ? "PopupPageTypeButton-Selected" : "PopupPageTypeButton"} onClick={() => setPageType(option)}>
                                { option }
                            </div>
                            <div style={{borderRight: "1px solid black", height: "20px", margin: "0px 5px"}}>
                            </div>
                        </Fragment>
                    ))
                }
            </div>
            <hr style={{marginBottom: "20px", marginTop: "5px"}} />
            {
                pageType === "Device Creation" &&
                <>
                <div style={{display: 'flex', flexDirection: 'column', overflowY: "auto", maxHeight: "calc(100vh - 220px)", gap: "10px",}}>
                    <div>
                    <h2>
                        Device Creation:
                    </h2>
                    <div style={{display: 'flex', alignItems: 'center', gap: "20px", flexWrap: "wrap"}}>
                    <label>
                        <div>Device Name</div>
                        <input type="text" style={{padding: '10px 15px', borderRadius: '5px', fontWeight: 'bold', color: 'gray', borderColor: 'rgba(118, 118, 118, 0.3)'}} value={newName} onChange={(e) => setNewName(e.target.value)} />
                    </label>
                    <div className="audits_filter_datepicker_wrapper">
                        Parent Group:
                        <select style={{padding: '10px 15px', borderRadius: '5px', fontWeight: 'bold', color: 'gray', borderColor: 'rgba(118, 118, 118, 0.3)', cursor: "pointer"}} value={newParent == null ? -1 : newParent.group_id} onChange={(e) => newParentHandler(parseInt(e.target.value))}>
                            <option value={-1}>Select a Group</option>
                            {
                                props.groupList?.map((group) => (
                                    <option key={group.group_id} value={group.group_id}>{group.group_name}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>
                </div>
                <div>

                </div>
                <div>
                    {
                      (newOS != null && newSTIGVariables != null) &&
                      <>
                      <h3 style={{textDecoration: "underline", marginBottom: "5px"}}>
                        Device Variables:
                      </h3>
                      <div style={{display: 'flex', alignItems: 'center', gap: "20px", flexWrap: "wrap"}}>
                          {
                              newSTIGVariables.map((stig_var, index) => (
                                  <label key={index}>
                                      <div>{stig_var.var_name}</div>
                                      <input type="text" style={{padding: '10px 15px', borderRadius: '5px', fontWeight: 'bold', color: 'gray', borderColor: 'rgba(118, 118, 118, 0.3)'}} value={stig_var.var_value} onChange={(e) => newSTIGVariablesHandler(stig_var.var_name, e.target.value)}/>
                                  </label>
                              ))
                          }
                      </div>
                      </>
                    }
                </div>
              </div>
              <div style={{display: 'flex', alignItems: 'center', gap: "10px"}}>
              {
                newOS != null &&
                <input type="button" value="Reset" className="widget-button" style={{marginLeft: "0px", marginTop: "10px"}} onClick={resetDeviceHandler}/>
              }
              <input type="button" value="Create Device" className={deviceCreationSubmitCheck() ? "widget-button" : "widget-button-disable"} style={{marginLeft: "0px", marginTop: "10px"}} disabled={!deviceCreationSubmitCheck()} onClick={submitDeviceHandler}/>
              </div>
              </>  
            }
        </div>
  )
}

export default DevicePopup