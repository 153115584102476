import React from 'react'
import FastForwardIcon from '@mui/icons-material/FastForward';
import DevicePic from "./../../../styles/imgs/DevicePic.png";
import DeviceEditModePic from "./../../../styles/imgs/DeviceEditModePic.png";


const DeviceDoc = (props) => {
  const device_vars = [{variable: "ansible_host", desc: "", value: "string"}];

  return (
    <div className="documentation-container" style={{display: props.display === true ? "flex" : "none", flexDirection: "column", justifyContent:"center" }}>
    <p>One thing with the GUI that we try to aim for is allowing users to easily manage devices. Some ways to accomplish this were by adding in a grouping feature and a device naming scheme. Groups also worked with the ansible automation platform that we use. We wanted to use a list-based system that allows users to filter through different types of fields. Fields can consist of IP/Port numbers, OS, total audits, etc.</p>
    <div style={{display: "flex", width: "90%", padding: "20px 0px"}}>
        <div className='documentation-button' style={{letterSpacing: "1px", cursor: "pointer"}} onClick={() => props.pageClick("Devices")}>
            <FastForwardIcon style={{marginRight: "10px", color: "#FF0000"}} /> Quick Move
        </div>
    </div>
    <div style={{border: "2px solid #A4A4A4",width: "90%"}}>
        <img alt="Device" src={DevicePic} style={{width: "100%", height: "100%"}} />
    </div>
    <h3 className="documentation-subsection-title" id="DeviceGroups" style={{color: props.theme ? "#FFF" : "#2B2B2B"}}>
        Grouping
    </h3>
    <p>
        Groups are a bundle of subgroups and devices. Groups allow us to easily split devices into categories that make them easier to manage. For example, creating a group called "Building_A" and a subgroup called "Floor_01", allows easier distinction of devices. You can remove and delete groups according to certain selections. Removing groups will only remove the subgroup from the parent group. Deleting a subgroup will remove and delete the group entirely.  
    </p>
    <h3 style={{fontSize: "32px", color: props.theme ? "#FFF" : "#2B2B2B", lineHeight: "48px", fontWeight: "normal", paddingTop: "20px"}} id="DeviceVariables">
        Variables
    </h3>
    <p>
        Variables are essential to running audits or remediation. Certain variables are required to be able to run a successful audit or remediation. One of those is "ansible_host". This allows our backend to determine what IP that device is located on. Other variables that can be included are listed below with a description of their usage.
    </p>
    <div style={{padding: "10px"}}>
        <table className="documentation-table">
            <thead>
            <tr>
                <th>Variable</th>
                <th>Description</th>
                <th>Value</th>
            </tr>
            </thead>
            <tbody>
            {
                device_vars.map((device_var,index) =>(
                    <tr key={index}>
                        <td>{device_var.variable}</td>
                        <td>{device_var.desc}</td>
                        <td>{device_var.value}</td>
                    </tr>
                ))
            }
            </tbody>
        </table>
    </div>
    <h3 className="documentation-subsection-title" id="DeviceEditMode" style={{color: props.theme ? "#FFF" : "#2B2B2B"}}>
        Edit Mode
    </h3>
    <p>
        Upon clicking the Edit button at the top right of the Device/Group widget, you will turn on the edit mode for that device. In edit mode, you are allowed to change device or group variables, parent group, and the name of the group or device. You can also add or delete variables as you need. Changes aren't made until the save button is pressed. If you initiated a change and pressed the cancel button, the changes are reverted back to the original state.
    </p>
    <div style={{border: "2px solid #A4A4A4",width: "50%"}}>
        <img alt="Device" src={DeviceEditModePic} style={{width: "100%", height: "50%"}} />
    </div>
</div>
  )
}

export default DeviceDoc