import React from 'react'

const Banner = (props) => {
  return (
    <div style={{width: '100%', height: '40px', backgroundColor: 'red', display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'white', textAlign: 'center'}}>
        { props.licenseError == null ? props.banner : props.licenseError }
    </div>
  )
}

export default Banner