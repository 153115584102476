import React, { useContext } from 'react';
import { UserContext } from '../../../App';

// Icons
import DevicesIcon from '@mui/icons-material/Devices';
import ComputerIcon from '@mui/icons-material/Computer';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AddModeratorIcon from '@mui/icons-material/AddModerator';

const Tab = (props) => {
    const pageClickHandler = () => {
        props.pageClick(props.value);
    }

    return(
    <div title={props.pageClick && "Go to " + props.title} style={{border: props.theme ? "1px solid black" : "1px solid white", borderRadius: "10px", backgroundColor: props.theme ? "#242424" : "#FFF", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", cursor: props.pageClick === undefined || props.pageClick === null ? "" : "pointer", padding: "5px", color: props.theme ? "#fff" : "#444"}} onClick={pageClickHandler}>
        <h3 className="widget-header">
        <span style={{paddingLeft: "5px"}}>{props.title}</span>
        {props.icon}
        </h3>
        <div className="widget-text" style={{fontSize: "28px", fontWeight: "bold" ,display: "flex", paddingLeft: "5px", paddingTop: "5px" }}>
            {props.content}
        </div>
    </div>
    )
    
}

const InventoryBar = (props) => {
    const { theme } = useContext(UserContext);

    const pageClickHandler = (page) => {
        props.pageClick(page);
    }

  return (
    <div style={{width: "100%", justifyContent: "center"}}>
        <div style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", paddingRight: "16px", gap: "10px"}}>
            <Tab title={"Devices"} value={"Devices"} content={props.devices != null ? props.devices.length : "n/a"} pageClick={pageClickHandler} theme={theme} icon={<ComputerIcon />}/>
            <Tab title={"Groups"} value={"Groups"} content={props.host != null ? props.host.length : "n/a"} pageClick={pageClickHandler} theme={theme} icon={<DevicesIcon />}/>
            <Tab title={"Audits"} value={"Audits"} content={props.audits != null ? props.audits : "n/a"} pageClick={pageClickHandler} theme={theme} icon={<AssignmentIcon />}/>
            <Tab title={"Remediations"} value={"Remediations"} content={props.remediate != null ? props.remediate : "n/a"} pageClick={pageClickHandler} theme={theme} icon={<AddModeratorIcon />}/>
        </div>
    </div>
  )
}

export default InventoryBar;