import React, {useState, useContext} from 'react';
import { UserContext } from '../../App';
import { NavLink, useLocation, useNavigate  } from 'react-router-dom';

//Assets
import "./Navigation.css"
import DuroSuiteHorizontalWhite from "./../../styles/imgs/DuroSuiteHorizontalWhite.png";

//Icons
import { Switch } from '@mui/material';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PopupNotification from '../dashboard/profile/PopupNotification';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const Header = (props) => {
  const {user, theme, logout} = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [notificationPopup, setNotificationPopup] = useState(false);
  let navigate = useNavigate();
  let location = useLocation();

  const handleLogout = (e) => {
    e.stopPropagation();
    if(window.confirm("Do you wish to log out?"))
      logout();
  }

  return (
      <div className="navigation-container" style={{backgroundColor: theme ? "#242424" : ""}}>
        <div style={{display: "flex", alignItems: "center"}}>
          <NavLink to="/"><div style={{display: 'flex', alignItems: 'center', justifyContent:'center'}}><img src={DuroSuiteHorizontalWhite} alt="DuroSuite Logo" style={{padding: "15px", marginLeft: "15px", width: "125px", height: "30px"}} /></div></NavLink>
          <NavLink to="/" className={!location.pathname.includes("/viewer") ? 'navigation-navlink-active' : 'navigation-navlink'}>Dashboard</NavLink>
          <NavLink to="/viewer" className={location.pathname.includes("/viewer") ? 'navigation-navlink-active' : 'navigation-navlink'}>Viewer</NavLink>
        </div>

        <div style={{display: 'flex', alignItems: 'center', marginRight: "10px"}}>
          <div style={{display: 'flex', alignItems: 'center', margin: '0px'}}>
            <span><LightModeIcon /></span>
              <Switch checked={theme} onChange={() => props.setTheme(!theme)} />
            <span><DarkModeIcon /></span>
          </div>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: "center" ,margin: '0px 15px', paddingBottom:"3px", cursor: 'pointer'}} onClick={() => setNotificationPopup(true)}>
            <NotificationsIcon />
            <div style={{display: props.notificationList.filter(x => x.notification.acknowledged === false || x.notification.acknowledged == null).length > 0 ? "flex" : "none", position: 'relative', alignItems: 'center', justifyContent: 'center', background: 'red', borderRadius: "50%", width: '12px', textAlign: 'center', height: '12px', fontSize: '10px', right: '12px', bottom: '5px', fontWeight: 'bold'}}>{props.notificationList.filter(x => x.notification.acknowledged === false || x.notification.acknowledged == null).length}</div>
          </div>
          {
            notificationPopup && 
            <PopupNotification notificationList={props.notificationList} setNotificationPopup={setNotificationPopup} setNotificationList={props.setNotificationList}  />
          }
          <div onClick={() => navigate("/profile")} onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)} style={{display: 'flex', alignItems: 'center', cursor: "pointer"}}>
            <span>{user === null ? "n/a" : user.email}</span>
            <AccountCircleIcon fontSize="large" className="navigation-profile-button" onClick={() => navigate("/profile")}/>
            {/* <img src={DuroSuiteIcon} className="navigation-profile-button" onClick={() => navigate("/profile")} /> */}
            {
              open && 
              <div className="navigation-profile-dropdown" style={{top: props.banner ? '76px' : '36px'}}>
                <NavLink to="/profile" className='navigation-profile-link'>
                  <ManageAccountsIcon />
                  My Profile
                </NavLink>
                <NavLink to="" className='navigation-profile-link' onClick={(e) => handleLogout(e)}>
                  Log Out
                </NavLink>
              </div>
            }
          </div>
        </div>
      </div>
  )
}

export default Header