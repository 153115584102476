import React, { useContext, useEffect,  useState } from 'react'
import { CircularProgress } from '@mui/material';
import { UserContext } from '../../../App';

const RemediationsWidget = (props) => {
    const { user, FormatTimestamp, theme, FetchCatch} = useContext(UserContext);
    const [templateList, setTemplateList] = useState([]);

    useEffect(async () => {
        if(props.remediate != null && props.remediate.audit != null){
            if(props.remediate.audit.group_id != null){
                let data = await API_get_group_templates(props.remediate.audit.group_id);
                if(data != null && Array.isArray(data)){
                    setTemplateList(data);
                }else{
                    setTemplateList([]);
                }
            }
        }
    }, [props.remediate])

    const getDeviceIP = (device) => {
        if(device === null) return;
        for(let i in device.device_vars){
          if(device.device_vars[i].var_name === "ansible_host"){
            return device.device_vars[i].var_value;
          }
        }
        return "n/a"
    }

    
    const getTemplateName = (template_id) => {
        if(template_id == null) return "n/a";
        let template = templateList.find(x => x.id === template_id);
        if(template){
            return template.name;
        }else{
            return "n/a";
        }
    }

    const API_get_group_templates = async (group_id) => {
        const url_groups_template = "/api/stigs/templates/group/" + group_id;
        let data = await fetch(url_groups_template,{
            method: 'GET',
            headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${user.token}` },
        })
        .then(res=>{
            if(res.ok) return res.json();
            throw res;
        })
        .then(data =>{
            return data;
        }).catch(async error => {
            return await FetchCatch(error, API_get_group_templates, [group_id]);
        });
    
        return data;
      }

    const remediation_noncomplete = () => {
        return (
            <div className="widget-content">
                <div className="widget-text">
                    <span>Device: </span>
                    <span>{props.remediate.device == null || props.remediate.device.name == null ? "n/a" : props.remediate.device.name}</span>
                </div>
                <div className="widget-text">
                    <span>STIG: </span>
                    <span>{props.remediate.playbook == null || props.remediate.playbook.friendly_name == null ? "n/a" : props.remediate.playbook.friendly_name}</span>
                </div> 
                <div className="widget-text">
                    <span>Template: </span>
                    <span>{props.remediate.audit == null ? "n/a" : getTemplateName(props.remediate.audit.template_id)}</span>
                </div>
                <div className="widget-text">
                    <span>IP/Port: </span>
                    <span>{props.remediate.device == null ? "n/a" : getDeviceIP(props.remediate.device)}</span>
                </div>
                {
                    props.remediate.audit.status == "in-progress" &&
                    <div style={{width: "100%", display: "flex", alignItems:"center", justifyContent:"center",height: "200px"}}><CircularProgress /></div>
                }
            </div>
        )
    }

    const remediation_complete = () => {
        return(
            <div className="widget-content">
                <div className="widget-text">
                    <span>Device: </span>
                    <span>{props.remediate.device == null ? "n/a" : props.remediate.device.name}</span>
                </div>
                <div className="widget-text">
                    <span>STIG: </span>
                    <span>{props.remediate.playbook == null ? "n/a" : props.remediate.playbook.friendly_name}</span>
                </div>
                <div className="widget-text">
                    <span>Template: </span>
                    <span>{props.remediate.audit == null ? "n/a" : getTemplateName(props.remediate.audit.template_id)}</span>
                </div>
                <div className="widget-text">
                    <span>IP/Port: </span>
                    <span>{props.remediate.device == null ? "n/a" : getDeviceIP(props.remediate.device)}</span>
                </div>
                <div className="widget-text">
                    <span>Time Started </span>
                    <span>{FormatTimestamp(props.remediate.audit.time_started)}</span>
                </div> 
                <div className="widget-text">
                    <span>Time Finished: </span>
                    <span>{FormatTimestamp(props.remediate.audit.time_finished)}</span>
                </div> 
                    <div className="widget-text">
                        <span>Time length: </span>
                        <span>{((props.remediate.audit.time_finished - props.remediate.audit.time_started).toFixed(2) / 60).toFixed(2) + " minutes"}</span>
                    </div>
            </div>
        )
    }

    return (
        <div className="widget-container" style={{backgroundColor: theme ? "#242424" : "#FFF", border: theme ? "1px solid #000" : "1px solid #EBEBEB", color: theme ? "#fff" : "#000", borderRadius: "10px", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", width: "90%", margin: "10px 0px", height: "auto"}}>
            <h3 className="widget-header">
            <span>{props.title !== undefined ? props.title : props.mostrecent ? "Most Recent Remediation #" + (props.remediate == null || props.remediate.audit == null ? "n/a" : props.remediate.audit.id) : "Remediation #" + (props.remediate == null || props.remediate.audit == null ? "n/a" : props.remediate.audit.id)}</span>
            </h3>
            {
                props.remediate == null || props.remediate.audit == null ? "" : props.remediate.audit.status === "Complete" ? remediation_complete() : remediation_noncomplete()
            }
        </div>
    )
}

export default RemediationsWidget;