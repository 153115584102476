import React, { useState, Fragment, useContext, useEffect }from 'react'
import { UserContext } from '../../../App';
import { var_password_check } from "../../Helper";
import DatePicker from "react-datepicker";

//Icons
import { ToggleButton, ToggleButtonGroup, Switch } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import InfoIcon from '@mui/icons-material/Info';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';



const GroupEditPopup = (props) => {  
  const { user, FetchCatch } = useContext(UserContext);

  const pageOptions = ['Edit', 'Create Template', 'Schedule Task']
  const [pageType, setPageType] = useState(props.page == null ? pageOptions[0] : props.page);

  const createNewTemplateHandler = (name, group, variables) => {
    props.createTemplate(name, group, variables)
    props.close();
  }

  const scheduleAuditHandler = async (group, device, template, playbook, type, date, repeatDay, repeatTime, typeOfRun) => {
    //type: weekly, daily, or monthly || string?
    //datetime: monday -> friday, or day for monthly || string?
    //time || string?
    //device_id || number
    //group_id || number
    //template_id || number
    if(group == null || template == null || playbook == null || type == null){
        alert("Group, STIG, and Template are required.")
        return;
    }
    if(type === "Daily"){
        if(repeatTime == null){
            alert("Repeat Time are required.");
            return;
        }
    }else if(type === "Weekly"){
        if(repeatDay == null || repeatTime == null){
            alert("Repeat Day and Time are required.");
            return;
        }
    }else{
        if(date == null){
            alert("Schedule Date are required.")
            return;
        }
    }
    let data = await API_Schedule_Repeat(group.group_id, device ? device.device_id : null, playbook.id, template.id, typeOfRun, type, repeatTime ? repeatTime.getTime() : null, repeatDay, date ? date.getTime() : null);
    props.close();
  }

  const scheduleAuditSingleHandler = async (group, device, template, playbook, date, typeOfRun) => {
    //datetime 
    //group_id
    //device_id
    //template_id
    //typeofrun : false = audit || true = remediation
    if(date == null || group == null || template == null || playbook == null) {
        alert("Group, STIG, Template, and Schedule Date are required.")
        return;
    }
    let datetime = date.getTime() / 1000;
    let data = await API_Schedule_Single(group.group_id, device ? device.device_id : null, playbook.id, template.id, typeOfRun, datetime);
    props.close();
  }

  const API_Schedule_Single = async (group_id, device_id, playbook_id, template_id, remediation, datetime) => {
    const url_schedule = "/api/schedule/single";
    let body = {
        "group_id": group_id,
        "template_id": template_id,
        "playbook_id": playbook_id,
        "datetime": datetime,
        "remediation": remediation,
        "device_id": device_id
    }
    let data = await fetch(url_schedule ,{
      method: "POST",
      headers:{
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${user.token}`,
      },
      body: JSON.stringify(body),    
    })
    .then(res=>{
      if(res.ok) return res.json();
      throw res
    })
    .then(data =>{
      return data;
    }).catch(async error => {
      return await FetchCatch(error, API_Schedule_Single, [group_id, datetime, device_id, playbook_id, template_id]);
    })
    
    return data;
  }

  const API_Schedule_Repeat = async (group_id, device_id, playbook_id, template_id, remediation, repeat_type, repeat_time, repeat_day, datetime) => {
    const url_schedule = "/api/schedule/repeat";
    let body = {
        "group_id": group_id,
        "template_id": template_id,
        "playbook_id": playbook_id,
        "remediation": remediation,
        "repeat_type": repeat_type,
        "repeat_day": repeat_day,
        "repeat_time": repeat_time ? repeat_time : "",
        "datetime": datetime ? datetime : -1,
        "device_id": device_id,
    }
    let data = await fetch(url_schedule ,{
      method: "POST",
      headers:{
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${user.token}`,
      },
      body: JSON.stringify(body),    
    })
    .then(res=>{
      if(res.ok) return res.json();
      throw res
    })
    .then(data =>{
      return data;
    }).catch(async error => {
      return await FetchCatch(error, API_Schedule_Repeat, [group_id, datetime, device_id, playbook_id, template_id]);
    })
    
    return data;
  }

  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
            <div style={{display: 'flex', alignItems: 'center'}}>
                {
                    pageOptions.map((option, index) => (
                        <Fragment key={option}>
                            <div className={option === pageType ? "PopupPageTypeButton-Selected" : "PopupPageTypeButton"} onClick={() => setPageType(option)}>
                                { option }
                            </div>
                            <div style={{borderRight: "1px solid black", height: "20px", margin: "0px 5px"}}>
                            </div>
                        </Fragment>
                    ))
                }
            </div>
            <hr style={{marginBottom: "20px", marginTop: "5px", gap: "10px"}} />
            <div style={{height: "calc(100vh - 150px)", overflowY: "auto"}}>
            {
              (pageType === "Edit" && props.select != null && props.select.length > 0) &&
                <EditHost select={props.select[0]} saveDevice={props.saveDevice} saveGroup={props.saveGroup} close={props.close} />
            }
            {
              pageType === "Create Template" ?
              <TemplateCreation select={props.select[0]} groupList={props.groupList} createTemplate={createNewTemplateHandler} />
              :
              pageType === "Schedule Task" ? 
              <ScheduleAudit select={props.select[0]} groupList={props.groupList} playbookList={props.playbookList} deviceList={props.deviceList} scheduleAudit={scheduleAuditHandler} scheduleAuditSingle={scheduleAuditSingleHandler} />
              :
              <></>
            }
            </div>
        </div>
  )
}

const EditHost = (props) => {
    const { user, FetchCatch } = useContext(UserContext);

    const [host, setHost] = useState(null);
    const [hostChanged, setHostChanged] = useState(false);
    const [templateList, setTemplateList] = useState([]);
    const [templateSelect, setTemplateSelect] = useState(null);
    const [template, setTemplate] = useState(null);
    const [templateChanged, setTemplateChanged] = useState(false);
    const [templateVarChanged, setTemplateVarChanged] = useState(false);
    const [unchangedTemplate, setUnchangedTemplate] = useState(null);
    const [baselineTemplate, setBaselineTemplate] = useState(null);

    useEffect(async () => {
        setTemplateChanged(false);
        if(templateSelect){
            let baseline = await API_get_template_variable(templateSelect.id);
            if(!baseline) return;
            setBaselineTemplate(baseline);
            setUnchangedTemplate(JSON.parse(JSON.stringify(baseline)));
            setTemplate(JSON.parse(JSON.stringify(baseline)));
        }else{
            setBaselineTemplate(null);
            setUnchangedTemplate(null);
            setTemplate(null);
        }
    }, [templateSelect])

    useEffect(async () => {
        setHost(JSON.parse(JSON.stringify(props.select)));
        if("group_id" in props.select){
            let templates = await API_get_group_templates(props.select.group_id);
            if(templates){
                setTemplateList(templates);
                let baseline_template = templates.find(x => x.name === "Baseline");
                setTemplateSelect(baseline_template);
            }else{
                setTemplateList([]);
                setBaselineTemplate(null);
            }
        }
    }, [props.select]);

    const submitGroupHandler = async () => {
        await props.saveGroup(host);

        // Handle template changes here
        if(templateVarChanged){
            for(let i in template.connection_vars){
                if(template.connection_vars[i].var_value != unchangedTemplate.connection_vars[i].var_value){
                    let template_update = await API_update_template_var(templateSelect.id, template.connection_vars[i].var_id, template.connection_vars[i].var_name, template.connection_vars[i].var_value);
                }
            }

            for(let i in template.stig_vars){
                if(template.stig_vars[i].id != -1){
                    if(template.stig_vars[i].var_name == 'Deleted'){
                        let template_update = await API_delete_template_var(templateSelect.id, template.stig_vars[i].var_id);
                    }
                    if(template.stig_vars[i].var_value != unchangedTemplate.stig_vars[i].var_value){
                        let template_update = await API_update_template_var(templateSelect.id, template.stig_vars[i].var_id, template.stig_vars[i].var_name, template.stig_vars[i].var_value);
                    }
                }else{
                    if(template.stig_vars[i].var_name != ''){
                        let template_update = await API_add_template_var(templateSelect.id, template.stig_vars[i].var_name, template.stig_vars[i].var_value);
                    }
                }
            }
        }
        props.close();
    }

    const submitDeviceHandler = async () => {
        for(let i in host.device_vars){
            console.log(host.device_vars[i]);
            if(host.device_vars[i].var_id != -1){
                if(host.device_vars[i].var_name == 'Deleted'){
                    let template_update = await API_delete_device_var(host.device_id, host.device_vars[i].var_id);
                }
            }else{
                if(host.device_vars[i].var_name != ''){
                    let template_update = await API_add_device_var(host.device_id, host.device_vars[i].var_name, host.device_vars[i].var_value);
                }
            }
        }
        await props.saveDevice(host);
        props.close();
    }

    const clearHandler = () => {
        setHost(JSON.parse(JSON.stringify(props.select)));
        setTemplate(JSON.parse(JSON.stringify(unchangedTemplate)));
        setHostChanged(false);
        setTemplateChanged(false);
    }

    const addVarHandler = () => {
        let tmp = {...template}
        tmp.stig_vars = tmp.stig_vars.concat({id: -1, var_name: '', var_value: ''})
        setTemplate(tmp);
    }
    
    const hostVarHandler = (type, value) => {
        setHostChanged(true);
        let tmp = {...host};
        tmp[type] = value;
        setHost(tmp);
    }

    const deviceVarHandler = (index, value, param=0) => {
        setHostChanged(true);
        let tmp = {...host};
        if(param == 0){
            tmp.device_vars[index].var_value = value;
        }else if(param == 1){
            tmp.device_vars[index].var_name = value;
        }else if(param == 2){
            tmp.device_vars[index].var_name = 'Deleted'
        }
        setHost(tmp);
    }

    const addDeviceVarHandler = () => {
        let tmp = {...host}
        tmp.device_vars = tmp.device_vars.concat({var_id: -1, var_name: '', var_value: ''})
        setHost(tmp);
    }

    const templateVarHandler = (type, index, value, param=0) => {
        setTemplateVarChanged(true);
        let tmp = {...template}
        if(type === "connection_vars"){
            if(param == 1){
                tmp.connection_vars[index].var_name = value;
            }else if(param == 0){
                tmp.connection_vars[index].var_value = value;
            }else if(param == 2){
                tmp.connection_vars[index].var_name = 'Deleted'
            }
        }else{
            if(param == 1){
                tmp.stig_vars[index].var_name = value;
            }else if(param == 0){
                tmp.stig_vars[index].var_value = value;
            }else if(param == 2){
                tmp.stig_vars[index].var_name = 'Deleted'
            }
        }
        setTemplate(tmp);
    }

    const API_get_group_templates = async (group_id) => {
        const url_groups_template = "/api/stigs/templates/group/" + group_id;
        let data = await fetch(url_groups_template,{
            method: 'GET',
            headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${user.token}` },
        })
        .then(res=>{
            if(res.ok) return res.json();
            throw res;
        })
        .then(data =>{
            return data;
        }).catch(async error => {
            return await FetchCatch(error, API_get_group_templates, [group_id]);
        });
    
        return data;
    }

    const API_get_template_variable = async (template_id) => {
        const url_get_template = "/api/stigs/templates/" + template_id;
        let data = await fetch(url_get_template,{
            method: 'GET',
            headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${user.token}` },
        })
        .then(res=>{
            if(res.ok) return res.json();
            throw res;
        })
        .then(data =>{
            if(data != null){
                data.stig_vars.sort((a,b) => a.var_name > b.var_name ? 1 : -1);
                data.connection_vars.sort((a,b) => a.var_name > b.var_name ? 1 : -1);
            }
            return data;
        }).catch(async error => {
            FetchCatch(error, API_get_template_variable, [template_id]);
            return null;
        });
    
        return data;
    }

    const API_update_template = async (template_id, name, os_id, group_id) => {
        const url_update_template = "/api/stigs/templates";
        let data = await fetch(url_update_template,{
            method: 'PUT',
            headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${user.token}` },
            body: JSON.stringify({"id": template_id, "name": name, "os_id": os_id, "group_id": group_id })
        })
        .then(res=>{
            if(res.ok) return res.json();
            throw res;
        })
        .then(data =>{
            return data;
        }).catch(async error => {
            return await FetchCatch(error, API_update_template, [template_id, name, os_id, group_id]);
        });
    
        return data;
      }

    const API_update_template_var = async (template_id, var_id, var_name, var_value) => {
        const url_save_var = "/api/stigs/templates/var";
        let data = await fetch(url_save_var,{
            method: 'PUT',
            headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${user.token}` },
            body: JSON.stringify({"id": var_id, "template_id": template_id, "var_name": var_name, "var_value": var_value })
        })
        .then(res=>{
            if(res.ok) return res.json();
            throw res;
        })
        .then(data =>{
            return data;
        }).catch(async error => {
            return await FetchCatch(error, props.logout, props.refreshToken, API_update_template_var, [template_id, var_id, var_name, var_value]);
        });

        return data;
    }

    const API_add_template_var = async (template_id, var_name, var_value) => {
        const url_save_var = "/api/stigs/templates/var";
        let data = await fetch(url_save_var,{
            method: 'POST',
            headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${user.token}` },
            body: JSON.stringify({"template_id": template_id, "var_name": var_name, "var_value": var_value })
        })
        .then(res=>{
            if(res.ok) return res.json();
            throw res;
        })
        .then(data =>{
            return data;
        }).catch(async error => {
            return await FetchCatch(error, props.logout, props.refreshToken, API_add_template_var, [template_id, var_name, var_value]);
        });

        return data;
    }

    const API_delete_template_var = async (template_id, var_id) => {
        const url_save_var = "/api/stigs/templates/var/" + var_id;
        let data = await fetch(url_save_var,{
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${user.token}` },
        })
        .then(res=>{
            if(res.ok) return res.json();
            throw res;
        })
        .then(data =>{
            return data;
        }).catch(async error => {
            return true
            // return await FetchCatch(error, props.logout, props.refreshToken, API_delete_template_var, [template_id, var_id]);
        });

        return data;
    }    

    const API_add_device_var = async (device_id, var_name, var_value) => {
        const url_save_var = "/api/devices/vars";
        let data = await fetch(url_save_var,{
            method: 'POST',
            headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${user.token}` },
            body: JSON.stringify({"device_id": device_id, "var_name": var_name, "var_value": var_value })
        })
        .then(res=>{
            if(res.ok) return res.json();
            throw res;
        })
        .then(data =>{
            return data;
        }).catch(async error => {
            return await FetchCatch(error, props.logout, props.refreshToken, API_add_device_var, [device_id, var_name, var_value]);
        });

        return data;
    }

    const API_delete_device_var = async (device_id, var_id) => {
        const url_save_var = "/api/devices/vars/" + var_id;
        let data = await fetch(url_save_var,{
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${user.token}` },
        })
        .then(res=>{
            if(res.ok) return res.json();
            throw res;
        })
        .then(data =>{
            return data;
        }).catch(async error => {
            return true
            // return await FetchCatch(error, props.logout, props.refreshToken, API_delete_template_var, [template_id, var_id]);
        });

        return data;
    }    

    if(host != null && "group_id" in host){
        return (
                <>
                    <div style={{display: 'flex', flexDirection: 'column', overflowY: "auto", maxHeight: "calc(100vh - 220px)", gap: "10px"}}>
                        <h2>
                            Edit Group
                        </h2>
                        <div style={{display: 'flex', alignItems: 'center', gap: "20px", flexWrap: "wrap"}}>
                            <label>
                                <div>Group Name</div>
                                <input type="text" style={{padding: '10px 15px', borderRadius: '5px', fontWeight: 'bold', color: 'gray', borderColor: 'rgba(118, 118, 118, 0.3)'}} value={host ? host.group_name : ""} onChange={(e) => hostVarHandler("group_name", e.target.value)}/>
                            </label>
                            <div className="audits_filter_datepicker_wrapper">
                                Template Select:
                                <select style={{padding: '10px 15px', borderRadius: '5px', fontWeight: 'bold', color: 'gray', borderColor: 'rgba(118, 118, 118, 0.3)', cursor: "pointer", width: "210px"}} value={templateSelect ? templateSelect.id : -1} onChange={(e) => setTemplateSelect(templateList.find(x => x.id == e.target.value))}>
                                    <option value={-1}>Select a template</option>
                                    {
                                        templateList.map((temp, index) => (
                                            <option key={temp.id} value={temp.id}>{temp.name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        {
                            template != null &&
                            <>
                                <h3 style={{textDecoration: "underline", display: 'flex', alignItems: 'center', gap: '5px'}}>
                                    <div title="Some connection variables are case sensitive." style={{display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'help'}}>
                                        <InfoIcon fontSize="small"/>
                                    </div>
                                    Connection Variables:
                                </h3>
                                <div style={{display: 'flex', alignItems: 'center', gap: "20px", flexWrap: "wrap"}}>
                                    {
                                        template.connection_vars.map((d_var, index) => (
                                            <label key={index}>
                                                <div>
                                                    {d_var.var_name}
                                                </div>
                                                {
                                                <input type={var_password_check(d_var.var_name) ? "password" : "text"}  style={{padding: '10px 15px', borderRadius: '5px', fontWeight: 'bold', color: 'gray', borderColor: 'rgba(118, 118, 118, 0.3)'}} value={d_var.var_value} onChange={(e) => templateVarHandler("connection_vars", index, e.target.value)}/>
                                                }
                                            </label>
                                        ))
                                    }
                                </div>
                            </>
                        }
                        {
                            template != null &&
                            <>
                                <h3 style={{textDecoration: "underline"}}>
                                    STIG Variables:
                                    <label style={{marginLeft: "10px"}}>
                                        <input type="button" value="Add Var" className="widget-button" style={{marginLeft: "0px", marginTop: "10px"}} onClick={addVarHandler}/>
                                    </label>
                                </h3>
                                <div style={{display: 'flex', alignItems: 'center', gap: "20px", flexWrap: "wrap"}}>
                                    {
                                        template.stig_vars.map((d_var, index) => (
                                                d_var.id != -1 ?
                                                <label key={index}>
                                                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                                        {d_var.var_name}
                                                        <HighlightOffIcon style={{cursor: "pointer", width: '20px'}} onClick={() => templateVarHandler("stig_vars", index, -2, 2)} />
                                                    </div>
                                                    <input type="text" style={{padding: '10px 15px', borderRadius: '5px', fontWeight: 'bold', color: 'gray', borderColor: 'rgba(118, 118, 118, 0.3)'}} value={d_var.var_value} onChange={(e) => templateVarHandler("stig_vars", index, e.target.value)}/>
                                                </label>
                                                :
                                                <label key={index} style={{display: 'flex', flexDirection: 'column'}}>
                                                    <input type="text" style={{padding: '5px 15px', height: '10px', borderRadius: '5px', fontWeight: 'bold', color: 'gray', borderColor: 'rgba(118, 118, 118, 0.3)'}} value={d_var.var_name} onChange={(e) => templateVarHandler("stig_vars", index, e.target.value, 1)}/>
                                                    <input type="text" style={{padding: '10px 15px', height: '10px', borderRadius: '5px', fontWeight: 'bold', color: 'gray', borderColor: 'rgba(118, 118, 118, 0.3)'}} value={d_var.var_value} onChange={(e) => templateVarHandler("stig_vars", index, e.target.value)}/>
                                                </label>
                                        ))
                                    }
                                </div>
                            </>
                        }
                    </div>
                    <div style={{display: 'flex', alignItems: 'center', gap: "10px"}}>
                        <input type="button" value="Reset" className="widget-button" style={{marginLeft: "0px", marginTop: "10px"}} onClick={clearHandler}/>
                        <input type="button" value="Save Group" className={hostChanged || templateVarChanged || templateChanged ? "widget-button" : "widget-button-disable"} disabled={!hostChanged && !templateVarChanged && !templateChanged} style={{marginLeft: "0px", marginTop: "10px"}} onClick={submitGroupHandler}/>
                    </div>
                </>
        )
    }else if(host != null && "device_id" in host){
        return (
            <>
            <div style={{display: 'flex', flexDirection: 'column', overflowY: "auto", maxHeight: "calc(100vh - 220px)", gap: "10px"}}>
                <h2>
                    Edit Device
                </h2>
                <div style={{display: 'flex', alignItems: 'center', gap: "20px", flexWrap: "wrap"}}>
                    <label>
                        <div>Device Name</div>
                        <input type="text" style={{padding: '10px 15px', borderRadius: '5px', fontWeight: 'bold', color: 'gray', borderColor: 'rgba(118, 118, 118, 0.3)'}} value={host ? host.name : ""} onChange={(e) => hostVarHandler("name", e.target.value)}/>
                    </label>
                </div>
                {
                    <>
                        <h3 style={{textDecoration: "underline"}}>
                            Device Variables:
                            <label style={{marginLeft: "10px"}}>
                                <input type="button" value="Add Var" className="widget-button" style={{marginLeft: "0px", marginTop: "10px"}} onClick={addDeviceVarHandler}/>
                            </label>
                        </h3>
                        <div style={{display: 'flex', alignItems: 'center', gap: "20px", flexWrap: "wrap"}}>
                            {
                                host.device_vars.map((d_var, index) => (
                                    d_var.var_id != -1 ?
                                    <label key={d_var.var_id}>
                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                            {d_var.var_name}
                                            <HighlightOffIcon style={{cursor: "pointer", width: '20px'}} onClick={() => deviceVarHandler(index, -2, 2)} />
                                        </div>
                                        <input type={var_password_check(d_var.var_name) ? "password" : "text"} style={{padding: '10px 15px', borderRadius: '5px', fontWeight: 'bold', color: 'gray', borderColor: 'rgba(118, 118, 118, 0.3)'}} value={d_var.var_value} onChange={(e) => deviceVarHandler(index, e.target.value)}/>
                                    </label>
                                    :
                                    <label key={index} style={{display: 'flex', flexDirection: 'column'}}>
                                        <input type="text" style={{padding: '5px 15px', height: '10px', borderRadius: '5px', fontWeight: 'bold', color: 'gray', borderColor: 'rgba(118, 118, 118, 0.3)'}} value={d_var.var_name} onChange={(e) => deviceVarHandler(index, e.target.value, 1)}/>
                                        <input type="text" style={{padding: '10px 15px', height: '10px', borderRadius: '5px', fontWeight: 'bold', color: 'gray', borderColor: 'rgba(118, 118, 118, 0.3)'}} value={d_var.var_value} onChange={(e) => deviceVarHandler(index, e.target.value)}/>
                                    </label>
                                ))
                            }
                        </div>
                    </>
                }
            </div>
                <div style={{display: 'flex', alignItems: 'center', gap: "10px"}}>
                    <input type="button" value="Reset" className="widget-button" style={{marginLeft: "0px", marginTop: "10px"}} onClick={clearHandler}/>
                    <input type="button" value="Save Device" className={hostChanged ? "widget-button" : "widget-button-disable"} disabled={!hostChanged} style={{marginLeft: "0px", marginTop: "10px"}} onClick={submitDeviceHandler}/>
                </div>
            </>
    )
    }else{
        return <></>
    }
}

const TemplateCreation = (props) => {
    const { user, FetchCatch } = useContext(UserContext);
  
      //Template Creation Variables;
      const [group, setGroup] = useState(null);
      const [variables, setVariables] = useState(null);
      const [newTemplateName, setNewTemplateName] = useState("");
  
      useEffect(() => {
        if(props.select != null && "group_id" in props.select){
            setGroup(props.select);
        }
      }, [props.select])
    
      useEffect(async () => {
          if(group == null){
              setVariables(null);
              return;
          }
          let template_list = await API_get_group_templates(group.group_id);
          if(template_list == null) return;
          let baseline_tmp = template_list.find(x => x.name == "Baseline" && x.group_id == group.group_id);
          if(baseline_tmp == null) return;
          let variables = await API_get_template_variable(baseline_tmp.id);
          setVariables(variables);
      }, [group])
  
      const API_get_template_variable = async (template_id) => {
          const url_get_template = "/api/stigs/templates/" + template_id;
          let data = await fetch(url_get_template,{
              method: 'GET',
              headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${user.token}` },
          })
          .then(res=>{
              if(res.ok) return res.json();
              throw res;
          })
          .then(data =>{
              if(data != null){
                  data.stig_vars.sort((a,b) => a.var_name > b.var_name ? 1 : -1);
                  data.connection_vars.sort((a,b) => a.var_name > b.var_name ? 1 : -1);
              }
              return data;
          }).catch(async error => {
              FetchCatch(error, API_get_template_variable, [template_id]);
              return null;
          });
      
          return data;
      }
  
      const API_get_group_templates = async (group_id) => {
          const url_template_group = "/api/stigs/templates/group/" + group_id;
          let data = await fetch(url_template_group ,{
            headers:{
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${user.token}`,
            }
          })
          .then(res=>{
            if(res.ok) return res.json();
            throw res
          })
          .then(data =>{
            if(Array.isArray(data)){
              for(let i in data){
                data[i].group_id = group_id;
              }
              return data;
            }else{
              return [];
            }
          }).catch(async error => {
            return await FetchCatch(error, API_get_group_templates, [group_id]);
          })
          
          return data;
        }
  
        const variableChangeHandler = (var_id, var_value) => {
          let index = variables.stig_vars.findIndex(x => x.var_id === var_id);
          if(index === -1) return;
          let tmp = {...variables};
          tmp.stig_vars[index].var_value = var_value;
          setVariables(tmp);
        }
  
      const createNewTemplateHandler = async (type) => {
          if(newTemplateName === "" || newTemplateName.toLowerCase() === "baseline"){
              alert("Template name can not be 'baseline' or empty.");
              return;
          }
  
          if(group === null){
              alert("A Group must be selected.");
              return;
          }
          
          await props.createTemplate(newTemplateName, group, [...variables.connection_vars, ...variables.stig_vars]);
      }  
  
      return (
              <>
              <div style={{display: 'flex', flexDirection: 'column', overflowY: "auto", maxHeight: "calc(100vh - 220px)", gap: "10px"}}>
                  <h2>
                      Create Template:
                  </h2>
                  <div style={{display: 'flex', alignItems: 'center', gap: "20px", flexWrap: "wrap"}}>
                      <label>
                          <div>New Template Name</div>
                          <input type="text" style={{padding: '10px 15px', borderRadius: '5px', fontWeight: 'bold', color: 'gray', borderColor: 'rgba(118, 118, 118, 0.3)'}} value={newTemplateName} onChange={(e) => setNewTemplateName(e.target.value)}/>
                      </label>
                      <div className="audits_filter_datepicker_wrapper">
                          Group Select:
                          <select style={{padding: '10px 15px', borderRadius: '5px', fontWeight: 'bold', color: 'gray', borderColor: 'rgba(118, 118, 118, 0.3)', cursor: "pointer"}} value={group ? group.group_id : -1} onChange={(e) => setGroup(e.target.value == -1 ? null : props.groupList.find(x => x.group_id == e.target.value))}>
                              <option value={-1}>Select a group</option>
                          {
                              props.groupList?.map((group_tmp, index) => (
                                  <option key={group_tmp.group_id} value={group_tmp.group_id}>{group_tmp.name}</option>
                              ))
                          }
                          </select>
                      </div>
                  </div>
                  {
                      (group != null && variables != null) &&
                      <div style={{display: 'flex', alignItems: 'center', gap: "20px", flexWrap: "wrap"}}>
                          {
                              variables.stig_vars.map((template_var, index) => (
                                  !template_var.var_name.includes("_remediate") &&
                                  <label key={template_var.var_id}>
                                      <div>{template_var.var_name}</div>
                                      <input type="text" style={{padding: '10px 15px', borderRadius: '5px', fontWeight: 'bold', color: 'gray', borderColor: 'rgba(118, 118, 118, 0.3)'}} value={template_var.var_value} onChange={(e) => variableChangeHandler(template_var.var_id, e.target.value)}/>
                                  </label>
                              ))
                          }
                      </div>
                  }
              </div>
              <div>
                  <input type="button" value="Create Template" className={newTemplateName === "" || newTemplateName.toLowerCase() === "baseline" || group === null ? "widget-button-disable" : "widget-button"} disabled={newTemplateName === "" || newTemplateName.toLowerCase() === "baseline" || group === null} style={{marginLeft: "0px", marginTop: "10px"}} onClick={createNewTemplateHandler}/>
              </div>
              </>
      )
}

const ScheduleAudit = (props) => {
    const { user, FetchCatch, FormatTimestamp, theme } = useContext(UserContext);
    const [repeat, setRepeat] = useState(false);
    const [scheduleDate, setScheduleDate] = useState(null);
    const [group, setGroup] = useState(null);
    const [groupList, setGroupList] = useState([]);
    const [device, setDevice] = useState(null);
    const [deviceList, setDeviceList] = useState(props.deviceList);
    const [templateList, setTemplateList] = useState([]);
    const [template, setTemplate] = useState(null);
    const [playbook, setPlaybook] = useState(null);
    const [playbookList, setPlaybookList] = useState([]);

    const [repeatType, setRepeatType] = useState("Daily");
    const repeatTypeList = ["Daily", "Weekly", "Monthly"];
    const [repeatDay, setRepeatDay] = useState("Monday");
    const repeatDayList = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    const [repeatTime, setRepeatTime] = useState(null);
    const [repeatTimeWeekly, setRepeatTimeWeekly] = useState(null);

    const [typeOfRun, setTypeOfRun] = useState(false); //false = audit || true = remediation

    const [scheduledTaskList, setScheduledTaskList] = useState([]);

    const [minTime, setMinTime] = useState(new Date());

    const darkTheme = createTheme({
        palette: {
          mode: 'dark',
        },
      });

    const lightTheme = createTheme({
    palette: {
        mode: 'light',
    },
    });

    useEffect(() => {
        if(scheduleDate == null) return;
        if(new Date(scheduleDate).setHours(0,0,0,0) == new Date().setHours(0,0,0,0)){
            setMinTime(new Date());
        }else{
            setMinTime(new Date().setHours(0,0,0));
        }
    },[scheduleDate])

    useEffect(() => {
        if(props.select != null){
            if("group_id" in props.select){
                setGroup(props.select);
            }else{
                setDevice(props.select);
                let tmp = props.groupList.filter(x => props.select.groups.find(y => y.group_id == x.group_id) != null);
                setGroupList(tmp);
            }
        }
    }, [props.select])

    useEffect(async () => {
        if(group){
            let template_list = await API_get_group_templates(group.group_id);
            if(template_list){
                setTemplateList(template_list);
            }else{
                setTemplateList([]);
            }
            let scheduled_task_list = await API_get_scheduled_tasks(group.group_id, null);
            setScheduledTaskList(scheduled_task_list == null ? [] : scheduled_task_list);
            setPlaybookList(props.playbookList.filter(x => x.os_id === group.os_id));
            let tmp = props.deviceList.filter(x => x.groups.find(y => y.group_id === group.group_id) != null);
            setDeviceList(tmp);
        }else{
            setTemplateList([]);
            setDeviceList(props.deviceList);
            setPlaybookList([]);
        }
    }, [group])

    useEffect(() => {
        if(!group){
            if(device){
                let tmp = props.groupList.filter(x => device.groups.find(y => y.group_id == x.group_id) != null);
                setGroupList(tmp);
            }else{
                setGroupList(props.groupList);
            }
        }
    }, [device])

    const deleteScheduledTaskHandler = async (scheduled_task_id) => {
        let confirmed = window.confirm("Are you sure you want to delete this scheduled task?");
        if(!confirmed) return;

        let data = await API_delete_scheduled_task(scheduled_task_id);
        if(data){
            let scheduled_task_list = await API_get_scheduled_tasks(group.group_id, null);
            setScheduledTaskList(scheduled_task_list == null ? [] : scheduled_task_list);
        }
    }

    const API_get_group_templates = async (group_id) => {
        const url_template_group = "/api/stigs/templates/group/" + group_id;
        let data = await fetch(url_template_group ,{
          headers:{
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${user.token}`,
          }
        })
        .then(res=>{
          if(res.ok) return res.json();
          throw res
        })
        .then(data =>{
          if(Array.isArray(data)){
            for(let i in data){
              data[i].group_id = group_id;
            }
            return data;
          }else{
            return [];
          }
        }).catch(async error => {
          return await FetchCatch(error, API_get_group_templates, [group_id]);
        })
        
        return data;
    }

    const API_get_scheduled_tasks = async (group_id, device_id) => {
        const url_get_schedule = "/api/schedule" + (group_id != null ? "?group_id=" + group_id : "") + (device_id != null ? "?device_id=" + device_id : "")
        let data = await fetch(url_get_schedule ,{
          headers:{
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${user.token}`,
          },  
        })
        .then(res=>{
          if(res.ok) return res.json();
          throw res
        })
        .then(data =>{
          return data;
        }).catch(async error => {
          return await FetchCatch(error, API_get_scheduled_tasks, [group_id, device_id]);
          return null;
        })
        
        return data;
      }

    const API_delete_scheduled_task = async (scheduled_task_id) => {
        const url_delete_schedule = "/api/schedule/" + scheduled_task_id;
        let data = await fetch(url_delete_schedule ,{
          method: "DELETE",
          headers:{
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${user.token}`,
          },  
        })
        .then(res=>{
          if(res.ok) return true;
          throw res
        })
        .then(data =>{
          return data;
        }).catch(async error => {
          return await FetchCatch(error, API_delete_scheduled_task, [scheduled_task_id]);
        })
        
        return data;
    }

    const FormatDateTime = (datetime, type) => {
        const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        const date = new Date(datetime * 1000);

        if(type === "Single"){
            return FormatTimestamp(datetime);
        }else if(type === "Daily"){
            return (date).toLocaleTimeString("en-US", {
                hour: '2-digit',
                minute: '2-digit',
            });
        }else if(type === "Weekly"){
            return weekdays[date.getDay()] + " " + (date).toLocaleTimeString("en-US", {
                hour: '2-digit',
                minute: '2-digit',
            });
        }else if(type === "Monthly"){
            return ordinal_suffix_of(date.getDate()) + " " + (date).toLocaleTimeString("en-US", {
                hour: '2-digit',
                minute: '2-digit',
            });
        }
    }

    function ordinal_suffix_of(i) {
        var j = i % 10,
            k = i % 100;
        if (j == 1 && k != 11) {
            return i + "st";
        }
        if (j == 2 && k != 12) {
            return i + "nd";
        }
        if (j == 3 && k != 13) {
            return i + "rd";
        }
        return i + "th";
    }

    const testdate = (date) => {
        console.log(new Date(date));
        setScheduleDate(date);
    }

    return (
        <>
        <h2>
            Scheduled Tasks:
        </h2>
        <div style={{display: "flex", flexDirection: "column", gap: "5px" , overflowY: "auto" ,height: "200px", borderRadius: "10px", padding: "5px", marginBottom: "20px"}}>
            {
                scheduledTaskList.map((task, index) => (
                    <div key={task.id} style={{display: "flex", width: "50%", alignItems: 'center', justifyContent: "space-between", border: "1px solid gray", borderRadius: "20px", padding: "5px 10px"}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            {FormatDateTime(task.datetime, task.repeat_type) + " "}
                            |
                            {" " + task.repeat_type + " "}
                            |
                            {task.remediation ? " Remediation " : " Audit "}
                        </div>
                        <div><DeleteForeverIcon style={{color: "red", cursor: "pointer"}} onClick={() => deleteScheduledTaskHandler(task.id)}/></div>
                    </div>
                ))
            }
        </div>
        <h2 style={{marginBottom: "10px"}}>
            Create New Tasks:
        </h2>
        <div style={{display: 'flex', gap: "20px"}}>
        <div>
        <ThemeProvider theme={theme ?  darkTheme : lightTheme}>
            <ToggleButtonGroup
            value={repeat}
            exclusive
            onChange={(e) => setRepeat(e.target.value == "true" ? true : false)}
            aria-label="Platform"
            >
            <ToggleButton value={false}>Singular</ToggleButton>
            <ToggleButton value={true}>Repeat</ToggleButton>
            </ToggleButtonGroup>
        </ThemeProvider>
        </div>
        <div className="widget-text-no-grid" style={{display: "grid", gridTemplateColumns: "20% 20% 15%", width: "300px"}}>
            <span>Audit</span>
            <Switch checked={typeOfRun} onChange={() => setTypeOfRun(!typeOfRun)} />
            <span>Remediation</span>
        </div>
        </div>
        {
            repeat ?
            <div style={{marginTop: "10px"}}>
                <div style={{marginTop: "10px"}}>
                    <div style={{display: 'flex', alignItems: 'center', gap: "20px", flexWrap: "wrap"}}>
                        {
                            "group_id" in props.select ?
                            <>
                            <label>
                                <div>Group</div>
                                <select style={{padding: '10px 15px', borderRadius: '5px', fontWeight: 'bold', color: 'gray', borderColor: 'rgba(118, 118, 118, 0.3)', cursor: "pointer"}} value={group ? group.group_id : -1} onChange={(e) => setGroup(e.target.value == -1 ? null : props.groupList.find(x => x.group_id == e.target.value))}>
                                <option value={-1}>Select a group</option>
                                {
                                    groupList?.map((group_tmp, index) => (
                                        <option key={group_tmp.group_id} value={group_tmp.group_id}>{group_tmp.name}</option>
                                    ))
                                }
                                </select>
                            </label>
                            </>
                            :
                            <>
                            <label>
                                <div>Device</div>
                                <select style={{padding: '10px 15px', borderRadius: '5px', fontWeight: 'bold', color: 'gray', borderColor: 'rgba(118, 118, 118, 0.3)', cursor: "pointer"}} value={device ? device.device_id : -1} onChange={(e) => setDevice(e.target.value == -1 ? null : props.deviceList.find(x => x.device_id == e.target.value))}>
                                <option value={-1}>Select a device</option>
                                {
                                    deviceList.map((device_tmp, index) => (
                                        <option key={device_tmp.device_id} value={device_tmp.device_id}>{device_tmp.name}</option>
                                    ))
                                }
                                </select>
                            </label>
                            <label>
                                <div>Group</div>
                                <select style={{padding: '10px 15px', borderRadius: '5px', fontWeight: 'bold', color: 'gray', borderColor: 'rgba(118, 118, 118, 0.3)', cursor: "pointer"}} value={group ? group.group_id : -1} onChange={(e) => setGroup(e.target.value == -1 ? null : props.groupList.find(x => x.group_id == e.target.value))}>
                                <option value={-1}>Select a group</option>
                                {
                                    groupList?.map((group_tmp, index) => (
                                        <option key={group_tmp.group_id} value={group_tmp.group_id}>{group_tmp.name}</option>
                                    ))
                                }
                                </select>
                            </label>
                            </>
                        }
                        <label>
                            <div>STIG</div>
                            <select style={{padding: '10px 15px', borderRadius: '5px', fontWeight: 'bold', color: 'gray', borderColor: 'rgba(118, 118, 118, 0.3)', cursor: "pointer"}} value={playbook ? playbook.id : -1} onChange={(e) => setPlaybook(e.target.value == -1 ? null : props.playbookList.find(x => x.id == e.target.value))}>
                            <option value={-1}>Select a STIG</option>
                            {
                                playbookList.map((playbook_tmp, index) => (
                                    <option key={playbook_tmp.id} value={playbook_tmp.id}>{playbook_tmp.friendly_name}</option>
                                ))
                            }
                            </select>
                        </label>
                        <label>
                            <div>Template</div>
                            <select style={{padding: '10px 15px', borderRadius: '5px', fontWeight: 'bold', color: 'gray', borderColor: 'rgba(118, 118, 118, 0.3)', cursor: "pointer"}} value={template ? template.id : -1} onChange={(e) => setTemplate(e.target.value == -1 ? null : templateList.find(x => x.id == e.target.value))}>
                            <option value={-1}>Select a template</option>
                            {
                                templateList.map((template_tmp, index) => (
                                    <option key={template_tmp.id} value={template_tmp.id}>{template_tmp.name}</option>
                                ))
                            }
                            </select>
                        </label>
                    </div>
                    <div style={{display: 'flex', flexDirection: "column", gap: "20px", marginTop: "20px"}}>
                        <div>
                            <input id="radio-daily" type="radio" value="Daily" checked={repeatType === "Daily"} onChange={(e) => setRepeatType(e.target.value)} name="repeatType" style={{marginBottom: "10px", cursor: "pointer"}} /> 
                            <label for="radio-daily" style={{cursor: "pointer", paddingLeft: "5px"}}>Daily</label>
                            <div style={{display: 'flex', alignItems: 'center', gap: "20px", flexWrap: "wrap"}}>
                                <div className="audits_filter_datepicker_wrapper">
                                    Repeat Time: 
                                    <DatePicker 
                                    showTimeSelect
                                    showTimeSelectOnly
                                    selected={repeatTime} 
                                    onChange={(date) => setRepeatTime(date)} 
                                    onChangeRaw={(e) => e.preventDefault()} 
                                    dateFormat="h:mm aa"
                                    timeIntervals={5}
                                    disabled={repeatType != "Daily"}/>
                                </div>
                            </div>
                        </div>
                        <div>
                            <input id="radio-weekly" type="radio" value="Weekly" checked={repeatType === "Weekly"} onChange={(e) => setRepeatType(e.target.value)} name="repeatType" style={{marginBottom: "10px", cursor: "pointer"}}/> 
                            <label for="radio-weekly" style={{cursor: "pointer", paddingLeft: "5px"}}>Weekly</label>
                            <div style={{display: 'flex', alignItems: 'center', gap: "20px", flexWrap: "wrap"}}>
                                <label>
                                    <div>Repeat Day</div>
                                    <select style={{padding: '10px 15px', borderRadius: '5px', fontWeight: 'bold', color: 'gray', borderColor: 'rgba(118, 118, 118, 0.3)', cursor: "pointer"}} value={repeatDay} onChange={(e) => setRepeatDay(e.target.value)}>
                                    {
                                        repeatDayList.map((day, index) => (
                                            <option key={day} value={day}>{day}</option>
                                        ))
                                    }
                                    </select>
                                </label>
                                <div className="audits_filter_datepicker_wrapper">
                                    Repeat Time: 
                                    <DatePicker 
                                    showTimeSelect
                                    showTimeSelectOnly
                                    selected={repeatTimeWeekly} 
                                    onChange={(date) => setRepeatTimeWeekly(date)} 
                                    onChangeRaw={(e) => e.preventDefault()} 
                                    dateFormat="h:mm aa"
                                    timeIntervals={5}
                                    disabled={repeatType != "Weekly"}/>
                                </div>
                            </div>
                        </div>
                        <div>
                            <input id="radio-monthly" type="radio" value="Monthly" checked={repeatType === "Monthly"} onChange={(e) => setRepeatType(e.target.value)} name="repeatType" style={{marginBottom: "10px", cursor: "pointer"}} /> 
                            <label for="radio-monthly" style={{cursor: "pointer", paddingLeft: "5px"}}>Monthly</label>
                            <div style={{display: 'flex', alignItems: 'center', gap: "20px", flexWrap: "wrap"}}>
                                <div className="audits_filter_datepicker_wrapper">
                                        Schedule Date: 
                                        <DatePicker 
                                        showTimeSelect
                                        selected={scheduleDate} 
                                        onChange={(date) => setScheduleDate(date)} 
                                        onChangeRaw={(e) => e.preventDefault()} 
                                        dateFormat="h:mm aa dd"
                                        disabled={repeatType != "Monthly"}
                                        />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            :
            <div style={{marginTop: "10px"}}>
                <div style={{display: 'flex', alignItems: 'center', gap: "20px", flexWrap: "wrap"}}>
                    {
                        "group_id" in props.select ?
                        <>
                        <label>
                            <div>Group</div>
                            <select style={{padding: '10px 15px', borderRadius: '5px', fontWeight: 'bold', color: 'gray', borderColor: 'rgba(118, 118, 118, 0.3)', cursor: "pointer"}} value={group ? group.group_id : -1} onChange={(e) => setGroup(e.target.value == -1 ? null : props.groupList.find(x => x.group_id == e.target.value))}>
                            <option value={-1}>Select a group</option>
                            {
                                groupList?.map((group_tmp, index) => (
                                    <option key={group_tmp.group_id} value={group_tmp.group_id}>{group_tmp.name}</option>
                                ))
                            }
                            </select>
                        </label>
                        {/* <label>
                            <div>Device</div>
                            <select style={{padding: '10px 15px', borderRadius: '5px', fontWeight: 'bold', color: 'gray', borderColor: 'rgba(118, 118, 118, 0.3)', cursor: "pointer"}} value={device ? device.device_id : -1} onChange={(e) => setDevice(e.target.value == -1 ? null : props.deviceList.find(x => x.device_id == e.target.value))}>
                            <option value={-1}>Select a device</option>
                            {
                                deviceList.map((device_tmp, index) => (
                                    <option key={device_tmp.device_id} value={device_tmp.device_id}>{device_tmp.name}</option>
                                ))
                            }
                            </select>
                        </label> */}
                        </>
                        :
                        <>
                        <label>
                            <div>Device</div>
                            <select style={{padding: '10px 15px', borderRadius: '5px', fontWeight: 'bold', color: 'gray', borderColor: 'rgba(118, 118, 118, 0.3)', cursor: "pointer"}} value={device ? device.device_id : -1} onChange={(e) => setDevice(e.target.value == -1 ? null : props.deviceList.find(x => x.device_id == e.target.value))}>
                            <option value={-1}>Select a device</option>
                            {
                                deviceList.map((device_tmp, index) => (
                                    <option key={device_tmp.device_id} value={device_tmp.device_id}>{device_tmp.name}</option>
                                ))
                            }
                            </select>
                        </label>
                        <label>
                            <div>Group</div>
                            <select style={{padding: '10px 15px', borderRadius: '5px', fontWeight: 'bold', color: 'gray', borderColor: 'rgba(118, 118, 118, 0.3)', cursor: "pointer"}} value={group ? group.group_id : -1} onChange={(e) => setGroup(e.target.value == -1 ? null : props.groupList.find(x => x.group_id == e.target.value))}>
                            <option value={-1}>Select a group</option>
                            {
                                groupList?.map((group_tmp, index) => (
                                    <option key={group_tmp.group_id} value={group_tmp.group_id}>{group_tmp.name}</option>
                                ))
                            }
                            </select>
                        </label>
                        </>
                    }
                    <label>
                        <div>STIG</div>
                        <select style={{padding: '10px 15px', borderRadius: '5px', fontWeight: 'bold', color: 'gray', borderColor: 'rgba(118, 118, 118, 0.3)', cursor: "pointer"}} value={playbook ? playbook.id : -1} onChange={(e) => setPlaybook(e.target.value == -1 ? null : props.playbookList.find(x => x.id == e.target.value))}>
                        <option value={-1}>Select a STIG</option>
                        {
                            playbookList.map((playbook_tmp, index) => (
                                <option key={playbook_tmp.id} value={playbook_tmp.id}>{playbook_tmp.friendly_name}</option>
                            ))
                        }
                        </select>
                    </label>
                    <label>
                        <div>Template</div>
                        <select style={{padding: '10px 15px', borderRadius: '5px', fontWeight: 'bold', color: 'gray', borderColor: 'rgba(118, 118, 118, 0.3)', cursor: "pointer"}} value={template ? template.id : -1} onChange={(e) => setTemplate(e.target.value == -1 ? null : templateList.find(x => x.id == e.target.value))}>
                        <option value={-1}>Select a template</option>
                        {
                            templateList.map((template_tmp, index) => (
                                <option key={template_tmp.id} value={template_tmp.id}>{template_tmp.name}</option>
                            ))
                        }
                        </select>
                    </label>
                    <div className="audits_filter_datepicker_wrapper">
                        Schedule Date: 
                        <DatePicker 
                        showTimeSelect
                        selected={scheduleDate} 
                        onChange={(date) => testdate(date)} 
                        onChangeRaw={(e) => e.preventDefault()} 
                        dateFormat="MMM d, yyyy h:mm aa"
                        timeIntervals={5}
                        minDate={new Date()}
                        minTime={minTime}
                        maxTime={new Date().setHours(23,59,59)}/>
                    </div>
                </div>

            </div>
        }

        <div>
            {
                repeat ? 
                <input type="button" value="Submit" className={"widget-button"} style={{marginLeft: "0px", marginTop: "10px"}} onClick={() => props.scheduleAudit(group, device ,template, playbook, repeatType, scheduleDate, repeatDay, repeatType === "Daily" ? repeatTime : repeatTimeWeekly, typeOfRun)}/>
                :
                <input type="button" value="Submit" className={"widget-button"} style={{marginLeft: "0px", marginTop: "10px"}} onClick={() => props.scheduleAuditSingle(group, device, template, playbook, scheduleDate, typeOfRun)}/>
            }
        </div>
        </>
    )
}

export default GroupEditPopup