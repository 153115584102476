import React, {useContext, useEffect, useState} from 'react'
import { useLocation } from 'react-router-dom';
import { UserContext } from '../../../App';

// Icons
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import InfoIcon from '@mui/icons-material/Info';
import DownloadIcon from '@mui/icons-material/Download';

const Notifications = (props) => {
  const { theme, user, FetchCatch, FormatTimestamp} = useContext(UserContext);

  let location = useLocation();
  const [notiSelect, setNotiSelect] = useState(null);

  useEffect(() => {
    if(location.state != null){
      if(location.state.id != null){
        let index = props.notificationList.findIndex(x => x.notification.id === location.state.id);
        if(index !== -1){
          setNotiSelect(props.notificationList[index]);
          return;
        }
      }
    }
    setNotiSelect(null);
  },[location.state, props.notificationList]);

  const acknowledgedNoti = async (e, noti, index) => {
    if(e != null)
      e.stopPropagation();
    if(noti.notification.acknowledged) return;
    let id = noti.notification.id;
    const url_acknowledge = "/api/notification/acknowledge?id=" + id;
    let status = await fetch(url_acknowledge ,{
        method: 'PUT',
        headers:{
        'Authorization': `Bearer ${user.token}`
        }
    })
    .then(res=>{
      if(res.ok)
          return res.json();
      throw res;
    })
    .then(data =>{
      return true;
    }).catch(async error => {
      FetchCatch(error, acknowledgedNoti, [noti, index]);
      return false;
    });

    if(status){
      let tmp = [...props.notificationList];
      tmp[index].notification.acknowledged = true;
      tmp.sort((a,b) => a.notification.acknowledged === b.notification.acknowledged ? a.notification.id > b.notification.id ? -1 : 1 : a.notification.acknowledged ? 1 : -1)
      props.setNotificationList(tmp);
    }
  }

  const deleteNoti = async (e, noti, index) => {
    e.stopPropagation();
    let id = noti.notification.id;
    const url_acknowledge = "/api/notification?id=" + id;
    let status = await fetch(url_acknowledge ,{
        method: 'DELETE',
        headers:{
        'Authorization': `Bearer ${user.token}`
        }
    })
    .then(res=>{
      if(res.ok)
          return true;
      throw res;
    })
    .catch(async error => {
      FetchCatch(error, deleteNoti, [e, noti, index]);
      return false;
    });

    if(status){
      if(notiSelect != null && notiSelect.notification.id === noti.notification.id) setNotiSelect(null);
      let tmp = [...props.notificationList];
      tmp.splice(index, 1);
      props.setNotificationList(tmp);
    }
  }

  const downloadNoti = (e, noti) => {
    e.stopPropagation();
    alert("Download Feature is not implemented yet.");
    return;
  }

const getNotiInfo = (noti, key) => {
  if(noti._info[key] == null) return ''
  if(typeof noti._info[key] === 'object'){
    return JSON.stringify(noti._info[key]);
  }
  if(typeof noti._info[key] === 'boolean'){
    return noti._info[key] ? "true" : "false";
  }
  if(key === 'timestamp'){
    return FormatTimestamp(noti._info[key]);
  }
  return noti._info[key];
}

const setNoti = (noti, index) => {
  if(notiSelect != null){
    if(notiSelect.notification.id === noti.notification.id){ 
      setNotiSelect(null);
      return;
    }
  }
  if(!noti.notification.acknowledged){
    acknowledgedNoti(null, noti, index);
  }
  let tmp = {...props.notificationList[index]}; 
  tmp.notification.acknowledged = true;
  setNotiSelect(tmp)
}

  return (
    <div className="inventory-content-wrapper" style={{color: theme ? "white" : "black"}}>
      <div style={{fontSize: "20px", fontWeight: "bold"}}>
          All Notifications:
      </div>
      <div style={{width: "90%", maxHeight: '400px', overflowY: "auto", display: 'block', marginTop: '10px'}}>
        <table style={{borderCollapse: "collapse", width: "100%", textAlign: 'center'}} className="profile-admin-user-table">
          <thead>
            <tr style={{backgroundColor: theme ? "#454545" : "#dddddd"}}>
              <th style={{textAlign: 'center'}}>Message</th>
              <th style={{textAlign: 'center'}}>Timestamp</th>
              <th style={{textAlign: 'center'}}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              props.notificationList.map((noti,index) => (
                <tr key={noti.notification.id} style={{textAlign: 'center', height: "36px", backgroundColor: notiSelect != null && notiSelect.notification.id === noti.notification.id ? "#9BCFFF" : (index % 2 !== 0) ? "#454545" : "#dddddd", cursor: "pointer"}} onClick={() => setNoti(noti, index)}>
                  <td style={{textAlign: 'center', padding: '5px', border: "1px solid #d5d5d5"}}>
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    {noti._info.message}
                    {
                      !noti.notification.acknowledged &&
                      <div style={{width: "6px", height: '6px', color: 'red', borderRadius: '50%', marginLeft: "5px"}}>
                        *
                      </div>
                    }
                  </div>
                  </td>
                  <td style={{textAlign: 'center', padding: '5px', border: "1px solid #d5d5d5", width: '25%'}}>{FormatTimestamp(noti._info.timestamp)}</td>
                  <td style={{textAlign: 'center', border: "1px solid #d5d5d5", width: '10%'}}>     
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>  
                    {
                      !noti.notification.acknowledged &&
                      <div title="Acknowledged" style={{width: '24px', marginRight: '15px'}}>
                          <ThumbUpIcon style={{width: '22px', color: 'lightgreen', cursor: 'pointer'}} onClick={(e) => acknowledgedNoti(e, noti, index)}/>
                      </div>
                    }                         
                    <div title="Delete" style={{width: "24px", display: 'flex'}}>
                        <DeleteForeverIcon style={{color: 'red', cursor: 'pointer'}} onClick={(e) => deleteNoti(e, noti, index)}/>
                    </div>
                    {/* <div title="Download" style={{width: "24px", display: 'flex', marginLeft: '5px'}}>
                      <DownloadIcon style={{cursor: 'pointer'}} onClick={(e) => downloadNoti(e, noti)}/>
                    </div> */}
                    </div>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
      <div style={{fontSize: "20px", fontWeight: "bold", marginTop: "25px"}}>
        Notification Info:
      </div>
      {
        notiSelect != null ?
        <>
        {/* <div style={{width: "90%", display: 'flex', alignItems: 'center', justifyContent: 'space-between', color: "#000"}}>
        <table style={{borderCollapse: "collapse", width: "100%"}}>
          <thead>
            <tr style={{border: "1px solid #d5d5d5", backgroundColor: "#d5d5d5"}}>
              <th style={{padding: '5px', width: "50%"}}>Key</th>
              <th style={{padding: '5px'}}>Value</th>
            </tr>
          </thead>
          <tbody>
            {
              Object.keys(notiSelect._info).map((key,index) => (
                <tr key={index} style={{textAlign: 'center', height: "36px", backgroundColor: (index % 2 !== 0) ? "#e8e8e8" : "white"}}>
                  <td style={{padding: '5px', border: "1px solid #d5d5d5"}}>{key}</td>
                  <td style={{padding: '5px', border: "1px solid #d5d5d5"}}>{getNotiInfo(notiSelect, key)}</td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div> */}
      {
        // Object.keys(notiSelect._info).map((key,index) => (
        //   <div style={{width: '90%', display: 'flex', flexDirection: 'column', fontSize: '18px', marginTop: '15px'}}>
        //     <label>{key}:</label>
        //     <input style={{padding: '10px 15px', borderRadius: '5px', fontWeight: 'bold', color: 'gray'}} type="text" disabled value={getNotiInfo(notiSelect, key)} />
        //   </div>
        // ))
      }
        <div style={{width: '90%', background: 'rgba(239, 239, 239, 0.3)', borderRadius: '15px',border: '2px solid rgba(118, 118, 118, 0.3)', marginTop: '10px', padding: '10px', boxSizing: 'border-box'}}>
          {
            Object.keys(notiSelect._info).map((key,index) => (
              <div key={index} style={{display: 'flex', fontSize: '16px'}}>
                <label>{key}: {getNotiInfo(notiSelect, key)}</label>
              </div>
            ))
          }        
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '10px'}}>
            <button style={{display: 'flex', alignItems: 'center', padding: '0px 5px', cursor: 'pointer'}} onClick={(e) => downloadNoti(e, notiSelect)}>
              <DownloadIcon style={{width: '20px'}} />
              Download
            </button>
          </div>
        </div>

      </>
      :
      <div style={{width: '90%', background: 'rgba(239, 239, 239, 0.3)', borderRadius: '15px',border: '2px solid rgba(118, 118, 118, 0.3)', marginTop: '10px', padding: '10px', boxSizing: 'border-box', minHeight: '280px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <InfoIcon style={{width: '16px', marginRight: '5px'}}/>
          Please select a notification to see more info.
      </div>
      }
    </div>
  )
}

export default Notifications