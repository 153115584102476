import React, {useState, useEffect, useContext} from 'react';
import { UserContext } from '../../../App';

import DoneIcon from '@mui/icons-material/Done';
import InfoIcon from '@mui/icons-material/Info';

import { CircularProgress } from '@mui/material';

const KerberosConfig = (props) => {
    const {user, theme, FetchCatch} = useContext(UserContext);

    const [defaultConfig, setDefaultConfig] = useState(
        {
            "domain_name_servers": [],
            "domain_controllers": [],
            "ansible_user": "",
            "ansible_pass": "",
        }
    );
    const [config, setConfig] = useState(
        {
            "domain_name_servers": [],
            "domain_controllers": [],
            "ansible_user": "",
            "ansible_pass": "",
        }
    );
    const [domainName, setDomainName] = useState("");
    const [domainController, setDomainController] = useState("");
    const [changed, setChanged] = useState(false);
    const [saved, setSaved] = useState(false);
    const [saving, setSaving] = useState(false);

    useEffect(async () => {
        let data = await API_retrieve_config();
        let tmp = {...defaultConfig};
        tmp.domain_name_servers = data.domain_name_servers ? data.domain_name_servers : [];
        tmp.domain_controllers = data.domain_controllers ? data.domain_controllers : [];
        setDefaultConfig(tmp);
        setConfig(tmp);
        setChanged(false);
        setSaved(false);
        setSaving(false);
    }, [])

    const API_retrieve_config = async () => {
        const url_dns_config = "/api/settings/dns";
        let data = await fetch(url_dns_config,{
            headers:{
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${user.token}`
            },
        })
        .then(res=>{
            if(res.ok) return res.json();
            throw res;
        })
        .then(data =>{
            return data;
        }).catch(async error => {
            return await FetchCatch(error, API_retrieve_config, []);
        });  

        return data;
    }

    const handleChange = (val,data) => {
        setChanged(true);
        let tmp_config = {...config};
        tmp_config[val] = data;
        setConfig(tmp_config);
    };

    const handleCancel = () => {
        setConfig({...defaultConfig});
        setChanged(false);
        setSaved(false);
        setSaving(false);
    }

    const handleSave = async () => {
        setSaving(true);
        let data = await API_save_dns(config);
        if(data != null){
            setSaved(true);
            setDefaultConfig({...config});
            setChanged(false);
            setTimeout(() => {
                setSaved(false);
            }, 5000)
        }
        setSaving(false);
    }

    const API_save_dns = async (settings) => {
        const url_dns = "/api/settings/dns";
        let data = await fetch(url_dns,{
            method: 'POST',
            headers:{
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${user.token}`
            },
            body: JSON.stringify(settings),
        })
        .then(res=>{
            if(res.ok) return true;
            throw res;
        })
        .then(data =>{
            return true;
        }).catch(async error => {
            return await FetchCatch(error, API_save_dns, [settings]);
        });  

        return data;
    }

    const addDomain = (status) => {
        setChanged(true);
        let tmp = {...config};
        if(status){
            if(domainName != ""){
                tmp["domain_name_servers"].push(domainName);
            }
            setDomainName("");
        }else{
            if(domainController != ""){
                tmp["domain_controllers"].push(domainController);
            }
            setDomainController("");
        }
        setConfig(tmp);
    }



    const removeDomain = (domain, status) => {
        setChanged(true);
        let tmp = {...config};
        if(status){
            let idx = tmp["domain_name_servers"].findIndex(x => x == domain);
            tmp["domain_name_servers"].splice(idx, 1);
        }else{
            let idx = tmp["domain_controllers"].findIndex(x => x == domain);
            tmp["domain_controllers"].splice(idx, 1);
        }
        setConfig(tmp);
    }

    return (
            <>
                <div className="inventory-content-header">
                    <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                        <h2>Kerberos</h2> 
                        <a href="ConfigureRemotingForAnsible.ps1" download>ConfigureRemotingForAnsible.ps1</a> 
                        <div style={{cursor: "help"}} title="For Ansible to function over WinRM, the following script needs to be run on the endpoint.">
                        <InfoIcon fontSize="small"/>
                        </div>
                    </div>
                </div>
                <div style={{minHeight: "0px", height: "200px", backgroundColor: theme ? "#242424" : "#FFF", border: theme ? "1px solid #000" : "1px solid #EBEBEB", color: theme ? "#fff" : "#000", borderRadius: "10px", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", padding: "15px", marginLeft: "16px"}}>
                    <div style={{display: "grid", gridTemplateColumns: "1fr 1fr"}}>
                        <div style={{display: 'flex', flexDirection: 'column', paddingTop: "15px"}}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <span style={{paddingRight: "10px"}}>DNS Server(s): </span>
                                <input type="text" placeholder='IP Address (eg. 127.0.0.1)' value={domainName} onChange={(e) => setDomainName(e.target.value)}/>
                                <input type="button" value="Add" onClick={() => addDomain(true)} style={{padding: "0px 5px", marginLeft: "5px", cursor: 'pointer'}}/>
                            </div>
                            <div style={{display: 'flex', gap: "2px", flexDirection: "column", marginTop: "5px"}}>
                                {
                                    config.domain_name_servers?.map((name, index) => (
                                        <div key={index} style={{display: 'flex', alignItems: 'center', gap: "10px"}}>
                                            <p>#{index + 1}: {name}</p>                                
                                            <input type="button" value="Remove" onClick={() => removeDomain(name, true)} style={{padding: "0px 5px", marginLeft: "5px", cursor: 'pointer'}}/>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', paddingTop: "15px"}}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <span style={{paddingRight: "10px"}}>Domain Controller(s): </span>
                                <input type="text" placeholder='FQDN (eg. dc1.contoso.com)' value={domainController} onChange={(e) => setDomainController(e.target.value)}/>
                                <input type="button" value="Add" onClick={() => addDomain(false)} style={{padding: "0px 5px", marginLeft: "5px", cursor: 'pointer'}}/>
                            </div>
                            <div style={{display: 'flex', gap: "2px", flexDirection: "column", marginTop: "5px"}}>
                                {
                                    config.domain_controllers?.map((name, index) => (
                                        <div key={index} style={{display: 'flex', alignItems: 'center', gap: "10px"}}>
                                            <p>#{index + 1}: {name}</p>                                
                                            <input type="button" value="Remove" onClick={() => removeDomain(name, false)} style={{padding: "0px 5px", marginLeft: "5px", cursor: 'pointer'}}/>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>

                    <div style={{display: "grid", gridTemplateColumns: "1fr 1fr"}}>
                        <div style={{display: 'flex', alignItems: 'baseline', paddingTop: "15px"}}>
                            <span style={{paddingRight: "10px"}}>Local Admin Username: </span>
                            <input type="text" placeholder="RHEL Admin (eg. duroadmin)" value={config.ansible_user} onChange={(e) => handleChange("ansible_user", e.target.value)}/>
                        </div>
                        <div style={{display: 'flex', alignItems: 'baseline', paddingTop: "15px"}}>
                            <span style={{paddingRight: "10px"}}>Local Admin Password: </span>
                            <input type="password" placeholder="Password" value={config.ansible_pass} onChange={(e) => handleChange("ansible_pass", e.target.value)}/>
                        </div>
                    </div>
                </div>
                <div style={{margin: "15px", display: 'flex', alignItems: 'center'}}>
                    <input type="button" value="Cancel" className="widget-button" onClick={handleCancel} />
                    <input type="button" value="Save" className={changed ? "widget-button" : "widget-button-disable"} onClick={handleSave} disabled={!changed} />
                    {
                        saved ? 
                        <span style={{display: 'flex', alignItems: 'center'}}><div style={{color: "#34B233"}}><DoneIcon /></div>Saved</span>
                        :
                        <>
                            {
                                saving &&
                                <span style={{display: 'flex', alignItems: 'center'}}><CircularProgress style={{width: "24px", height: "24px"}} /></span>
                                
                            }
                        </>
                    }
                </div>
            </>
    )
}

export default KerberosConfig;