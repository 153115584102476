import React, { useEffect, useState, createContext } from "react";
import { BrowserRouter } from "react-router-dom";

//Components
import Main from "./components/Main";
import Login from "./components/login/Login"
// import logo from "./styles/imgs/AgileDefenseLogo.png";
import DuroSuiteLogo from "./styles/imgs/DuroSuiteLogoHorizontal.png";
import Popup from "./components/Popup";
import Banner from "./components/Banner";

//CSS
import './styles/css/App.css';

const getTheme = () => {
  const tokenString = sessionStorage.getItem('theme');
  if(tokenString === null || tokenString === undefined)
    sessionStorage.setItem('theme', JSON.stringify(false));
  const userToken = JSON.parse(tokenString);
  return userToken;
};

const getToken = () => {
  const tokenString = sessionStorage.getItem('token');
  const userToken = JSON.parse(tokenString);
  return userToken;
};

const getRefreshToken = () => {
  const tokenString = sessionStorage.getItem('refresh-token');
  const userToken = JSON.parse(tokenString);
  return userToken;
};

function App() {
  const [bannerConfig, setBannerConfig] = useState({brand: "AgileDefense", color: "#09347a", text: ""})
  const [theme, setTheme] = useState(getTheme()); //false = light || true = dark
  const [token, setToken] = useState(getToken());
  const [refreshToken, setRefreshToken] = useState(getRefreshToken());
  const [user, setUser] = useState(null);
  const [logoutNoti, setLogoutNoti] = useState(null);
  const [userName, setUsername] = useState(null);
  const [refreshLoading, setRefreshLoading] = useState(false);
  // const [userLoading, setUserLoading] = useState(false);
  const [refreshTimer, setRefreshTimer] = useState(null);
  const [userError, setUserError] = useState(null);
  const [timezone, setTimezone] = useState("Locale");

  // Notification Popups
  const [notificationMsg, setNotificationMsg] = useState([]);
  const [notificationMsgTimeout, setNotificationMsgTimeout] = useState(null);

  const [bannerMode, setBannerMode] = useState(0);
  const [banner, setBanner] = useState(null);
  var userLoading = false;

  useEffect(() => {
    async function fetchlicense(){
      let data = await API_get_license_status();
      if(data != null){
        if(data.status === 'No License Found'){
          setBannerMode(-1);
          setBanner('No License Found. If you do not have a license key, please contact DuroSuite or Agile Defense for more information.')
        }else if(data.status === 'Success'){
          setBannerMode(0);
          setBanner(null);
        }else if(data.status === 'Warning'){
          setBannerMode(1);
          // var timeleft = data.expiration_date - (new Date()).getTime();
          // var days = Math.ceil((((timeleft / 1000) / 60) / 60) / 24)
          setBanner(`DuroSuite expires on ${FormatTimestamp(data.expiration_date)}. DuroSuite will go into read-only mode after expiration date.`)
        }else if(data.status === 'Expired'){
          setBannerMode(2);
          setBanner(`DuroSuite's license has expired. DuroSuite will remain in read-only mode until ${FormatTimestamp(data.expiration_date)}. If you do not have a license key, please contact DuroSuite or Agile Defense for more information.`)
        }else if(data.status === 'No Authorization'){
          setBannerMode(3)
          setBanner("DuroSuite's license has expired. If you do not have a license key, please contact DuroSuite or Agile Defense for more information.")
        }
      }
    }
    fetchlicense()
  }, [])

  useEffect(async() => {
    if(user === null || theme === user.settings.dark_mode) return;
    async function fetchuser(){
      const user_api = "/api/users/settings";
      await fetch(user_api,{
        method: "PUT",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({user_id: user.id, dark_mode: theme})
      }).then(res => {
        if(!res.ok)
          throw "Error has occured when changing user's settings."
      }).catch(error => {
        console.log(error);
      })
      let tmp_user = {...user};
      tmp_user.settings.dark_mode = theme;
      setUser(tmp_user);
    }
    fetchuser()
  },[theme])

  useEffect(() => {
    if(logoutNoti !== null){
      setTimeout(function(){
          setLogoutNoti(null);
      }, 5000);
    }
  },[logoutNoti]);

  useEffect(() => {
    if(notificationMsg != null && notificationMsg.length > 0){
        if(notificationMsgTimeout !== null){
            clearTimeout(notificationMsgTimeout);
        }
        setNotificationMsgTimeout(setTimeout(function(){
            let tmp = [...notificationMsg];
            tmp.splice(0,1);
            setNotificationMsg(tmp);
        }, 5000));
    }else{
        setNotificationMsgTimeout(null);
    }
  },[notificationMsg]);

  useEffect(() => {
    if(token == null || refreshToken == null || user == null) return;
    if(refreshTimer == null){
      if(user.roles.find(x => x.role_name === "system_admin")){
        setRefreshTimer(setTimeout(() => {
          if(token != null && refreshToken != null){
            refresh();
          }
        }, 9 * 60 * 1000));
      }else{
        setRefreshTimer(setTimeout(() => {
          if(token != null && refreshToken != null){
            refresh();
          }
        }, 14 * 60 * 1000));
      }
    }
  },[token, user]);

  
  useEffect(async() => {
    async function fetchuser(){
      let data = await API_Get_User();
      if(data){
        data.token = token;
        sessionStorage.setItem('token', JSON.stringify(token));
        sessionStorage.setItem('refresh-token', JSON.stringify(refreshToken));
        if(data.settings.length > 0){
          setTheme(data.settings[0].dark_mode);
        }
        setUser(data);
        userLoading = false;
        setUserError(null);
      }
    }
    if(token && user === null && !userLoading && bannerMode != 3111){
      fetchuser();
    }
  }, [token, user, userLoading]);

  const user_is_admin = () => {
    if(user != null && user.roles.find(x => x.role_name === "system_admin")){
      return true;
    }
    return false
  }

  /*
    API Get to recieve license status
  */
  const API_get_license_status = async() => {
    const license_status_url = "/api/settings/license/status";
    return await fetch(license_status_url,{
      headers: {
        'accept': 'application/json',
      },
    }).then(res => {
      if(res.ok)
        return res.json();
      throw res;
    }).then(data => {
      return data;
    }).catch(async response => {
      setBannerMode(-2);
      try{
        let res = await response.json();
        if(res && res.detail){
          setBanner(res.detail);
        }else{
          setBanner("License Status return an error. DuroSuite does not have a license. Please Contact DuroSuite for Assistance.");
        }
      }catch{
        setBanner("License Status return an error. DuroSuite does not have a license. Please Contact DuroSuite for Assistance.");
      }

      return null;
    });
  }

  /*
    API GET to retrieve Users info
  */
  const API_Get_User = async (diffToken=null) => {
    userLoading = true;
    const user_api = "/api/users/me";
    return await fetch(user_api,{
      headers: {
        'accept': 'application/json',
        'Authorization': `Bearer ${diffToken === null ? token : diffToken}`
      },
    }).then(res => {
      if(res.ok)
        return res.json()
      throw res;
    }).then(data => {
      return data;
    }).catch(async res => {
      userLoading = false;
      if(res.status == 403){
        let detail = await res.json();
        detail.token = token
        setUserError(detail);
      }
      setToken(null);
      return null;
    });
  }

  const FetchCatch = async (res, callback, param) => {
    if(res == null) await logout();
    let detail = null;
    if(res.status === 204){
      return true;
    }
    if(res.status >= 400 && res.status < 500){
        try{
            let tmp = await res.json();
            detail = tmp.detail;

        }catch{
            detail = null;
        }
    }
    console.log('Error Detail Response: ', detail);
    if(detail != null && (detail == 'Invalid or expired token.' || detail == 'Signature has expired')){
        let result = await refresh();
        if(result)
            return await callback.apply(this, param);
        else
            await logout();
    }
    if(detail != null){
      notificationMsgHandler("Error: " + detail);
    }else{
      notificationMsgHandler("Error: No response's detail");
    }
    return null;
  }

  const FormatTimestamp = (time) =>{
    if(time == null) return "";
    return (new Date(time * 1000)).toLocaleDateString("en-US", timezone === "Locale" ? {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
    } : {
        timeZone: timezone,
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
    }).replace(",", "")
  }


  const refresh = async () => {
    sessionStorage.removeItem('token');
    setRefreshLoading(true);
    const refresh_api = "/api/refresh-token";
    let result = await fetch(refresh_api,{
      method: "POST",
      headers: {
        'accept': 'application/json',
        'Authorization': `Bearer ${refreshToken}`
      },
    }).then(res => {
      if(res.ok){
        return res.json();
      }else{
        return null;
      }
    }).then(data => {
      if(data != null){
        let user_tmp = {...user};
        user_tmp.token = data.access_token;
        user.token = data.access_token;
        sessionStorage.setItem('token', JSON.stringify(data.access_token));
        setUser(user_tmp);
        setToken(data.access_token);
        return true
      }else{
        setToken(null);
        setUser(null);
        return false;
      }
    });
    setRefreshLoading(false);
    return result;
  }

  const logout = async () => {
    if(refreshTimer != null){
      clearTimeout(refreshTimer);
      setRefreshTimer(null);
    }
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('refresh-token');
    setToken(null);
    setRefreshToken(null);
    setUsername(user.email);
    setUser(null);
    setUserError(null);

    const refresh_api = "/api/refresh-revoke";
    await fetch(refresh_api,{
      method: "DELETE",
      headers: {
        'accept': 'application/json',
        'Authorization': `Bearer ${refreshToken}`
      },
    }).then(res => {
      if(res.ok){
        console.log('Refresh-token removed successfully');
      }else{
        console.log('Refresh-token removed unsuccessfully');
      }
    });

    const access_api = "/api/access-revoke";
    await fetch(access_api,{
      method: "DELETE",
      headers: {
        'accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }).then(res => {
      if(res.ok){
        setLogoutNoti("Logout Successfully");
      }else{
        setLogoutNoti("Logout Unsuccessfully");
      }
    });
  }


  const clearNotification = () => {
    if(notificationMsgTimeout != null){
      setNotificationMsgTimeout(null);
      clearTimeout(notificationMsgTimeout);
    }
    let tmp = [...notificationMsg];
    tmp.splice(0,1);
    setNotificationMsg(tmp);
  }

  const notificationMsgHandler = async (msg) => {
    let tmp = [...notificationMsg];
    tmp.push(msg);
    setNotificationMsg(tmp);
  }

  if(((user == null || token == null) && !refreshLoading) || bannerMode == 3 || bannerMode == -1 || bannerMode == -2) {
    return (
      <>
        {
          banner != null &&
          <Banner banner={banner} bannerMode={bannerMode}/>
        }
        <Login setToken={setToken} setRefreshToken={setRefreshToken} email={userName} setUser={setUser} bannerConfig={bannerConfig} theme={theme} error={userError} token={token} banner={banner != null ? true : false} bannerMode={bannerMode}/>
        <Popup display={logoutNoti !== null ? true : false} msg={logoutNoti} close={() => setLogoutNoti(null)} />
      </>
    )
  }

  // If theres a token, a user, license is valid.
  if(token != null && user != null && (bannerMode != 3 || bannerMode != -1 || bannerMode != -2)){
    try{
    return (
      <div className="App" style={{overflowX:"hidden",minHeight: "100vh", backgroundColor: theme ? "rgb(18, 18, 18)" : "white"}}>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <Popup display={notificationMsg != null && notificationMsg.length > 0 ? true : false} msg={notificationMsg != null && notificationMsg.length > 0 ? notificationMsg[0] : ""} close={clearNotification} theme={theme} />
          <UserContext.Provider value={{user: user, setUser: setUser, theme: theme, FetchCatch: FetchCatch, logout: logout, refreshToken: refresh, FormatTimestamp: FormatTimestamp, AddNotification: notificationMsgHandler, UserIsAdmin: user_is_admin}}>
            <Main bannerConfig={bannerConfig} setTheme={setTheme} setTimezone={setTimezone} banner={banner} />
          </UserContext.Provider>
        </BrowserRouter>
      </div>    
    );
    }catch{
      logout();
    }
  }

  return(
    <div className="App" style={{overflowX:"hidden",minHeight: "100vh"}}>
      <div style={{display: "flex", background: theme != null && theme ? "#121212" : "" , top: 0, left: 0, position: "absolute", width: "100vw", height: "100vh", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
            <a href="https://agile-defense.com/" target="_blank"><img src={DuroSuiteLogo} alt="DuroSuite Logo" /></a>
      </div>
    </div>
  )
}

export const UserContext = createContext(null);

export default App;
