import React, { useContext } from 'react'
import { UserContext } from '../../App';

import "./PopupPage.css";

//Icons
import CloseIcon from '@mui/icons-material/Close';

const PopupPage = (props) => {
  const { theme } = useContext(UserContext);

  const clickDetection = (e) => {
    e.stopPropagation();
    props.close();
  }

  return (
    <div className="PopupPage-Wrapper" onMouseDown={clickDetection}>
        <div className="PopupPage-Container" style={{background: theme ? "rgb(36, 36, 36)" : "white", color: theme ? "white" : "black", padding: "10px"}} onMouseDown={(e) => e.stopPropagation()} onClick={(e) => e.stopPropagation()}>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: "20px"}}>
            <h1>{props.title}</h1>
            <div title="Close Popup" className="PopupPage-CloseBtn" style={{cursor: "pointer"}} onClick={() => props.close()}><CloseIcon /></div>
          </div>
          { props.content }
        </div>
    </div>
  )
}

export default PopupPage;