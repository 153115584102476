import React, {useState, useEffect, useContext, useRef} from 'react'
import { UserContext } from "../../../App";

// Components
import AdminSettingsUserPopup from './AdminSettingsUserPopup';

// Icons
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import GroupIcon from '@mui/icons-material/Group';
import { CircularProgress } from '@mui/material';

const AdminSettings = (props) => {
    const { user, FetchCatch, theme, logout, FormatTimestamp} = useContext(UserContext);
    const [users, setUsers] = useState([])
    const [editUser, setEditUser] = useState(null);
    const [allRoles, setAllRoles] = useState([]);
    const [backupDownloading, setBackupDownloading] = useState(false);
    const [backupUploading, setBackupUploading] = useState(false);
    const [fileUploading, setFileUploading] = useState(false);
    const [newUserEmail, setNewUserEmail] = useState("");
    const [newUserPassword, setNewUserPassword] = useState("");
    const [newUserConfirm, setNewUserConfirm] = useState("");
    const [newUserErrorMsg, setNewUserErrorMsg] = useState(null);
    const [newUserErrorMsgTimer, setNewUserErrorMsgTimer] = useState(null);
    const inputUpdateRef = useRef(null);
    const inputDBRef = useRef(null);
    const inputLicenseRef = useRef(null);
    const [licenseInfo, setLicenseInfo] = useState(null);
    const [versionInfo, setVersionInfo] = useState(null);

    useEffect(() => {
      getLicenseInfo()
      getVersionInfo()
    }, [])
    const getLicenseInfo = async() => {
      let data = await API_get_license_info();
      setLicenseInfo(data);
    }
    
    const getVersionInfo = async() => {
      let data = await API_get_version_info();
      setVersionInfo(data);
    }

    const API_get_license_info = async () => {
      const get_license_info_url = "/api/settings/license";
      let data = await fetch(get_license_info_url,{
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${user.token}`
          },
        }).then(res => {
          if(res.ok){
            return res.json()
          }
          throw res;
        }).then(data => {
          return data;
        }).catch(async error => {
          return await FetchCatch(error, API_get_license_info, []);
      })
      return data;
    }  
    
    const API_get_version_info = async () => {
      const get_version_info_url = "/api/settings/version";
      let data = await fetch(get_version_info_url,{
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${user.token}`
          },
        }).then(res => {
          if(res.ok){
            return res.json()
          }
          throw res;
        }).then(data => {
          return data;
        }).catch(async error => {
          return await FetchCatch(error, API_get_version_info, []);
      })
      return data;
    }

    useEffect(() => {
      if(newUserErrorMsg !== null && newUserErrorMsgTimer === null){
        setNewUserErrorMsgTimer(setTimeout(() => {
          setNewUserErrorMsg(null);
        }, 10000));
      }
    }, [newUserErrorMsg]);
  
  const handleDBBackup = async () => {
    if(backupDownloading) return;
    setBackupDownloading(true);
    const url_fetch = '/api/settings/backup';
    let filename = "";
    let data = await fetch(url_fetch,{
        headers:{
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${user.token}`
        }
    })
    .then(res=>{
        if(res.ok){
            const header = res.headers.get('content-disposition');
            if(header == null){
                filename = prompt("Please enter a file name");
            }else{
                const parts = header.split(';');
                filename = parts[1].split('=')[1];
                filename = filename.substring(1, filename.length - 1);
            }
            return res.blob();
        }else{
            if(res.status === 404){
                alert('File Not Found.');
                return null;
            }
            throw res;
        }
    })
    .then(data =>{
        if(data == null) return;
        let a = document.createElement('a');
        a.href = window.URL.createObjectURL(data);
        a.download = filename;
        a.click();
        a.remove();
        return true;
    }).catch(async error => {
        return await FetchCatch(error, handleDBBackup, []);
    });  

    setBackupDownloading(false);
    return data;
  }


  const DBFileHandler= async () => {
      inputDBRef.current.click();
  }

  const uploadFileHandler = async () => {
    if(window.confirm("Are you sure you want to update DuroSuite? \n\n* Recommend to Backup DB before applying an update.")){
      inputUpdateRef.current.click();
    }
  }

  const updateFileRef = async (e) => {
    if(e.target.files.length === 1){
      var form_data = new FormData();
      form_data.append('db_file', e.target.files[0]);
      setFileUploading(false);
      // let data = await API_update_file(form_data);
    }
  }

  const DBFileRef = async (e) => {
    if(e.target.files.length === 1){
      var form_data = new FormData();
      form_data.append('db_file', e.target.files[0]);
      await API_restore_db(form_data);
    }
  }

    useEffect(() => {
        if(user == null) return;
        async function fetchdata(){
          let roles = await API_get_roles();
          if(roles){
            setAllRoles(roles);
          }else{
            setAllRoles([]);
          }
  
          let users = await API_get_users();
          if(users){
            users.sort((a,b) => a.email > b.email ? 1 : -1);
            for(let i in users){
              users[i].edit = false;
            }
            setUsers(users);
          }else{
            setUsers([]);
          }
        }

        fetchdata()
    },[user])

    const API_get_roles = async () => {
      const get_roles_api = "/api/users/roles/all";
      let data = await fetch(get_roles_api,{
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${user.token}`
          },
        }).then(res => {
          if(res.ok){
            return res.json()
          }
          throw res;
        }).then(data => {
          return data;
        }).catch(async error => {
          return await FetchCatch(error, API_get_roles, []);
      })

      return data;
    }

    const API_get_users = async () => {
      const get_users_api = "/api/users/all";
      let data = await fetch(get_users_api,{
        headers: {
          'accept': 'application/json',
          'Authorization': `Bearer ${user.token}`
        },
      }).then(res => {
        if(res.ok) return res.json();
        throw res;
      }).then(data => {
        return data;
      }).catch(async error => {
        return await FetchCatch(error, API_get_users, []);
      })

      return data;
    }

    const API_restore_db = async (file) => {
      setBackupUploading(true);
      const restore_db_api = "/api/settings/restore";
      let data = await fetch(restore_db_api,{
        method: 'POST',
        headers: {'Authorization': `Bearer ${user.token}` },
        body: file
      }).then(res => {
        if(res.ok) return res.json();
        throw res;
      }).then(data => {
        setBackupUploading(false);
        return data;
      }).catch(async error => {
        setBackupUploading(false);
        return await FetchCatch(error, API_restore_db, [file]);
      })

      return data;
    }

    const disabledOnChangeHandler = async (id) => {
        let tmp = [...users];
        let index = tmp.findIndex(x => x.id === id);
        let value = !tmp[index].disabled;
        tmp[index].disabled = value;
        setUsers(tmp);
        if(!value){
          await API_enable_user(id);
        }else{
          await API_disable_user(id);
        }
    }

    const API_enable_user = async (user_id) => {
      const enable_user_api = "/api/users/enable?user_id=" + user_id;
      let data = fetch(enable_user_api,{
      method: 'PUT',
        headers: {
          'accept': 'application/json',
          'Authorization': `Bearer ${user.token}`
        },
      }).then(res => {
        if(res.ok) return res.json();
        throw res;
      }).then(data => {
          console.log("Enabled User's ID: " + user_id);
          return true;
      }).catch(async error => {
        return await FetchCatch(error, API_enable_user, [user_id]);
      })

      return data;
    }

    const API_disable_user = async (user_id) => {
      const disable_user_api = "/api/users/disable?user_id=" + user_id;
      let data = await fetch(disable_user_api,{
      method: 'PUT',
        headers: {
          'accept': 'application/json',
          'Authorization': `Bearer ${user.token}`
        },
      }).then(res => {
        if(res.ok) return '';
        throw res;
      }).then(data => {
        console.log("Disabled User's ID: " + user_id);
        return true;
      }).catch(async error => {
        return await FetchCatch(error, API_disable_user, [user_id]);
      })

      return data;
    }

    const API_add_user = async (email, password) => {
      const new_user_api = "/api/users";
      let data = await fetch(new_user_api,{
        method: 'POST',
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.token}`
        },
        body: JSON.stringify({"email": email, "password": password})
      }).then(res => {
        if(res.ok) return res.json();
        throw res;
      }).then(data => {
        return data;
      }).catch(async error => {
        if(error.status === 404){
          try{
            let detail = (await error.json()).detail;
            if(detail != null){
              setNewUserErrorMsg(detail);
              return;
            }
          }catch{

          }
        }
        return await FetchCatch(error, API_add_user, [email, password]);
      })

      return data;
    }

    const deleteUserHandler = async (user) => {
      if(window.confirm("Are you sure you want to delete: " + user.email + "?")){
        let status = await API_delete_user(user.id);
        if(status){
          let tmp = [...users];
          let index = tmp.findIndex(x => x.id === user.id);
          if(index !== -1){
            tmp.splice(index, 1);
            setUsers(tmp);
          }
        }
      }
    }

    const API_delete_user = async (user_id) => {
      const delete_user_api = "/api/users/" + user_id;
      let data = await fetch(delete_user_api,{
        method: 'DELETE',
        headers: {
          'accept': 'application/json',
          'Authorization': `Bearer ${user.token}`
        },
      }).then(res => {
        if(res.ok) return true;
        throw res;
      }).then(data => {
        return true;
      }).catch(async error => {
        return await FetchCatch(error, API_delete_user, [user_id]);
      })

      return data;
    }

    const removeRole = async (perm_id) => {
      if(editUser === null) return;
      let tmp_users = [...users];
      let index = tmp_users.findIndex(x => x.id === editUser.id);
      let role_index = tmp_users[index].roles.findIndex(x => x.perm_id == perm_id);
      if(perm_id == 1 || perm_id == 2 || perm_id == 3){
        let status = await API_remove_system_role(editUser.id, perm_id);
        if(status){
          console.log("Remove role successful");
          tmp_users[index].roles.splice(role_index,1);
          setUsers(tmp_users);
          if(editUser.id == user.id){
            alert("Moving to login screen, re-login due to role changing for your user.");
            logout();
          }
        }
      }else{
        let status = await API_remove_role(editUser.id, perm_id);
        if(status){
          console.log("Remove role successful");
          tmp_users[index].roles.splice(role_index,1);
          setUsers(tmp_users);
        }
      }

    }

    const API_remove_system_role = async (user_id, perm_id) => {
      const remove_user_api = "/api/users/roles/remove";
      let data = await fetch(remove_user_api,{
        method: 'PUT',
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.token}`,
        },
        body: JSON.stringify({user_id: user_id, role_id: perm_id})
      }).then(res => {
        if(res.ok) return '';
        throw res;
      }).then(data => {
        return true;
      }).catch(async error => {
        return await FetchCatch(error, API_remove_system_role, [user_id, perm_id]);
      })

      return data;
    }

    const API_remove_role = async (user_id, perm_id) => {
      const remove_user_api = "/api/groups/user/" + user_id + "/" + perm_id;
      let data = await fetch(remove_user_api,{
        method: 'DELETE',
        headers: {
          'accept': 'application/json',
          'Authorization': `Bearer ${user.token}`,
        },
      }).then(res => {
        if(res.ok) return '';
        throw res;
      }).then(data => {
        return true;
      }).catch(async error => {
        return await FetchCatch(error, API_remove_role, [user_id, perm_id]);
      })

      return data;
    }

    const addRole = async (role_id) => {
      if(editUser === null) return;
      let role = allRoles.find(x => x.id == role_id);
      let group_id = role.group_id;
      let index = users.findIndex(x => x.id === editUser.id);
      let tmp_users = [...users];
      if(role.role_name === "system_admin" || role.role_name === "user" || role.role_name === "system_admin_read_only"){
        let data = await API_add_user_global_role(editUser.id, role_id);
        if(data){
          if(editUser.id == user.id){
            alert("Moving to login screen, re-login due to role changing for your user.");
            logout();
          }
          console.log("Added new role to user: ", editUser.email);
          tmp_users[index].roles.push(data.roles[data.roles.findIndex(x => x.role_id == role_id)]);
          setUsers(tmp_users);
        }
      }else{
        let data = await API_add_user_role(editUser.id, group_id, role_id);
        if(data){
          console.log("Added new role to user: ", editUser.email);
          data.role_name = role.role_name;
          tmp_users[index].roles.push(data);
          setUsers(tmp_users);
        } 
      }
    }

    const API_add_user_global_role = async (user_id, role_id) => {
      const add_user_api = "/api/users/roles/add";
      let data = await fetch(add_user_api,{
        method: 'PUT',
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.token}`,
        },
        body: JSON.stringify({user_id: user_id, role_id: role_id})
      }).then(res => {
        if(res.ok) return res.json();
        throw res;
      }).then(data => {
        return data;
      }).catch(async error => {
        return await FetchCatch(error, API_add_user_global_role, [user_id, role_id]);
      })

      return data;
    }

    const API_add_user_role = async (user_id, group_id, role_id) => {
      const add_user_api = "/api/groups/user";
      let data = await fetch(add_user_api,{
        method: 'POST',
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.token}`,
        },
        body: JSON.stringify({new_user_id: user_id, group_id: group_id, role_id: role_id})
      }).then(res => {
        if(res.ok) return res.json();
        throw res;
      }).then(data => {
        return data;
      }).catch(async error => {
        return await FetchCatch(error, API_add_user_role, [user_id, role_id]);
      })

      return data;
    }

  const addUserHandler = async () => {
    if(newUserEmail === "" || newUserPassword === "" || newUserConfirm === ""){
      alert("Form is not filled out.");
      return;
    }
    if(newUserPassword !== newUserConfirm){
      alert("Default Password and Confirm Password are not the same.");
      return;
    }

    let new_user = await API_add_user(newUserEmail, newUserPassword);
    if(new_user){
      let user_tmp = [...users];
      user_tmp.push(new_user);
      setUsers(user_tmp);
    }
    clearNewUserHandler();
  }

  const clearNewUserHandler = () => {
    setNewUserEmail("");
    setNewUserPassword("");
    setNewUserConfirm("");
  }

  const uploadLicenseFileHandler = () => {
    inputLicenseRef.current.click();
}

const licenseFileRef = async (e) => {
if(e.target.files.length === 1){
    var form_data = new FormData();
    form_data.append('license_file', e.target.files[0]);
    await API_upload_license_file(form_data);
}
}

const API_upload_license_file = async (file) => {
    const license_file_api = "/api/settings/license";
    let data = await fetch(license_file_api,{
        method: 'POST',
        body: file
    }).then(res => {
        if(res.ok) return true;
        throw res;
    }).then(data => {
        window.location.reload();
    }).catch(async error => {
        window.alert("Error in uploading license file. Please check the license file is correct, and backend is up and running!")
    })

    return data;
}

  return (
    <div>
        <div style={{margin: "0px", gap: '10px', display: 'flex', flexDirection: 'column'}}>
          <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
          <h2>License Information</h2>                 
          <button className="widget-button" onClick={uploadLicenseFileHandler} style={{display: 'flex', alignItems: 'center', justifyContent: "space-evenly", marginTop: "10px"}} title="Upload License File"><UploadIcon />Upload License File</button>
          </div>
          <div style={{width: '90%', display: 'flex', flexDirection: 'column', fontSize: '18px'}}>
            <label>Number of Licensed Endpoints: </label>
            <input style={{padding: '10px 15px', borderRadius: '5px', fontWeight: 'bold', color: theme ? "white" : "gray"}} type="text" disabled value={licenseInfo != null ? licenseInfo.number_of_nodes : 'n/a'} />
          </div>
          <div style={{width: '90%', display: 'flex', flexDirection: 'column', fontSize: '18px'}}>
            <label>License Expiration Date: </label>
            <input style={{padding: '10px 15px', borderRadius: '5px', fontWeight: 'bold', color: theme ? "white" : "gray"}} type="text" disabled value={licenseInfo != null ? FormatTimestamp(licenseInfo.expiration_date) : 'n/a'} />
          </div>
          <div style={{display: 'flex', flexDirection: "column"}}>
          <h2 style={{marginBottom:14}}>DuroSuite Version: {versionInfo && versionInfo.durosuite_release_version ? versionInfo.durosuite_release_version : null}</h2> 
          <h2>Database Maintenance</h2> 
          <div style={{display: 'flex', alignItems: 'center'}}>
                <button className="widget-button" onClick={handleDBBackup} style={{display: 'flex', alignItems: 'center', justifyContent: "center", marginTop: "10px"}} title="Download Database Backup">
                    {backupDownloading ? <CircularProgress style={{color: "white", width: "16px", height: "16px"}}/> : <><DownloadIcon />Download DB Backup</>}
                </button>
                <button className="widget-button" onClick={DBFileHandler} style={{display: 'flex', alignItems: 'center', justifyContent: "space-evenly", marginTop: "10px"}} title="Upload DB File">
                      {backupUploading ? <CircularProgress style={{color: "white", width: "16px", height: "16px"}}/> : <><UploadIcon />Upload DB File</>}</button>
              </div>
          </div>
          <div className="inventory-content-header" style={{margin: "0px"}}>
            <div style={{display: 'flex', flexDirection: "column"}}>
                <h2>Update DuroSuite</h2> 
                <span>* Please contact DuroSuite for tool updates!</span>
                <button className="widget-button" onClick={uploadFileHandler} style={{display: 'flex', alignItems: 'center', justifyContent: "space-evenly", marginTop: "10px"}} title="Upload Update File">
                    {fileUploading ? <CircularProgress style={{color: "white", width: "16px", height: "16px"}}/> : <><UploadIcon />Upload Update File</>}</button>
            </div>
        </div>
        </div>
        <h2 style={{paddingBottom: "10px", marginTop: "10px", display: 'flex', alignItems: "center"}}><GroupIcon style={{marginRight: "5px"}} />Users: </h2>
        <div style={{width: "100%", maxHeight: "400px", overflowY: 'auto'}}>
        <table style={{width: "100%"}} className="profile-admin-user-table" >
            <thead>
                <tr style={{textAlign: 'left', backgroundColor: theme ? "#454545" : "#dddddd"}}>
                    <th style={{width: "20px", textAlign: "center"}}>ID</th>
                    <th style={{}}>Username</th>
                    <th style={{width: "200px", textAlign: "center"}}>Roles</th>
                    <th style={{width: "50px", textAlign: "center"}}>Disabled</th>
                    <th style={{width: "50px", textAlign: "center"}}>Password</th>
                    <th style={{width: "75px", textAlign: "center"}}>Actions</th>
                </tr>
            </thead>
            <tbody>
            {
                users.map((user_tmp,index) => (
                    <tr key={user_tmp.id} style={{backgroundColor: (index % 2) !== 0 ? theme ? "#454545" : "#dddddd" : ""}}>
                        <td style={{textAlign: 'center'}}>{user_tmp.id}</td>
                        <td>{user_tmp.email}</td>
                        <td style={{textAlign: "center"}}>
                        <select style={{width: '90%'}}>
                            {
                                user_tmp.roles.map((role,index) => (
                                    <option key={index}>{role.role_name}</option>
                                ))
                            }
                        </select>
                        </td>
                        <td style={{textAlign: "center"}}><input type="checkbox" checked={user_tmp.disabled ? true : false} onChange={() => disabledOnChangeHandler(user_tmp.id)} /></td>
                        <th style={{textAlign: "center", fontWeight: "normal", borderBottom: "1px solid #dddddd"}}>{user_tmp.default_password_changed == null ? "n/a" : user_tmp.default_password_changed ? "Changed" : "Default"}</th>
                        <td style={{textAlign: 'center'}}>               
                        {
                          user_tmp.id !== user.id &&
                          <>
                            <span style={{textDecoration: "underline", color: '#00dd00', cursor: "pointer", margin: "0px 5px"}} onClick={() => setEditUser(user_tmp)} title="Edit User"><ManageAccountsIcon /></span>
                            <span style={{textDecoration: "underline", color: 'red', cursor: "pointer", margin: "0px 5px"}} onClick={() => deleteUserHandler(user_tmp)}title="Delete User"><PersonRemoveIcon /></span>
                          </>
                        }
                        </td>
                    </tr>
                ))
            }
            </tbody>
        </table>
        </div>
          <h2 style={{paddingBottom: "10px", display: 'flex', alignItems: 'center', marginTop: "15px"}}><PersonAddIcon style={{marginRight: "5px"}} />New Users : </h2>
          <div style={{width: "100%", maxHeight: "200px", overflowY: 'auto'}}>
            <div style={{display: 'flex', justifyContent: "space-evenly", paddingBottom: "15px", fontSize: '18px'}}>
            <label style={{width: "90%"}}>
              <p>Username:</p>
              <input type="text" style={{padding: '10px 15px', borderRadius: '5px', fontWeight: 'bold', color: 'gray', borderColor: 'rgba(118, 118, 118, 0.3)', width: "70%"}} value={newUserEmail} onChange={(e) => setNewUserEmail(e.target.value)} />
            </label>
            <label style={{width: "90%"}}>
              <p>Default Password:</p>
              <input type="password" style={{padding: '10px 15px', borderRadius: '5px', fontWeight: 'bold', color: 'gray', borderColor: 'rgba(118, 118, 118, 0.3)', width: "70%"}} value={newUserPassword} onChange={(e) => setNewUserPassword(e.target.value)}/>
            </label>
            <label style={{width: "90%"}}>
              <p>Confirm Default Password:</p>
              <input type="password" style={{padding: '10px 15px', borderRadius: '5px', fontWeight: 'bold', color: 'gray', borderColor: 'rgba(118, 118, 118, 0.3)', width: "70%"}} value={newUserConfirm} onChange={(e) => setNewUserConfirm(e.target.value)}  />
            </label>
          </div>
          {
            newUserErrorMsg &&
            <div style={{color: "red"}}>
              * Error: {newUserErrorMsg}
            </div>
          }
          <div>
            <input ref={inputUpdateRef} onChange={updateFileRef} type="file" id="input-file-upload" multiple={false} />
            <input ref={inputDBRef} onChange={DBFileRef} type="file" id="input-file-upload" multiple={false} />
            <input ref={inputLicenseRef} onChange={licenseFileRef} type="file" id="input-file-upload" multiple={false} />
            <input className="widget-button" type="button" value="Clear" style={{width: "100px"}} onClick={clearNewUserHandler}/> 
            <input className={true ? "widget-button"  : "widget-button-disable"} type="button" value="Add User" style={{width: "100px"}} onClick={addUserHandler}/> 
          </div>
        </div>
        <AdminSettingsUserPopup editUser={editUser} close={setEditUser} allRoles={allRoles} removeRole={removeRole} addRole={addRole} groups={props.groups} />
    </div>
  )
}

export default AdminSettings