import React, { useContext, useEffect, useState } from 'react'
import WarningIcon from '@mui/icons-material/Warning';
import { UserContext } from '../../../App';

// Icons
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';

const AdminSettingsUserPopup = (props) => {
  const { user, FetchCatch, FormatTimestamp, theme } = useContext(UserContext);
  const [newRole, setNewRole] = useState("");
  const [editUser, setEditUser] = useState(props.editUser);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  useEffect(() => {
    if(props.editUser !== null){
      setNewRole("");
      clearPassword();
      setEditUser({...props.editUser});
    }
  },[props.editUser])
  

  const addNewRole = () => {
    props.addRole(newRole);
  }

  const getGroupName = (role_id) => {
    let role = props.allRoles.find(x => x.id == role_id);
    if(role === undefined) return "Role Error";
    if(role.role_name === "system_admin" || role.role_name === "user" || role.role_name === "system_admin_read_only") return "Global";
    let group = props.groups.find(x => x.group_id === role.group_id);
    if(group) return group.group_name;
    return "Group Error";
  }

  function validatePassword(p) {
    let errors = [];
    if (p.length < 15) {
        errors.push("User password must be at least 15 characters long."); 
    }
    if (p.search(/[0-9]/) < 0) {
        errors.push("User password must contain at least one digit."); 
    }
    if (p.search(/[a-z]/) < 0) {                         
      errors.push("User password must contain at least one lowercase letter.");                   
    }                       
    if (p.search(/[A-Z]/) < 0) {                         
      errors.push("User password must contain at least one uppercase letter.");                   
    }
    if (p.search(/[!@#$%&*?]/) < 0){
      errors.push("User password must contain at least one special character.");
    }

    if (errors.length > 0) {
      alert(errors.join("\n"));
      return false;
    }
    return true;
}

  const changePassword = () => {
    if(currentPassword.length == 0){
      alert("Current Password is required");
      return;
    }
    if(newPassword != confirmPassword){
      alert("New password doesn't match with confirmed new password.");
      return;
    }
    let valid = validatePassword(newPassword, currentPassword);
    if(valid){
      let tmp = window.confirm("Are you sure you want to change password?");
      if(tmp){
        // Change password here
        const password_fetch = '/api/users/reset_password';
        fetch(password_fetch,{
          method: "POST",
          headers:{
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${user.token}`,
          },
          body: JSON.stringify({'admin_password': currentPassword, 'user_id': props.editUser.id, 'new_password': newPassword})
        })
        .then(res=>{
            if(res.ok) return null;
            throw res;
        })
        .then(data =>{
            alert(props.editUser.email + '\'s password has changed.');
            clearPassword();
        }).catch(async error => {
          FetchCatch(error, changePassword, []);
          let detail = await error.json();
          alert(detail.detail);
        })
      }else{
        return;
      }
    }else{
      return;
    }
  }

  const clearPassword = () => {
    setCurrentPassword("");
    setNewPassword("");
    setConfirmPassword("");
  }

  return (
      editUser !== null && props.editUser !== null &&
      <div style={{display: editUser === null ? "none" : "flex", width: "100vw",height: "100vh", position:"absolute", zIndex: "1",top: 0, left: 0, overflowY: "hidden", justifyContent: 'center', alignItems: 'center', backgroundColor: "rgba(25,25,25,0.85)"}} onClick={() => props.close(null)}>
          <div style={{width: "700px", height: "500px", backgroundColor: theme ? "#454545" : "#FFFFFF", borderRadius: "10px", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", display: 'flex', alignItems:"center", flexDirection:"column", padding: "20px 10px", zIndex: "100", border: theme ? "1px solid #000" : "1px solid #EBEBEB", color: theme ? "#fff" : "#000", opacity: '1', overflowY: "auto"}} onClick={(e) => e.stopPropagation()}>
            <div style={{width: "90%", display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: "24px", paddingBottom: '10px'}}>
                Edit User<CloseIcon style={{cursor: "pointer"}} onClick={() => props.close(null)}/>
            </div>
            <div style={{width: '90%', display: 'flex', flexDirection: 'column', fontSize: '18px', paddingBottom: "10px"}}>
              <label>Email: </label>
              <input style={{padding: '10px 15px', borderRadius: '5px', fontWeight: 'bold', color: theme ? "white" : ""}} type="text" disabled value={editUser.email} />
            </div>
            <div style={{width: '90%', display: 'flex', flexDirection: 'column', fontSize: '18px', paddingBottom: "10px"}}>
              <label>Last Failed Login: </label>
              <input style={{padding: '10px 15px', borderRadius: '5px', fontWeight: 'bold', color: theme ? "white" : ""}} type="text" disabled value={FormatTimestamp(editUser.last_failed_login)} />
            </div>
            <div style={{width: '90%', display: 'flex', flexDirection: 'column', fontSize: '18px', paddingBottom: "10px"}}>
              <label>Last Successful Login: </label>
              <input style={{padding: '10px 15px', borderRadius: '5px', fontWeight: 'bold', color: theme ? "white" : ""}} type="text" disabled value={FormatTimestamp(editUser.last_successful_login)} />
            </div>
            <div style={{width: "90%"}}>
              <div style={{display: 'flex', alignItems: 'center', fontSize: "18px"}}>
                {editUser.default_password_changed ? <div title="Default Password has been changed." style={{display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: "help"}}><CheckCircleIcon style={{color: 'blue', width: "18px", marginRight: "3px"}} /></div> : <div title="Default Password has not been changed." style={{display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: "help"}}><WarningIcon style={{color: 'red', width: "18px", marginRight: "3px"}} /></div>}Change Password:
              </div>
              <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <label>
                  <p>{user.email}'s Password:</p>
                  <input style={{padding: '5px 7px', borderRadius: '5px', fontWeight: 'bold',  borderColor: 'rgba(118, 118, 118, 0.3)'}} type="password" value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} />
                </label>
                <label>
                  <p>New Password:</p>
                  <input style={{padding: '5px 7px', borderRadius: '5px', fontWeight: 'bold',  borderColor: 'rgba(118, 118, 118, 0.3)'}} type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                </label>
                <label>
                  <p>Confirm New Password:</p>
                  <input style={{padding: '5px 7px', borderRadius: '5px', fontWeight: 'bold',  borderColor: 'rgba(118, 118, 118, 0.3)'}} type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                </label>
              </div>
              <div style={{marginTop: "5px"}}>
                <input type="button" value="Cancel" className="widget-button" onClick={() => clearPassword()} style={{marginRight: "10px", padding: '2px', marginLeft: "0px", paddingLeft: "0px"}}/> 
                <input type="button" value="Submit" className={currentPassword.length > 0 && newPassword.length > 0 && confirmPassword.length > 0 ? "widget-button" : "widget-button-disable"} disabled={currentPassword.length > 0 && newPassword.length > 0 && confirmPassword.length > 0 ? false : true} onClick={() => changePassword()} style={{padding: '2px'}}/> 
              </div>
            </div>
            <div style={{width: "90%", display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: "20px", textDecoration: "underline"}}>
              Add Role: 
              <div>
              <select onChange={(e) => setNewRole(e.target.value)}>
                  <option value={""}>Add a new role</option>
                  {
                      props.allRoles.map((role,index) => (
                          editUser.roles.findIndex(x => x.role_id === role.id) === -1 &&
                          <option key={role.id} value={role.id}>{role.role_name}</option>
                      ))
                  }
              </select>
              <input type="button" value="Add Role" className={newRole !== "" ? "widget-button" : "widget-button-disable"} disabled={newRole === "" ? true : false} onClick={() => addNewRole()}/>
              </div>
            </div>
            <div style={{width: "90%", display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
              <table style={{borderCollapse: "collapse", width: "100%"}}>
                <thead>
                  <tr style={{border: "1px solid #d5d5d5", backgroundColor: theme ? "#454545" : "#dddddd"}}>
                    <th style={{padding: '5px', width: '50%'}}>Role</th>
                    <th style={{width: '35%'}}>Group</th>
                    <th style={{width: '15%'}}>Action</th>  
                  </tr>
                </thead>
                <tbody>
                  {
                    editUser.roles.map((role,index) => (
                      <tr key={role.role_id} style={{textAlign: 'center', backgroundColor: (index % 2) !== 0 ? theme ? "#454545" : "#dddddd" : ""}}>
                        <td style={{padding: '5px', border: "1px solid #d5d5d5"}}>{role.role_name}</td>
                        <td style={{border: "1px solid #d5d5d5"}}>{getGroupName(role.role_id)}</td>
                        <td style={{border: "1px solid #d5d5d5"}}>{role.role_name != "user"  && <span onClick={() => props.removeRole(role.perm_id)} style={{color: 'red', textDecoration: 'underline', cursor: 'pointer'}}>Delete</span>}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>
      </div>
  )
}

export default AdminSettingsUserPopup