import React, {useEffect, useState} from 'react'
import { Checkbox, FormGroup, FormControlLabel } from '@mui/material'
import { PieChart, Pie, ResponsiveContainer, Legend, Tooltip} from 'recharts';

//Component
import { InfoGraphHeader } from './InfoGraphHeader';

//CSS
import "../../styles/css/InfoGraph.css"

const InfoGraph = (props) => {
  const [vulnList,setVulnList] = useState([]);
  const [statusData, setStatusData] = useState(      
    [
      {status: 'Not A Finding', total: 0, fill: '#75C626'},
      {status: 'Not Reviewed', total: 0, fill: '#ffba08'},
      {status: 'Open', total: 0, fill: '#DD462B'},
      {status: 'Not Applicable', total: 0}
    ]
  );

  useEffect(()=>{
    if(props.vuln === null) return;
    setVulnList(props.vuln);
  },[props.vuln])

  useEffect(()=>{
    if(vulnList.length === 0) return;
    severityFilterHandler();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[vulnList])

  const severityCheckHandler = (severity) =>{
    let tmp = props.severityFilter;
    tmp[severity] = !tmp[severity]
    // on this btn check, user has not filtered vulns yet, so default == 'All'
    tmp['All'] = true
    props.setSelectedStatus('All')
    props.setSeverityFilter(tmp);
    // re-filter vuln data fetched from API 
    props.filterVulnData(tmp);
    severityFilterHandler();
  }

  const severityFilterHandler = () =>{
    let opencount = vulnList.filter((vuln) =>  vuln.status === 'Open' && props.severityFilter[vuln.severity] === true).length;
    let closedcount = vulnList.filter((vuln) => vuln.status === 'NotAFinding' && props.severityFilter[vuln.severity] === true).length;
    let nrcount = vulnList.filter((vuln) => vuln.status === 'Not_Reviewed' && props.severityFilter[vuln.severity] === true).length;
    let nacount = vulnList.filter((vuln) => vuln.status === 'Not_Applicable' && props.severityFilter[vuln.severity] === true).length;
    setStatusData(
      [
        {status: 'Not A Finding', total: closedcount, fill: '#75C626'},
        {status: 'Not Reviewed', total: nrcount, fill: '#ffba08'},
        {status: 'Open', total: opencount, fill: '#DD462B'},
        {status: 'Not Applicable', total: nacount}
      ]
    );
  }

  const renderLabel = (e) => {
    return e.name;
  }

  return (
    <div className="info-graph-container" style={{backgroundColor: props.theme ? "#242424" : "#FFF", border: props.theme ? "1px solid #000" : "1px solid #EBEBEB", color: props.theme ? "#fff" : "#000"}}>
      <div className="grid-item-header" style={{background: props.theme ? "#202020" : ""}}>
        <h4>Info Graph</h4>
      </div>
      {
        props.vuln === null ?             
        <div style={{width: "100%", height: "90%", display: "flex", justifyContent: "center", alignItems:"center"}}>
            <h4>Select an Audit</h4>
        </div> 
    :
        <>
            <FormGroup style={{width: "100%", display: "flex",flexWrap: "wrap",justifyContent: "space-around", flexDirection: "row", alignItems:"center",paddingLeft: "5px",paddingRight: "5px"}}>
              <h4>Severity:</h4>
              <FormControlLabel control={<Checkbox size="small" defaultChecked onChange={() => severityCheckHandler("high")} />} label="CAT I" />
              <FormControlLabel control={<Checkbox size="small" defaultChecked onChange={() => severityCheckHandler("medium")}/>} label="CAT II" />
              <FormControlLabel control={<Checkbox size="small" defaultChecked onChange={() => severityCheckHandler("low")}/>} label="CAT III" />
            </FormGroup>
            <InfoGraphHeader statusData={statusData}/>
            <ResponsiveContainer width="100%" height={250}>
              <PieChart>
                <Pie data={statusData} dataKey="total" nameKey="status" outerRadius={50}/>
                <Legend verticalAlign="bottom" align="center" iconSize={5} fontStyle={{size: "5px"}} style={{border: "1px solid black"}}/>
              <Tooltip/>
              </PieChart>
            </ResponsiveContainer>
            </>
      }
    </div>
  )
}

export default InfoGraph