import React, { useRef, useEffect, useState, useContext, Fragment } from 'react'
import { UserContext } from '../../../App';
import { var_password_check } from "../../Helper";

//Icons
import { CircularProgress } from '@mui/material';
import TapAndPlayRoundedIcon from '@mui/icons-material/TapAndPlayRounded';
import EditIcon from '@mui/icons-material/Edit';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AddBoxIcon from '@mui/icons-material/AddBox';
import WifiFindIcon from '@mui/icons-material/WifiFind';


const GroupWidget = (props) => {
  const { user, FetchCatch, theme} = useContext(UserContext);
  const [editMode, setEditMode] = useState(false);
  const [host, setHost] = useState(null);
  const [templateList, setTemplateList] = useState([]);
  const [templateSelect, setTemplateSelect] = useState(null);
  const [stigVariables, setStigVariables] = useState([]);
  const [variableChange, setVariableChange] = useState([]);
  const [connectionVariables, setConnectionVariables] = useState([]);
  const [addNewTemplateMode, setAddNewTemplateMode] = useState(false);
  const [baselineTemplateMode, setBaselineTemplateMode] = useState(false);

  const [newTemplateName, setNewTemplateName] = useState('');
  const [loading, setLoading] = useState(false);
  const [pinging, setPinging] = useState(false);
  const [scanning, setScanning] = useState(false)
  const [deviceOS, setDeviceOS] = useState(null);

  const divRef = useRef(null);

  useEffect(async () => {
    setEditMode(false);
    setAddNewTemplateMode(false);
    setBaselineTemplateMode(false);
    setLoading(false);
    if(props.selected === null || props.selected === undefined){
        setHost(null);
        reset();
        setDeviceOS(null);
    }else{
        setHost(JSON.parse(JSON.stringify(props.selected)));
        if("group_id" in props.selected){
            let group_templates = await API_get_group_templates(props.selected.group_id);
            if(group_templates){
                setTemplateList(group_templates);
                if(group_templates.length > 0){
                    getTemplate(group_templates[0]);
                }else{
                    getTemplate(null);
                }
            }
            setDeviceOS(props.OS.find(x => x.id == props.selected.os_id));
        }else{
            getVariables(props.selected.os_id, 1);
            setDeviceOS(props.OS.find(x => x.id == props.selected.os_id));
        }
    }
  },[props.selected])

  const API_get_group_templates = async (group_id) => {
    const url_groups_template = "/api/stigs/templates/group/" + group_id;
    let data = await fetch(url_groups_template,{
        method: 'GET',
        headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${user.token}` },
    })
    .then(res=>{
        if(res.ok) return res.json();
        throw res;
    })
    .then(data =>{
        return data;
    }).catch(async error => {
        return await FetchCatch(error, API_get_group_templates, [group_id]);
    });

    return data;
  }

  const reset = () => {
    setTemplateList([]);
    setTemplateSelect(null);
    setStigVariables([]);
    setConnectionVariables([]);
  }

  const getVariables = async (os_id, deviceCheck) => {
    let default_vars = await API_get_defaults_vars(os_id);
    if(default_vars){
        if(!deviceCheck){
            setStigVariables(default_vars.groups);
        }else{
            setStigVariables(default_vars.devices);
        }
    }

    let connection_var = await API_get_connection_vars(os_id);
    if(connection_var){
        if(connection_var){
            setConnectionVariables(connection_var);
        }
    }
  }

  const API_get_defaults_vars = async (os_id) => {
    const url_get_default = "/api/operating-systems/defaults/" + os_id;
    let data = await fetch(url_get_default,{
        method: 'GET',
        headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${user.token}` },
    })
    .then(res=>{
        if(res.ok) return res.json();
        throw res;
    })
    .then(data =>{
        return data;
    }).catch(async error => {
        return await FetchCatch(error, API_get_defaults_vars, [os_id]);
    });
    return data;
  }

  const API_get_connection_vars = async (os_id) => {
    const url_get_connection = "/api/operating-systems/connection-vars/" + os_id;
    let data = await fetch(url_get_connection,{
        method: 'GET',
        headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${user.token}` },
    })
    .then(res=>{
        if(res.ok) return res.json();
        throw res;
    })
    .then(data =>{
        return data;
    }).catch(async error => {
        return await FetchCatch(error, API_get_connection_vars, [os_id]);
    });

    return data;
  }

  const getTemplate = async (template) => {
    if(template == null){
        setTemplateList([]);
        setTemplateSelect(null);
        setConnectionVariables([]);
        setStigVariables([]);
        props.templateSelect(null);
        return;
    }
    setTemplateSelect(JSON.parse(JSON.stringify(template)));
    props.templateSelect(JSON.parse(JSON.stringify(template)));
    let variable_data = await API_get_template_variable(template.id);
    if(variable_data == null) return; 
    setConnectionVariables(variable_data.connection_vars);
    setStigVariables(variable_data.stig_vars);
  }

  const API_get_template_variable = async (template_id) => {
    const url_get_template = "/api/stigs/templates/" + template_id;
    let data = await fetch(url_get_template,{
        method: 'GET',
        headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${user.token}` },
    })
    .then(res=>{
        if(res.ok) return res.json();
        throw res;
    })
    .then(data =>{
        if(data != null){
            data.stig_vars.sort((a,b) => a.var_name > b.var_name ? 1 : -1);
        }
        return data;
    }).catch(async error => {
        return await FetchCatch(error, API_get_template_variable, [template_id]);
    });

    return data;
  }

  const cancelEdit = () => {
    if("device_id" in host){
        setHost(JSON.parse(JSON.stringify(props.selected)));
        setEditMode(false);
        return;
    }

    getTemplate(templateSelect);
    setEditMode(false);
  }

  const saveEdit = async (type) => {
    if(type === 1){
        let tmp_array = [];
        for(let i in host.device_vars){
            let status_var_name = host.device_vars[i].var_name;
            let status_value = host.device_vars[i].var_value;
            if(host.device_vars[i].var_name === "" || !status_var_name || !status_value)
                tmp_array.push(i);
        }
        for(let i in tmp_array){
            host.device_vars.splice(tmp_array[i]-i,1);
        }
        props.saveDevice(host);
    }

    if(type === 0){
        let template_index = templateList.findIndex(x => x.id == templateSelect.id);
        if(templateList[template_index].name != templateSelect.name){
            let template_update = await API_update_template(templateSelect.id, templateSelect.name, host.os_id, host.group_id);
            if(template_update){
                let tmp = [...templateList];
                tmp[template_index] = template_update;
                setTemplateList(tmp);
            }

        }

        let connectionVariable_tmp = [...connectionVariables];
        let stigVariables_tmp = [...stigVariables];
        for(let i in variableChange){
            let variable = null;
            let index = connectionVariables.findIndex(x => x.var_id == variableChange[i].var_id);
            if(index == -1){
                index = stigVariables.findIndex(x => x.var_id == variableChange[i].var_id);
                if(index != -1){
                    variable = stigVariables[index];
                }
            }else{
                variable = connectionVariables[index];
            }
            
            if(variable == null) continue;
            const url_save_var = "/api/stigs/templates/var";
            await fetch(url_save_var,{
                method: 'PUT',
                headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${user.token}` },
                body: JSON.stringify({"id": variable.var_id, "template_id": templateSelect.id, "var_name": variable.var_name, "var_value": variable.var_value })
            })
            .then(res=>{
                if(res.ok) return res.json();
                throw res;
            })
            .then(data =>{
            }).catch(async error => {
                FetchCatch(error, props.logout, props.refreshToken, saveEdit, [type]);

                let fail_index = connectionVariables.findIndex(x => x.var_id == variableChange[i].var_id);
                if(fail_index == -1){
                    fail_index = stigVariables.findIndex(x => x.var_id == variableChange[i].var_id);
                    stigVariables_tmp[fail_index] = variableChange[i];
                }else{
                    connectionVariable_tmp[fail_index] = variableChange[i];
                }
                return null;
            });
        }

        setVariableChange([]);
        setConnectionVariables(connectionVariable_tmp);
        setStigVariables(stigVariables_tmp);

        if(props.selected.group_name != host.group_name){
            props.saveGroup(host);
        }
    }

    setEditMode(false);
  }

  const API_update_template = async (template_id, name, os_id, group_id) => {
    const url_update_template = "/api/stigs/templates";
    let data = await fetch(url_update_template,{
        method: 'PUT',
        headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${user.token}` },
        body: JSON.stringify({"id": template_id, "name": name, "os_id": os_id, "group_id": group_id })
    })
    .then(res=>{
        if(res.ok) return res.json();
        throw res;
    })
    .then(data =>{
        return data;
    }).catch(async error => {
        return await FetchCatch(error, API_update_template, [template_id, name, os_id, group_id]);
    });

    return data;
  }

  const newTemplateModeHandler = () => {
    setNewTemplateName("");
    getTemplate(templateList[0]);
    setAddNewTemplateMode(true);
    if(templateList.length == 0){
        setBaselineTemplateMode(true);
        getVariables(host.os_id, "device_id" in host);
    }
  }

  const cancelNewTemplate = () => {
    setNewTemplateName("");
    setBaselineTemplateMode(false);
    setAddNewTemplateMode(false);
    getTemplate(templateSelect);
  }

  const deleteTemplateHandler = async (template_id) => {
    let index = templateList.findIndex(t => t.id == template_id);
    if(templateList[index].name == "Baseline"){
        alert("Can not delete the 'Baseline' template.");
        return;
    }

    let template_delete = await API_delete_template(template_id);
    if(template_delete){
        getTemplate(templateList[0]);
        let tmp = [...templateList];
        tmp.splice(index, 1);
        setTemplateList(tmp);
    }
  }

  const API_delete_template = async (template_id) => {
    let url_delete_template = "/api/stigs/templates/" + template_id;
    let data = await fetch(url_delete_template ,{
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${user.token}` },
    })
    .then(res=>{
        if(res.ok) return true;
        throw res;
    })
    .then(data =>{
        return true;
    }).catch(async error => {
        return await FetchCatch(error, API_delete_template, [template_id]);
    });  

    return data;
  }

  const saveNewTemplate = async () => {
    if(newTemplateName.length == 0){
        alert("Template must have a name.");
        return;
    }

    if(newTemplateName.toLowerCase() == "baseline"){
        alert("New Template can not have the name 'Baseline'");
        return;
    }

    let new_template = {
        id: -1,
        name: newTemplateName,
        os_id: host.os_id
    }

    setLoading(true);
    let template_data = await API_create_template(newTemplateName, host.group_id, host.os_id);
    if(template_data == null){
        alert("Failed to create new template.");
        setLoading(false);
        return;
    }
    new_template.id = template_data.id;
    let templateList_tmp = [...templateList];
    templateList_tmp.push(new_template);
    setTemplateList(templateList_tmp);

    let defaultVariable = [...connectionVariables, ...stigVariables];

    for(let i in defaultVariable){
        let variable_data = await API_create_template_variable(new_template.id, defaultVariable[i].var_name, defaultVariable[i].var_value);
        if(variable_data == null){
            console.log("Fail to create new template: " + defaultVariable[i].var_name + " " + defaultVariable[i].var_value);
        }
    }

    setLoading(false);
    getTemplate(templateList[0]);
    setAddNewTemplateMode(false);
  }

  const API_create_template = async (template_name, group_id, os_id) => {
    let url_new_template = "/api/stigs/templates";
    let data = await fetch(url_new_template ,{
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${user.token}` },
        body: JSON.stringify({"name": template_name, "os_id": os_id, "group_id": group_id})
    })
    .then(res=>{
        if(res.ok) return res.json();
        throw res;
    })
    .then(data =>{
        return data;
    }).catch(async error => {
        return await FetchCatch(error, API_create_template, [template_name, group_id, os_id]);
    });  

    return data;
  }

  const API_create_template_variable = async (id, var_name, var_value) => {
    const url_new_template_var = "/api/stigs/templates/var";
    let data = await fetch(url_new_template_var,{
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${user.token}` },
        body: JSON.stringify({ "template_id": id, "var_name": var_name, "var_value": var_value})
    })
    .then(res=>{
        if(res.ok) return res.json();
        throw res;
    })
    .then(data =>{
        return data 
    }).catch(async error => {
        return await FetchCatch(error, API_create_template_variable, [id, var_name, var_value]);
    });  

    return data;
  }

  const API_update_variable = async (var_id, template_id, var_name, var_value) => {
    const url_update_var = "/api/stigs/templates/var";
    let data = await fetch(url_update_var,{
        method: 'PUT',
        headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${user.token}` },
        body: JSON.stringify({"id": var_id, "template_id": template_id, "var_name": var_name, "var_value": var_value })
    })
    .then(res=>{
        if(res.ok) return res.json();
        throw res;
    })
    .then(data =>{
        return data;
    }).catch(async error => {
        return await FetchCatch(error, API_update_variable, [var_id, template_id, var_name, var_value]);
    });

    return data;
  }

  const makeBaselineHandler = async () => {
    let confirmed = window.confirm("Are you sure you want make this your baseline template?");
    if(confirmed === null || !confirmed) return;
    let new_variable = [...connectionVariables, ...stigVariables];
    let baseline_template = templateList.find(x => x.name == 'Baseline');
    setLoading(true);
    let variable_data = await API_get_template_variable(baseline_template.id);
    if(variable_data == null){
        setLoading(false);
        return;
    }
    let old_variable = [...variable_data.connection_vars, ...variable_data.stig_vars];

    for(let i in new_variable){
        let index = old_variable.findIndex(x => x.var_name == new_variable[i].var_name);
        if(index == -1){
            alert("Baseline Template is missing variable");
        }else{
            if(new_variable[i].var_value != old_variable[index].var_value){
                let data = await API_update_variable(old_variable[index].var_id, baseline_template.id,  new_variable[i].var_name, new_variable[i].var_value);
                if(data === null){
                    console.log("Failed to update variable: id=" + old_variable[index].var_id + " template_id=" + baseline_template.id + " var_name" + new_variable[i].var_name + " var_value" + new_variable[i].var_value);
                }
            }
        }
    }

    setLoading(false);
    getTemplate(templateSelect);
  }

  const saveBaselineTemplate = async () => {
    let defaultVariable = [...connectionVariables, ...stigVariables];
    let index = templateList.findIndex(x => x.name == "Baseline");
    if(index == -1){
        let template_data = await API_create_template("Baseline", host.group_id, host.os_id);
        if(template_data == -1 || template_data == null) return;
        let new_template_id = template_data.id;

        if(defaultVariable.length > 0){
            for(let i in defaultVariable){
                let variable_data = await API_create_template_variable(new_template_id, defaultVariable[i].var_name, defaultVariable[i].var_value);
                if(variable_data == null){
                    console.log("Fail to create baseline template: " + defaultVariable[i].var_name + " " + defaultVariable[i].var_value);
                }
            }
        }
    }else{
        await makeBaselineHandler();
    }

    cancelNewTemplate();
    let group_templates = await API_get_group_templates(props.selected.group_id);
    if(group_templates){
        setTemplateList(group_templates);
        if(group_templates.length > 0){
            getTemplate(group_templates[0]);
        }else{
            getTemplate(null);
        }
    }
  }

  const updateTemplateName = (value) => {
    let tmp = {...templateSelect};
    tmp.name = value;
    setTemplateSelect(tmp);
  }

  const updateVariable = (index, value, type) => {
    if(type == 0){
        let tmp = [...connectionVariables];
        tmp[index].var_value = value;
        setConnectionVariables(tmp);
    }else{
        let tmp = [...stigVariables];
        tmp[index].var_value = value;
        setStigVariables(tmp);
    }
  }

  const updateName = (name, type) => {
    // Device
    if(type === 1){
        let tmp = {...host};
        tmp.name = name;
        setHost(tmp);
    }

    //Group
    if(type === 0){
        let tmp = {...host};
        tmp.group_name = name;
        setHost(tmp);
    }
  }

  const updateDeviceVar = (index, value, type) => {
    let tmp = {...host};
    if(!type){
        host.device_vars[index].var_value = value;
    }else{
        host.device_vars[index].var_name = value;
    }
    setHost(tmp);
  }

  const updateVar = (id, value, type, var_type, var_name = false) => {
    //Device
    if(type === 1){
        let tmp = {...host};
        let index = host.device_vars.findIndex(x => x.var_id == id);
        if(var_name){
            tmp.device_vars[index].var_name = value;
        }else{
            tmp.device_vars[index].var_value = value;
        }
        setHost(tmp);
    }

    //Group
    if(type === 0){
        let index2 = -1;
        let tmp = [];
        if(var_type == 0){
            index2 = connectionVariables.findIndex(x => x.var_id == id);
            tmp = [...connectionVariables];
            tmp[index2].var_value = value;
            setConnectionVariables(tmp);
        }else{
            index2 = stigVariables.findIndex(x => x.var_id == id);
            tmp = [...stigVariables];
            tmp[index2].var_value = value;
            setStigVariables(tmp);
        }
        let index = variableChange.findIndex(x => x.var_id == id);
        if(index == -1){
            let variableChange_tmp = [...variableChange];
            variableChange_tmp.push(JSON.parse(JSON.stringify(tmp[index2])));
            setVariableChange(variableChange_tmp);
        }
      }
  }

  const addVar = (type) => {
    //Device
    if(type === 1){
        let tmp = {...host};
        tmp.device_vars.push({"var_id": -1, "var_name": "", "var_value": ""});
        setHost(tmp);
    }

    //Group
    if(type === 0){
        let tmp = {...host};
        tmp.host_group_vars.push({"host_group_var_id": -1, "var_name": "", "value": ""});
        setHost(tmp);
    }

    setTimeout(() => {
        if (divRef.current) {
            divRef.current.scrollIntoView(
            {
                behavior: 'smooth',
                block: 'end',
                inline: 'nearest'
            })
        }
    },200);
  }
  
  const removeVar = (index) => {
    //Device
      let tmp = {...host};
      tmp.device_vars.splice(index,1);
      setHost(tmp);
  }

  const getIP = (device) => {
    let index = device.device_vars.findIndex(dv => dv.var_name === "ansible_host");
    if(index === -1) return "n/a";
    return device.device_vars[index].var_value;
  }

  const getOS = (device) => {
    let index = props.OS.findIndex(os => os.id === device.os_id);
    return props.OS[index].operating_system;
  }

  const getParentGroups = (device) => {
    let tmp = "";
    if(device.groups.length === 0) return "None";
    for(let i in device.groups){
        tmp += device.groups[i].group_name + " ";
    }
    return tmp;
  }

  const getRoleGlobal = () => {
    if(props.groupRole === undefined) return false;
    return (props.groupRole.findIndex(x => x.role_id === 1) !== -1)
  }

  const getRoleWrite = (group_id) => {
    if(props.groupRole === undefined) return false;
    if(!getRoleGroup(group_id)) return false;
    let index = props.groupRole.findIndex(x => x.group_id === group_id);
    if(index === -1) return false;
    return (props.groupRole[index].write || props.groupRole[index].admin);
  }

  const getRoleGroup = (group_id) => {
    if(props.groupRole === undefined) return false;
    return (props.groupRole.findIndex(x => x.group_id === group_id) !== -1);
  }

  const getOSName = (os_id) => {
    if(props.OS == null || os_id == null) return 'n/a';
    let index = props.OS.findIndex(x => x.id === os_id);
    if(index == -1) return 'n/a';
    return props.OS[index].operating_system;
  }


  const pingHandler = async () => {
    if("device_id" in props.selected && props.parent.length > 0){
        setPinging(true);
        let tmp = await API_ping(props.parent[props.parent.length - 1].group_id, props.selected.device_id);
        setPinging(false);
        setTimeout(function () {
            if(tmp == null){
                window.alert("Error as occured.");
                return;
            }
            if(tmp.detail == null){
                window.alert(`Successful`);
            }else{
                window.alert(`${tmp.detail}`);
            }
        }, 200);
    }
  }

  const API_ping = async(group_id, device_id) => {
    const url_ping = `/api/connection-test?device_id=${device_id}&group_id=${group_id}`
    let data = await fetch(url_ping,{
        method: 'GET',
        headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${user.token}` },
    })
    .then(res=>{
        if(res.ok) return res.json();
        throw res;
    })
    .then(data =>{
        return data;
    }).catch(async error => {
        return await FetchCatch(error, API_ping, [group_id, device_id]);
    });
    return data;
  }

  const stigScannerHandler = async() => {
    if("device_id" in props.selected && props.parent.length > 0){
        setScanning(true);
        let tmp = await API_stig_Scanner(props.parent[props.parent.length - 1].group_id, props.selected.device_id);
        setScanning(false);
        setTimeout(function () {
            if(tmp == null){
                window.alert("Error as occured.");
                return;
            }
            if(tmp.status == "Success"){
                window.alert(tmp.detail.join("\n"));
            }else{
                window.alert(tmp.detail);
            }
        }, 200);
    }
  }

  const API_stig_Scanner = async(group_id, device_id) => {
    const url_ping = `/api/stig-scanner?device_id=${device_id}&group_id=${group_id}`;
    let data = await fetch(url_ping,{
        method: 'GET',
        headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${user.token}` },
    })
    .then(res=>{
        if(res.ok) return res.json();
        throw res;
    })
    .then(data =>{
        return data;
    }).catch(async error => {
        return await FetchCatch(error, API_stig_Scanner, [group_id, device_id]);
    });
    return data;
  }

  const getDevice = () => {
    let latest_parent = props.parent == null ? null : props.parent.length === 0 ? null : props.parent[props.parent.length - 1];
    return(
        <>
            <h3 className="widget-header">
            <span style={{display: "flex", alignItems: "center"}}>
                <div title="Close Window" style={{display: "flex", alignItems: "center", cursor: "pointer"}} onClick={() => props.close()}>
                    <HighlightOffIcon style={{width: "20px"}} />
                </div>
                Device #{host.device_id}
                <div title="Test Device Connectivity" style={{display: "flex", alignItems: "center", cursor: "pointer", marginLeft: "10px"}} onClick={() => pingHandler()}>
                    {
                        pinging ? <CircularProgress style={{width: "20px", height: "20px"}} /> : <TapAndPlayRoundedIcon style={{width: "20px", color: 'red'}}/>
                    }
                </div>
                {
                    deviceOS != null && deviceOS.stig_dir == "windows_workstation" &&
                    <div title="Scan Windows Workstation for STIGs" style={{display: "flex", alignItems: "center", cursor: "pointer", marginLeft: "10px"}} onClick={() => stigScannerHandler()}>
                        {
                            scanning ? <CircularProgress style={{width: "20px", height: "20px"}} /> : <WifiFindIcon style={{width: "20px", color: 'red'}}/>
                        }
                    </div>
                }
                </span>
                {
                    props.groupRole != null && (getRoleGlobal() || (latest_parent != null && getRoleWrite(latest_parent.group_id))) &&
                    <div style={{display: 'flex', alignItems: 'center', cursor: "pointer"}} onClick={() => editMode === true ? cancelEdit() : props.edit()}>Edit<EditIcon sx={{width: "18px", height: "18px"}}/></div>
                }
            </h3>
            <div className="widget-content">
            <div className="widget-text">
                <span>Name: </span>
                <span>{host.name}</span>
            </div>
            <div className="widget-text">
                <span>Groups: </span>
                <span>{getParentGroups(host)}</span>
            </div>
            <div className="widget-text">
                <span>IP/Port: </span>
                <span>{getIP(host)}</span>
            </div>
            <div className="widget-text">
                <span>Operating System: </span>
                <span>{getOS(host)}</span>
            </div>
            {
                (getRoleGlobal() || (latest_parent !== null && getRoleGroup(latest_parent.group_id))) &&
                <>
                    <div className="widget-text" style={{gridTemplateColumns: "1fr"}}>
                        <span style={{fontWeight: "bold", textDecoration: "underline"}}>Device Variables: </span>
                    </div>
                    <div className="widget-text">
                    {
                        host.device_vars.map((dv,index) => (
                            <Fragment key={dv.var_id}>
                                <span style={{overflowX: "hidden", textOverflow: "ellipsis"}} title={dv.var_name}>{dv.var_name}</span>
                                <span>{var_password_check(dv.var_name) ? "***********" : dv.var_value}</span>
                            </Fragment>
                        ))
                    }
                    </div>
                </>
            }
            </div>
            {
                (getRoleGlobal() || (latest_parent !== null && getRoleGroup(latest_parent.group_id))) &&
                <>
                <div style={{display: 'flex', justifyContent:'center', alignItems:"center", flexWrap: "wrap"}}>
                    {                        
                    (getRoleGlobal() || getRoleWrite(latest_parent.group_id)) &&
                        <input type="button" value="Run" className={host.groups.length > 0 ? "widget-button" : "widget-button-disable"} 
                disabled={host.groups.length > 0 ? false : true} style={{width: "fit-content", padding: "0px 15px"}} onClick={() => props.runHost()}/>
                    }
                    <input type="button" style={{width: "fit-content", padding: "0px 10px"}}  value="Schedule Task(s)" className="widget-button" onClick={() => props.schedule()}/>
                    <input type="button" value="Connectivity" className="widget-button" style={{width: "fit-content", padding: "0px 10px"}} onClick={() => pingHandler()}/>
                    {
                        deviceOS != null && deviceOS.stig_dir == "windows_workstation" &&
                        <input type="button" value="Stig Scan" className="widget-button" style={{width: "fit-content", padding: "0px 10px"}} onClick={() => stigScannerHandler()}/>
                    }
                    <input type="button" value="View Audit(s)" className="widget-button" style={{width: "fit-content", padding: "0px 10px"}} onClick={() => props.viewHost(0)}/>
                    <input type="button" value="View Remediation(s)" className="widget-button" style={{width: "fit-content", padding: "0px 10px"}} onClick={() => props.viewHost(1)}/>
                </div>
                </>
            }
        </>
    )
  }

  const getDevice_Edit = () => {
    return(
        <>
            <h3 className="widget-header">
                <span>Device</span>
                <div style={{display: 'flex', alignItems: 'center', cursor: "pointer"}} onClick={() => editMode === true ? cancelEdit() : props.edit()}>Edit<EditIcon sx={{width: "18px", height: "18px"}}/></div>
            </h3>
            <div className="widget-content">
            <div className="widget-text">
                <span>Name: </span>
                <input type="text" className="widget-textfield" value={host.name} onChange={(e) => updateName(e.target.value,1)} />
            </div>
            <div className="widget-text">
                <span>Groups: </span>
                <span>{getParentGroups(host)}</span>
            </div>
            <div className="widget-text">
                <span>IP/Port: </span>
                <span>{getIP(host)}</span>
            </div>
            <div className="widget-text">
                <span>Operating System: </span>
                <span>{getOS(host)}</span>
            </div>
            <div className="widget-text" style={{gridTemplateColumns: "1fr"}}>
                <span style={{fontWeight: "bold", textDecoration: "underline"}}>Device Variables: </span>
            </div>
            <div style={{maxHeight: "200px", overflowY: "auto"}}>
                {
                    host.device_vars.map((item,index) => (
                        item.var_id != -1 ? 
                        <div key={index} className="widget-text" style={{display: 'grid', gridTemplateColumns: '30px 1fr 2fr'}}>
                            <RemoveCircleOutlineIcon style={{color: 'red', cursor: "pointer"}} onClick={() => removeVar(index)}/>
                            <span style={{overflowX: "hidden", textOverflow: "ellipsis"}} title={item.var_name}>{item.var_name}: </span>
                                <input type={var_password_check(item.var_name) ? "password" : "text"} value={item.var_value} className="widget-textfield" onChange={(e) => updateVar(item.var_id, e.target.value, 1, 0)}/>
                        </div>
                        :
                        <div key={index} className="widget-text" style={{display: 'grid', gridTemplateColumns: '30px 1fr 2fr'}}>
                            <RemoveCircleOutlineIcon style={{color: 'red', cursor: "pointer"}} onClick={() => removeVar(index)}/>
                            {
                                <select value={item.var_name} onChange={(e) => updateDeviceVar(index, e.target.value, true)}>
                                    {
                                        connectionVariables.map((item, index) => (
                                            <option key={item.var_name} value={item.var_name}>{item.var_name}</option>
                                        ))
                                    }
                                    {
                                        stigVariables.map((item, index) => (
                                            <option key={item.var_name} value={item.var_name}>{item.var_name}</option>
                                        ))
                                    }
                                </select>
                            }
                            <input type={var_password_check(item.var_name) ? "password" : "text"} value={item.var_value} className="widget-textfield" onChange={(e) => updateDeviceVar(index, e.target.value, false)}/>
                        </div>
                    ))
                }
                <div ref={divRef}></div>
            </div>
            </div>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px'}}>
                <span style={{color: "#0088FF", cursor:"pointer", padding: "5px"}} onClick={() => addVar(1)}>+ Add Device Variable</span>
            </div>
            <div style={{display: 'flex', justifyContent:'center', alignItems:"center", marginTop: "10px"}}>
                <input type="button" value="Cancel" className="widget-button" onClick={() => cancelEdit()}/>
                <input type="button" value="Save" className="widget-button" onClick={() => saveEdit(1)} />
            </div>
        </>
    ) 
  }

  const getGroup = () => {
    return(
        <>
            <h3 className="widget-header">
                <span style={{display: "flex", alignItems: "center"}}><div title="Close Window" style={{display: "flex", alignItems: "center", cursor: "pointer", marginRight: "5px"}} onClick={() => props.close()}><HighlightOffIcon style={{width: "20px"}} /></div>Group #{host.group_id}
                </span>
                {
                    (getRoleGlobal() || getRoleWrite(host.group_id)) &&
                    <div style={{display: 'flex', alignItems: 'center', cursor: "pointer"}} onClick={() => editMode === true ? cancelEdit() : props.edit()}>Edit<EditIcon sx={{width: "18px", height: "18px"}}/></div>
                }
            </h3>
            <div className="widget-content"  style={{marginBottom: "10px"}}>
            <div className="widget-text">
                <span>Name: </span>
                <span>{host.group_name}</span>
            </div>
            <div className="widget-text">
                <span>Operating System: </span>
                <span>{getOSName(host.os_id)}</span>
            </div>
            <div className="widget-text">
                <span>Total Devices: </span>
                <span>{props.deviceList.filter(d => d.groups.find(g => g.group_id == host.group_id) != null).length}</span>
            </div>
                {
                <>
                    {
                    (getRoleGlobal() || getRoleGroup(host.group_id)) &&
                    <>
                        <div className="widget-text" style={{gridTemplateColumns: "1fr"}}>
                            <span style={{fontWeight: "bold", textDecoration: "underline"}}>Connection Variables: </span>
                        </div>
                        <div style={{maxHeight: "200px", overflowY: "auto"}}>
                        {
                            connectionVariables != null &&
                            connectionVariables.map((item, index) => (
                                <div key={index} className="widget-text">
                                    <span style={{overflowX: "hidden", textOverflow: "ellipsis", cursor: "help"}} title={item.var_name}>{item.var_name}: </span>
                                    <span>{var_password_check(item.var_name) ? "********" : item.var_value}</span>
                                </div>
                            ))
                        }
                        </div>

                        <div className="widget-text" style={{marginTop: "10px"}}>
                            <span>Template List: </span>
                            <span>
                                { 
                                    <select value={templateSelect == null ? "" : templateSelect.id} onChange={(e) => getTemplate(templateList.find(x => x.id == e.target.value))} style={{width: "100%"}}>
                                        {
                                            templateList != null && templateList.length > 0 && 
                                            templateList.map((template,index) => (
                                                <option key={template.id} value={template.id}>{template.name}</option>
                                            ))
                                        }
                                    </select>
                                }
                            </span>
                        </div>
                        {
                            stigVariables && stigVariables.length > 0 &&
                            <>
                                <div className="widget-text" style={{gridTemplateColumns: "1fr", marginTop: "10px"}}>
                                    <span style={{fontWeight: "bold", textDecoration: "underline"}}>STIG Variables: </span>
                                </div>
                                <div style={{maxHeight: "300px", overflowY: "auto"}}>
                                {
                                    stigVariables.map((item, index) => (
                                        <div key={index} className="widget-text">
                                            <span style={{overflowX: "hidden", textOverflow: "ellipsis", cursor: "help"}} title={item.var_name}>{item.var_name}</span>
                                            <span>{var_password_check(item.var_name) ? "********" : item.var_value}</span>
                                        </div>
                                    ))
                                }
                                </div>
                            </>
                        }
                    </>
                    }
                    </>
                }
                </div>
                {
                    (getRoleGlobal() || getRoleGroup(host.group_id)) &&
                    <>
                        {
                            (getRoleGlobal() || getRoleWrite(host.group_id)) &&
                            <div style={{display: 'flex', justifyContent:'center', alignItems:"center", flexWrap: "wrap"}}>
                                <input type="button" style={{width: "fit-content", padding: "0px 10px"}}  value="Run" className="widget-button" onClick={() => props.runHost()}/>
                                {/* <input type="button" style={{width: "fit-content", padding: "0px 10px"}}  value="Create Device" className="widget-button" onClick={() => props.addHost()} /> */}
                                <input type="button" style={{width: "fit-content", padding: "0px 10px"}}  value="Schedule Task(s)" className="widget-button" onClick={() => props.schedule()}/>
                                <input type="button" style={{width: "fit-content", padding: "0px 10px"}} value="Create Template" className="widget-button" onClick={() => props.addTemplate()} />
                            </div>
                        }
                        {/* <div style={{display: 'flex', justifyContent:'center', alignItems:"center", flexWrap: "wrap"}}>
                            <input type="button" value="View Audit(s)" className="widget-button" style={{width: "fit-content", padding: "0px 10px"}} onClick={() => props.viewHost(0)}/>
                            <input type="button" value="View Remediation(s)" className="widget-button" style={{width: "fit-content", padding: "0px 10px"}} onClick={() => props.viewHost(1)}/>
                        </div> */}
                    </>
            }
        </>
    )
  }  
  
  const getGroup_newTemplate = () =>{
    return(
        <>
            <h3 className="widget-header">
                <span>Group</span>
                <div style={{display: 'flex', alignItems: 'center', cursor: "pointer"}}></div>
            </h3>
            <div className="widget-content">
                <div className="widget-text">
                    <span>Group Selected: </span>
                    <span style={{overflowX: "hidden", textOverflow: "ellipsis"}} title={host.group_name}>{host.group_name}</span>
                <span style={{textAlign: "center"}}></span>
                </div>
                <div className="widget-text">
                    <span style={{marginRight: "5px"}}>Template Name </span>
                    <input type="text" required value={newTemplateName} onChange={(e) => setNewTemplateName(e.target.value)} style={{width: "75%"}}/>
                </div>
                {/* <div className="widget-text" style={{gridTemplateColumns: "1fr"}}>
                    <span style={{fontWeight: "bold", textDecoration: "underline"}}>Connection Variables <span style={{fontStyle: "italic"}}>( Required )</span>: </span>
                </div>
                <div style={{maxHeight: "200px", overflowY: "auto"}}>
                    {
                        connectionVariables.map((item, index) => (
                            <div key={index} className="widget-text" style={{display: 'grid', gridTemplateColumns: '1fr 1fr'}}>
                                <span style={{overflowX: "hidden", textOverflow: "ellipsis"}} title={item.var_name}>{item.var_name}: </span>
                                {
                                <input type={var_password_check(item.var_name) ? "password" : "text"} className="widget-text" required value={item.var_value == null ? "" : item.var_value} onChange={(e) => updateVariable(index, e.target.value, 0)}/>
                                }
                            </div>
                        ))
                    }
                </div> */}
                <div className="widget-text" style={{gridTemplateColumns: "1fr", marginTop: "10px"}}>
                    <span style={{fontWeight: "bold", textDecoration: "underline"}}>STIG Variable: </span>
                </div>
                <div style={{maxHeight: "200px", overflowY: "auto"}}>
                    {
                        stigVariables.map((item, index) => (
                            <div key={index} className="widget-text" style={{display: 'grid', gridTemplateColumns: '1fr 1fr'}}>
                                <span style={{overflowX: "hidden", textOverflow: "ellipsis"}} title={item.var_name}>{item.var_name}: </span>
                                {
                                <input type={var_password_check(item.var_name) ? "password" : "text"} className="widget-text" required value={item.var_value == null ? "" : item.var_value} onChange={(e) => updateVariable(index, e.target.value, 1)}/>
                                }
                            </div>
                        ))
                    }
                </div>
            </div>
            <div style={{display: 'flex', justifyContent:'center', alignItems:"center", marginTop: "10px"}}>
                <input type="button" value="Create Baseline" className="widget-button" onClick={() => setBaselineTemplateMode(!baselineTemplateMode)}/>
                <input type="button" value="Cancel" className="widget-button" onClick={() => cancelNewTemplate()}/>
                <input type="button" value="Save" className="widget-button" onClick={() => saveNewTemplate()} />
                { loading && <CircularProgress style={{width: "20px", height: "20px"}} /> }
            </div>
        </>
    )
  }

  const getGroup_baselineTemplate = () =>{
    return(
        <>
            <h3 className="widget-header">
                <span>Group</span>
                <div style={{display: 'flex', alignItems: 'center', cursor: "pointer"}}></div>
            </h3>
            <div className="widget-content">
                {/* <div className="widget-text">
                    <span style={{marginRight: "5px"}}>Template Name </span>
                    <input type="text" required value={newTemplateName} onChange={(e) => setNewTemplateName(e.target.value)} style={{width: "75%"}}/>
                </div> */}
                <div className="widget-text" style={{gridTemplateColumns: "1fr"}}>
                    <span style={{fontWeight: "bold", textDecoration: "underline"}}>Connection Variables <span style={{fontStyle: "italic"}}>( Required )</span>: </span>
                </div>
                <div style={{maxHeight: "200px", overflowY: "auto"}}>
                    {
                        connectionVariables.map((item, index) => (
                            <div key={index} className="widget-text" style={{display: 'grid', gridTemplateColumns: '1fr 1fr'}}>
                                <span style={{overflowX: "hidden", textOverflow: "ellipsis"}} title={item.var_name}>{item.var_name}: </span>
                                <input type={var_password_check(item.var_name) ? "password" : "text"} className="widget-text" required value={item.var_value == null ? "" : item.var_value} onChange={(e) => updateVariable(index, e.target.value, 0)}/>
                            </div>
                        ))
                    }
                </div>
                <div className="widget-text" style={{gridTemplateColumns: "1fr", marginTop: "10px"}}>
                    <span style={{fontWeight: "bold", textDecoration: "underline"}}>STIG Variable: </span>
                </div>
                <div style={{maxHeight: "200px", overflowY: "auto"}}>
                    {
                        stigVariables.map((item, index) => (
                            <div key={index} className="widget-text" style={{display: 'grid', gridTemplateColumns: '1fr 1fr'}}>
                                <span style={{overflowX: "hidden", textOverflow: "ellipsis"}} title={item.var_name}>{item.var_name}: </span>
                                <input type={var_password_check(item.var_name) ? "password" : "text"} className="widget-text" required value={item.var_value == null ? "" : item.var_value} onChange={(e) => updateVariable(index, e.target.value, 1)}/>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div style={{display: 'flex', justifyContent:'center', alignItems:"center", marginTop: "10px"}}>
                <input type="button" value="Cancel" className="widget-button" onClick={() => cancelNewTemplate()}/>
                <input type="button" value="Save" className="widget-button" onClick={() => saveBaselineTemplate()} />
                { loading && <CircularProgress style={{width: "20px", height: "20px"}} /> }
            </div>
        </>
    )
  }

  const getGroup_Edit = () => {
    return(
        <>
            <h3 className="widget-header">
                <span>Group</span>
                <div style={{display: 'flex', alignItems: 'center', cursor: "pointer"}} onClick={() => editMode === true ? cancelEdit() : props.edit()}>Edit<EditIcon sx={{width: "18px", height: "18px"}}/></div>
            </h3>
            <div className="widget-content">
            <div className="widget-text">
                <span>Name: </span>
                <input type="text" className="widget-textfield" value={host.group_name} onChange={(e) => updateName(e.target.value,0)} />
            </div>
            <div className="widget-text">
                <span>Operating System: </span>
                <span>{getOSName(host.os_id)}</span>
            </div>
            <div className="widget-text">
                <span>Total Devices: </span>
                <span>{host.devices.length}</span>
            </div>
            {
                templateSelect != null &&
                <div className="widget-text">
                    <span>Template: </span>
                    {
                        templateSelect.name != "Baseline" ?
                        <input type="text" className="widget-textfield" value={templateSelect.name} onChange={(e) => updateTemplateName(e.target.value)} />
                        :
                        <span>{templateSelect.name}</span>
                    }
                </div>
            }
            {
                templateSelect == null ?
                <></>
                :
                templateSelect.name == "Baseline" ?
                <>
                <div style={{maxHeight: "200px", overflowY: "auto"}}>
                    <div className="widget-text" style={{gridTemplateColumns: "1fr"}}>
                        <span style={{fontWeight: "bold", textDecoration: "underline"}}>Connection Variables: </span>
                    </div>
                    <div style={{maxHeight: "200px", overflowY: "auto"}}>
                    {
                        connectionVariables.map((item, index) => (
                            <div key={index} className="widget-text" style={{display: 'grid', gridTemplateColumns: '1fr 1fr'}}>
                                <span style={{overflowX: "hidden", textOverflow: "ellipsis"}} title={item.var_name}>{item.var_name}: </span>
                                    <input type={var_password_check(item.var_name) ? "password" : "text"} value={item.var_value} className="widget-textfield" onChange={(e) => updateVar(item.var_id, e.target.value, 0, 0)}/>
                            </div>
                        ))
                    }
                    </div>
                    </div>
                    <div className="widget-text" style={{gridTemplateColumns: "1fr", marginTop: "10px"}}>
                        <span style={{fontWeight: "bold", textDecoration: "underline"}}>STIG Variables: </span>
                    </div>
                    <div style={{maxHeight: "200px", overflowY: "auto"}}>
                    {
                        stigVariables.map((item, index) => (
                            <div key={index} className="widget-text" style={{display: 'grid', gridTemplateColumns: '1fr 1fr'}}>
                                <span style={{overflowX: "hidden", textOverflow: "ellipsis"}} title={item.var_name}>{item.var_name}: </span>
                                <span style={{textAlign: "center"}}>{var_password_check(item.var_name) ? "********" : item.var_value}</span>
                            </div>
                        ))
                    }
                    <div ref={divRef}></div>
                </div>
                </>
                :
                <>
                <div className="widget-text" style={{gridTemplateColumns: "1fr"}}>
                    <div onClick={makeBaselineHandler} style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
                        <AddBoxIcon /> Make this the Baseline Template
                    </div>
                </div>
            <div style={{maxHeight: "200px", overflowY: "auto"}}>
                <div className="widget-text" style={{gridTemplateColumns: "1fr"}}>
                    <span style={{fontWeight: "bold", textDecoration: "underline"}}>Connection Variables: </span>
                </div>
                <div style={{maxHeight: "200px", overflowY: "auto"}}>
                {
                    connectionVariables.map((item, index) => (
                        <div key={index} className="widget-text" style={{display: 'grid', gridTemplateColumns: '1fr 1fr'}}>
                            <span style={{overflowX: "hidden", textOverflow: "ellipsis"}} title={item.var_name}>{item.var_name}: </span>
                                <input type={var_password_check(item.var_name) ? "password" : "text"} value={item.var_value} className="widget-textfield" onChange={(e) => updateVar(item.var_id, e.target.value, 0, 0)}/>
                        </div>
                    ))
                }
                </div>
                </div>
                <div className="widget-text" style={{gridTemplateColumns: "1fr", marginTop: "10px"}}>
                    <span style={{fontWeight: "bold", textDecoration: "underline"}}>STIG Variables: </span>
                </div>
                <div style={{maxHeight: "200px", overflowY: "auto"}}>
                {
                    stigVariables.map((item, index) => (
                        <div key={index} className="widget-text" style={{display: 'grid', gridTemplateColumns: '1fr 1fr'}}>
                            <span style={{overflowX: "hidden", textOverflow: "ellipsis"}} title={item.var_name}>{item.var_name}: </span>
                                <input type={var_password_check(item.var_name) ? "password" : "text"} value={item.var_value} className="widget-textfield" onChange={(e) => updateVar(item.var_id, e.target.value, 0, 1)}/>
                        </div>
                    ))
                }
                <div ref={divRef}></div>
            </div>
            </>
            }
            </div>
            {/* <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px'}}>
                <span style={{color: "#0088FF", cursor:"pointer", padding: "5px"}} onClick={() => addVar(0)}>+ Add Group Variable</span>
            </div> */}
            <div style={{display: 'flex', justifyContent:'center', alignItems:"center", marginTop: "10px"}}>
                <input type="button" value="Cancel" className="widget-button" onClick={() => cancelEdit()}/>
                <input type="button" value="Save" className="widget-button" onClick={() => saveEdit(0)} />
            </div>
        </>
    )
  }

  return (
    <div className="widget-container" style={{display: host == null ? "none" : "" , backgroundColor: theme ? "#242424" : "#FFF", border: theme ? "1px solid #000" : "1px solid #EBEBEB", color: theme ? "#fff" : "#000", borderRadius: "10px", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", width: "90%", margin: "10px 0px", height: "auto"}}>
        {host === null || host === undefined ? <></> : addNewTemplateMode ? baselineTemplateMode ? getGroup_baselineTemplate() : getGroup_newTemplate() : editMode ? ('group_id' in host) ? getGroup_Edit() : getDevice_Edit() : ('group_id' in host) ? getGroup() : getDevice()}
    </div>
  )
}

export default GroupWidget