import React, { useContext, useEffect, useState } from 'react'
import { Switch } from '@mui/material';
import { UserContext } from '../../../App';

const GroupAuditing = (props) => {
    const { user, FetchCatch, theme } = useContext(UserContext);
    const [typeOfRun, setTypeOfRun] = useState(false); //false = audit || true = remediation
    const [playbook, setPlaybook] = useState([]);
    const [playbookList, setPlaybookList] = useState([]);
    const [groupSelect, setGroupSelect] = useState(null);
    const [templateList, setTemplateList] = useState([]);
    const [templateSelect, setTemplateSelect] = useState(props.templateSelect);
    
    useEffect(() => {
        if(templateList.length > 0){
            if(playbookList.length == 1){
                PlaybookSelectHandler(playbookList[0]);
            }
        }
    }, [templateList])

    useEffect(() => {
        setTemplateSelect(props.templateSelect);
    }, [props.templateSelect])

    useEffect(async () => {
        if(props.display === false || props.selected === null) return;
        setTemplateList([]);
        setPlaybook([]);
        let tmp = [];
        tmp = props.playbookList.filter(playbook => playbook.os_id === props.selected.os_id);
        setPlaybookList(tmp);
        if("device_id" in props.selected){
            if(props.parent.length === 0){
                if(props.selected.groups.length > 0){
                    groupSelectHandler(props.selected.groups[0]);
                }else{
                    groupSelectHandler(null);
                }
            }else{
                groupSelectHandler(props.parent[props.parent.length-1]);
            }
        }else{
            groupSelectHandler(props.selected);
        }
    },[props.display, props.selected, props.parent])

    const API_group_template = async (group_id) => {
        if(group_id == -1 || group_id == null) return;
        const url_groups_template = "/api/stigs/templates/group/" + group_id;
        let data = await fetch(url_groups_template,{
            method: 'GET',
            headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${user.token}` },
        })
        .then(res=>{
            if(res.ok) return res.json();
            throw res;
        })
        .then(data =>{
            return data;
        }).catch(async error => {
            return await FetchCatch(error, API_group_template, [group_id]);
        });

        return data;
    }
    

    const auditRun = (status) => {
        if(playbook.length == 0){
            alert('Select a STIG to run.');
            return;
        }
        let index = templateList.findIndex(x => x.id == templateSelect.id);
        if(templateSelect == null || index == -1){
            alert('Select a template to run.');
            return;
        }

        if(window.confirm("Are you sure you want to " + (typeOfRun ? "REMEDIATION" : "AUDIT") + " on " + ("device_id" in props.selected ? props.selected.name : props.selected.group_name) + "?" + (typeOfRun ? "\n\n * Recommend to backup VM, DB, or devices before running remediations." : ""))){
            props.auditRun(status,playbook,typeOfRun ? 1 : 0, groupSelect, templateSelect.id);
        }
    }

    const getOS = (os_id) => {
        if(props.OS == null || props.OS.length == 0) return "n/a";
        let index = props.OS.findIndex(os => os.id == os_id);
        if(index == -1) return "n/a";
        return props.OS[props.OS.findIndex(os => os.id === props.selected.os_id)].operating_system;
    }

    const PlaybookSelectHandler = (pb) => {
        let playbook_tmp = [...playbook];
        let index = playbook_tmp.findIndex(x => x.id == pb.id);
        if(index == -1){
            playbook_tmp.push(pb);
        }else{
            playbook_tmp.splice(index,1);
        }

        setPlaybook(playbook_tmp);
    }

    const groupSelectHandler = async (group) => {
        setGroupSelect(group);
        if(group != null){
            let group_templates = await API_group_template(group.group_id);
            if(group_templates){
                setTemplateList(group_templates);
                if(templateSelect == null && group_templates.length > 0){
                    setTemplateSelect(group_templates[0]);
                }
            }
        }else{
            setTemplateList([]);
            setTemplateSelect(null);
        }
    }

    const checkAllSTIGHandler = (checked) => {
        if(checked){
            setPlaybook([...playbookList]);
        }else{
            setPlaybook([]);
        }
    }

    const auditCreate = () => {
        if("group_id" in props.selected){
            return(
                <>
                <div className="widget-text">
                    <span style={{marginRight: "5px"}}>Group Name: </span>
                    <span>{props.selected.group_name != null && props.selected.group_name}</span>
                </div>
                <div className="widget-text">
                    <span style={{marginRight: "5px"}}>Template: </span>
                    <span>    
                        { 
                        templateList != null && templateList.length > 0 &&
                        <select value={templateSelect == null ? "" : templateSelect.id} style={{width: "100%"}} onChange={(e) => setTemplateSelect(templateList.find(x => x.id == e.target.value))}>
                            <option key={-1} value={null}>Select a template</option>
                            {
                                templateList.map((template,index) => (
                                    <option key={template.id} value={template.id}>{template.name}</option>
                                ))
                            }
                        </select>
                        }
                    </span>
                </div>
                <div className="widget-text" style={{gridTemplateColumns: "1fr",}}>
                    <span style={{fontWeight: "bold", textDecoration: "underline", display: 'flex'}}>
                    {
                        (playbookList != null && playbookList.length > 1) &&
                        <input type="checkbox" style={{marginRight: "5px", cursor: "pointer"}} onChange={(e) => checkAllSTIGHandler(e.target.checked)}/>
                    }
                        Choose a STIG: </span>
                </div>
                <div className="widget-text" style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                    <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', width: "100%"}}>
                        {
                            playbookList.map((pb,index) => (
                                <div key={pb.id} style={{display: 'grid', gridTemplateColumns:"1fr 1fr", width: '100%'}}>
                                    <div style={{display: 'flex', alignItems: 'center', cursor: "default"}} onClick={() => PlaybookSelectHandler(pb)}><input type="checkbox" style={{marginRight: "5px", cursor: "pointer"}} checked={playbook.findIndex(x => x.id == pb.id) != -1} onChange={() => PlaybookSelectHandler(pb)}/>{pb.friendly_name}</div>
                                </div> 
                            ))
                        }
                    </div>
                </div>  
                <div style={{display: 'flex', justifyContent:'center', alignItems:"center"}}>
                    <input type="button" value="Cancel" className="widget-button" onClick={() => props.cancel()}/>
                    <input type="button" value="Run/View" className="widget-button" onClick={() => auditRun(1)}/>
                    <input type="button" value="Run" className="widget-button" onClick={() => auditRun(3)}/>
                </div>
                </> 
            )
        }else{
            return(
                <>
                <div className="widget-text">
                    <span style={{marginRight: "5px"}}>Device Name: </span>
                    <span>{props.selected.name}</span>
                </div>
                <div className="widget-text">
                    <span>Choose a Group:</span>
                    <select value={groupSelect == null ? "" : groupSelect.group_id} onChange={(e) => groupSelectHandler(props.hostList.find(x => x.group_id == e.target.value))}>
                        {
                            props.selected.groups.map((group,index) => (
                                <option key={index} value={group.group_id}>{group.group_name}</option>
                            ))
                        }
                    </select>
                </div>        
                <div className="widget-text">
                    <span style={{marginRight: "5px"}}>OS: </span>
                    <span>{getOS(props.selected.os_id)}</span>
                </div>
                <div className="widget-text">
                    <span style={{marginRight: "5px"}}>Template: </span>
                    <span>    
                        { 
                        templateList != null && templateList.length > 0 &&
                        <select value={templateSelect == null ? "" : templateSelect.id} style={{width: "100%"}} onChange={(e) => setTemplateSelect(templateList.find(x => x.id == e.target.value))}>
                            <option key={-1} value={null}>Select a template</option>
                            {
                                templateList.map((template,index) => (
                                    <option key={template.id} value={template.id}>{template.name}</option>
                                ))
                            }
                        </select>
                        }
                    </span>
                </div>
                <div className="widget-text" style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                    <span style={{fontWeight: "bold", textDecoration: "underline", display: 'flex'}}>
                    {
                        (playbookList != null && playbookList.length > 1) &&
                        <input type="checkbox" style={{marginRight: "5px", cursor: "pointer"}} onChange={(e) => checkAllSTIGHandler(e.target.checked)}/>
                    }
                        Choose a STIG:</span>
                    <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', width: "100%"}}>
                    {
                            playbookList.map((pb,index) => (
                                <div key={pb.id} style={{display: 'grid', gridTemplateColumns:"1fr 1fr", width: '100%'}}>
                                    <div style={{display: 'flex', alignItems: 'center', cursor: "default"}} onClick={() => PlaybookSelectHandler(pb)}><input type="checkbox" style={{marginRight: "5px", cursor: "pointer"}} checked={playbook.findIndex(x => x.id == pb.id) != -1} onChange={() => PlaybookSelectHandler(pb)}/>{pb.friendly_name}</div>
                                </div> 
                            ))
                        }
                    </div>
                </div>    
                <div style={{display: 'flex', justifyContent:'center', alignItems:"center"}}>
                    <input type="button" value="Cancel" className="widget-button" onClick={() => props.cancel()}/>
                    <input type="button" value="Run/View" className="widget-button" onClick={() => auditRun(0)}/>
                    <input type="button" value="Run" className="widget-button" onClick={() => auditRun(2)}/>
                </div>
                </> 
            )
        }
    }


    return (
        <div className="widget-container" style={{display: props.display && props.selected != null ? "" : "none", backgroundColor: theme ? "#242424" : "#FFF", border: theme ? "1px solid #000" : "1px solid #EBEBEB", color: theme ? "#fff" : "#000", borderRadius: "10px", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", width: "90%", height: "auto", margin: "10px 0px"}}>
            <h3 className="widget-header"><span>Run {typeOfRun ? "a Remediation" : "an Audit"}</span></h3>
            <div className="widget-content">
            <div className="widget-text-no-grid" style={{display: "grid", gridTemplateColumns: "20% 20% 15%"}}>
                <span> 
                    <p style={!(typeOfRun) ? {width:46, color:'#09347A', fontWeight:'bold'} : {}}>Audit</p>
                </span>

                <Switch color='default' checked={typeOfRun} onChange={() => setTypeOfRun(!typeOfRun)}  />
                
                <span>
                    <p style={(typeOfRun) ? {width:98, color:'#09347A', fontWeight:'bold'} : {}}>Remediation</p>
                </span>
            </div>
                {
                    props.selected !== undefined && props.selected !== null ? auditCreate() : <></>
                }
            </div>
        </div>
    )
}

export default GroupAuditing;