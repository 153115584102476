import React from 'react';


const StigTabHeader = (props) => {
    const getPlaybookName = (audit) => {
        if(props.audit === null) return "";
        let index = props.playbookList.findIndex(playbook => playbook.id === audit.audit.playbook_id);
        if(index === -1) return "n/a";
        return props.playbookList[index].friendly_name;
    }

    const getDeviceName = (audit) => {
        if(props.audit === null) return "";
        if(props.deviceList.length === 0) return "n/a";
        let index = props.deviceList.findIndex(device => device.device_id === audit.audit.device_id);
        if(index === -1) return "n/a";
        return props.deviceList[index].name;
    }

    const handleAuditBtnClick = (index) => {
        // load newly selected Audit & render
        props.select(index);
    }

    return (
        <>
            <div className='stiglist-container' style={{backgroundColor: props.theme ? "#242424" : ""}}>
                <input className="stiglist-stigbutton" type="button" value="+" onClick={() => props.popup()}/>
                {
                props.audits.map((audit,index) =>(
                    <div key={index} className={props.audit !== null && props.audit.audit.id === audit.audit.id ?  "stiglist-stigbutton stiglist-select" : "stiglist-stigbutton"} style={{backgroundColor: props.theme && props.audit !== null && props.audit.audit.id === audit.audit.id ? "#454545" : "", color: props.theme ? "#fff" : "#000" }} onClick={() => handleAuditBtnClick(index)} >
                        <p style={{fontSize: "10px"}}>{ getDeviceName(audit) + " | " + getPlaybookName(audit)}
                        <input type="button" value="x" className="stiglist-closebutton " style={{color: props.theme ? "#fff" : "#000"}} onClick={() => {props.remove(index)}}/>
                        </p>
                    </div>
                ))
                }
            </div>
        </>
    )
}

export default StigTabHeader;