import React, { useContext, useEffect, useState } from 'react'
import { Switch } from '@mui/material';
import { UserContext } from '../../../App';

const DeviceAuditing = (props) => {
    const { user, FetchCatch, theme } = useContext(UserContext);
    const [typeOfRun, setTypeOfRun] = useState(false); //false = audit || true = remediation
    const [playbook, setPlaybook] = useState([]);
    const [playbookList, setPlaybookList] = useState([]);
    const [groupSelect, setGroupSelect] = useState(null);
    const [templateList, setTemplateList] = useState([]);
    const [templateSelect, setTemplateSelect] = useState(null);
    
    useEffect(() => {
        if(templateList.length > 0){
            if(playbookList.length == 1){
                PlaybookSelectHandler(playbookList[0]);
            }
        }
    }, [templateList])

    useEffect(async () => {
        if(props.display === false || props.selected === null) return;
        setTemplateSelect(null);
        setTemplateList([]);
        setPlaybook([]);
        let tmp = [];
        tmp = props.playbookList.filter(playbook => playbook.os_id === props.selected.os_id);
        setPlaybookList(tmp);
        if("device_id" in props.selected){
            let group_id = -1;
            if(props.parent.length === 0){
                if(props.selected.groups.length > 0){
                    setGroupSelect(props.selected.groups[0]);
                    group_id = props.selected.groups[0].group_id;
                }else{
                    setGroupSelect(null);
                }
            }else{
                setGroupSelect(props.parent[props.parent.length-1]);
                group_id = props.parent[props.parent.length-1].group_id;
            }
            getGroupTemplate(group_id);
        }else{
            getGroupTemplate(props.selected.group_id);
        }
    },[props.display, props.selected, props.parent])

    const getGroupTemplate = async (groupId) => {
        if(groupId == -1 || groupId == null) return;
        const url_groups_template = "/api/stigs/templates/group/" + groupId;
        await fetch(url_groups_template,{
            method: 'GET',
            headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${user.token}` },
        })
        .then(res=>{
            if(res.ok) return res.json();
            throw res;
        })
        .then(data =>{
            setTemplateList(data);
            if(data.length > 0){
                setTemplateSelect(data[0]);
            }
        }).catch(async error => {
            return await FetchCatch(error, getGroupTemplate, [groupId]);
        });
    }

    const auditRun = (status) => {
        if(playbook.length == 0){
            alert('Select a STIG to run.');
            return;
        }
        
        if(templateSelect == null){
            alert('Select a template to run.');
            return;
        }

        if(window.confirm("Are you sure you want to " + (typeOfRun ? "REMEDIATION" : "AUDIT") + " on " + ("device_id" in props.selected ? props.selected.name : props.selected.group_name) + "?" + (typeOfRun ? "\n\n * Recommend to backup VM, DB, or devices before running remediations." : ""))){
            props.auditRun(status,playbook,typeOfRun ? 1 : 0, groupSelect, templateSelect.id);
        }
    }

    const getOS = (os_id) => {
        if(props.OS == null || props.OS.length == 0) return "n/a";
        let index = props.OS.findIndex(os => os.id == os_id);
        if(index == -1) return "n/a";
        return props.OS[props.OS.findIndex(os => os.id === props.selected.os_id)].operating_system;
    }

    const PlaybookSelectHandler = (pb) => {
        let playbook_tmp = [...playbook];
        let index = playbook_tmp.findIndex(x => x.id == pb.id);
        if(index == -1){
            playbook_tmp.push(pb);
        }else{
            playbook_tmp.splice(index,1);
        }

        setPlaybook(playbook_tmp);
    }

    const groupSelectHandler = (group) => {
        setGroupSelect(group);
        if(group != null){
            getGroupTemplate(group.group_id);
        }
    }

    const typeOfRunHandler = () => {
        setTypeOfRun(!typeOfRun);
    }

    const auditCreate = () => {
        return(
            <>
            <div className="widget-text">
                <span style={{marginRight: "5px"}}>Device Name: </span>
                <span>{props.selected.name}</span>
            </div>
            <div className="widget-text">
                <span>Choose a Group:</span>
                <select value={groupSelect == null ? "" : groupSelect.group_id} onChange={(e) => groupSelectHandler(props.hostList.find(x => x.group_id == e.target.value))}>
                    {
                        props.selected.groups.map((group,index) => (
                            <option key={group.group_id} value={group.group_id}>{group.group_name}</option>
                        ))
                    }
                </select>
            </div>        
            <div className="widget-text">
                <span style={{marginRight: "5px"}}>OS: </span>
                <span>{getOS(props.selected.os_id)}</span>
            </div>
            <div className="widget-text">
                    <span style={{marginRight: "5px"}}>Template: </span>
                    <span>    
                        { 
                        templateList != null && templateList.length > 0 &&
                        <select value={templateSelect == null ? "" : templateSelect.id} style={{width: "100%"}} onChange={(e) => setTemplateSelect(templateList.find(x => x.id == e.target.value))}>
                            <option key={-1} value={null}>Select a template</option>
                            {
                                templateList.map((template,index) => (
                                    <option key={template.id} value={template.id}>{template.name}</option>
                                ))
                            }
                        </select>
                        }
                    </span>
                </div>
            <div className="widget-text" style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                <span>Choose a STIG:</span>
                <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', width: "100%"}}>
                {
                        playbookList.map((pb,index) => (
                            <div key={pb.id} style={{display: 'grid', gridTemplateColumns:"1fr 1fr", width: '100%'}}>
                                <div style={{display: 'flex', alignItems: 'center', cursor: "default"}} onClick={() => PlaybookSelectHandler(pb)}><input type="checkbox" style={{marginRight: "5px", cursor: "pointer"}} checked={playbook.findIndex(x => x.id == pb.id) != -1} onChange={() => PlaybookSelectHandler(pb)}/>{pb.friendly_name}</div>
                            </div> 
                        ))
                    }
                </div>
            </div>    
            <div style={{display: 'flex', justifyContent:'center', alignItems:"center"}}>
                <input type="button" value="Cancel" className="widget-button" onClick={() => props.cancel()}/>
                <input type="button" value="Run/View" className="widget-button" onClick={() => auditRun(0)}/>
                <input type="button" value="Run" className="widget-button" onClick={() => auditRun(2)}/>
            </div>
            </> 
        )
    }


    return (
        <div className="widget-container" style={{display: props.display && props.selected != null ? "" : "none", backgroundColor: theme ? "#242424" : "#FFF", border: theme ? "1px solid #000" : "1px solid #EBEBEB", color: theme ? "#fff" : "#000", borderRadius: "10px", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", width: "90%", height: "auto", margin: "10px 0px"}}>
            <h3 className="widget-header"><p>Run{typeOfRun ? <> a <span style={{fontWeight:'bold'}}> Remediation</span></> : <> an <span style={{fontWeight:'bold'}}>Audit</span></>}</p></h3>
            <div className="widget-content">
            <div className="widget-text-no-grid" style={{display: "grid", gridTemplateColumns: "20% 20% 15%"}}>              
                <span> 
                    <p style={!(typeOfRun) ? {width:46, color:'#09347A', fontWeight:'bold'} : {}}>Audit</p>
                </span>
                <Switch color='default' checked={typeOfRun} onChange={typeOfRunHandler} />
                <span>
                    <p style={(typeOfRun) ? {width:98, color:'#09347A', fontWeight:'bold'} : {}}>Remediation</p>
                </span>
            </div>
                {
                    props.selected !== undefined && props.selected !== null ? auditCreate() : <></>
                }
            </div>
        </div>
    )
}

export default DeviceAuditing;