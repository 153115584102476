import React, { useEffect, useState } from 'react'

const Pagination = (props) => {
    const totalPageNumbers = 8;
    const [pageNumbers, setPageNumbers] = useState([]);

    useEffect(() => {
        if(props.totalPosts === 0) setPageNumbers([0]);
        else getNumbers();
    },[props.totalPosts])

    useEffect(() => {
        if(props.totalPosts === 0) return;
        getNumbers();
    },[props.page]);


    const getNumbers = () => {
        let tmp = [];
        let total = Math.ceil(props.totalPosts / props.postsPerPage);
        let startingIndex = 1;
        let finishedIndex = totalPageNumbers;
        if(total < totalPageNumbers){
            finishedIndex = total;
        }else{
            startingIndex = props.page - Math.ceil(totalPageNumbers / 2);
            if(startingIndex < 1){
                startingIndex = 1;
            }
            if(total - startingIndex < totalPageNumbers){
                startingIndex = total - totalPageNumbers;
            }
            finishedIndex = startingIndex + totalPageNumbers;
        }

        for(let i = startingIndex; i <= finishedIndex; i++){
            if(i === 0) continue;
            tmp.push(i);
        }
        setPageNumbers(tmp);
    }




    return (
        <>
        <div onClick={() => props.paginate(props.page === 1 ? props.page : props.page - 1)} style={{padding: "5px", border: props.theme ? "1px solid #fff" : "1px solid #ababab", cursor: "pointer", marginRight: "25px", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"}}>Previous</div>
        <nav style={{overflow: "hidden"}}>
            <ul style={{display: 'flex', alignItems: 'center', flexDirection: 'row', listStyle: "none" }} className="pagination-container">
                {
                    pageNumbers.map(number => (
                        <li key={number} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: "30px", height: "30px" ,border: props.theme ? "1px solid #fff" : "1px solid #ababab", cursor: "pointer", backgroundColor: props.page === number ? "#9bcfff" : ""}} onClick={() => props.paginate(number)}>
                            <div className='page-link'>
                                {number}
                            </div>
                        </li>
                    ))
                }
                {
                    pageNumbers.indexOf(Math.ceil(props.totalPosts / props.postsPerPage)) === -1 ?
                    <>
                    <li style={{padding: "0px 10px"}}>...</li>
                    
                    <li style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: "30px", height: "30px" ,border: props.theme ? "1px solid #fff" : "1px solid #ababab", cursor: "pointer"}} onClick={() => props.paginate(Math.ceil(props.totalPosts / props.postsPerPage))}>
                        <div className='page-link'>
                            {Math.ceil(props.totalPosts / props.postsPerPage)}
                        </div>
                    </li>
                    </>
                    :
                    <></>
                }
            </ul>
        </nav>    
        <div onClick={() => props.paginate(props.page === Math.ceil(props.totalPosts / props.postsPerPage) ? props.page : props.page + 1)} style={{padding: "5px", border: props.theme ? "1px solid #fff" : "1px solid #ababab", cursor: "pointer", marginLeft: "25px", boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)"}}>Next</div>
        </>
    )
}

export default Pagination;