import React, {Fragment, useState} from 'react';
import DatePicker from "react-datepicker";

const AuditPopup = (props) => {
    const pageOptions = ['Filter']
    const [pageType, setPageType] = useState("Filter");
    const [beginDate, setBeginDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [deviceFilterSelect, setDeviceFilterSelect] = useState(undefined);
    const [playbookFilterSelect, setPlaybookFilterSelect] = useState(undefined);
    const maxDay = (new Date()).setDate(new Date().getDate() + 1);

    const clearFilter = () => {
        setBeginDate(null);
        setEndDate(null);
        setDeviceFilterSelect(undefined);
        setPlaybookFilterSelect(undefined);
    }

    const submitFilter = () => {
        props.filterSave({begin_time: beginDate == null ? null : beginDate.getTime() / 1000, end_time: endDate == null ? null : endDate.getTime() / 1000, device_id: deviceFilterSelect, playbook_id: playbookFilterSelect?.id});
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <div style={{display: 'flex', alignItems: 'center'}}>
                {
                    pageOptions.map((option, index) => (
                        <Fragment key={option}>
                            <div className={option === pageType ? "PopupPageTypeButton-Selected" : "PopupPageTypeButton"} onClick={() => setPageType(option)}>
                                { option }
                            </div>
                            <div style={{borderRight: "1px solid black", height: "20px", margin: "0px 5px"}}>
                            </div>
                        </Fragment>
                    ))
                }
            </div>
            <hr style={{marginBottom: "20px", marginTop: "5px"}} />
            {
                pageType === "Filter" &&
                <div style={{display: 'flex', flexDirection: 'column', overflowY: "auto", height: "calc(100vh - 175px)", gap: "10px",}}>
                    <h2>
                        Filter Options:
                    </h2>
                    <div style={{display: 'flex', alignItems: 'center', gap: "20px", flexWrap: "wrap"}}>
                        <div className="audits_filter_datepicker_wrapper">
                            Begin Date: <DatePicker selected={beginDate} onChange={(date) => setBeginDate(date)} onChangeRaw={(e) => e.preventDefault()} dateFormat="MMM d, yyyy" maxDate={endDate != null ? endDate : maxDay}/>
                        </div>
                        <div className="audits_filter_datepicker_wrapper">
                            End Date: <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} onChangeRaw={(e) => e.preventDefault()} dateFormat="MMM d, yyyy" minDate={beginDate != null ? beginDate : null} maxDate={maxDay}/>
                        </div>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center', gap: "20px", flexWrap: "wrap"}}>
                        <div className="audits_filter_datepicker_wrapper">
                            Device:                         
                            <select className="widget-select" style={{width: "221.6px", margin: "0px"}} value={deviceFilterSelect} onChange={(e) => setDeviceFilterSelect(e.target.value)}>
                                <option className="widget-select-option" value={undefined}>None</option>
                                {
                                    props.deviceList?.map((device,index) => (
                                        <option className="widget-select-option" key={device.device_id} value={device.device_id}>{device.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                        {/* <div className="audits_filter_datepicker_wrapper">
                            STIG:                         
                            <select className="widget-select" style={{width: "221.6px", margin: "0px"}} value={playbookFilterSelect} onChange={(e) => setPlaybookFilterSelect(e.target.value)}>
                                <option className="widget-select-option" value={undefined}>None</option>
                                {
                                    props.playbookList?.map((pb,index) => (
                                        <option className="widget-select-option" key={pb.id} value={pb}>{pb.friendly_name}</option>
                                    ))
                                }
                            </select>
                        </div> */}
                    </div>
                    <div>
                        {/* <input type="button" value="Clear" className="widget-button" style={{marginLeft: "0px"}} onClick={clearFilter}/> */}
                        <input type="button" value="Submit" className="widget-button" style={{marginLeft: "0px"}} onClick={submitFilter}/>
                    </div>
                </div>
            }
        </div>
    )
}

export default AuditPopup