import React, {useEffect, useState} from 'react'

const AnsibleConfig = (props) => {
    //default config
    const [config, setConfig] = useState(
        {
            "gather_subset": "all",
            "display_skipped_hosts": false,
            "fact_caching_connection": "default_caching_connection",
            "forks": 5,
            "gathering": "smart",
            "host_key_checking": false,
            "fact_caching": "jsonfile",
            "inventory": "default_inventory",
            "stdout_callback": "default"
          }
    );
    const gatherSubsetOptions = ["all", "network", "hardware", "virtual", "facter", "ohai"]
    const factCachingOptions = ["jsonfile", "none"];
    const gatheringOptions = ["smart", "implicit", "explicit"];
    const stdoutCallbackOptions = ["default", "skip", "selective"];
    const forksOptions= {min: 1, max: 15};

    useEffect(() => {
        if(props.data === null || props.data.length === 0) return;
        setConfig(props.data);
    },[props.data])

    const handleChange = (val,data) => {
        let tmp_config = {...config};
        tmp_config[val] = data;
        setConfig(tmp_config);
        props.update(tmp_config);
    };

    return (
            <>
                <div className="inventory-content-header">
                    <div>
                        <h2>Ansible</h2>
                    </div>
                </div>
                <div style={{minHeight: "0px", height: "200px", backgroundColor: props.theme ? "#242424" : "#FFF", border: props.theme ? "1px solid #000" : "1px solid #EBEBEB", color: props.theme ? "#fff" : "#000", borderRadius: "10px", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", padding: "15px", marginLeft: "16px"}}>
                    <div style={{display: "grid", gridTemplateColumns: "1fr 1fr"}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <input type="checkbox" checked={config.display_skipped_hosts ? false : true} onChange={() => handleChange("display_skipped_hosts",!config.display_skipped_hosts)}/>
                            <span style={{paddingLeft: "10px"}}>Display Skipped Hosts</span>
                        </div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <input type="checkbox" checked={config.host_key_checking ? false : true} onChange={() => handleChange("host_key_checking",!config.host_key_checking)}/>
                            <span style={{paddingLeft: "10px"}}>Host Key Checking</span>
                        </div>
                    </div>
                    <div style={{display: "grid", gridTemplateColumns: "1fr 1fr"}}>
                        <div style={{display: 'flex', alignItems: 'center', paddingTop: "15px"}}>
                                <span style={{paddingRight: "10px"}}>Gathering Subset</span>
                                <select value={config.gather_subset} onChange={(e) => handleChange("gather_subset", e.target.value)}>
                                {
                                    gatherSubsetOptions.map((child,index) => (
                                        <option key={index} value={child}>{child}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div style={{display: 'flex', alignItems: 'center', paddingTop: "15px"}}>
                                <span style={{paddingRight: "10px"}}>Gathering</span>
                                <select value={config.gathering} onChange={(e) => handleChange("gathering", e.target.value)}>
                                {
                                    gatheringOptions.map((child,index) => (
                                        <option key={index} value={child}>{child}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    <div style={{display: "grid", gridTemplateColumns: "1fr 1fr"}}>
                        <div style={{display: 'flex', alignItems: 'center', paddingTop: "15px"}}>
                            <span style={{paddingRight: "10px"}}>Fact Caching Options</span>
                            <select value={config.fact_caching} onChange={(e) => handleChange("fact_caching", e.target.value)}>
                                {
                                    factCachingOptions.map((child,index) => (
                                        <option key={index} value={child}>{child}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div style={{display: 'flex', alignItems: 'center', paddingTop: "15px"}}>
                            <span style={{paddingRight: "10px"}}>stdout Callback</span>
                            <select value={config.stdout_callback} onChange={(e) => handleChange("stdout_callback", e.target.value)}>
                                {
                                    stdoutCallbackOptions.map((child,index) => (
                                        <option key={index} value={child}>{child}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    <div style={{display: "grid", gridTemplateColumns: "1fr 1fr"}}>
                        <div style={{display: 'flex', alignItems: 'center', paddingTop: "15px"}}>
                            <span style={{paddingRight: "10px"}}>Fact Caching Connection</span>
                            <input type="text" value={config.fact_caching_connection} onChange={(e) => handleChange("fact_caching_connection", e.target.value)}/>
                        </div>
                        <div style={{display: 'flex', alignItems: 'center', paddingTop: "15px"}}>
                            <span style={{paddingRight: "10px"}}>Inventory Path</span>
                            <input type="text" value={config.inventory} onChange={(e) => handleChange("inventory", e.target.value)}/>
                        </div>
                    </div>
                    <div style={{display: "grid", gridTemplateColumns: "1fr 1fr"}}>
                        <div style={{display: 'flex', alignItems: 'center', paddingTop: "15px"}}>
                            <span style={{paddingRight: "10px"}}>Forks</span>
                            <input type="range" min={forksOptions.min} max={forksOptions.max} value={config.forks} onChange={(e) => handleChange("forks",e.target.value)} />
                            <span style={{marginLeft: "5px"}}>{config.forks}</span>
                        </div>
                        <div style={{display: 'flex', alignItems: 'center', paddingTop: "15px"}}>
                        </div>
                    </div>
                </div>
            </>
    )
}

export default AnsibleConfig;