import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Link, useLocation} from 'react-router-dom';


import IntroDoc from "./IntroDoc";
import DashboardDoc from "./DashboardDoc";
import DeviceDoc from "./DeviceDoc";
import AuditingDoc from "./AuditingDoc";
import RemediationDoc from './RemediationDoc';
import ViewerDoc from "./ViewerDoc";
import ResultsDoc from "./ResultsDoc";
import SettingsDoc from "./SettingsDoc";
import TableofContent from './TableofContent';

import "./Docs.css";

const Documentation = (props) => {
    const [page, setPage] = useState("durosuite");

    let location = useLocation();
    useEffect(() => {
        if(location.pathname.includes("/documentation")){
            if(location.pathname === "/documentation"){
                setPage("durosuite");
            }else{
                if(location.pathname.includes("#")){
                    return;
                }
                setPage(location.pathname.replace('/documentation/',''));
            }
        }
    },[location])

    const pageSelectHandler = (toc) => {
        setPage(toc);
    }

    return (
        <div className="inventory-content" style={{height: "100%"}}>
            <div className="inventory-content-container" style={{alignItems: "stretch", paddingLeft: "16px",scrollBehavior: "smooth" ,height: "calc(100vh - 40px)", overflowY: "auto", color: props.theme ? "#fff" : "#000"}}>
                {/* <div className="inventory-content-header" style={{display: page === "durosuite" ? "none" : "", paddingLeft: "0px", paddingBottom: "20px"}}> */}
                <div className="inventory-content-header" style={{paddingLeft: "0px", paddingBottom: "10px"}}>
                    <div>
                        <h2 style={{fontSize: "48px", color: props.theme ? "#fff" : "#2b2b2b", lineHeight: "62px"}} id={page}>{page[0].toUpperCase() + page.slice(1)}</h2>
                        {/* <span style={{color: "#757575", paddingLeft: "5px"}}>Last updated: Oct. 3, 2022</span> */}
                    </div>
                </div>
                <div style={{paddingLeft: "5px"}}>
                    <Routes>
                        <Route path="/" element={<IntroDoc display={true} pageClick={props.pageClick} theme={props.theme}/>} />
                        <Route path="/dashboard" element={<DashboardDoc display={true} pageClick={props.pageClick} theme={props.theme}/>} />
                        <Route path="/devices" element={<DeviceDoc display={true} pageClick={props.pageClick} theme={props.theme}/>} />
                        <Route path="/auditing" element={<AuditingDoc display={true} pageClick={props.pageClick} theme={props.theme}/>} />
                        <Route path="/remediation" element={<RemediationDoc display={true} pageClick={props.pageClick} theme={props.theme}/>} />
                        {/* <Route path="/results" element={<ResultsDoc display={true} pageClick={props.pageClick} theme={props.theme}/>} /> */}
                        <Route path="/settings" element={<SettingsDoc display={true} pageClick={props.pageClick} theme={props.theme}/>} />
                        <Route path="/viewer" element={<ViewerDoc display={true} pageClick={props.pageClick} auditSelect={props.auditSelect} theme={props.theme}/>} />
                    </Routes>
                </div>
            </div>
            <TableofContent pageSelect={pageSelectHandler} page={page} theme={props.theme}/>
        </div>
    )
}

export default Documentation