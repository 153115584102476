import React, { useEffect, useState, useRef } from 'react'

import DuroSuiteHorizontalLogo from "./../../styles/imgs/DuroSuiteLogoHorizontal.png";
// import DuroSuiteIcon from "./../../styles/imgs/DuroSuiteIcon.png";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const LicenseLogin = () => {
    const [errorMsg, setErrorMsg] = useState(null);
    const [machineId, setMachineId] = useState('');
    const inputLicenseRef = useRef(null);

    useEffect(() => {
        getLicenseStatus();
    }, [])

    const getLicenseStatus = async () => {
        let data = await API_get_license_status();
        if(data == null) return;
        setMachineId(data.machine_id);
    }

    const API_get_license_status = async() => {
        const license_status_url = "/api/settings/license/status";
        return await fetch(license_status_url,{
            headers: {
            'accept': 'application/json',
            },
        }).then(res => {
            if(res.ok)
            return res.json();
            throw res;
        }).then(data => {
            return data;
        }).catch(async res => {
            return null;
        });
    }

    const handleUploadLicenseClick = () => {
        inputLicenseRef.current.click();
    }

    const licenseFileRef = async (e) => {
    if(e.target.files.length === 1){
        var form_data = new FormData();
        form_data.append('license_file', e.target.files[0]);
        await API_upload_license_file(form_data);
    }
    }

    const API_upload_license_file = async (file) => {
        const restore_db_api = "/api/settings/license";
        let data = await fetch(restore_db_api,{
            method: 'POST',
            body: file
        }).then(res => {
            if(res.ok) return true;
            throw res;
        }).then(data => {
            window.location.reload();
        }).catch(async error => {
            setErrorMsg("Error in uploading license file. Please check the license file is correct, and backend is up and running!")
        })

        return data;
    }

    const copyToClipboard = () => {
        navigator.clipboard.writeText(machineId);
        alert("Copied unique ID to clipboard.");
    }

  return (
    <>
    <h1 style={{fontSize: "36px", color: "#2B2B2B", lineHeight: "48px", paddingLeft: '15px', display: 'flex', alignItems:' center', justifyContent: 'space-between'}}>
    <img src={DuroSuiteHorizontalLogo} alt="DuroSuite Horizontal Logo" className="login-branding-logo" style={{paddingRight: '0px'}} title="DuroSuite" />
    </h1>
    <div className="login-form">
      <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', width: '90%', marginTop: '10px'}}>
            <label style={{width: '100%'}}>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    <p>Unique ID</p>
                    <button style={{cursor: 'pointer', background: 'none', border: 'none'}} title="Copy to Clipboard" onClick={() => {copyToClipboard()}}><ContentCopyIcon /></button>
                </div>
                <textarea autoFocus style={{width: '100%', padding: '5px', boxSizing: 'border-box'}} name="Machine Id" value={machineId} disabled/>
            </label>
        </div>
        <div style={{width: "100%", display: 'flex', flexDirection: "column" ,alignItems: 'center', justifyContent: 'center', height: '165px'}}>
          <span style={{visibility: errorMsg != null ? "visible" : "hidden", color: 'red', textAlign: 'center'}}>{errorMsg}</span>
          <button className="widget-button" style={{backgroundColor: "#09347a", width: "60%", height: "40px", fontSize: "16px"}} onClick={handleUploadLicenseClick}>Upload License File</button>
        </div>
        <input ref={inputLicenseRef} onChange={licenseFileRef} type="file" id="input-file-upload" multiple={false} />
    </div>
    </>
  )
}

export default LicenseLogin