import React, { useState } from 'react'

//CSS
import "./../../styles/css/FilterSearch.css"

const FilterSearch = (props) => {
  const [keyword, setKeyword] = useState('');
  const [keywordList, setKeywordList] = useState([]);
  const [removeList, setRemoveList] = useState([]);
  const [ruleSet,setRuleSet] = useState([false,true]);
  const [section,setSection] = useState('All');
  
  const addKeywordHandler = () => {
    if(keyword === '') return;
    if(keywordList.filter(word => word.toLowerCase() === keyword.toLowerCase()).length > 0){
      setKeyword('');
      return;
    }
    let tmp = [...keywordList];
    tmp.push(keyword);
    setKeywordList(tmp);
    setKeyword('');
    props.filterHandler([tmp,ruleSet[0],section])
  }

  const checkboxHandler = (word) => {
    if(removeList.indexOf(word) === -1)setRemoveList([...removeList,word]);
    else  setRemoveList(removeList.filter(removeWord => removeWord !== word));
  }

  const removeButtonHandler = () => {
    let tmp = keywordList.filter(keyword => removeList.indexOf(keyword) === -1);
    console.log(tmp);
    setKeywordList(tmp);
    setRemoveList([]);
    props.filterHandler([tmp,ruleSet[0],section]);
  }

  const handleSelectChange = (e) => {
    setSection(e.target.value);
  };

  return (
    <div className="filter-search-container" style={{backgroundColor: props.theme ? "#242424" : "#FFF", border: props.theme ? "1px solid #000" : "1px solid #EBEBEB", color: props.theme ? "#fff" : "#000"}}>   
        <div className="grid-item-header" style={{background: props.theme ? "#202020" : ""}}>
            <h4>Filter Search</h4>
        </div>
        <div className="filter-search-wrapper">
          <div className="filter-search-header-wrapper">
            <div style={{display: "flex", justifyContent:"space-evenly", alignItems:"center",width: "60%"}}>
              <span>Match:</span>
              <span style={{display: 'flex', alignItems:"center"}}><input type="checkbox" style={{marginRight: "5px"}} onChange={() => setRuleSet([true,false])} checked={ruleSet[0]}/>All</span>
              <span style={{display: 'flex', alignItems:"center"}}><input type="checkbox" style={{marginRight: "5px"}} onChange={() => setRuleSet([false,true])} checked={ruleSet[1]}/>Any</span>
            </div>
            <div style={{display: "flex", justifyContent: "space-between",alignItems: "center" , width: "100%"}}>
              <span>Keyword:</span>
              <input type="text" value={keyword} placeholder="Enter Keyword Here" onChange={(e) => setKeyword(e.target.value)} style={{padding: "5px"}}/>
              <input type="button" value="Search" onClick={addKeywordHandler} className='filter-button'/>
            </div>
            <div style={{padding: "10px"}}>
            <span>Section: </span>
            <select value={section} onChange={handleSelectChange} style={{width: '200px', height: '40px'}}>
            <option value={"All"}>All</option>
              <option value={"rule_title"}>Title</option>
              <option value={"vuln_discuss"}>Discussion</option>
              <option value={"check_content"}>How to Check</option>
              <option value={"fix_text"}>How to Fix</option>
              <option value={"finding_details"}>Finding Details</option>
            </select>
            </div>
          </div>
          <div style={{height: "60%", width: "100%"}}>
            <div className="scrollbar-wrapper" >
              <table className="table-container">
                  <thead className="table-head">
                      <tr className="table-row">
                          <th style={{width: "16px"}}>+/-</th>
                          <th>Keyword</th>
                      </tr>
                  </thead>
                  <tbody className="table-body">
                  {
                    keywordList.map((word,index) =>(
                      <tr className="table-row" key={word}>
                        <td>
                          <input type="checkbox" onChange={() => checkboxHandler(word)}/>
                        </td>
                        <td>
                          {word}
                        </td>
                      </tr>
                    ))
                  }
                  </tbody>
              </table>
              </div>
            </div>
            <div>
              <input type="button" value="Remove" className='filter-button' onClick={removeButtonHandler}/>
              <input type="button" value="Filter" className='filter-button' onClick={() => props.filterHandler([keywordList,ruleSet[0],section])}/>
            </div>
        </div>

    </div>
  )
}

export default FilterSearch