import React, { useEffect, useState, useRef, useContext, Fragment } from 'react'
import { UserContext } from '../../../App';
import { var_password_check } from "../../Helper";

//Icons
import EditIcon from '@mui/icons-material/Edit';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const DeviceWidget = (props) => {
  const { user, FetchCatch, theme, UserIsAdmin } = useContext(UserContext);
  const [editMode, setEditMode] = useState(false);
  const [host, setHost] = useState(null);
  const [stigVariables, setStigVariables] = useState([]);
  const [connectionVariables, setConnectionVariables] = useState([]);
  const divRef = useRef(null);

  useEffect(async () => {
    setEditMode(false);
    if(props.selected === null || props.selected === undefined){
        setHost(null)
    }else{
        setHost(JSON.parse(JSON.stringify(props.selected)));
        let default_var = await API_get_defaults_vars(props.selected.os_id);
        if(default_var){
            setStigVariables(default_var.devices);
        }

        let connection_var = await API_get_connection_vars(props.selected.os_id);
        if(connection_var){
            setConnectionVariables(connection_var);
        }
    }
  },[props.selected]);

  const API_get_defaults_vars = async (os_id) => {
    const url_get_default = "/api/operating-systems/defaults/" + os_id;
    let data = await fetch(url_get_default,{
        method: 'GET',
        headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${user.token}` },
    })
    .then(res=>{
        if(res.ok) return res.json();
        throw res;
    })
    .then(data =>{
        return data;
    }).catch(async error => {
        return await FetchCatch(error, API_get_defaults_vars, [os_id]);
    });
    return data;
  }

  const API_get_connection_vars = async (os_id) => {
    const url_get_connection = "/api/operating-systems/connection-vars/" + os_id;
    let data = await fetch(url_get_connection,{
        method: 'GET',
        headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${user.token}` },
    })
    .then(res=>{
        if(res.ok) return res.json();
        throw res;
    })
    .then(data =>{
        return data;
    }).catch(async error => {
        return await FetchCatch(error, API_get_connection_vars, [os_id]);
    });

    return data;
  }

  const cancelEdit = () => {
    setHost(JSON.parse(JSON.stringify(props.selected)));
    setEditMode(false);
  }

  const saveEdit = (type) => {
    if(type === 1){
        let tmp_array = [];
        for(let i in host.device_vars){
            let status_var_name = host.device_vars[i].var_name;
            let status_value = host.device_vars[i].var_value;
            if(host.device_vars[i].var_name === "" || !status_var_name || !status_value)
                tmp_array.push(i);
        }
        for(let i in tmp_array){
            host.device_vars.splice(tmp_array[i]-i,1);
        }
        props.saveDevice(host);
    }

    setEditMode(false);
  }

  const updateName = (name, type) => {
    if(type === 1){
        let tmp = {...host};
        tmp.name = name;
        setHost(tmp);
    }
  }

  const updateDeviceVar = (index, value, type) => {
    let tmp = {...host};
    if(!type){
        tmp.device_vars[index].var_value = value;
    }else{
        tmp.device_vars[index].var_name = value;
    }
    setHost(tmp);
  }

  const addVar = (type) => {
    if(type === 1){
        let tmp = {...host};
        tmp.device_vars.push({"var_id": -1, "var_name": "", "var_value": ""});
        setHost(tmp);
    }

    setTimeout(() => {
        if (divRef.current) {
            divRef.current.scrollIntoView(
            {
                behavior: 'smooth',
                block: 'end',
                inline: 'nearest'
            })
        }
    },200);
  }
  
  const removeVar = (index) => {
      let tmp = {...host};
      tmp.device_vars.splice(index,1);
      setHost(tmp);
  }

  const getIP = (device) => {
    let index = device.device_vars.findIndex(dv => dv.var_name === "ansible_host");
    if(index === -1) return "n/a";
    return device.device_vars[index].var_value;
  }

  const getOS = (device) => {
    if(props.OS == null || device == null || device.os_id == null) return 'n/a';
    let index = props.OS.findIndex(x => x.id === device.os_id);
    if(index == -1) return 'n/a';
    return props.OS[index].operating_system;
  }

  const getParentGroups = (device) => {
    let tmp = "";
    if(device.groups.length === 0) return "None";
    for(let i in device.groups){
        tmp += device.groups[i].group_name + " ";
    }
    return tmp;
  }


  const getRoleWrite = (device) => {
    for(let i in device.groups){
        let role = props.groupRole.find(x => x.group_id == device.groups[i].group_id);
        if(role != null){
            if(role.write || role.group_admin){
                return true;
            }
        }
    }

    return false;
  }

  const getRoleDevice = (device) => {
    for(let i in device.groups){
        let role = props.groupRole.find(x => x.group_id == device.groups[i].group_id);
        if(role != null){
            return true;
        }
    }

    return false;
  }



  const getDevice = () => {
    return(
        <>
            <h3 className="widget-header">
            <span style={{display: "flex", alignItems: "center"}}><div title="Close Window" style={{display: "flex", alignItems: "center", cursor: "pointer"}} onClick={() => props.close()}><HighlightOffIcon style={{width: "20px"}} /></div>Device #{host.device_id  }</span>
                {
                    props.groupRole != null && (UserIsAdmin() || getRoleWrite(host)) &&
                    <div style={{display: 'flex', alignItems: 'center', cursor: "pointer"}} onClick={() => props.edit()}>Edit<EditIcon sx={{width: "18px", height: "18px"}}/></div>
                }
            </h3>
            <div className="widget-content">
            <div className="widget-text">
                <span>Name: </span>
                <span>{host.name}</span>
            </div>
            <div className="widget-text">
                <span>Groups: </span>
                <span>{getParentGroups(host)}</span>
            </div>
            <div className="widget-text">
                <span>IP/Port: </span>
                <span>{getIP(host)}</span>
            </div>
            <div className="widget-text">
                <span>Operating System: </span>
                <span>{getOS(host)}</span>
            </div>
            {
                (UserIsAdmin() || getRoleDevice(host)) &&
                <>
                    <div className="widget-text" style={{gridTemplateColumns: "1fr"}}>
                        <span style={{fontWeight: "bold", textDecoration: "underline"}}>Device Variables: </span>
                    </div>
                    <div className="widget-text">
                    {
                        host.device_vars.map((dv,index) => (
                            <Fragment key={dv.var_id}>
                                <span style={{overflowX: "hidden", textOverflow: "ellipsis"}} title={dv.var_name}>{dv.var_name}</span>
                                <span>{var_password_check(dv.var_name) ? "***********" : dv.var_value}</span>
                            </Fragment>
                        ))
                    }
                    </div>
                </>
            }
            </div>
            {
                (UserIsAdmin() || getRoleDevice(host)) &&
                <>
                <div style={{display: 'flex', justifyContent:'center', alignItems:"center", flexWrap: "wrap"}}>
                    {                        
                    (UserIsAdmin() || getRoleWrite(host)) &&
                        <input type="button" value="Run" className={host.groups.length > 0 ? "widget-button" : "widget-button-disable"} 
                disabled={host.groups.length > 0 ? false : true} style={{width: "fit-content", padding: "0px 15px"}} onClick={() => props.runHost()}/>
                    }
                    <input type="button" style={{width: "fit-content", padding: "0px 10px"}}  value="Schedule Task(s)" className="widget-button" onClick={() => props.schedule()}/>
                    <input type="button" value="View Audit(s)" className="widget-button" style={{width: "fit-content", padding: "0px 10px"}} onClick={() => props.viewHost(0)}/>
                    <input type="button" value="View Remediation(s)" className="widget-button" style={{width: "fit-content", padding: "0px 10px"}} onClick={() => props.viewHost(1)}/>
                </div>
                </>
            }
        </>
    )
  }

  const getDevice_Edit = () => {
    return(
        <>
            <h3 className="widget-header">
                <span>Device</span>
                <div style={{display: 'flex', alignItems: 'center', cursor: "pointer"}} onClick={() => editMode === true ? cancelEdit() : setEditMode(true)}>Edit<EditIcon sx={{width: "18px", height: "18px"}}/></div>
            </h3>
            <div className="widget-content">
            <div className="widget-text">
                <span>Name: </span>
                <input type="text" className="widget-textfield" title={host.name} value={host.name} onChange={(e) => updateName(e.target.value,1)} />
            </div>
            <div className="widget-text">
                <span>Groups: </span>
                <span>{getParentGroups(host)}</span>
            </div>
            <div className="widget-text">
                <span>IP/Port: </span>
                <span>{getIP(host)}</span>
            </div>
            <div className="widget-text">
                <span>Operating System: </span>
                <span>{getOS(host)}</span>
            </div>
            <div className="widget-text" style={{gridTemplateColumns: "1fr"}}>
                <span style={{fontWeight: "bold", textDecoration: "underline"}}>Device Variables: </span>
            </div>
            <div className="widget-text-" style={{display: "grid", gridTemplateColumns: "30px 1fr 2fr"}}>
                {
                    host.device_vars.map((item,index) => (
                        item.var_id != -1 ? 
                        <Fragment key={index}>
                            <RemoveCircleOutlineIcon style={{color: 'red', width: "20px", cursor: "pointer"}} onClick={() => removeVar(index)}/>
                            <span style={{overflowX: "hidden", textOverflow: "ellipsis"}} title={item.var_name}>{item.var_name}: </span>
                                <input type={var_password_check(item.var_name) ? "password" : "text"} value={item.var_value} className="widget-textfield" onChange={(e) => updateDeviceVar(index, e.target.value, false)}/>
                        </Fragment>
                        :
                        <Fragment key={index}>
                            <RemoveCircleOutlineIcon style={{color: 'red', cursor: "pointer"}} onClick={() => removeVar(index)}/>
                            {
                                <select value={item.var_name} onChange={(e) => updateDeviceVar(index, e.target.value, true)}>
                                    {
                                        connectionVariables.map((item, index) => (
                                            <option key={item.var_name} value={item.var_name}>{item.var_name}</option>
                                        ))
                                    }
                                    {
                                        stigVariables.map((item, index) => (
                                            <option key={item.var_name} value={item.var_name}>{item.var_name}</option>
                                        ))
                                    }
                                </select>
                            }
                            <input type={var_password_check(item.var_name) ? "password" : "text"} value={item.var_value} className="widget-textfield" onChange={(e) => updateDeviceVar(index, e.target.value, false)}/>
                        </Fragment>
                    ))
                }
                <div ref={divRef}></div>
            </div>
            </div>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px'}}>
                <span style={{color: "#0088FF", cursor:"pointer", padding: "5px"}} onClick={() => addVar(1)}>+ Add Device Variable</span>
            </div>
            <div style={{display: 'flex', justifyContent:'center', alignItems:"center"}}>
                <input type="button" value="Cancel" className="widget-button" onClick={() => cancelEdit()}/>
                <input type="button" value="Save" className="widget-button" onClick={() => saveEdit(1)} />
            </div>
        </>
    ) 
  }

  return (
    <div className="widget-container" style={{display: host == null ? "none" : "" , backgroundColor: theme ? "#242424" : "#FFF", border: theme ? "1px solid #000" : "1px solid #EBEBEB", color: theme ? "#fff" : "#000", borderRadius: "10px", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", width: "90%", margin: "10px 0px", height: "auto"}}>
        {host === null || host === undefined ? <></> : editMode ? getDevice_Edit() : getDevice()}
    </div>
  )
}

export default DeviceWidget