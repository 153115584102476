import React from 'react';
import FastForwardIcon from '@mui/icons-material/FastForward';
import ViewerPic from "./../../../styles/imgs/ViewerPic.png";
import VulnDescriptionPic from "./../../../styles/imgs/VulnDescriptionPic.png";

const ViewerDoc = (props) => {
  return (
    <div className="documentation-container" style={{display: props.display === true ? "flex" : "none", flexDirection: "column", justifyContent:"center" }}>
        <p>We implemented a viewer into our GUI for ease of access. We wanted to speed up users' access ability to view completed audits. Currently, users have to import or export a checklist from the CLI into a viewer to view the audit. We tried to design it in a way to allow users to be comfortable with our viewer as if it was any other viewer. You can easily select an audit to view within the Audits' page or by selecting the (+) sign at the top left of the viewer to easily bring you to the Audits' page.</p>
        <div style={{display: "flex", width: "90%", padding: "20px 0px"}}>
            <div className='documentation-button' style={{letterSpacing: "1px", cursor: "pointer"}} onClick={() => props.pageClick("viewer")}>
                <FastForwardIcon style={{marginRight: "10px", color: "#FF0000"}} /> Quick Move
            </div>
        </div>
        <div style={{border: "2px solid #A4A4A4",width: "90%"}}>
            <img alt="Viewer Screenshot" src={ViewerPic} style={{width: "100%", height: "100%"}} />
        </div>
        <h3 className="documentation-subsection-title" id="ViewerDescription" style={{color: props.theme ? "#FFF" : "#2B2B2B"}}>
            Vulnerability Description
        </h3>
        <p>Vulnerability description layouts the fundamental description about the vulernability. It split into few fundamental sections: Rule Title, Discussion, How to Check, How to Fix, etc. Selecting a vulnerability within the STIG Viewer's vulnerability list will showcase that vulnerability details. Comments below are comments you can change within the GUI. When we run an audit, we will sometimes set certain comments for users to recognize.</p>
        <div style={{border: "2px solid #A4A4A4",width: "50%", marginTop: "25px"}}>
            <img alt="Vulnerability Description Widget" src={VulnDescriptionPic} style={{width: "100%", height: "100%"}} />
        </div>
    </div>
  )
}

export default ViewerDoc