import React, {useState, useEffect, useContext} from 'react'
import { UserContext } from '../../App';
import { useLocation } from 'react-router-dom';
import Grid from "@mui/material/Grid";

//Components
import InfoGraph from "./InfoGraph";
import FilterSearch from './FilterSearch';
import VulnTable from "./VulnTable";
import VulnDescription from "./VulnDescription";
import StigTabHeader from "./StigTabHeader"
import PopupPage from '../dashboard/PopupPage';
import StigViewerPopup from './StigViewerPopup';

//CSS

const StigViewer = (props) => {
    let location = useLocation();
    const {theme} = useContext(UserContext);
    const [audit, setAudit] = useState(null);
    const [vuln, setVuln] = useState(null);
    const [vulnList, setVulnList] = useState([]);
    const [severityFilter, setSeverityFilter] = useState({"low":true, "medium":true,"high":true});
    const [selectedStatus, setSelectedStatus] = useState('All')

    const [popupagepagecheck, setPopuppagecheck] = useState(false);

    useEffect(()=>{
        setVuln(null);
        setVulnList([]);
        if(props.audits.length === 0){
            setAudit(null);
            setVuln(null);
            return;
        }
        if(location.state !== null && "index" in location.state){
          setAudit(props.audits[location.state.index]);
          return;
        }
        setAudit(props.audits[0]);
    },[props.audits]);

    useEffect(() => {
        if(audit === null) return;
        if (selectedStatus === 'Not a Finding') {
          filterInfoHandler({...severityFilter, 'Not a Finding': true })
        }
        if (selectedStatus === 'Not Reviewed') {
          filterInfoHandler({...severityFilter, 'Not Reviewed': true })
        }
        if (selectedStatus === 'Not Applicable') {
          filterInfoHandler({...severityFilter, 'Not Applicable': true })
        }
        if (selectedStatus === 'Open') {
          filterInfoHandler({...severityFilter, 'Open': true })
        }
        if (selectedStatus === 'All') {
          filterInfoHandler({...severityFilter, 'All': true })
        }
    },[audit]);

    const vulnSelectHandler = (vul) =>{
      if(vul != null){
        Object.keys(vul).forEach((key) => {
          if(vul[key] == null){
            vul[key] = "";
          }
        })
      }
      setVuln(vul);
    }

    const auditSelectHandler= (index) =>{
        setVuln(null);
        setAudit(props.audits[index]);
    }

    const auditRemoveHandler = (index) =>{
        setVuln(null);
        if(props.audits[index] === audit) setAudit(null);
        props.remove(index);
    }

    const filterHandler = (filter) => {
        //params[0] = filter world 
        //params[1] = true === have all || false === any words allowed
        //params[2] = what section of description

       //filter = [[keywords],[all,any],'all or specific section'']
       if(vulnList.length  === 0) return;
       //finding_details makes an error for some stigs
       const keys = ["rule_title","vuln_discuss","check_content","fix_text"];
       let keywords = filter[0];
       let ruleset = filter[1];
       let tmpVulnList = [...audit.vuln];
       if(keywords.length === 0){
         setVulnList(tmpVulnList);
         return;
       }
       setVuln(null);
       let tmp = [];
       if(ruleset){
         if(filter[2] === 'All'){
           for(let i in tmpVulnList){
             let big_text = "";
             for(let vulnKeyIndex in keys){
               big_text += tmpVulnList[i][keys[vulnKeyIndex]];
             }
             let status = true;
             for(let keyindex in keywords){
               let results = big_text.toLowerCase().includes(keywords[keyindex].toLowerCase());
               if(results === false){
                 status = false;
                 break;
               }
             }
             if(status) tmp.push(tmpVulnList[i]);
           }
         }else{
           for(let keyindex in keywords){
             tmpVulnList = tmpVulnList.filter(vuln => vuln[filter[2]].toLowerCase().includes(keywords[keyindex].toLowerCase()));
           }
           tmp = tmpVulnList;
         }
       }else{
         for(let keyindex in keywords){
           if(filter[2] === 'All'){
             for(let vulnkeyindex in keys){
               let result = tmpVulnList.filter(vuln => vuln[keys[vulnkeyindex]].toLowerCase().includes(keywords[keyindex].toLowerCase()));
               for(let resultIndex in result){
                 if(tmp.indexOf(result[resultIndex]) === -1) tmp.push(result[resultIndex]);
               }
             }
           }else{
             tmp = tmpVulnList.filter(vuln => vuln[filter[2]].toLowerCase().includes(keywords[keyindex].toLowerCase()));
           }
         }
       }
       setVulnList(tmp);
    }

    const filterInfoHandler = (filter) => {
        let tmp_high = [];
        let tmp_med = [];
        let tmp_low = [];
        if(filter.high){
          tmp_high = audit.vuln.filter(x => x.severity === "high");
        }
        if(filter.medium){
          tmp_med = audit.vuln.filter(x => x.severity === "medium");
        }
        if(filter.low){
          tmp_low = audit.vuln.filter(x => x.severity === "low");
        }
        if(filter['Not a Finding']) {
          tmp_low = tmp_low.filter(x => x.status === 'NotAFinding');
          tmp_med = tmp_med.filter(x => x.status === 'NotAFinding');
          tmp_high = tmp_high.filter(x => x.status === 'NotAFinding');
        }
        if(filter['Not Reviewed']) {
          tmp_low = tmp_low.filter(x => x.status === 'Not_Reviewed');
          tmp_med = tmp_med.filter(x => x.status === 'Not_Reviewed');
          tmp_high = tmp_high.filter(x => x.status === 'Not_Reviewed');
        }
        if(filter['Not Applicable']) {
          tmp_low = tmp_low.filter(x => x.status === 'Not_Applicable');
          tmp_med = tmp_med.filter(x => x.status === 'Not_Applicable');
          tmp_high = tmp_high.filter(x => x.status === 'Not_Applicable');
        }if(filter['Open']) {
          tmp_low = tmp_low.filter(x => x.status === 'Open');
          tmp_med = tmp_med.filter(x => x.status === 'Open');
          tmp_high = tmp_high.filter(x => x.status === 'Open');
        }      
        if(filter['All']) {
          // no filter needed
        }
        let tmp = [...tmp_high, ...tmp_med, ...tmp_low];
        tmp.sort((a,b) => a["vuln_num"]> b["vuln_num"] ? 1 : -1)
        setVulnList(tmp);
    }

    const viewinViewerHandler = async (audits) => {
      console.log(audits);
      await props.viewinViewer(audits);
      setPopuppagecheck(false);
    }

    return (
      <div style={{zIndex: '10', height: "calc(100vh - 40px)", backgroundColor: theme ? "#121212" : ""}}>
        <Grid item sm={12} xs={12} md={12}>
          <StigTabHeader audits={props.audits} audit={audit !== null ? audit : null} playbookList={props.playbookList} select={auditSelectHandler} remove={auditRemoveHandler} deviceList={props.deviceList} theme={theme} popup={() => setPopuppagecheck(true)}/>
        </Grid>
        <Grid item sm={12} xs={12} md={12} style={{height: "calc(100vh - 80px)"}}>
          <Grid container spacing={0.5} style={{height: "100%"}}>
              <Grid item sm={6} xs={12} md={6} lg={3}>
                  <div style={{minHeight: "837.5px", height: "100%", width: "100%"}}>
                      <InfoGraph setSelectedStatus={setSelectedStatus} severityFilter={severityFilter} setSeverityFilter={setSeverityFilter} vuln={audit === null ? null : audit.vuln} filterVulnData={filterInfoHandler} theme={theme}/>
                      <FilterSearch filterHandler={filterHandler} theme={theme}/>
                  </div>
              </Grid>
              <Grid item sm={6} xs={12} md={6} lg={3} style={{height: "100%"}}>
                <VulnTable selectedStatus={selectedStatus} severityFilter={severityFilter} setSelectedStatus={setSelectedStatus}
                 vuln_list={vulnList} onVulnSelect={vulnSelectHandler} theme={theme} filterInfoHandler={filterInfoHandler}/>
              </Grid>

              <Grid item sm={12} xs={12} md={12} lg={6} style={{height: "100%"}}>
                <VulnDescription audit={audit} vuln={vuln} playbookList={props.playbookList} deviceList={props.deviceList} theme={theme}/>
              </Grid>
          </Grid>
        </Grid>
          {
              popupagepagecheck &&
              <PopupPage close={() => setPopuppagecheck(false)} title="Audits" content={<StigViewerPopup playbookList={props.playbookList} viewinViewer={viewinViewerHandler} />}/>
          }
      </div>
    )
}

export default StigViewer;