import React from 'react'

//Icons
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const Popup = (props) => {
  return (
    <div style={{display: props.display != null && props.display ? "flex" : 'none', flexDirection: "column", width: "350px", borderRadius: "5px", border: "2px solid", borderColor: props.msg == null || props.msg.toLowerCase().includes('error') || props.msg.toLowerCase().includes('fail') ? "#DD462B" :  "#09347A", position:"absolute", zIndex: "200", top: 60, right: 30, background: !props.theme ? "white" : "#242424", boxShadow: "0px 1px 1px 1px rgba(0,0,0,0.15)"}}>
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: props.msg == null || props.msg.toLowerCase().includes('error') ? "#DD462B" :  "#09347A"}}>
        <div></div>
        <div style={{fontSize: '12px', cursor: 'pointer'}} onClick={() => props.close()}><HighlightOffIcon /></div>
      </div>
      <div style={{padding: "5px", maxHeight: "200px", overflowY: "auto", color: !props.theme ? "black" : "white"}}>
        { 
          props.msg != null ? 
            (
              props.msg.includes("\n") ? 
              props.msg.split("\n").map(function(item,idx){
                return (<span key={idx}>{item}<br/></span>)
              }) 
              :
              props.msg
            )
          :
          ""
        }

      </div>
    </div>
  )
}

export default Popup;
