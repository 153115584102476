import React, {useState, useContext} from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { UserContext } from '../../../App';

const RemediationsTable = (props) => {
    const { FormatTimestamp, theme } = useContext(UserContext);
    const [sort, setSort] = useState(['timestamp']);

    const auditSelectHandler = (event, audit) => {
        props.select(audit, event.ctrlKey, event.shiftKey);
    }

    const sortHandler = (field) => {
        props.sort(field);
        let index = sort.indexOf(field);
        let tmp = [...sort];
        if(index === -1){
            tmp.push(field);
        }else{
            tmp.splice(index,1);
        }
        setSort(tmp);
    }

    const getStatus = (audit) => {
        if(audit.status?.toLowerCase() == "complete"){
            return(
                <span style={{borderRadius: "15px", backgroundColor: "rgb(117,198,38)", color: "#fff", padding: "3px 10px", border: '1px solid #fff', fontWeight: "bold", fontSize: "14px"}}>Complete</span>
            )
        }else if(audit.status?.toLowerCase() == "in-progress"){
            return(
                <span style={{borderRadius: "15px", backgroundColor: "gold", color: "white", padding: "3px 10px", border: '1px solid #fff', fontWeight: "bold", fontSize: "13px"}}>
                    In-Progress
                </span>
            )
        }else if(audit.status?.toLowerCase() == "failed"){
            return(
                <span style={{borderRadius: "15px", backgroundColor: "red", color: "white", padding: "3px 10px", border: '1px solid #fff', fontWeight: "bold", fontSize: "13px"}}>
                    Failed
                </span>
            )
        }else if(audit.status?.toLowerCase() == "cancelled"){
            return(
                <span style={{borderRadius: "15px", backgroundColor: "orange", color: "white", padding: "3px 10px", border: '1px solid #fff', fontWeight: "bold", fontSize: "13px"}}>
                    Cancelled
                </span>
            )
        }else if(audit.status?.toLowerCase()  == "scheduled"){
            return(
                <span style={{borderRadius: "15px", backgroundColor: "blue", color: "white", padding: "3px 10px", border: '1px solid #fff', fontWeight: "bold", fontSize: "13px"}}>
                    Scheduled
                </span>
            )    
        }else{
            return(
                <span style={{borderRadius: "15px", backgroundColor: "red", color: "white", padding: "3px 10px", border: '1px solid #fff', fontWeight: "bold", fontSize: "13px"}}>
                    Error
                </span>
            )  
        }
    }
    
    return (
        <div className="inventory-table-wrapper" style={{minWidth: "600px",height: "calc(100vh - 212px)", backgroundColor: theme ? "#242424" : "#FFF", border: theme ? "1px solid #000" : "1px solid #d5d5d5", color: theme ? "#fff" : "#000", borderRadius: "5px", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",}}>
        <table className="inventory-table-container">
            <thead>
                <tr className="inventory-table-header-row" style={{position: "sticky", top: "0", backgroundColor: theme ? "#202020" : "#D5D5D5"}}>
                <td style={{textAlign:'center', width: "10px"}}>
                </td>
                <td style={{width: "100px"}}><div style={{display: 'flex', alignItems:'center', justifyContent: "center", cursor: 'pointer'}} onClick={() => sortHandler('status')}>Status<ArrowDropDownIcon style={{transform: sort.indexOf('status') === -1 ? "" : "rotate(180deg)"}}/></div></td>
                <td style={{width: "40px"}}></td>
                <td><div style={{display: 'flex', alignItems:'center', cursor: 'pointer'}} onClick={() => sortHandler('device')}>Device<ArrowDropDownIcon style={{transform: sort.indexOf('device') === -1 ? "" : "rotate(180deg)"}}/></div></td>
                <td><div style={{display: 'flex', alignItems:'center', cursor: 'pointer', justifyContent: 'center'}} onClick={() => sortHandler('timestarted')}>Time Started<ArrowDropDownIcon style={{transform: sort.indexOf('timestarted') === -1 ? "" : "rotate(180deg)"}}/></div></td>
                <td><div style={{display: 'flex', alignItems:'center', cursor: 'pointer', justifyContent: 'center'}} onClick={() => sortHandler('timefinished')}>Time Finished<ArrowDropDownIcon style={{transform: sort.indexOf('timefinished') === -1 ? "" : "rotate(180deg)"}}/></div></td>
                <td style={{width: "250px", textAlign: "center"}}><div style={{display: 'flex', alignItems:'center', cursor: 'pointer', justifyContent: 'center'}} onClick={() => sortHandler('stig')}>STIG<ArrowDropDownIcon style={{transform: sort.indexOf('stig') === -1 ? "" : "rotate(180deg)"}}/></div></td>
                </tr>
            </thead>
            <tbody>
                {
                    props.auditList.map((audit,index) => (
                            <tr key={index} className="inventory-table-body-row" onClick={(e) => auditSelectHandler(e, audit)} style={{backgroundColor: props.audit != null && props.audit.find(x => x.audit.id == audit.audit.id) && !props.mostrecent ? "#9BCFFF" : ""}}>
                                <td style={{textAlign:'center'}}>
                                </td>                                
                                <td style={{color: audit.audit.status === "Complete" ? "rgb(117, 198, 38)" : audit.audit.status === "in-progress" ? "rgb(255, 186, 8)" : "rgb(221, 70, 43)", textAlign: "center"}}>
                                    {
                                        getStatus(audit.audit)
                                    }
                                </td>
                                <td style={{textAlign: "center"}}></td>
                                <td>{audit.device == null || audit.device.name == null ? "n/a" : audit.device.name }</td>
                                <td style={{textAlign: 'center'}}>{FormatTimestamp(audit.audit.time_started)}</td>
                                <td style={{textAlign: 'center'}}>{FormatTimestamp(audit.audit.time_finished)}</td>
                                <td style={{textAlign: "center"}}>{audit.playbook == null || audit.playbook.friendly_name == null ? "n/a" : audit.playbook.friendly_name}</td>
                            </tr>    
                    ))
                }
            </tbody>
        </table>
        </div>
    )
}

export default RemediationsTable;