import React, { useState, useEffect, useContext } from 'react'
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer'
import { UserContext } from '../App';

// Components
import Navigation from "./navigation/Navigation";
import StigViewer from "./stigviewer/StigViewer";
import Dashboard from "./dashboard/Dashboard";
import Popup from "./Popup";
import Grid from "@mui/material/Grid";

// IMGS
import DuroSuiteLogo from "./../styles/imgs/DuroSuiteLogoHorizontal.png";

// CSS
import "./../styles/css/Grid.css";
import Banner from './Banner';

const Main = (props) => {
  let navigate = useNavigate();
  const { user, FetchCatch, logout, theme, UserIsAdmin } = useContext(UserContext);
  const [auditingStatus, setAuditingStatus] = useState([]);
  const [remediatingStatus, setRemediatingStatus] = useState([]);
  const [deviceList, setDeviceList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [ansibleConfig,setAnsibleConfig] = useState([]);
  const [hostList, setHostList] = useState([]);
  const [auditList, setAuditList] = useState([]);
  const [allAuditList, setAllAuditList] = useState([]);
  const [auditData, setAuditData] = useState(0);
  const [allRoles, setAllRoles] = useState([]);
  const [remediateList, setRemediateList] = useState([]);
  const [totalRemediateList, setTotalRemediateList] = useState([]);
  const [remediateData, setRemediateData] = useState(0);
  const [playbookList, setPlaybookList] = useState([]);
  const [OSList, setOSList] = useState([]);
  const [inProgressAudits, setInProgressAudits] = useState([]);
  const [inProgressRemediate, setInProgressRemediate] = useState([]);
  const [settings,setSettings] = useState({"auditsRefreshTimer": 10, "remediateRefreshTimer": 10, timezone: "Locale", resultsPerPage: 30});
  const [groupRole, setGroupRole] = useState([]);

  const [stigviewerList, setStigviewerList] = useState([]);
  const [notificationList, setNotificationList] = useState([]);

  const handleOnIdle = event => {
    console.log("logging out due to inactivity.");
    logout();
  }

  const getTimeoutLength = () => {
    if(UserIsAdmin()){
        return 1000 * 60 * 10;
    }else{
        return 1000 * 60 * 15;
    }
  }

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: getTimeoutLength(),
    onIdle: handleOnIdle,
  })

  let location = useLocation();
  useEffect(() => {
      if(location.pathname.includes("/viewer/")){
        if(location.pathname === "/viewer/"){
            return;
        }
        let audit_id = location.pathname.replace('/viewer/','');
        const url_fetch = url_ip + 'audits/' + audit_id;
        fetch(url_fetch,{
            headers:{
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${user.token}`
            }
        })
        .then(res=>{
            if(res.ok)
                return res.json();
            throw res;
        })
        .then(data =>{
            setSplashScreenLoading(false);
            data.remediation = false;
            viewinViewerHandler([data]);
        })
        .catch((error) => {
            alert("No Audits with an id: " + audit_id);
        })
      }
  },[location])

  //Change this to false if testing w/o splash screen.
  const [splashScreenLoading, setSplashScreenLoading] = useState(true);

  const url_ip = "/api/";

  useEffect(() => {
    const fetchNotification = async(ids) => {
        let notification_list = [];
        for(let i in ids){
            const url_notification = url_ip + "notification?id=" + ids[i];
            let notification_data = await fetch(url_notification ,{
                headers:{
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${user.token}`
                }})
                .then(res=>{
                    if(res.ok)
                        return res.json();
                    return null;
                })
                .then(data =>{
                    return data;
                });
            if(notification_data != null) notification_list.push(notification_data);
        }
        notification_list.sort((a,b) => a.notification.acknowledged === b.notification.acknowledged ? a.notification.id > b.notification.id ? -1 : 1 : a.notification.acknowledged ? 1 : -1)
        return notification_list;
    }

    if(user != null){
        fetchNotification([...user.notifications.unacknowledged, ...user.notifications.acknowledged])
        .then(data => setNotificationList(data));
    }
  }, [])

  useEffect(() => {
    const url_playbooks = url_ip + "stigs";
    fetch(url_playbooks ,{
        headers:{
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${user.token}`
        }
    })
    .then(res=>{
        if(res.ok)
            return res.json();
        return null;
    })
    .then(data =>{
        if(data != null)
            setPlaybookList(data);
        else 
            setPlaybookList([]);
    });

    const url_os = url_ip + "operating-systems";
    fetch(url_os,{
        headers:{
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${user.token}`
        }
    })
    .then(res=>{
        if(res.ok)
            return res.json();
        return null;
    })
    .then(data =>{
        if(data != null)
            setOSList(data);
        else 
            setOSList([]);
    })

    const url_all_roles= url_ip + "users/roles/all";
    fetch(url_all_roles ,{
        headers:{
        'Accept': 'application/json',
        'Authorization': `Bearer ${user.token}`
        }
    })
    .then(res=>{
        if(res.ok)
            return res.json();
        return null;
    })
    .then(data =>{
        if(data != null)
            setAllRoles(data);
        else
            setAllRoles([]);
    })

    const url_ansible_cfg = url_ip + "ansible-cfg";
    fetch(url_ansible_cfg ,{
        headers:{
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${user.token}`
        }
    })
    .then(res=>{
        if(res.ok)
            return res.json();
        return null;
    })
    .then(data =>{
        setAnsibleConfig(data);
    })

    const url_devices = url_ip + 'devices';
    fetch(url_devices,{
        headers:{
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${user.token}`
        }
    })
    .then(res=>{
        if(res.ok)
            return res.json();
        return null
    })
    .then(data =>{
        if(data != null){
            setDeviceList(data);
        }else{
            setDeviceList([]);
            data = [];
        }
        
        const url_groups = url_ip + 'groups';
        fetch(url_groups,{
            headers:{
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${user.token}`
            }
        })
        .then(res=>{
            if(res.ok)
                return res.json();
            return null
        })
        .then(data2 =>{
            if(data2 != null){
                for(let i in data2){
                    data2[i].group_name = data2[i].name;
                    data2[i].devices = data.filter(x => x.groups.filter(y => y.group_id === data2[i].group_id).length > 0);
                }
                setGroupList(JSON.parse(JSON.stringify(data2)));
                setHostList(data2);
            }else{ 
                setHostList([]);
            }
        })
    });    

    const url_audit = url_ip + "audits?skip=0&limit=" + settings.resultsPerPage;
    fetch(url_audit, {
        headers:{
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${user.token}`
        }
    })
    .then(res=>{
        if(res.ok) return res.json();
        return null;
    })
    .then(data =>{
        let tmp = [];
        if(data != null && data._results != null){
            for(let i in data._results){
                data._results[i].remediation = false;
                if(data._results[i].status === "in-progress"){
                    tmp.push({"id":data._results[i].id, "time_started": data._results[i].time_started, "remediation": false, "device_id": data._results[i].device_id, "playbook_id": data._results[i].playbook_id});
                }
            }
            setInProgressAudits(tmp);
            setAuditList([...data._results]);
            setAuditData(data._metadata.total);
            setAllAuditList([{page: 1, results: data._results}]);
        }else{
            setInProgressAudits([]);
            setAuditList([]);
            setAuditData(0);
            setAllAuditList([{page: 1, results: []}]);
        }
    });

    const url_remediate = url_ip + "remediations?skip=0&limit=" + settings.resultsPerPage;
    fetch(url_remediate ,{
        headers:{
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${user.token}`
        }
    })
    .then(res=>{
        if(res.ok) return res.json();
        return null;
    })
    .then(data =>{
        if(data != null && data._results != null){
            data._results.sort((a,b) => a.time_started < b.time_started ? 1 : -1);
            let tmp = [];        
            for(let i in data._results){
                data._results[i].remediation = true;
                if(data._results[i].status === "in-progress"){
                    tmp.push({"id":data._results[i].id, "time_started": data._results[i].time_started, "remediation": true, "device_id": data._results[i].device_id, "playbook_id": data._results[i].playbook_id});
                }
            }
            setInProgressRemediate(tmp);
            setRemediateList([...data._results]);
            setRemediateData(data._metadata.total);
            setTotalRemediateList([{page: 1, results: data._results}]);
        }else{
            setInProgressRemediate([]);
            setRemediateList([]);
            setRemediateData(0);
            setTotalRemediateList([{page: 1, results: []}]);
        }
    })
    },[]);

    const GetGroupRole = async (group_id) => {
        if(user == null) return;
        if(group_id){
            let perms = await API_get_group_perms(group_id);
            if(perms){
                return perms;
            }
        }
        return null;
    }

    const UpdateGroupRole = async (group_id = null) => {
        if(user == null) return;
        let tmp = []
        if(group_id){
            tmp = [...groupRole];
            let index = tmp.findIndex(x => x.group_id == group_id);
            let perms = await API_get_group_perms(group_id);
            if(perms){
                if(index == -1){
                    tmp.push(perms);
                }else{
                    tmp[index] = perms;
                }
            }
        }else{
            for(let i in hostList){
                let perms = await API_get_group_perms(hostList[i].group_id);
                if(perms){
                    tmp.push(perms);
                }
            }
            // let perms = await API_get_all_group_perms();
            // if(perms){
            //     tmp = perms;
            // }
        }
    
        setGroupRole(tmp);
    }

    const API_get_all_group_perms = async () => {
        const url_get_permission = "/api/groups/perms/all";
        let data = await fetch(url_get_permission, {
        headers:{
        'Accept': 'application/json',
        'Authorization': `Bearer ${user.token}`
        }
        }).then(res=>{
            if(res.ok) return res.json();
            throw res;
        })
        .then(data =>{
            return data;
        }).catch(async error => {
            return await FetchCatch(error, API_get_all_group_perms, []);
        })
    
        return data
    }

    const API_get_group_perms = async (group_id) => {
        const url_get_permission = "/api/groups/perms/" + group_id;
        let data = await fetch(url_get_permission, {
        headers:{
        'Accept': 'application/json',
        'Authorization': `Bearer ${user.token}`
        }
        }).then(res=>{
            if(res.ok) return res.json();
            throw res;
        })
        .then(data =>{
            return data;
        }).catch(async error => {
            return await FetchCatch(error, API_get_group_perms, [group_id]);
        })
    
        return data
    }

    useEffect(() => {
        if(groupRole.length == 0){
            UpdateGroupRole();
        }
    }, [hostList]);

  useEffect(() => {
    setTimeout(function(){
      setSplashScreenLoading(false);
    }, 2000)
  },[])

  const stigSelectHandler = () =>{
    navigate('/viewer');
  }

  const dashboardSelectHandler = () => {
    navigate('/');
  }

  const auditingStatusHandler = (audits) => {
    setAuditingStatus(audits);
  }

  const remediatingStatusHandler = (remediating) => {
    setRemediatingStatus(remediating);
  }
  
  const stigviewerListHandler = async (auditList) => {
    auditList = auditList.filter(x => x.audit.status === "Complete" && x.playbook?.friendly_name !== process.env.REACT_APP_VM_STIG_NAME);
    if(auditList.length == 0) return;
    let stigviewerList_tmp = [...stigviewerList];
    let new_ckls = [];
    for(let i in auditList){
        let audit = auditList[i];
        let index = stigviewerList_tmp.findIndex(stig => stig.audit.id === audit.audit.id);
        if(index !== -1){
            new_ckls.push(stigviewerList_tmp[index]);
            stigviewerList_tmp.splice(index, 1);
        }else{
            let tmp = {};
            if("vuln" in audit){
                tmp = {audit: audit.audit, vuln: audit.vuln};
            }else{
                tmp = {audit: audit.audit};
                tmp.vuln = await API_get_vuln(audit.audit.id);
            }

            if(tmp.vuln != null){
                new_ckls.push(tmp);
            }
        }
    }
    new_ckls = new_ckls.reverse();
    stigviewerList_tmp = [...new_ckls, ...stigviewerList_tmp];
    setStigviewerList(stigviewerList_tmp);
    navigate('/viewer');
  }

  const API_get_vuln = async (audit_id) => {
    let url = '/api/vulnerabilities?audit_id=' + audit_id;
    let data = await fetch(url,{
        headers:{
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${user.token}`
        }
    })
    .then(res=>{
        if(res.ok) return res.json();
        throw res;
    })
    .then(data =>{
        return data;
    })
    .catch(async error => {
        return await FetchCatch(error, API_get_vuln, [audit_id]);
    });

    return data;
  }

  const stigviewerListRemoveHandler = (index) => {
    let tmp = [...stigviewerList];
    tmp.splice(index,1);
    setStigviewerList(tmp);
  }

  const viewinViewerHandler = async (audits) => {
    audits = audits.filter(audit => audit.remediation === false);
    if(audits.length === 0) return;
    if(audits.length === 1){
        stigviewerListHandler([{audit: audits[0]}]);
        return;
    }
    let stigviewerList_tmp = [];
    for(let i in audits){
        let index = stigviewerList.findIndex(stig => stig.audit.id === audits[i].id);
        if(index !== -1) continue;
        let tmp = {};
        tmp = {audit: audits[i]};
        tmp.vuln = await API_get_vuln(audits[i].id);
        if(tmp.vuln != null)
            stigviewerList_tmp.push(tmp);
    }
    setStigviewerList([...stigviewerList_tmp,...stigviewerList]);
    navigate('/viewer');
  }

  const refreshGroups = async() => {
    let devices = await API_get_devices();
    if(devices == null) return;
    setDeviceList(devices);
    let groups = await API_get_groups();
    let new_perms = [...groupRole];
    if(groups != null){
        for(let i in groups){
            groups[i].group_name = groups[i].name;
            groups[i].devices = devices.filter(x => x.groups.filter(y => y.group_id === groups[i].group_id).length > 0);
            if(groupRole.find(x => x.group_id == groups[i].group_id) == null){
                new_perms.push(await GetGroupRole(groups[i].group_id));
            }
        }
        setGroupList(groups);
        setGroupRole(new_perms);
        setHostList(groups);
    }else{ 
        setHostList([]);
    }
  }

  const refreshDevices = async() => {
    let devices = await API_get_devices();
    if(devices){
        setDeviceList(devices);
    }else{
        setDeviceList([]);
    }
  }

  const API_get_devices = async () => {
    const url_devices = url_ip + 'devices';
    let data = await fetch(url_devices,{
        headers:{
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${user.token}`
        }
    })
    .then(res=>{
        if(res.ok)
            return res.json();
        throw res;
    })
    .then(data =>{
        return data;
    })
    .catch(async error => {
        return await FetchCatch(error, API_get_devices, []);
    })

    return data;
  }

  const API_get_groups = async () => {
    const url_groups = url_ip + 'groups';
    let data = await fetch(url_groups,{
        headers:{
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${user.token}`
        }
    })
    .then(res=>{
        if(res.ok)
            return res.json();
        throw res;
    })
    .then(data =>{
        return data;
    }).catch(async error => {
        return await FetchCatch(error, API_get_groups, []);
    })

    return data;
  }

  return (
    <>
    <div style={{display: splashScreenLoading ? "flex" : "none", background: theme ? "#121212" : "", top: 0, left: 0, position: "absolute", width: "100vw", height: "100vh", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
          <a href="https://agile-defense.com/" target="_blank"><img style={{width: "300px"}} src={DuroSuiteLogo} alt="DuroSuite Logo" /></a>
          <div style={{width: "200px", height: "8px", backgroundColor: "#A4A4A4", borderRadius: "15px"}}>
            <div className="splash-screen-progress-bar" style={{animation: "2s progressbar ease"}}></div>
          </div>
    </div>
    {
        (deviceList != null && playbookList != null && groupList != null && OSList != null) &&
        <>
            <Grid container spacing={0} className="grid-container" style={{display: splashScreenLoading ? "none" : ""}}>
                <Grid item sm={12} xs={12} md={12}>
                    {
                        props.banner != null &&
                        <Banner banner={props.banner} />
                    }
                <Navigation stigViewerSelect={stigSelectHandler} dashboardSelect={dashboardSelectHandler} auditStatus={auditingStatus} remediatingStatus={remediatingStatus}  notificationList={notificationList} setNotificationList={setNotificationList} setTheme={props.setTheme} banner={props.banner != null ? true : false}/>
                </Grid>
                <Routes>
                <Route path="/*" element={<Dashboard groupList={groupList} playbookList={playbookList} auditsList={auditList} auditData={auditData} remediateList={remediateList} OSList={OSList} hostList={hostList} inProgressQueue={[...inProgressAudits, ...inProgressRemediate]} inProgressAudits={inProgressAudits} inProgressRemediate={inProgressRemediate} deviceList={deviceList} ansibleConfig={ansibleConfig} auditSelect={stigviewerListHandler} auditingStatus={auditingStatusHandler} remediateStatus={remediatingStatusHandler} viewinViewer={viewinViewerHandler} bannerConfig={props.bannerConfig} settings={settings} remediateData={remediateData}  allAuditList={allAuditList} totalRemediateList={totalRemediateList} setTotalRemediateList={setTotalRemediateList} allRoles={allRoles} notificationList={notificationList} setNotificationList={setNotificationList} setTimezone={props.setTimezone} groupRole={groupRole} UpdateGroupRole={UpdateGroupRole} refreshGroups={refreshGroups} refreshDevices={refreshDevices} />} />
                <Route path="viewer/*" element={<StigViewer playbookList={playbookList} deviceList={deviceList} audits={stigviewerList} remove={stigviewerListRemoveHandler} viewinViewer={viewinViewerHandler} />} />
                </Routes>
            </Grid>
        </>
    }
    </>
  )
}

export default Main;