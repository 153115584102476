import React, {useState, useEffect, useContext} from 'react'
import { UserContext } from '../../../App';

import SaveAltIcon from '@mui/icons-material/SaveAlt';

const AuditFailure = (props) => {
    const { theme, FetchCatch, user} = useContext(UserContext);

    const exportHandler = () => {
        const url_export = "/api/audits/checklist/" + props.audit.id;
        let filename = "";
        fetch(url_export, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
        .then(res=>{
            if(res.ok){
                const header = res.headers.get('content-disposition');
                if(header === null || header === undefined){
                    filename = prompt("Please enter a file name");
                }else{
                    const parts = header.split(';');
                    filename = parts[1].split('=')[1];
                    filename = filename.substring(1, filename.length - 1);
                }
                return res.blob();
            }else{
                if(res.status == 404){
                    console.log('File Not Found.');
                    return null;
                }
                throw res;
            }
        })
        .then(data =>{
            if(data == null) return;
            let a = document.createElement('a');
            a.href = window.URL.createObjectURL(data);
            a.download = filename;
            a.click();
            a.remove();
        }).catch(async error => {
            return await FetchCatch(error, exportHandler, []);
        });
    }


    const onClickHandler = () => {
        exportHandler();
    }

    return (
        <div className="widget-container" style={{backgroundColor: theme ? "#242424" : "#FFF", border: theme ? "1px solid #000" : "1px solid #EBEBEB", color: theme ? "#fff" : "#000", borderRadius: "10px", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", width: "90%", margin: "10px 0px", height: "auto"}}>
            <h3 className="widget-header">
            <span>Audit #{(props.audit != null ? props.audit.id : "N/A")} Failed Log</span>
            <span style={{cursor: "pointer"}} onClick={onClickHandler}><SaveAltIcon /></span>
            </h3>
            <div className="widget-content">
                <div className="widget-text" style={{gridTemplateColumns: "1fr"}}>
                    <span>{props.audit.detail ? props.audit.detail : "N/A"}</span>
                </div>
            </div>
        </div>
    )
}

export default AuditFailure;