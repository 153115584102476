import React, {useEffect, useState, useContext} from 'react';
import { useLocation } from 'react-router-dom';
import { UserContext } from "../../../App";

import "react-datepicker/dist/react-datepicker.css";

//CSS
import "../audits/Audits.css";
import "react-datepicker/dist/react-datepicker.css";

//Components
import RemediationsTable from "./RemediationsTable";
import RemediationWidget from "./RemediationWidget";
import Pagination from './../Pagination';
import PopupPage from "../PopupPage";
import AuditPopup from '../audits/AuditPopup';

//Icons
import RefreshIcon from '@mui/icons-material/Refresh';

const Remediations = (props) => {
    const { theme } = useContext(UserContext);
    let location = useLocation();
    const [audit,setAudit] = useState([]);
    const [sort,setSort] = useState([]);
    const [mostrecent, setMostrecent] = useState(true);
    const [width, setWidth]   = useState(window.innerWidth);

    const [currentPage, setCurrentPage] = useState(1);
    const [currentPosts, setCurrentPosts] = useState([])
    const [popupagepagecheck, setPopuppagecheck] = useState(false);
    const [filtered, setFiltered] = useState(null);

    useEffect(async () => {
        if(props.deviceList == null) return;
        props.refresh();
    }, [props.deviceList])

    useEffect(() => {
        getPosts(currentPage, filtered);
    }, [props.totalRemediateList])

    const getPosts = async (page, filter = filtered) => {
        if(props.totalRemediateList == null){
            setCurrentPage([]);
            return;
        }

        setCurrentPage(page);

        let index = -1;
        if(filter != filtered){
            setFiltered(filter);
        }else{
            index = props.totalRemediateList.findIndex(x => x.page === page);
        }

        let tmp = [];
        let tmp_results = [];
        if(index !== -1){
            tmp = props.totalRemediateList[index].results;
        }else{
            tmp = await props.getRemediatePage(page, filter);
        }
        
        for(let i in tmp){
            tmp_results.push({audit: tmp[i], device: props.deviceList[props.deviceList.findIndex(x => x.device_id === tmp[i].device_id)], playbook: props.playbookList[props.playbookList.findIndex(x => x.id === tmp[i].playbook_id)]})
        }

        if(tmp_results.length > 0)
            setAudit([tmp_results[0]]);
        else
            setAudit([]);

        setCurrentPosts(tmp_results);
    }

    const updateDimensions = () => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    useEffect(() => {
        if(location.state == null){
        }else if(location.state.host != null){
            if(location.state.host.length > 0){
                setTimeout(() => {
                    getPosts(1, {device_id: location.state.host[0].device_id});
                }, 500)   
            }
        }
    },[location.state]);

    const auditSelectHandler = (audit_select, ctrlKey, shiftKey) => {
        let index = audit.findIndex(x => x.audit.id == audit_select.audit.id);
        if(ctrlKey){
            let tmp = [...audit];
            if(index === -1){
                tmp.push(audit_select);
            }else{
                tmp.splice(index, 1);
            }
            setAudit(tmp);
            if(tmp.length == 0){
                setMostrecent(true);
            }
        }else if(shiftKey){
            if(audit.length == 0){
                setAudit(audit_select);
                setMostrecent(false);
            }else{
                let firstIndex = currentPosts.findIndex(x => x.audit.id == audit[audit.length - 1].audit.id);
                let secondIndex = currentPosts.findIndex(x => x.audit.id == audit_select.audit.id);
                let tmp = [];
                if(firstIndex < secondIndex){
                    tmp = currentPosts.slice(firstIndex + 1, secondIndex + 1);
                    tmp.push(currentPosts[firstIndex]);
                }else{
                    tmp = currentPosts.slice(secondIndex, firstIndex + 1);
                }
                setAudit(tmp);
            }
        }else{
            if(index === -1){
                setAudit([audit_select]);
                setMostrecent(false);
            }else{
                if(audit.length > 1){
                    setAudit([audit_select]);
                    setMostrecent(false);
                }else{
                    if(mostrecent){
                        setMostrecent(false);
                    }else{
                        setAudit([]);
                        setMostrecent(true);
                    }
                }
            }
        }
    }

    const auditSortHandler = (field) => {
        let tmp = [...currentPosts];
        if(field === 'device'){
            tmp.sort((a,b) => a.device.name > b.device.name ? 1 : -1);
        }else if(field === 'timestarted'){
            tmp.sort((a,b) => a.audit.time_started > b.audit.time_started ? 1 : -1);
        }else if(field === 'timefinished'){
            tmp.sort((a,b) => a.audit.time_finished > b.audit.time_finished ? 1 : -1);
        }else if(field === 'stig'){
            tmp.sort((a,b) => a.playbook.time_started > b.playbook.time_started ? 1 : -1);
        }else if(field === 'opens'){
            tmp.sort((a,b) => a.audit.metadata.cat_1 > b.audit.metadata.cat_1 ? 1 : -1);
        }else if(field === 'status'){
            tmp.sort((a,b) =>             
            a.audit.status > b.audit.status ? 1 : 
            a.audit.status < b.audit.status  ? -1 : 
            a.audit.time_started > b.audit.time_started ? 1 : -1);
        }else if(field === "remediation"){
            tmp.sort((a,b) => a.audit.remediation === true && b.audit.remediation === true ? a.audit.time_started > b.audit.time_started ? 1 : -1 : a.audit.remediation === true && b.audit.remediation === false ? 1 : b.audit.remediation === true && a.audit.remediation === false ? -1 : a.audit.remediation === false && b.audit.remediation === false ? a.audit.time_started > b.audit.time_started ? 1 : -1 : -1);
        }

        let index = sort.indexOf(field);
        let tmp2 = [...sort];
        if(index === -1){
            setCurrentPosts(tmp);
            tmp2.push(field);
        }else{
            setCurrentPosts(tmp.reverse());
            tmp2.splice(index,1);
        }
        setSort(tmp2);
    }

    const refreshRemediateHandler = () => {
        props.refresh(filtered);
        setAudit([]);
        setMostrecent(true);
    }

    const deleteAuditsHandler = () => {
        if(window.confirm("Are you sure you want to delete the remediations?")){
            let tmp = [];
            for(let i in audit){
                tmp.push(audit[i].audit);
            }
            props.deleteAudit(tmp, currentPage);
            setAudit([]);
            setMostrecent(true);
        }
    }

    const cancelAuditsHandler = () => {
        if(window.confirm("Are you sure you want to cancel the in-progress remediations?")){
            let tmp = [];
            for(let i in audit){
                tmp.push(audit[i].audit);
            }
            props.cancelRemediate(tmp.filter(x => x.status == "in-progress"), currentPage);
            setAudit([]);
            setMostrecent(true);
        }
    }

    const rerunAuditHandler = () => {
        let tmp = [];
        for(let i in audit){
            tmp.push(audit[i].audit);
        }
        props.rerunAudits(tmp);
        setAudit([]);
        setMostrecent(true);
    }

    const filterOptionSubmit = async (filter) => {
        setPopuppagecheck(false);
        getPosts(1, filter);
    }
    
    const UndoFilter = () => {
        getPosts(1, null);
    }

    return (
        <div className="inventory-content">
            <div className="inventory-content-container" style={{alignItems: "stretch", color: theme ? "#FFF" : "#000"}}>
            <div className="inventory-content-header" style={{display: 'flex', flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <h1>Remediations</h1>
                        <div title="Refresh Remediations"  style={{display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: "pointer", marginLeft: "10px"}} onClick={() => refreshRemediateHandler()}>
                            <RefreshIcon style={{height: "20px", width: "20px", paddingTop: "5px"}}/>
                        </div>
                    </div>
                    <div>
                        <div style={{display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
                        {
                            filtered !== null ?
                            <input className="widget-button" type="button" value="Clear" onClick={() => UndoFilter()} style={{marginRight: "0px"}}/>
                            :
                            <input className="widget-button" type="button" value="Filter" onClick={() => setPopuppagecheck(true)} style={{marginRight: "0px"}}/>
                            }
                        </div>
                        <div style={{ display: "flex", alignItems:"center", justifyContent:"end"}}>
                            <input className={audit.length > 0 && !mostrecent ? "widget-button" : "widget-button-disable"} disabled={audit.length > 0 && !mostrecent ? false : true} type="button" value="Re-run" onClick={() => rerunAuditHandler()} />
                            <input className={audit.length > 0 && !mostrecent > 0 ? "widget-button" : "widget-button-disable"} disabled={audit.length > 0 && !mostrecent ? false : true} type="button" value="Delete" onClick={() => deleteAuditsHandler()} style={{marginRight: "0px"}} />
                            <input className={audit.length > 0 && !mostrecent > 0 ? "widget-button" : "widget-button-disable"} disabled={audit.length > 0 && !mostrecent > 0 ? false : true} type="button" value="Cancel" onClick={() => cancelAuditsHandler()} style={{marginRight: "0px"}}/>
                        </div>
                    </div>
                </div>
                {
                    width <= 1280 &&
                    <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                        <RemediationWidget remediate={audit.length > 0 ? audit[0] : currentPosts.length > 0 ? currentPosts[0] : null} moreDetail={() => props.auditSelect([audit])} mostrecent={mostrecent} />
                    </div>
                }
                <div className="inventory-content-wrapper">
                    <RemediationsTable audit={audit} groupRole={props.groupRole} mostrecent={mostrecent} auditList={currentPosts} select={auditSelectHandler} sort={auditSortHandler} inProgressQueue={props.inProgressQueue} refresh={refreshRemediateHandler}/>
                    <div style={{overflow: "auto", display: 'flex', alignItems: 'center' ,justifyContent: 'end', marginRight: "16px", height: "70px"}}>
                        <Pagination postsPerPage={props.resultsPerPage} totalPosts={props.remediateData} paginate={getPosts} page={currentPage} theme={theme} />
                    </div>
                </div>
            </div>
            {
                width > 1280 &&
                <div className="inventory-content-container" style={{marginRight: "20px"}}>
                    <RemediationWidget remediate={audit.length > 0 ? audit[0] : currentPosts.length > 0 ? currentPosts[0] : null} moreDetail={() => props.auditSelect([audit])} mostrecent={mostrecent} />
                </div>
            }
            {
                popupagepagecheck &&
                <PopupPage close={() => setPopuppagecheck(false)} title="Remediations" content={<AuditPopup filterSave={filterOptionSubmit} deviceList={props.deviceList} OS={props.OS} playbookList={props.playbookList}/>}/>
            }
        </div>
    )
}

export default Remediations;