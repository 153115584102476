import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../App';

// Icons
import CloseIcon from '@mui/icons-material/Close';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

// CSS
import "./Profile.css";

const PopupNotification = (props) => {
  const {user, FetchCatch} = useContext(UserContext);
  let navigate = useNavigate();

  const close = () => {
    props.setNotificationPopup(false);
  }

  const navigateNoti = (id) => {
    props.setNotificationPopup(false);
    navigate("/profile/notifications", {state:{id: id}});
  }

  const acknowledgedNoti = async (e, noti, index) => {
    e.stopPropagation();
    if(noti.notification.acknowledged) return;
    let id = noti.notification.id;
    const url_acknowledge = "/api/notification/acknowledge?id=" + id;
    let status = await fetch(url_acknowledge ,{
        method: 'PUT',
        headers:{
        'Authorization': `Bearer ${user.token}`
        }
    })
    .then(res=>{
      if(res.ok)
          return res.json();
      throw res;
    })
    .then(data =>{
      return true;
    }).catch(async error => {
      FetchCatch(error, acknowledgedNoti, [e, noti, index]);
      return false;
    });

    if(status){
      let tmp = [...props.notificationList];
      tmp[index].notification.acknowledged = true;
      props.setNotificationList(tmp);
    }
  }

  const deleteNoti = async (e, noti, index) => {
    e.stopPropagation();
    let id = noti.notification.id;
    const url_acknowledge = "/api/notification?id=" + id;
    let status = await fetch(url_acknowledge ,{
        method: 'DELETE',
        headers:{
        'Authorization': `Bearer ${user.token}`
        }
    })
    .then(res=>{
      if(res.ok)
          return true;
      throw res;
    }).catch(async error => {
      FetchCatch(error, deleteNoti, [e, noti, index]);
      return false;
    });

    if(status){
      let tmp = [...props.notificationList];
      tmp.splice(index, 1);
      props.setNotificationList(tmp);
    }
  }

  return (
    <div style={{width: "100vw",height: "100vh", position:"absolute", zIndex: "1",top: 0, left: 0, display: "flex", overflowY: "hidden", justifyContent: 'center', alignItems: 'center', backgroundColor: "rgba(25,25,25,0.85)"}} onClick={() => close()}>
        <div style={{minWidth: "400px", width: "30%", minHeight: "400px", height: "40%", backgroundColor: "#ffffff", borderRadius: "10px", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", display: 'flex', flexDirection:"column", padding: '5px 10px', zIndex: "100", border: "1px solid #d5d5d5", opacity: '1'}} onClick={(e) => e.stopPropagation()}>
            <div style={{width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'space-between', color: "#000", fontSize: "20px", fontWeight: "bold", marginBottom: "10px" }}>
                Notifications
                <CloseIcon style={{color: "#252525", cursor: "pointer"}} onClick={() => close()}/>
            </div>
            <div style={{overflowY: 'auto'}}>
                {
                    props.notificationList.map((noti,index) => (
                        <div key={noti.notification.id} className="notificationCard" style={{borderLeftColor: noti.notification.acknowledged ? "lightblue" : "red", zIndex: "101"}} onClick={() => navigateNoti(noti.notification.id)}>
                            <span style={{padding: '5px 0px'}}>{noti._info.message}</span>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                {
                                    !noti.notification.acknowledged &&
                                    <div title="Acknowledged" style={{color: 'lightgreen', marginRight: '5px'}} onClick={(e) => acknowledgedNoti(e, noti, index)}>
                                        <ThumbUpIcon style={{width: '22px'}}/>
                                    </div>
                                }
                                <div title="Delete" style={{color: 'red'}} onClick={(e) => deleteNoti(e, noti, index)}>
                                    <DeleteForeverIcon />
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    </div>
  )
}

export default PopupNotification