import React from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { NavLink } from 'react-router-dom';


const TableofContent = (props) => {
  const device_sections = [{title:"Groups", id: "#DeviceGroups"}, {title: "Variables", id: "#DeviceVariables"}, {title: "Edit Mode", id: "#DeviceEditMode"}];
  const dashboard_sections = [{title: "Recent Acitivty", id: "#DashboardRecentActivity"}, {title: "Compliance Table", id:"#DashboardComplianceTable"}]
  const stig_sections = [{title: "Vuln. Description", id: "#ViewerDescription"}];

  return (
    <div style={{width: "100%", display: "flex", alignItems: "center", flexDirection: "column"}}>
      <div style={{width: "50%"}}>
        <h3 style={{width: "100%", fontSize: "24px", color: props.theme ? "#FFF" : "#2B2B2B", borderBottom: "1px solid #ADADAD", paddingTop: "20px"}}>Overview</h3>
        <div style={{paddingTop: "10px", display: "flex", flexDirection: "column"}}>
          <NavLink to="" className="tableofcontent-list-item" style={{color: props.page === "durosuite" ? props.theme ? "#FFF" : "#000" : "#999999"}} onClick={() => props.pageSelect("durosuite")} href="#DuroSuite">
            <span>DuroSuite</span>
          </NavLink>  
          <NavLink to="dashboard" className="tableofcontent-list-item" style={{color: props.page === "dashboard" ? props.theme ? "#FFF" :"#000" : "#999999"}} onClick={() => props.pageSelect("dashboard")} href="#Dashboard">
            <span>Dashboard</span>
            <KeyboardArrowDownIcon style={{width: "16px", height: "16px", transform: props.page === "dashboard" ? "rotate(180deg)" : "", color: props.page === "Dashboard" ? props.theme ? "#FFF" : "#000" : "#999999"}} />
          </NavLink>  
          {
            props.page === "dashboard" ?
              dashboard_sections.map((section,index) => (
                <a key={index} className="tableofcontent-list-subitem" style={{paddingLeft: "15px", color: props.page === "dashboard" ?  props.theme ? "#FFF" : "#000" : "#999999"}} href={section.id}>
                  <span>{" " + section.title}</span>
                </a>  
              ))
            :
            <></>
          }
          <NavLink to="devices" className="tableofcontent-list-item" style={{display: "flex", alignItems:"center", color: props.page === "Devices" ? props.theme ? "#FFF" : "#000" : "#999999"}} onClick={() => props.pageSelect("devices")} href="#Devices">
            <span>Devices</span>
            <KeyboardArrowDownIcon style={{width: "16px", height: "16px", transform: props.page === "devices" ? "rotate(180deg)" : "", color: props.page === "devices" ? props.theme ? "#FFF" : "#000" : "#999999"}} />
          </NavLink>  
          {
            props.page === "devices" ?
              device_sections.map((section,index) => (
                <a key={index} className="tableofcontent-list-subitem" style={{paddingLeft: "15px", color: props.page === "devices" ? props.theme ? "#FFF" : "#000" : "#999999"}} href={section.id}>
                  <span>{" " + section.title}</span>
                </a>  
              ))
            :
            <></>
          }
          <NavLink to="auditing" className="tableofcontent-list-item" style={{color: props.page === "auditing" ? props.theme ? "#FFF" : "#000" : "#999999"}} onClick={() => props.pageSelect("auditing")} href="#Auditing">
            <span>Auditing</span>
          </NavLink>  
          <NavLink to="remediation" className="tableofcontent-list-item" style={{color: props.page === "remediation" ? props.theme ? "#FFF" : "#000" : "#999999"}} onClick={() => props.pageSelect("remediation")} href="#Remediation">
            <span>Remediation</span>
          </NavLink>  
          <NavLink to="viewer" className="tableofcontent-list-item" style={{color: props.page === "viewer" ? props.theme ? "#FFF" : "#000" : "#999999"}} onClick={() => props.pageSelect("viewer")} href="#Viewer">
            <span>Viewer</span>
            <KeyboardArrowDownIcon style={{width: "16px", height: "16px", transform: props.page === "viewer" ? "rotate(180deg)" : "", color: props.page === "viewer" ? props.theme ? "#FFF" : "#000" : "#999999"}} />
          </NavLink>   
          {
            props.page === "viewer" ?
              stig_sections.map((section,index) => (
                <a key={index} className="tableofcontent-list-subitem" style={{paddingLeft: "15px", color: props.page === "viewer" ? props.theme ? "#FFF" : "#000" : "#999999"}} href={section.id}>
                  <span>{" " + section.title}</span>
                </a>  
              ))
            :
            <></>
          }
            <NavLink to="settings" className="tableofcontent-list-item" style={{color: props.page === "settings" ? props.theme ? "#FFF" : "#000" : "#999999"}} onClick={() => props.pageSelect("settings")} href="#Settings">
            <span>Settings</span>
            </NavLink>   
        </div>
        </div>
    </div>
  )
}

export default TableofContent