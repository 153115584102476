import React, { useContext, useState, useEffect } from 'react';
import { UserContext } from "../../../App";

//Icons
import ComputerIcon from '@mui/icons-material/Computer';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const DevicesTable = (props) => {
    const { theme, FormatTimestamp } = useContext(UserContext);
    const [devices, setDevices] = useState([]);

    // Sort Tracker
    const [name, setName] = useState(false);
    const [os, setOS] = useState(false)

    useEffect(() => {
        setDevices(props.devices);
    }, [props.devices])

    const selectHandler = (event, device) => {
        props.select(device, [], event.ctrlKey, event.shiftKey)
    }

    const getCompliance = (device) => {
        let recent_audit = props.auditList.find(x => x.device_id === device.device_id && x.status === "Complete");
        if(recent_audit == null) return 'No Audit'
        if(recent_audit.metadata.Open == null || recent_audit.metadata.Open.cat_1 > 0 || recent_audit.metadata.Open.cat_2 > 0)
            return "No"
        return "Yes"
    }

    const GetDeviceIP = (device) => {
        let index = device.device_vars.findIndex(dv => dv.var_name === "ansible_host");
        if(index === -1) return "n/a";
        return device.device_vars[index].var_value;
    }

    const getRecentAudit = (device) => {
        let recent_audit = props.auditList.find(x => x.device_id === device.device_id && x.status === "Complete");
        if(recent_audit){
            if(recent_audit.time_finished){
                return FormatTimestamp(recent_audit.time_finished);
            }else{
                return "n/a";
            }
        }else{
            return "No Audit"
        }
    }

    const sortNameHandler = () => {
        const sorted = [...devices].sort((a, b) => {
            return a.name.localeCompare(b.name)
          });
        if(name){
            setDevices(sorted.reverse());
            setName(false);
        }else{
            setDevices(sorted);
            setName(true);
        }
    }

    const sortOSHandler = () => {
        const sorted = [...devices].sort((a, b) => {
            return a.os_id > b.os_id ? 1 : b.os_id > a.os_id ? -1 : 0
          });
        if(os){
            setDevices(sorted.reverse());
            setOS(false);
        }else{
            setDevices(sorted);
            setOS(true);
        }
    }

    const getRow = (device) => {
        return(
            <div key={device.device_id} className="inventory-table-row" style={{backgroundColor: props.selected.find(x => x.device_id === device.device_id) != null ? "#9BCFFF" : ""}} onClick={(e) => selectHandler(e, device)}>
                <div></div>
                <div className="center-left-container">
                    <ComputerIcon fontSize='small' style={{paddingRight: "5px"}}/>{device.name}
                </div>
                <div className="center-container text-center">
                    {getRecentAudit(device)}
                </div>
                <div className="center-container text-center">
                    {props.OS.length > 0 ? props.OS[props.OS.findIndex(x => x.id === device.os_id)].operating_system : ""}
                </div>
                <div className="center-container text-center" title={GetDeviceIP(device)}>
                    <span style={{overflow: 'hidden', textOverflow: 'ellipsis', width: '120px', display: 'block'}}>{GetDeviceIP(device)}</span>
                </div>
                <div className="center-container text-center">
                    {getCompliance(device)}
                </div>
            </div>  
        )
    }

    return (
        <div className="inventory-table" style={{backgroundColor: theme ? "#242424" : "#FFF", border: theme ? "1px solid #000" : "1px solid #d5d5d5", color: theme ? "#fff" : "#000"}}>
                <div className="inventory-table-header" style={{backgroundColor: theme ? "#202020" : "#D5D5D5"}}>
                    <div></div>
                    <div className="center-container" onClick={sortNameHandler} style={{cursor: "pointer"}}>
                        Name
                        <ArrowDropDownIcon style={{transform: !name ? "" : "rotate(180deg)"}}/>
                    </div>
                    <div className="center-container text-center">Most Recent Audit</div>
                    <div className="center-container text-center" onClick={sortOSHandler} style={{cursor: "pointer"}}>
                        OS
                        <ArrowDropDownIcon style={{transform: !os ? "" : "rotate(180deg)"}}/>
                    </div>
                    <div className="center-container text-center">IP</div>
                    <div className="center-container text-center">Compliance</div>
                </div>    
                {
                    devices.map((device,index) => (
                        getRow(device)
                    ))
                }
        </div>
    )
}

export default DevicesTable;