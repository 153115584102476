import React from 'react'

const ProgressBar = (props) => {
  return (
    <div style={{width: props.width == null || props.width == undefined ? "100%" : props.width, height: props.height == null || props.height == undefined ? "20px" : props.width , borderRadius: "15px", background: "#EEEEEE", display: "flex", flexDirection: "row", border: "1px solid #A4A4A4", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"}}>
        <div style={{position: "absolute", marginLeft: "60px", marginTop: "-3px", color: props.total === 1 ? "#fff" : "#000"}}><span>{props.total == 0 ? "0 / 0" : "1 / " + props.total}</span></div>
        <div style={{width: props.total == null || props.total == 0 ? "0" : Math.floor(1 / props.total * 100) + "%", height: "100%", backgroundColor: props.color, borderRadius: "15px", animation: "1s all ease-in"}}></div>
    </div>
  )
}

export default ProgressBar;