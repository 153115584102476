import React, { useEffect, useState } from 'react';
import { Switch } from '@mui/material';


const STIGTableRow = (props) => {
    const [vul, setVul] = useState(props.vul);
    const [msg, setMsg] = useState(props.vul.comments);

    useEffect(() => {
        setVul(props.vul);
        setMsg(props.vul.comments);
    }, [props.vul])

    useEffect(() => {
        if(msg == props.vul.comments) return;
        const timeOutId = setTimeout(() => onChangeCommentHandler(msg), 500);
        return () => clearTimeout(timeOutId);
      }, [msg]);

    const onChangeCommentHandler = event => {
        let vul_tmp = {...vul};
        vul_tmp.comments = event;
        setVul(vul_tmp);
        props.ChangeCommentHandler(vul_tmp);
    }

    const onChangeEnableHandler = event => {
        let vul_tmp = {...vul}; 
        vul_tmp.enabled = !vul_tmp.enabled;
        setVul(vul_tmp);
        props.ChangeEnableHandler(vul_tmp);
    }

    return (
    <tr style={{height: '48px', textAlign: "center", backgroundColor: vul.enabled ? "" : "rgba(221, 70, 43, 0.8)"}}>
        <td style={{border: "1px solid #d5d5d5"}}>
        <Switch checked={vul.enabled} onChange={onChangeEnableHandler}/></td>
        <td style={{border: "1px solid #d5d5d5"}}><div style={{height: "100%", width:"100%", display: 'flex', alignItems: "center", justifyContent: "center"}}>{vul.vuln_num}</div></td>
        <td style={{border: "1px solid #d5d5d5", cursor: "default"}}>{vul.rule_title}</td>
        <td style={{border: "1px solid #d5d5d5"}}><input type="text" style={{fontSize: "14px",width: "95%", padding: "5px", border: "1px solid #9f9f9f", borderRadius: "5px" ,outline: "none", boxShadow: 'none'}} value={msg == null ? "" : msg} onChange={event => setMsg(event.target.value)} /></td>
    </tr>
    )
}

export default STIGTableRow