import React, {useEffect, useRef, useState} from 'react';

import "./Login.css";
import AgileLogo from "./../../styles/imgs/AgileDefenseLogo.png";
import LoginBanner from './LoginBanner';
import CACPopup from './CACPopup';
import { InputValidator } from '../InputValidator';
import DuroSuiteHorizontalLogo from "./../../styles/imgs/DuroSuiteLogoHorizontal.png";
import DuroSuiteIcon from "./../../styles/imgs/DuroSuiteIcon.png";
import pdf from "./../../styles/files/DuroSuite_EULA.pdf";
import LicenseLogin from './LicenseLogin';

// init var for toggle display of invalid pw msg entered by new user
let showInvalidPwMessage = false

const loginUser = async (credentials) => {
  const login_api = "/api/login";
  let success = false;
  let status = -1;
  let data = await fetch(login_api, {
    method: 'POST',
    headers: { 
      'Accept': 'application/json',
      'Content-Type':'application/json',
    },
    body: JSON.stringify(credentials)
  }).then((res) => {
    success = res.ok;
    status = res.status;
    if(res.ok || res.status == 401) return res.json();
    return null;
  })
  .then(data => {
    if(success)
      return([success, data]);
    if(status == 502) return([success, status]);
    if(status == 401) return([success, status, data]);
    return([success, status]);
  });

  return data;
}

const change_password = async (credentials, token) => {
  const change_password_api = "/api/users/change_password";
  let data = await fetch(change_password_api, {
    method: 'POST',
    headers: { 
      'Accept': 'application/json',
      'Content-Type':'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(credentials)
  }).then((res) => {
    if(res.ok) return res;
    throw res;
  }).then(data => {
    return true;
  })
  .catch(async error => {
    try{
      let res = await error.json();
      if(res.detail != null){
        return res.detail;
      }
    }catch{

    }
    return false;
  });

  return data;
}

const Login = (props) => {
    const inputElement = useRef(null);

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [closeBanner, setCloseBanner] = useState(false);
    const [CACAuthenticationMode, setCACAuthenticationMode] = useState(false);
    const [errorType, setErrorType] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [timeoutCheck, setTimeoutCheck] = useState(null);
    const [changePasswordPage, setChangePasswordPage] = useState(false);
    const [token, setToken] = useState(null);
    const DoDBanner = "You are accessing a U.S. Government (USG) Information System (IS) that is provided for USG-authorized use only.\n\n" +
    "By using this IS (which includes any device attached to this IS), you consent to the following conditions:\n\n" +
    "-The USG routinely intercepts and monitors communications on this IS for purposes including, but not limited to, penetration testing, COMSEC monitoring, network operations and defense, personnel misconduct (PM), law enforcement (LE), and counterintelligence (CI) investigations.\n\n" +
    "-At any time, the USG may inspect and seize data stored on this IS.\n\n" +
    "-Communications using, or data stored on, this IS are not private, are subject to routine monitoring, interception, and search, and may be disclosed or used for any USG-authorized purpose.\n\n" +
    "-This IS includes security measures (e.g., authentication and access controls) to protect USG interests not for your personal benefit or privacy.\n\n" + 
    "-Not withstanding the above, using this IS does not constitute consent to PM, LE or CI investigative searching or monitoring of the content of privileged communications, or work product, related to personal representation or services by attorneys, psychotherapists, or clergy, and their assistants. Such communications and work product are private and confidential. See User Agreement for details.\n"

    useEffect(() => {
      if (inputElement.current) {
        inputElement.current.focus();
      }
    }, [changePasswordPage]);

    // useEffect(() => {
    //   if(errorType){
    //     if(timeoutCheck != null){
    //       clearTimeout(timeoutCheck);
    //     }
    //     setTimeoutCheck(setTimeout(() => {
    //       setErrorType(false);
    //       setErrorMsg("");
    //       setTimeoutCheck(null);
    //     }, 15000));
    //   }
    // },[errorType, timeoutCheck])

    useEffect(() => {
      if(props.email === null) return;
      setUsername(props.email == null ? "" : props.email);
    },[props.email])

    useEffect(() => {
      if(props.error != null){
        if(props.error.detail == "Password must be changed."){
          setUsername("");
          setPassword("");
          setConfirmPassword("");
          setNewPassword("");
          setToken(props.error.token);
          setChangePasswordPage(true);
        }
      }
    }, [props.error]);

    const cancelChangePasswordHandler = () => {
      props.setToken(null);
      props.setRefreshToken(null);
      setUsername("");
      setPassword("");
      setConfirmPassword("");
      setNewPassword("");
      setChangePasswordPage(false);
    }

    const handleSubmit = async e => {
        e.preventDefault();
        setErrorMsg("");
        setErrorType(false);
        if(changePasswordPage){
          if(confirmPassword != newPassword){
            alert("New and Confirmed Password Does Not Match!");
            return;
          }
          const response = await change_password({
            old_password: password,
            new_password: newPassword
          }, token);
          if(response == true){
            alert("Change password successful");
            cancelChangePasswordHandler();
          }else{
            if(typeof response === 'string' && response.includes('Must be at least 15 characters')) {
              // for this case, we don't display errormsg normally, we must display a specially formatted error msg in this case, so it displays right.
              setErrorMsg('');
              setErrorType(true);
              // toggle display of error msg for this case
              showInvalidPwMessage = true
              }
              else {
            setErrorMsg(response);
            setErrorType(true);
              }
          }
        }else{
          let bannerConfirmation = window.confirm(DoDBanner);
          if(!bannerConfirmation ) return;
          if(!InputValidator(username, "username") || !InputValidator(password, "password")){
            setErrorMsg("* Incorrect Username or Password");
          }
          const response = await loginUser({
            username,
            password
          });
          if(response[0]){
            props.setRefreshToken(response[1].refresh_token);
            props.setToken(response[1].access_token);
            return;
          }

          props.setToken(null);
          if(response[1] == 502)
          {
            setErrorMsg("* Unable to communicate with server");
          }
          else if(response[1] == 500)
          {
            setErrorMsg("* Internal Server Error");
          }
          else if(response[1] == 401 && response[2].detail != null && response[2].detail.includes('disabled')){
            setErrorMsg("* Account has been disabled. Contact a System Administrator to re-enable the account.");
          }
          else if(response[1] == 401 && response[2].detail != null && (response[2].detail.includes("username") || response[2].detail.includes("password")))
          {
            setErrorMsg("* Incorrect Username or Password");
          }
          else 
          {
            setErrorMsg("* Unknown Error has occured with status: " + response[1] + (((response[2] != null && response[2].detail != null) ? (". Detail: " + response[2].detail) : "")));
          }
          setErrorType(true);
        }
      }

    return (
        <div className="login-form-container" style={{height: props.banner ? 'calc(100vh - 40px)' : '100vh'}}>
            <img src={DuroSuiteIcon} alt="DuroSuite Icon" style={{position: "fixed", width: "70px", right: "10px", bottom: "10px"}} />
            <LoginBanner status={closeBanner} close={setCloseBanner} open={setCloseBanner} config={props.bannerConfig} banner={props.banner}/>
            <div className="login-form-wrapper" style={{justifyContent: 'space-between'}}>
            {
                props.bannerMode == 3 || props.bannerMode == -1 || props.bannerMode == -2 ?
                <LicenseLogin />
                :  !changePasswordPage ?
            <>
            <h1 style={{fontSize: "36px", color: "#2B2B2B", lineHeight: "48px", paddingLeft: '15px', display: 'flex', alignItems:' center', justifyContent: 'space-between'}}>
            <img src={DuroSuiteHorizontalLogo} alt="DuroSuite Horizontal Logo" className="login-branding-logo" style={{paddingRight: '0px', width: "250px"}} title="DuroSuite" />
            </h1>
            <form onSubmit={handleSubmit} className="login-form">
              <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', width: '90%', marginTop: '10px'}}>
                    <label style={{width: '100%'}}>
                        <p>Username</p>
                        <input autoFocus style={{width: '100%', padding: '5px', boxSizing: 'border-box'}} type="text" name="username" value={username} onChange={(e) => setUsername(e.target.value)} />
                    </label>
                    <label style={{width: '100%', marginTop: '15px'}}>
                        <p>Password</p>
                        <input style={{width: '100%', padding: '5px', boxSizing: 'border-box'}} type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)}/>
                        <div style={{color: 'blue', textDecoration: 'underline', cursor: 'pointer'}} onClick={() => alert("Please contact your System Administrator for assistance.")}>Forgot Password?</div>
                    </label>
                </div>
                <div style={{width: "100%", display: 'flex', flexDirection: "column" ,alignItems: 'center', justifyContent: 'center', height: '165px'}}>
                  <span style={{visibility: errorType ? "visible" : "hidden", color: 'red', textAlign: 'center'}}>{errorMsg}</span>
                  <button type="submit" className={props.bannerMode == null || props.bannerMode == 3 ? "widget-button-disable" : "widget-button"} style={{backgroundColor: props.bannerConfig.color, width: "60%", height: "40px", fontSize: "16px"}} disabled={props.bannerMode == null || props.bannerMode == 3}>Log In</button>
                  <button type="button" onClick={() => setCACAuthenticationMode(true)} className={props.bannerMode == null || props.bannerMode == 3 ? "widget-button-disable" : "widget-button-disable"} style={{backgroundColor: props.bannerConfig.color, width: "60%", height: "40px", fontSize: "16px"}} disabled>Login using CAC</button>
                </div>
            </form>
            </>
            :
            <>
            <h1 style={{fontSize: "36px", color: "#2B2B2B", lineHeight: "48px", paddingLeft: '15px'}}>Change Password</h1>
            <form onSubmit={handleSubmit} className="login-form">
              <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', width: '90%', marginTop: '10px'}}>
                    <label style={{width: '100%'}}>
                        <p>Current Password</p>
                        <input ref={inputElement} style={{width: '100%', padding: '5px', boxSizing: 'border-box'}} type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} required/>
                    </label>
                    <label style={{width: '100%', marginTop: '15px'}}>
                        <p>New Password</p>
                        <input style={{width: '100%', padding: '5px', boxSizing: 'border-box'}} type="password" name="new password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} required/>
                    </label>
                    <label style={{width: '100%', marginTop: '15px'}}>
                        <p>Confirm New Password</p>
                        <input style={{width: '100%', padding: '5px', boxSizing: 'border-box'}} type="password" name="confirm new password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required/>
                        <span style={{visibility: confirmPassword.length > 3 && confirmPassword != newPassword ? "visible" : "hidden", color: 'red', textAlign: 'center'}}>* Password does not match</span>
                    </label>
                </div>
                <div style={{width: "100%", display: 'flex', flexDirection: "column" ,alignItems: 'center', justifyContent: 'center', height: '200px'}}>
                {/* Display Generic Error Messages */}
                <span style={{visibility: errorType ? "visible" : "hidden", color: 'red', textAlign: 'center'}}>{errorMsg}</span>         
                {/* Display Error Message CASE OF: Invalid Password Entered w/ special formatting */}
                {errorType && showInvalidPwMessage && (<span style={{ color: 'red', textAlign: 'left'}}><span style={{display:'inline-block'}}>Invalid Password. Requirements below:</span> <span style={{display:'inline-block'}}>Must be at least 15 characters</span><span style={{display:'inline-block'}}>Must include at least one upper-case character </span> <span style={{display:'inline-block'}}>Must include at least one lower-case letter</span> <span style={{display:'inline-block'}}>Must include at least one numeric character </span> <span style={{display:'inline-block'}}>Must include at least one special character: @$!%*#?&</span></span>)}                 
                     <button type="submit" className="widget-button" style={{backgroundColor: props.bannerConfig.color, width: "60%", height: "40px", fontSize: "16px"}}>Change Password</button>
                  <button type="button" onClick={() => cancelChangePasswordHandler()} className="widget-button" style={{backgroundColor: props.bannerConfig.color, width: "60%", height: "40px", fontSize: "16px", display: 'flex', alignItems: 'center', justifyContent: 'center'}}>Cancel</button>
                </div>
            </form>
            </>
            }
            <div className="login-img-wrapper"><a href={pdf} target="_blank" rel="noreferrer" style={{paddingLeft: '15px'}}>Terms of Agreement</a><img src={AgileLogo} alt="Agile Defense" className="login-img" style={{width: "80px"}}/></div>
            </div>
            {CACAuthenticationMode && <CACPopup close={() => setCACAuthenticationMode(false)} submit={handleSubmit} bannerConfig={props.bannerConfig}/>}
        </div>
    )
}

export default Login;