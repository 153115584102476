import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../App';
import { PieChart, Pie, Label, Legend } from 'recharts';


// Icon
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SettingsIcon from '@mui/icons-material/Settings';
import InfoIcon from '@mui/icons-material/Info';

const ProgressBar = (props) => {
  const navigate = useNavigate();

  return(
    <div style={{display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" ,width: "90%", paddingBottom: "15px"}}>
    <div style={{display: "flex", alignItems:"center", justifyContent:"space-between",width: "100%"}}>
      <div style={{display: 'flex', alignItems: 'center', fontSize: "16px"}}>
        <span >{props.pass + " Device(s)"}</span>
        <span style={{padding: "2px 5px"}}>{props.title}</span>
        <div title="Click for more detail"><InfoIcon fontSize="small" style={{cursor: "pointer"}} onClick={() => navigate("/devices", {state: { params: {"Compliance": props.severity}}})} /></div>
      </div>
      <span style={{fontSize: "18px"}}>{ props.pass > 0 ? Math.floor(props.pass / props.total * 100).toString() + "%" : "0%"}</span>
    </div>
    <div style={{border: "1px solid #D5D5D5",backgroundColor: "#F5F5F5", height: "10px", borderRadius: "15px", width: "100%", display: 'flex', alignItems: 'center'}} title={props.pass}>
      {
        props.auditonly ? 
        <div style={{height: "100%" , width: props.total == 0 ? "0px" : Math.floor(props.pass / props.total * 100).toString() + "%", margin: "0px"}}> 
          <div className="widget-progress-bar" style={{backgroundColor: "#75C626", height: "100%", borderRadius: "15px"}}>
          </div>
        </div>
        :
        <>
          <div style={{height: "100%" , width: props.total == 0 ? "0px" : Math.floor(props.fail / props.total * 100).toString() + "%", margin: "0px"}}> 
            <div className="widget-progress-bar" style={{backgroundColor: "#DD462B", height: "100%", borderRadius: props.noaudit == 0 && props.pass == 0 ? "15px" : "15px 0px 0px 15px"}}>
            </div>
          </div>


          <div style={{height: "100%" , width: props.total == 0 ? "0px" : Math.floor(props.noaudit / props.total * 100).toString() + "%", margin: "0px"}}> 
            <div className="widget-progress-bar" style={{backgroundColor: "#888", height: "100%",  borderRadius: props.fail == 0 && props.pass == 0 ? "15px" : props.fail == 0 && props.pass != 0 ? "15px 0px 0px 15px" : props.pass == 0 && props.fail != 0 ? "0px 15px 15px 0px" : "0px", width: "100%"}}>
            </div>
          </div>


          <div style={{height: "100%" , width: props.total == 0 ? "0px" : Math.floor(props.pass / props.total * 100).toString() + "%", margin: "0px"}}> 
            <div className="widget-progress-bar" style={{backgroundColor: "#75C626", height: "100%", borderRadius: props.noaudit == 0 && props.fail == 0 ? "15px" : "0px 15px 15px 0px"}}>
            </div>
          </div>
          </>
        }
    </div>
  </div>
  )
}


const ComplianceTableWidget = (props) => {
  const { theme, FormatTimestamp } = useContext(UserContext);
  const [data, setData] = useState([]);
  const [complianceData,setComplianceData] = useState([{name: "Compliance", total: 0, fill: '#75C626'},{name: "Not Compliance", total: 0, fill: '#DD462B'}]);
  const [sort ,setSort] = useState(null);
  const [latestAudit, setLatestAudit] = useState([]);
  const [totalCompliance, setTotalCompliance] = useState(0);
  const [popUpToggle, setPopUpToggle] = useState(false);
  const [complianceRulesList, setComplianceRulesList] = useState(
    [
      {
        "id": 1,
        "name": "No CAT I Opens",
        "desc": "Audit should find 0 CAT I Opens",
        "disable": false
      },
      {
        "id": 2,
        "name": "No CAT II Opens",
        "desc": "Audit should find 0 CAT II Opens",
        "disable": false
      }
    ]
  )

  useEffect(() => {
    if(props.auditList == null || props.deviceList == null) return;
    let tmp = [];
    for(let i in props.deviceList){
      let audit = props.auditList.find(x => x.device_id == props.deviceList[i].device_id && x.status == "Complete");
      if(audit){
        tmp.push({device: props.deviceList[i], latest_audit: audit});
      }else{
        tmp.push({device: props.deviceList[i], latest_audit: null });
      }
    }

    tmp.sort((a,b) => a.latest_audit === null && b.latest_audit !== null ? -1 : a.latest_audit !== null && b.latest_audit === null ? 1 : a.latest_audit === null && b.latest_audit === null ? a.device.device_id > b.device.device_id ? 1 : -1 : a.latest_audit.time_started > b.latest_audit.time_started ? 1 : -1).reverse();

    setLatestAudit(tmp);
    checkCompliance(tmp);
  }, [props.deviceList, props.auditList]);  

  useEffect(() => {
    if(latestAudit.length === 0) return;
    checkCompliance();
  },[complianceRulesList])

  const checkCompliance = (audits = null) => {
    let tmp = [];
    if(audits === null)
      tmp = [...latestAudit];
    else
      tmp = audits;
      for(let i in tmp){
        if(tmp[i].latest_audit === null){
          tmp[i].compliance = false;
          continue;
        }
      
      let compliance = true;
      let rules_broken = [];
      let rules_list = complianceRulesList.filter(x => !x.disable);
      
      for(let j in rules_list){
        let status = getCompliance(tmp[i].latest_audit, rules_list[j].id);
        if(!status){
          rules_broken.push(rules_list[j].id);
          compliance = false;
        }
      }

      tmp[i].compliance = compliance;
      tmp[i].rules_broken = rules_broken;
    }

    setLatestAudit(tmp);
    checkComplianceData(tmp);
  }

  const checkComplianceData = (audits = null) => {
    if(audits === null)
      audits = [...latestAudit];
    if(audits === null)
      audits = [];
    let audited_filter = audits.filter(x => x.latest_audit !== null);
    let cat_1_open = audits.filter(x => x.rules_broken != null && x.rules_broken.indexOf(1) !== -1);
    let cat_2_open = audits.filter(x => x.rules_broken != null && x.rules_broken.indexOf(2) !== -1);
    let tmp = [
      {"name": "Audited", "total": audited_filter.length, "severity": "Audited"},
      {"name": "CAT I Open", "total": cat_1_open.length, "severity": "cat_1"},
      {"name": "CAT II Open", "total": cat_2_open.length, "severity": "cat_2"}
    ]
    let compliance_filter = audits.filter(x => x.compliance === true).length;
    setComplianceData(
      [
        {name: "Compliance", total: compliance_filter, fill: '#75C626'},
        {name: "Not Compliance", total: audits.length - compliance_filter, fill: '#DD462B'}
      ]
    )

    setTotalCompliance((compliance_filter / audits.length));
    setData(tmp);
  }

  const getCompliance = (audit, rule_id) => {
    let compliance = false;
    switch(rule_id){
      case 1:
        if(audit.metadata.Open.cat_1 == 0)
          compliance = true;
      break;
      case 2:
        if(audit.metadata.Open.cat_2 == 0)
          compliance = true;
      break;
      default:
        break;
    }
    return compliance;
  }

  const sortTable = (field) => {
    let tmp = [...latestAudit];
    if(field === 'device'){
      tmp.sort((a,b) => a.device.name > b.device.name ? 1 : -1);
    }else if(field === 'date'){
      tmp.sort((a,b) => a.latest_audit === null && b.latest_audit !== null ? -1 : a.latest_audit !== null && b.latest_audit === null ? 1 : a.latest_audit === null && b.latest_audit === null ? a.device.device_id > b.device.device_id ? 1 : -1 : a.latest_audit.time_started > b.latest_audit.time_started ? 1 : -1);
    }else if(field === 'compliance'){
      tmp.sort((a,b) => a.compliance === true && b.compliance === false ? 1 : a.compliance === false && b.compliance === true ? -1 : a.device.device_id > b.device.device_id ? 1 : -1);
    }

    if(field === sort){
      tmp.reverse();
      setSort(null);
    }else{
      setSort(field);
    }

    setLatestAudit(tmp);
  }

  const ruleDisableHandler = (name) => {
      let tmp =[...complianceRulesList];
      let index = tmp.findIndex(x => x.name === name);
      tmp[index].disable = !tmp[index].disable;
      setComplianceRulesList(tmp);
  }

  const complianceTable = (theme) => {
    return(
      <div style={{overflowY: "auto", height: "360px", margin: "5px"}}>
        <div style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr", textAlign: "center", borderBottom: "1px solid #ABABAB", padding: "15px 0px", fontWeight: "bold", color: theme ? "#FFF" : "#5b5b5b"}}>
          <span style={{borderRight: "1px solid #ABABAB", display:"flex",justifyContent: "center", alignItems: "center", cursor: "pointer"}} onClick={() => sortTable('device')}>Device <ArrowDropDownIcon style={{transform: sort === "device" ? "rotate(180deg)" : ""}}/></span>
          <span style={{borderRight: "1px solid #ABABAB", display:"flex",justifyContent: "center", alignItems: "center", cursor: "pointer"}} onClick={() => sortTable('date')}>Most Recent Audit <ArrowDropDownIcon style={{transform: sort === "date" ? "rotate(180deg)" : ""}}/></span>
          <span style={{display:"flex",justifyContent: "center", alignItems: "center", cursor: "pointer"}} onClick={() => sortTable('compliance')}><div style={{cursor: "help", margin: "0px 5px"}} title="Currently checks for CAT I or CAT II Opens"><InfoIcon fontSize="small" /></div>Compliance<ArrowDropDownIcon style={{transform: sort === "compliance" ? "rotate(180deg)" : ""}}/></span>
        </div>
        {
          latestAudit.map((audit,index) => (
            (audit.device != null && checkDevicePerms(audit.device) ) &&
            <div key={index} style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr", textAlign: "center", borderBottom: "1px solid #ABABAB", padding: "10px 0px"}}>
              <span>{audit.device.name != null   ? audit.device.name : "n/a"}</span>
              <span>{audit.latest_audit !== null ? FormatTimestamp(audit.latest_audit.time_finished) : "n/a"}</span>
              <span>{audit.compliance != null ? audit.compliance ? "Yes" : "No" : "n/a"}</span>
            </div>
          ))
        }
      </div>
    )
  }
  
  const checkDevicePerms = (device) => {
    for(let i in device.groups){
      let index = props.groupRole.findIndex(x => x.group_id == device.groups[i].group_id);
      if(index != -1) return true;
    }

    return false;
  }

  const complianceRules = () => {
    return(
      <div style={{height: "90%", display: 'flex', alignItems: 'center', justifyContent: 'center', overflowY: 'auto'}}>
      <div style={{ height: "80%", display: 'flex', flexDirection: 'column'}}>
        <div style={{fontWeight: "bold", textDecoration: "underline"}}> Compliance Rules </div>
        {
          complianceRulesList.map((rule, index) => (
            <div style={{display: 'flex', alignItems: 'center'}} key={rule.name}> 
            <input type="checkbox" checked={rule.disable ? false : true} onChange={() => ruleDisableHandler(rule.name)} style={{marginRight: "5px"}}/>
            {rule.name}
            </div>
          ))
        }
      </div>
    </div>
    )
  }

  const complianceStats = () => {
    return(
      <div style={{display: "flex", flexDirection: "column", alignItems: "center", width: "100%"}}>
        <div style={{textAlign: "center", padding: "15px 0px", fontWeight: "bold", marginTop: "5px" ,width: "100%", color: "#5b5b5b"}}>
          <span>Statistics</span>
        </div>

        <PieChart width={200} height={150}>
          <Pie data={complianceData} dataKey="total" nameKey="name" cx="50%" cy="50%" innerRadius={40} outerRadius={50} label stroke="none">
            <Label value={(totalCompliance * 100).toFixed(2).toString() + "%"} position="center" style={{fill: theme ? "#FFF" : "#000"}} />
          </Pie>
          <Legend verticalAlign="bottom" align="center" iconSize={5} />
        </PieChart>

        {
          data.map((d, index) => (
            <ProgressBar key={d.severity} fail={latestAudit.length - d.total} pass={d.total} noaudit={latestAudit.filter(x => x.latest_audit == null).length} total={latestAudit.length} title={d.name} severity={d.severity} auditonly={index == 0}/>
          ))
        }
      </div>
    )
  }

  return (
  <div className="widget-container" style={{padding: "5px", width: "100%", backgroundColor: theme ? "#242424" : "#FFF", border: theme ? "1px solid #000" : "1px solid #EBEBEB", color: theme ? "#fff" : "#000", borderRadius: "10px", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", height: "400px"}}>
    <h3 className="widget-header">
      <span>Compliance Check</span>
      <SettingsIcon style={{padding: '0px', height: '22px', cursor: 'pointer'}} onClick={() => setPopUpToggle(!popUpToggle)} />
    </h3>
    {
      !popUpToggle ? 
        <div style={{display: "grid", gridTemplateColumns: "70% 30%"}}>
          {
            complianceTable(theme)
          }
          {
            latestAudit.length > 0 ? complianceStats() : <></>
          }
        </div>
      :
        complianceRules()
    }
  </div>
  )
}

export default ComplianceTableWidget;