import React from 'react'
import FastForwardIcon from '@mui/icons-material/FastForward';
import SettingsPic from "./../../../styles/imgs/SettingsPic.png";

const SettingsDoc = (props) => {
  const settings_var = [
    {
      variable: 'Audits Refresh Timer',
      desc: 'X Amount time that the GUI will check with the backend for updates on in-progress Audits',
      value: 'int'
    },
    {
      variable: 'Refresh Refresh Timer',
      desc: 'X Amount time that the GUI will check with the backend for updates on in-progress Remediations',
      value: 'int'
    }
  ]
  
  return (
<div className="documentation-container" style={{display: props.display === true ? "flex" : "none", flexDirection: "column", justifyContent:"center" }}>
        <p style={{width: "90%"}}>
          Settings page is where the administrative authority will be allowed to customize certain settings for DuroSuite. This settings can be configure to the users likings. They're different sections that targets different components of DuroSuite's process. Some settings include refresh check timers for any in-progress audits or remediations. Settings page also allow user to customize ansible's configuration. Ansible is the process we use to audit and remediate in DuroSuite.
        </p>
        <div style={{display: "flex", width: "90%", padding: "20px 0px"}}>
            <div className='documentation-button' style={{letterSpacing: "1px", cursor: "pointer", marginRight: "25px"}} onClick={() => props.pageClick("Settings")}>
                <FastForwardIcon style={{marginRight: "10px", color: "#FF0000"}} /> Quick Move
            </div>
        </div>
        <h3 style={{fontSize: "32px", color: props.theme ? "#FFF" : "#2B2B2B", lineHeight: "48px", fontWeight: "normal"}} id="SettingsVariables">
            Settings Variables
        </h3>
        <div style={{maxHeight: "400px", overflow: 'auto'}}>
          <table className="documentation-table">
              <thead>
              <tr>
                  <th>Variable</th>
                  <th>Description</th>
                  <th style={{textAlign: 'center'}}>Value</th>
              </tr>
              </thead>
              <tbody>
              {
                  settings_var.map((setting,index) =>(
                      <tr key={index}>
                          <td>{setting.variable}</td>
                          <td>{setting.desc}</td>
                          <td style={{textAlign: 'center'}}>{setting.value}</td>
                      </tr>
                  ))
              }
              </tbody>
          </table>
      </div>
        <div style={{border: "2px solid #A4A4A4",width: "70%", margin: "20px 0px"}}>
            <img src={SettingsPic} style={{width: "100%", height: "100%"}} />
        </div>
    </div>
  )
}

export default SettingsDoc