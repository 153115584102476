import React, {useContext, useState} from 'react';
import { UserContext } from "../../../App";

// Icons
import CloseIcon from '@mui/icons-material/Close';

const AuditExport = (props) => {
    const { theme, FormatTimestamp } = useContext(UserContext);
    const [checkList, setCheckList] = useState(props.audits.filter(x => x.status === "Complete"));

    const onChangeChecklist = (audit) => {
        let index = checkList.findIndex(x => x.id === audit.id);
        let tmp = [...checkList];
        if(index === -1){
            tmp.push(audit);
        }else{
            tmp.splice(index,1);
        }
        
        setCheckList(tmp);
    }

    const closePopupHandler = () => {
        props.setExportMode(false)
    }

  return (
    <div style={{width: "100vw",height: "100vh", position:"absolute", zIndex: "1",top: 0, left: 0, display: "flex", overflowY: "hidden", justifyContent: 'center', alignItems: 'center', backgroundColor: "rgba(25,25,25,0.85)"}} onClick={closePopupHandler}>
    <div style={{minWidth: "800px", height: "400px", backgroundColor: theme ? "#242424" : "#FFF", border: theme ? "1px solid #000" : "1px solid #EBEBEB", color: theme ? "#fff" : "#000",  borderRadius: "10px", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", display: 'flex', alignItems:"center", flexDirection:"column", justifyContent:"space-evenly", zIndex: "100", border: "1px solid #d5d5d5", opacity: '1'}} onClick={(e) => e.stopPropagation()}>
        <div style={{width: "90%", display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: "20px", fontWeight: "bold" }}>
            Exporting Checklist Files<CloseIcon style={{color: "#252525", cursor: "pointer"}} onClick={closePopupHandler}/>
        </div>
           <div className="audit-selector-header" style={{width: "90%", height: "150px", overflow: "auto"}}>
                <table style={{borderCollapse: "collapse", width: "100%"}}>
                    <thead style={{width: "500px"}}>
                        <tr style={{backgroundColor: !theme && "#d5d5d5"}}>
                            <th style={{padding: "5px", border: "1px solid #d5d5d5"}}>
                                <input type="checkbox" style={{visibility: "hidden"}}/>
                            </th>
                            <th style={{border: "1px solid #d5d5d5"}}>Device</th>
                            <th style={{border: "1px solid #d5d5d5"}}>Playbook/STIG</th>
                            <th style={{border: "1px solid #d5d5d5"}}>Time Started</th>
                            <th style={{border: "1px solid #d5d5d5"}}>Time Finished</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            props.audits.map((audit,index) => (
                                audit.remediation || audit.status !== "Complete" ?
                                <tr key={audit.id} style={{textAlign: "center", textDecoration: "line-through"}}>
                                    <td style={{padding: "5px",border: "1px solid #d5d5d5"}}><input type="checkbox" disabled/></td>
                                    <td style={{border: "1px solid #d5d5d5"}}>{props.deviceList[props.deviceList.findIndex(x => x.device_id === audit.device_id)]?.name}</td>
                                    <td style={{border: "1px solid #d5d5d5"}}>{props.playbookList[props.playbookList.findIndex(x => x.id === audit.playbook_id)]?.friendly_name}</td>
                                    <td style={{border: "1px solid #d5d5d5"}}>{FormatTimestamp(audit.time_started)}</td>
                                    <td style={{border: "1px solid #d5d5d5"}}>{FormatTimestamp(audit.time_finished)}</td>
                                </tr>
                                :
                                <tr key={audit.id} style={{textAlign: "center"}}>
                                    <td style={{padding: "5px",border: "1px solid #d5d5d5"}}><input type="checkbox" checked={checkList.findIndex(x => x.id === audit.id) !== -1 ? true : false} onChange={() => onChangeChecklist(audit)}/></td>
                                    <td style={{border: "1px solid #d5d5d5"}}>{props.deviceList[props.deviceList.findIndex(x => x.device_id === audit.device_id)]?.name}</td>
                                    <td style={{border: "1px solid #d5d5d5"}}>{props.playbookList[props.playbookList.findIndex(x => x.id === audit.playbook_id)]?.friendly_name}</td>
                                    <td style={{border: "1px solid #d5d5d5"}}>{FormatTimestamp(audit.time_started)}</td>
                                    <td style={{border: "1px solid #d5d5d5"}}>{FormatTimestamp(audit.time_finished)}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
           </div>
           <div style={{display: 'flex', justifyContent:'center', alignItems:"center"}}>
            <input type="button" value="Cancel" className="widget-button" style={{fontWeight: "bold"}} onClick={closePopupHandler}/>
             <input type="button" value="Export" className="widget-button" style={{backgroundColor: "#46DD2B", fontWeight: "bold"}} onClick={() => props.export(checkList, false)}/>
             <input type="button" value="Combine" className={checkList.length < 2 ? "widget-button-disable" : "widget-button" } style={{backgroundColor: "#46DD2B", fontWeight: "bold"}} onClick={() => props.export(checkList, true)} disabled={checkList.length < 2}/>
           </div>
   </div>
</div>
  )
}

export default AuditExport;