import React, {useState, useEffect, useContext} from 'react'
import { UserContext } from '../../../App';
import { useLocation } from 'react-router-dom';

//Components
import GroupTable from "./GroupTable";
import GroupWidget from "./GroupWidget";
import GroupAuditing from "./GroupAuditing";
import GroupPopup from "./GroupPopup";
import GroupEditPopup from './GroupEditPopup';
import PopupPage from '../PopupPage';
import GroupIngestPopup from './GroupIngestPopup';

//CSS
import "./../Widget.css";
import "../devices/InventoryTable.css";

//Icons
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import RefreshIcon from '@mui/icons-material/Refresh';
import UploadFileIcon from '@mui/icons-material/UploadFile';

const Groups = (props) => {
    const { user, FetchCatch, theme, UserIsAdmin } = useContext(UserContext);
    const [select, setSelect] = useState([]);
    const [parent, setParent] = useState([]);
    const [deviceList, setDeviceList] = useState([]);
    const [groupList, setGroupList] = useState([]);
    const [defaultDeviceList, setDefaultDeviceList] = useState([]);
    const [templateSelect, setTemplateSelect] = useState(null);

    const [runMode, setRunMode] = useState(false);

    //PopupPage
    const [popupagepagecheck, setPopuppagecheck] = useState(null);
    const [popuppageeditcheck, setPopuppageeditcheck] = useState(null);
    const [popuppageingestcheck, setpopuppageingestcheck] = useState(null);

    const [width, setWidth] = useState(window.innerWidth);

    const { state } = useLocation();

    useEffect(() => {
        props.refreshGroups();
    }, [])

    useEffect(() => {
        if(state !== null &&  deviceList.length === 0 && defaultDeviceList.length === 0){
            let { params } = state;
            if(params == null){
                setDeviceList([...props.deviceList]);
                return;
            }
            let tmp = [];
            if("OS" in params){
                if(params.OS !== null){
                    tmp = props.deviceList.filter(x => x.os_id === params.OS);
                    setDefaultDeviceList(tmp);
                    setDeviceList(tmp);
                    return;
                }
            }
            if("Compliance" in params){
                if(params.Compliance !== null){
                    tmp = [];
                    for(let i in props.deviceList){
                        if(getCompliance(props.deviceList[i].device_id,params.Compliance)) tmp.push(props.deviceList[i]);
                    }
                }
            }
            setDefaultDeviceList(tmp);
            setDeviceList(tmp);
            return;
        }
        setDeviceList([...props.deviceList]);
    },[props.deviceList])

    useEffect(() => {
        setGroupList(props.groupList);
    }, [props.groupList])

    const getCompliance = (device_id, severity) => {
        let index = props.auditList.findIndex(x => x.device_id === device_id);
        if(severity === "Audited"){
            if(index !== -1) return true;
            return false;
        }
        if(index === -1) return false;
        let audit = props.auditList[index];
        if(severity === "Yes"){
            if(audit.metadata.Open.cat_1 === 0 && audit.metadata.Open.cat_2 === 0) return true;
        }
        if(severity === "No"){
            if(audit.metadata.Open.cat_1 > 0 && audit.metadata.Open.cat_2 > 0) return true;
        }
        if(audit.metadata.Open[severity] > 0){
            return true;
        }
        return false;
    }

    const updateDimensions = () => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);


    const selectHandler = (host, parentHost, ctrlKey, shiftKey) => {
        let index = -1;
        if("device_id" in host){
            index = select.findIndex(x => x.device_id && x.device_id == host.device_id);
        }else{
            index = select.findIndex(x => x.group_id && x.group_id == host.group_id);
        }

        if(ctrlKey){
            let tmp = [...select];
            let parentTmp = [...parent];
            if(index == -1){
                tmp.push(host);
                parentTmp.push(parentHost);
            }else{
                if(parentCheck(parent[index], parentHost)){
                    tmp.splice(index, 1);
                    parentTmp.splice(index, 1);
                }else{
                    parentTmp[index] = parentHost;
                }

            }
            setSelect(tmp);
            setParent(parentTmp);
        }else if(shiftKey){
            if(!compareParent(parentHost, parent[parent.length - 1]) || parent[parent.length - 1].length == 0){
                setSelect([host]);
                setParent([parentHost]);
            }else{
                let parent_index = props.hostList.findIndex(x => x.group_id == parentHost[parentHost.length - 1].group_id);
                if(parent_index == -1){
                    console.log('error parent index');
                    return;
                }
                if("device_id" in select[select.length - 1]){
                    let firstIndex = props.hostList[parent_index].devices.findIndex(x => x.device_id == select[select.length - 1].device_id) ;
                    let secondIndex = props.hostList[parent_index].devices.findIndex(x => x.device_id == host.device_id);
                    let tmp = [];
                    if(firstIndex < secondIndex){
                        tmp = props.hostList[parent_index].devices.slice(firstIndex + 1, secondIndex + 1);
                        tmp.push(props.hostList[parent_index].devices[firstIndex]);
                    }else{
                        tmp = props.hostList[parent_index].devices.slice(secondIndex, firstIndex + 1);
                    }
                    
                    let parent_tmp = [];

                    for(let i in tmp){
                        parent_tmp.push([...parentHost[i]]);
                    }

                    setSelect(tmp);
                    setParent(parent_tmp);
                }
            }
        }else{
            if(index == -1){
                setSelect([host]);
                setParent([parentHost]);
            }else{
                if(select.length > 1){
                    setSelect([host]);
                    setParent([parentHost]);
                }else{
                    if(parentCheck(parent[index], parentHost)){
                        setSelect([]);
                        setParent([]);
                    }else{
                        setSelect([host]);
                        setParent([parentHost]);
                    }

                }
            }
        }
    }

    const compareParent = (a, b) => {
        if(a.length != b.length){
            return false;
        }

        for(let i in a){
            if(a[i].group_id != b[i].group_id){
                return false;
            }
        }

        return true;
    }

    const parentCheck = (a,b) => {
        if(a.length === b.length){
            if(a.length === 0){
                return true;
            }
            let status = true;
            for(let j in a){
                if(a[j].group_id !== b[j].group_id){
                    status = false;
                    break;
                }
            }
            return status;
        }
        return false;
    }

    const addDeviceHandler = (name,os,type,group,defaultVariable) => {
        group = props.hostList[props.hostList.findIndex(x => x.group_id == group)];
        if(type){
            props.addDevice(group,group);
        }else{
            props.newHost(name,group,1,parseInt(os), defaultVariable);
        }
    }

    const addGroupHandler = async (name, os, defaultVariable) => {
        return await props.newHost(name,select[0],0,os,defaultVariable);
    }

    
    const auditRunHandler = (status,playbookList,type, group, template) => {
        if(parent.length == 0){
            group = null;
        }
        if(select.length == 0) return;
        props.runAudit(status,{host: select[0], group: group, playbooks: playbookList, template: template},type);
        if(status === 0 || status === 1){
            setRunMode(false);
        }
    }

    const deleteHostHandler = () => {
        if(window.confirm("Are you sure you want to delete selected group(s) and/or device(s)?")){
            let tmp = [];
            for(let i in select){
                tmp.push(select[i]);
            }
            props.deleteHost(tmp);
            setSelect([]);
            return;
        }
    }

    const removeHostHandler = () => {
        if(window.confirm("Are you sure you want to remove selected Group(s) and/or Device(s)?")){
            for(let i in select){
                if(select[i] != null && parent[i].length > 0){
                    if("group_id" in select[i]){
                        props.removeGroupGroup(select[i], parent[i][parent[i].length - 1]);
                    }else{
                        props.removeDeviceGroup(select[i], parent[i][parent[i].length - 1]);
                    }
                }
            }
        setSelect([]);
        return;
        }
    }

    const saveDevice = async (device) => {
        //Updated on frontend 
        props.updateDevice(null, null);
        setSelect([]);
    }

    const API_update_device = async (device_id, os_id, name) => {
        const url_update_device = "/api/devices?id=" + device_id +  "&os_id=" + os_id + "&device_name=" + name;
        let data = await fetch(url_update_device, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${user.token}` },
        })
        .then(res=>{
            if(res.ok) return res.json();
            throw res;
        })
        .then(data =>{
            return data;
        }).catch(async error => {
            return await FetchCatch(error, API_update_device, [device_id, os_id, name]);
        });

        return data;
    }

    const API_update_device_var = async (var_id, device_id, var_name, var_value) => {
        const url_update_device_var = "/api/devices/vars?id=" + var_id + "&device_id=" + device_id + "&var_name=" + var_name + "&var_val=" + var_value;
        let data = await fetch(url_update_device_var,{
            method: 'PUT',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${user.token}` },
        })
        .then(res=>{
            if(res.ok) return res.json();
            throw res;
        })
        .then(data =>{
            return data;
        }).catch(async error => {
            return await FetchCatch(error, API_update_device_var, [var_id, device_id, var_name, var_value]);
        });

        return data;
    }

    const API_create_device_var = async (device_id, var_name, var_value) => {
        const url_new_device_var = "/api/devices/vars";
        let data = await fetch(url_new_device_var, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${user.token}` },
            body: JSON.stringify({ "device_id": device_id, "var_name": var_name,"var_value": var_value })
        })
        .then(res=>{
            if(res.ok) return res.json();
            throw res;
        })
        .then(data =>{
            return data;

        }).catch(async error => {
            return await FetchCatch(error, API_create_device_var, [device_id, var_name, var_value]);
        });

        return data;
    }

    const API_delete_device_var = async (var_id) => {
        const url_delete_device_var = "/api/devices/vars/" + var_id;
        let data = await fetch(url_delete_device_var, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
        .then(res=>{
            if(res.ok) return;
            throw res;
        }).then(data => {
            return true;
        }).catch(async error => {
            return await FetchCatch(error, API_delete_device_var, [var_id]);
        });

        return data;
    }

    const saveGroup = async (group) => {
        let copy_select = JSON.parse(JSON.stringify(select[0]));
        if(select[0].group_name !== group.group_name || select[0].os_id !== group.os_id){
            let group_update = await API_update_group(group.group_id, group.group_name, group.os_id);
            if(group_update){
                copy_select.group_name = group.group_name;
                copy_select.os_id = group.os_id
            }
        }

        props.updateGroup(select[0], copy_select);
        setSelect([copy_select]);
    }

    const API_update_group = async (group_id, name, os_id) => {
        const url_new_host = "/api/groups?id=" + group_id + "&name=" + name + "&os_id=" + os_id;
        let data = await fetch(url_new_host, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${user.token}` },
        })
        .then(res=>{
            if(res.ok) return res.json();
            throw res;
        })
        .then(data =>{
            return data;
        }).catch(async error => {
            return await FetchCatch(error, API_update_group, [group_id, name, os_id]);
        });

        return data;
    }

    const addExistingHandler = (type, parent, host) => {
        if(type === "Device"){
            props.addDevice(parent, host);
        }else{
            props.addChild(parent, host);
        }
    }

    const copyGroupHandler = async () => {
        let group = null;
        if(select.length == 1){
            group = select[0];
        }else{
            alert("Only select the group you want to copy.");
            return;
        }

        if(group == null || "device_id" in group){
            alert("A Group needs to be selected or highlighted to be copied.");
            return;
        }

        let new_name = window.prompt("What will the new group name be?");
        if(new_name == null || new_name == "") return;
        let templates = await API_get_templates(group.group_id);
        let baseline_template = templates.find(x => x.name == "Baseline");
        if(baseline_template == null){
            alert("Baseline template can't be found for Group: " + group.group_id);
            return;
        }

        let variables = await API_get_template_variable(baseline_template.id);
        if(variables == null){
            alert("Can't get variables for Baseline template with id: " + baseline_template.id);
            return;
        }

        props.newHost(new_name, null, 0, group.os_id, [...variables.connection_vars, ...variables.stig_vars]);
    }

    const API_get_template_variable = async (template_id) => {
        const url_get_template = "/api/stigs/templates/" + template_id;
        let data = await fetch(url_get_template,{
            method: 'GET',
            headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${user.token}` },
        })
        .then(res=>{
            if(res.ok) return res.json();
            throw res;
        })
        .then(data =>{
            if(data != null){
                data.stig_vars.sort((a,b) => a.var_name > b.var_name ? 1 : -1);
                data.connection_vars.sort((a,b) => a.var_name > b.var_name ? 1 : -1);
            }
            return data;
        }).catch(async error => {
            return await FetchCatch(error, API_get_template_variable, [template_id]);
        });
    
        return data;
      }

      const API_get_templates = async (group_id) => {
        const url_groups_template = "/api/stigs/templates/group/" + group_id;
        let data = await fetch(url_groups_template,{
            method: 'GET',
            headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${user.token}` },
        })
        .then(res=>{
            if(res.ok) return res.json();
            throw res;
        })
        .then(data =>{
            return data;
        }).catch(async error => {
            return await FetchCatch(error, API_get_templates, [group_id]);
        });

        return data;
      }
    
      const createNewTemplate = async (name, host, variables) => {
        if(name === "" || host === null) return;
        let template_data = await API_create_template(name, host.group_id, host.os_id);
        if(template_data == null){
            alert("Failed to create new template.");
            return;
        }

        for(let i in variables){
            let variable_data = await API_create_template_variable(template_data.id, variables[i].var_name, variables[i].var_value);
            if(variable_data == null){
                console.log("Fail to create new template: " + variables[i].var_name + " " + variables[i].var_value);
            }
        }

        setSelect([]);
      }

      const API_create_template = async (template_name, group_id, os_id) => {
        let url_new_template = "/api/stigs/templates";
        let data = await fetch(url_new_template ,{
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${user.token}` },
            body: JSON.stringify({"name": template_name, "os_id": os_id, "group_id": group_id})
        })
        .then(res=>{
            if(res.ok) return res.json();
            throw res;
        })
        .then(data =>{
            return data;
        }).catch(async error => {
            return await FetchCatch(error, API_create_template, [template_name, group_id, os_id]);
        });  
    
        return data;
      }
    
      const API_create_template_variable = async (id, var_name, var_value) => {
        const url_new_template_var = "/api/stigs/templates/var";
        let data = await fetch(url_new_template_var,{
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${user.token}` },
            body: JSON.stringify({ "template_id": id, "var_name": var_name, "var_value": var_value})
        })
        .then(res=>{
            if(res.ok) return res.json();
            throw res;
        })
        .then(data =>{
            return data 
        }).catch(async error => {
            return await FetchCatch(error, API_create_template_variable, [id, var_name, var_value]);
        });  
    
        return data;
      }

    const addExisitingButtonHandler = (host) => {
        setSelect([host]);
        setParent([[]]);
        setPopuppagecheck('Device Creation');
    }

    return (
        <div className="inventory-content">
            <div className="inventory-content-container" style={{alignItems: "stretch", color: theme ? "#FFF" : "#000"}}>
                <div className="inventory-content-header">
                    <div style={{display: 'flex', alignItems: 'center'}}>
                    <h1>Groups</h1>
                        <div title="Refresh Groups"  style={{display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: "pointer", marginLeft: "10px"}} onClick={() => props.refreshGroups()}>
                            <RefreshIcon style={{height: "20px", width: "20px", paddingTop: "5px"}}/>
                        </div>
                    </div>
                    <div style={{display: 'flex', borderBottom: "1px solid #454545", width: '100%', paddingBottom: "5px"}}>
                        <div style={{width: "100%", display: 'flex', flexDirection: 'row', alignItems: "center", fontSize: "16px"}}>
                            {
                                <div className="inventory-content-header-button"  onClick={() => setPopuppagecheck("Group Creation")}>
                                    <AddIcon style={{fontSize: "20px"}} />
                                    {/* Create Group */}
                                    Create
                                </div>
                            }
                            <div className="inventory-content-header-button"  onClick={deleteHostHandler}>
                                <DeleteForeverIcon style={{fontSize: "20px"}} />
                                {/* Delete Group/Device */}
                                Delete
                            </div>
                            <div className="inventory-content-header-button"  onClick={removeHostHandler}>
                                <RemoveCircleOutlineIcon style={{fontSize: "20px"}} />
                                {/* Remove Group/Device */}
                                Remove
                            </div>
                            {
                                UserIsAdmin() &&
                                <div className="inventory-content-header-button"  onClick={copyGroupHandler}>
                                    <CopyAllIcon style={{fontSize: "20px"}} />
                                    Copy Group
                                </div>
                            }
                            {
                                UserIsAdmin() &&
                                <div className="inventory-content-header-button"  onClick={() => setpopuppageingestcheck("Ingest Groups/Devices")}>
                                    <UploadFileIcon style={{fontSize: "20px"}} />
                                    Ingest Groups/Devices
                                </div>
                            }
                        </div>
                    </div>
                </div>
                {   
                    width <= 1280 &&
                    <div className="inventory-content-container">
                        <GroupAuditing groupRole={props.groupRole} selected={select.length > 0 ? select[0] : null} parent={parent.length > 0 ? parent[0] : null} hostList={props.hostList} display={runMode} cancel={() => setRunMode(false)} playbookList={props.playbookList} OS={props.OS} auditRun={auditRunHandler} templateSelect={templateSelect}/>
                        <GroupWidget groupRole={props.groupRole} selected={select.length > 0 ? select[0] : null} parent={parent.length > 0 ? parent[0] : null} deviceList={props.deviceList} hostList={props.hostList} OS={props.OS} addHost={() => setPopuppagecheck("Device Creation")} addTemplate={() => setPopuppagecheck("Template Creation")} runHost={() => setRunMode(true)} viewHost={(type) => props.hostAudit(select.length > 0 ? select[0] : null, type)} unique_group_names={props.uniqueGroupNames} saveDevice={saveDevice} saveGroup={saveGroup} close={() => setSelect([])} templateSelect={setTemplateSelect} edit={() => setPopuppageeditcheck('Edit')} schedule={() => setPopuppageeditcheck('Schedule Task')}/>
                    </div>
                }
                <div className="inventory-content-wrapper">
                    <GroupTable groupList={groupList} allRoles={props.allRoles} groupRole={props.groupRole} hostList={props.hostList} deviceList={props.deviceList} devices={deviceList} OS={props.OS} auditList={props.auditList} select={selectHandler} selected={select} parent={parent} addExisting={addExisitingButtonHandler} />
                </div>
            </div>
            {
                width > 1280 &&
                <div className="inventory-content-container" style={{marginRight: "20px"}}>
                    <GroupAuditing groupRole={props.groupRole} selected={select.length > 0 ? select[0] : null} parent={parent.length > 0 ? parent[0] : null} hostList={props.hostList} display={runMode} cancel={() => setRunMode(false)} playbookList={props.playbookList} OS={props.OS} auditRun={auditRunHandler} templateSelect={templateSelect}/>
                    <GroupWidget groupRole={props.groupRole} selected={select.length > 0 ? select[0] : null} parent={parent.length > 0 ? parent[0] : null} deviceList={props.deviceList} hostList={props.hostList} OS={props.OS} addHost={() => setPopuppagecheck("Device Creation")} addTemplate={() => setPopuppagecheck("Template Creation")} runHost={() => setRunMode(true)} viewHost={(type) => props.hostAudit(select.length > 0 ? select[0] : null, type)} unique_group_names={props.uniqueGroupNames} saveDevice={saveDevice} saveGroup={saveGroup} close={() => setSelect([])} templateSelect={setTemplateSelect} edit={() => setPopuppageeditcheck('Edit')} schedule={() => setPopuppageeditcheck('Schedule Task')}/>
                </div>
            }
            {
                popupagepagecheck != null &&
                <PopupPage close={() => setPopuppagecheck(null)} title="Group(s)" content={<GroupPopup page={popupagepagecheck} select={select} close={() => setPopuppagecheck(null)} OSList={props.OS} groupList={props.hostList} deviceList={props.deviceList} createGroup={addGroupHandler} createDevice={addDeviceHandler} addHost={addExistingHandler} createTemplate={createNewTemplate}/>}/>
            }
            {
                popuppageeditcheck != null &&
                <PopupPage close={() => setPopuppageeditcheck(null)} title={select != null & select.length > 0 ? select[0].name : ""} content={<GroupEditPopup page={popuppageeditcheck} close={() => setPopuppageeditcheck(null)} OSList={props.OS} groupList={props.hostList} deviceList={props.deviceList} playbookList={props.playbookList} select={select} saveDevice={saveDevice} saveGroup={saveGroup} createTemplate={createNewTemplate}/>}/>
            }
            {
                popuppageingestcheck != null && 
                <PopupPage close={() => setpopuppageingestcheck(null)} title={"Ingest Groups/Devices"} content={<GroupIngestPopup page={popuppageingestcheck} close={() => setpopuppageingestcheck(null)} OSList={props.OS} groupList={props.hostList} deviceList={props.deviceList} playbookList={props.playbookList} refreshGroups={props.refreshGroups}/>}/>
            }
        </div>
    )
}

export default Groups;