import React, { useState, useEffect } from 'react'

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

//CSS
import "./../../styles/css/VulnTable.css";

const VulnTable = (props) => {
  const [vulnList, setVulnList] = useState([]);
  const [prevSelectIndex, setPrevSelectIndex] = useState(null);
  const [reversed, setReversed] = useState({ 'severity': false, 'vuln_num': false, 'status': false });
  const statuses = ['All', 'Not a Finding', 'Not Reviewed', 'Open', 'Not Applicable']

  const nextSelectedStatus = () => {
    var nextSelectedIdx = 1 + statuses.indexOf(props.selectedStatus)
    // if out of bounds
    if (nextSelectedIdx > 4) {
      nextSelectedIdx = 0
    }
    return statuses[nextSelectedIdx]
  }
  

  useEffect(() => {
    if (props.vuln_list == null) return;
    let sorted_vulnList = [...props.vuln_list].sort((a, b) => a["vuln_num"] > b["vuln_num"] ? 1 : -1);
    setVulnList(sorted_vulnList);
    setPrevSelectIndex(null);
    setReversed({ 'severity': false, 'vuln_num': true, 'status': false });
  }, [props.vuln_list]);

  const handleVulnClick = (vulnClicked) => {
    //This determines what vulnerability is slected by index, and deselect prevIndex. 
    if (prevSelectIndex === vulnClicked) {
      setPrevSelectIndex(null);
      props.onVulnSelect(null);
    } else {
      setPrevSelectIndex(vulnClicked);
      props.onVulnSelect(vulnClicked);
    }
  }

  const handleTableHeadClick = (type) => {
    if (reversed[type]) {
      setVulnList([...vulnList].sort((a, b) => a[type] > b[type] ? 1 : -1).reverse());
    } else {
      setVulnList([...vulnList].sort((a, b) => a[type] > b[type] ? 1 : -1));
    }

    let tmp = reversed;
    tmp[type] = !tmp[type];
    setReversed(tmp);
    if (prevSelectIndex !== null) {
      props.onVulnSelect(prevSelectIndex)
    }
  }

  const severityString = (sev) => {
    if (sev === 'high') return 'CAT I'
    else if (sev === 'medium') return 'CAT II'
    else return 'CAT III'
  }

  const statusString = (status) => {
    if (status === 'NotAFinding') return 'NF'
    else if (status === 'Open') return 'Open'
    else if (status === 'Not_Applicable') return 'NA'
    else return 'NR'
  }

  const handleStatusColor = (status) => {
    if (status === 'NotAFinding') return '#75C626'
    else if (status === 'Open') return '#DD462B'
    else if (status === 'Not_Applicable') return 'NA'
    else return '#ffba08'
  }

  const ToggleFilterByStatus = () => {
    // get status
    var status = nextSelectedStatus()
    // update status
    props.setSelectedStatus(nextSelectedStatus())
    // filter by status
    if (status === 'Not a Finding') {
      props.filterInfoHandler({...props.severityFilter, 'Not a Finding': true })
    }
    if (status === 'Not Reviewed') {
      props.filterInfoHandler({...props.severityFilter, 'Not Reviewed': true })
    }
    if (status === 'Not Applicable') {
      props.filterInfoHandler({...props.severityFilter, 'Not Applicable': true })
    }
    if (status === 'Open') {
      props.filterInfoHandler({...props.severityFilter, 'Open': true })
    }
    if (status === 'All') {
      props.filterInfoHandler({...props.severityFilter, 'All': true })
    }
  }

  return (
    <div className='vuln-table-container' style={{ backgroundColor: props.theme ? "#242424" : "#FFF", border: props.theme ? "1px solid #000" : "1px solid #EBEBEB", color: props.theme ? "#fff" : "#000" }}>
      <div className="grid-item-header total" style={{ background: props.theme ? "#202020" : "" }}>
        <h4>Vulnerabilities</h4>
        <h4 style={{ marginLeft: "auto", marginRight: "5px" }}>Total: {vulnList.length}</h4>
      </div>
      <div className="scrollbar-wrapper">
        <table className="table-container">
          <thead className="table-head">
            <tr className="table-row" style={{ backgroundColor: props.theme ? "#202020" : "#D5D5D5" }}>
              <th style={{ width: "80px", cursor: "pointer" }} onClick={() => handleTableHeadClick("severity")}><div style={{ display: 'flex', flexDirection: 'row', alignItems: "center" }}>Severity{reversed.severity ? <ArrowDropUpIcon fontSize='small' /> : <ArrowDropDownIcon fontSize='small' />} </div></th>
              <th style={{ cursor: "pointer" }} onClick={() => handleTableHeadClick("vuln_num")}><div style={{ display: 'flex', flexDirection: 'row', alignItems: "center" }}>Vul ID{reversed.vuln_num ? <ArrowDropUpIcon fontSize='small' /> : <ArrowDropDownIcon fontSize='small' />}</div></th>


              <th style={{ width: "70px", cursor: "pointer" }} onClick={() => handleTableHeadClick("status")}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: "center" }}>

                  <div style={{display:'flex',flexDirection:'row'}} onClick={() => { ToggleFilterByStatus() }} >
                  {props.selectedStatus === 'All' &&
                    <div style={{ borderStyle: 'solid', borderWidth: 1, background: 'none', width: 30, height: 25, marginLeft: -55, marginRight: 0, alignContent: 'center', alignSelf: 'center', alignItems: 'center' }} title={`Currently selected Status: ${props.selectedStatus}`}><p style={{ marginLeft: 5 }}>{props.selectedStatus}</p></div>
                  }
                  {props.selectedStatus === 'Not Reviewed' &&
                    <div style={{ borderStyle: 'solid', borderWidth: 1, background: 'none', width: 112, height: 25, marginLeft: -138, marginRight: 0, alignContent: 'center', alignSelf: 'center', alignItems: 'center' }} title={`Currently selected Status: ${props.selectedStatus}`}><p style={{ marginLeft: 5 }}>{props.selectedStatus}</p></div>
                  }
                  {props.selectedStatus === 'Not Applicable' &&
                    <div style={{ borderStyle: 'solid', borderWidth: 1, background: 'none', width: 112, height: 25, marginLeft: -138, marginRight: 0, alignContent: 'center', alignSelf: 'center', alignItems: 'center' }} title={`Currently selected Status: ${props.selectedStatus}`}><p style={{ marginLeft: 5 }}>{props.selectedStatus}</p></div>
                  }
                  {props.selectedStatus === 'Not a Finding' &&
                    <div style={{ borderStyle: 'solid', borderWidth: 1, background: 'none', width: 112, height: 25, marginLeft: -138, marginRight: 0, alignContent: 'center', alignSelf: 'center', alignItems: 'center' }} title={`Currently selected Status: ${props.selectedStatus}`}><p style={{ marginLeft: 5 }}>{props.selectedStatus}</p></div>
                  }
                  {props.selectedStatus === 'Open' &&
                    <div style={{ borderStyle: 'solid', borderWidth: 1, background: 'none', width: 50, height: 25, marginLeft: -78, marginRight: 0, alignContent: 'center', alignSelf: 'center', alignItems: 'center' }} title={`Currently selected Status: ${props.selectedStatus}`}><p style={{ marginLeft: 5 }}>{props.selectedStatus}</p></div>
                  }
                  <div style={{ marginLeft: 6 }} title='click to Filter by Status'>
                    <FilterAltIcon sx={{ "&:hover": { color: "#09347A" } }}/>
                    </div>
                  </div>Status{reversed.status ? <ArrowDropUpIcon fontSize='small' /> : <ArrowDropDownIcon fontSize='small' />}
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="table-body">
            {
              vulnList.map((vuln, index) => (
                <tr key={index} onClick={() => handleVulnClick(vuln)} className={prevSelectIndex !== null && vuln.id === prevSelectIndex.id ? "table-row selected" : "table-row"} style={{ color: handleStatusColor(vuln.status) }}>
                  <td>{severityString(vuln.severity)}</td>
                  <td>{vuln.vuln_num}</td>
                  <td>{statusString(vuln.status)}</td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default VulnTable;