import React from 'react'
import FastForwardIcon from '@mui/icons-material/FastForward';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';

const IntroDoc = (props) => {
  return (
    <div className="documentation-container" style={{display: props.display === true ? "flex" : "none", flexDirection: "column", justifyContent:"center"}}>
        <p style={{width: "90%"}}>Welcome to <span style={{fontSize: "20px", display: 'inline', letterSpacing: "1px"}}>DuroSuite</span>, the scalable, agentless automation tool that eases the consuming Administrator work for DoD compliance!
        <br />
        <br />
        The tool can be used for on-prem, air-gapped, or cloud infrastructures. It also produces artifacts to ensure that STIGs are completed and that systems comply with Information Assurance (IA) standards and the DISA Security Requirements Guide (SRG).
        <br />
        <br />
        Please contact DuroSuite@agile-defense.com for any questions / issues.</p>
        <div style={{display: "flex", width: "90%", padding: "20px 0px"}}>
            {/* <div className='documentation-button' style={{letterSpacing: "1px", cursor: "pointer", marginRight: "25px"}} onClick={() => props.pageClick("Home")}>
                <FastForwardIcon style={{marginRight: "10px", color: "#FF0000"}} /> Quick Move
            </div> */}
            <a className='documentation-button' style={{letterSpacing: "1px", cursor: "pointer", textDecoration: "none", color: "inherit"}} href="https://www.youtube.com/watch?v=ZQIi30wPQ40" target="_blank">
                <PlayCircleIcon style={{marginRight: "10px", color: "#FF0000"}} /> CLI Demo
            </a>
        </div>
        <div style={{border: "2px solid #A4A4A4",width: "90%"}}>
            {/* <img src={DashboardPic} style={{width: "100%", height: "100%"}} /> */}
        </div>
    </div>
  )
}

export default IntroDoc