import { Grid } from '@mui/material'
import React from 'react'

//CSS
import "./../../styles/css/VulnDescription.css"

const VulnDescription = (props) => {
    const severityString = (sev) =>{
        if(sev === 'high') return 'CAT I'
        else if(sev === 'medium') return 'CAT II'
        else return 'CAT III'
      }

    const statusString = (status) => {
        if(status === "Not_Reviewed") return "Not Reviewed";
        else if(status === "Open") return "Open";
        else if(status === "NotAFinding") return "Not A Finding";
        else return "Not Applicable";
    }

    const getDeviceName = (audit) => {
        if(props.deviceList.length === 0) return "n/a";
        let index = props.deviceList.findIndex(device => device.device_id === props.audit.audit.device_id);
        if(index === -1) return "n/a";
        return props.deviceList[index].name;
    }

    const getPlaybookName = () => {
        if(props.audit === null) return "";
        let index = props.playbookList.findIndex(playbook => playbook.id === props.audit.audit.playbook_id);
        if(index === -1) return "n/a";
        return props.playbookList[index].friendly_name;
    }

  const handleContent = () =>{
    if(props.vuln === null){
        return(
            <div style={{width: "100%", height: "97%",display: "flex", justifyContent: "center", alignItems:"center"}}>
                <h4>Select a Vulnerability</h4>
            </div>
        )
    }else{
        return(
            <>
                <div>
                    <div className="vuln-description-title-wrapper">
                        <h4 className="vuln-description-title" style={{fontWeight: 'bold'}}>{getPlaybookName()}</h4>
                    </div>
                    <div className="vuln-description-id-list">
                        <Grid container spacing={1}>
                            <Grid item sm={6}><p style={{height: "10px"}}><span style={{fontWeight: "bold", marginRight: '5px'}}>Device:</span> {getDeviceName()}
                            </p>
                            </Grid>
                            <Grid item sm={6}><p style={{height: "10px"}}><span style={{fontWeight: "bold", marginRight: '5px'}}>VUL ID:</span>{props.vuln.vuln_num}</p></Grid>
                            <Grid item sm={3}><p><span style={{fontWeight: "bold", marginRight: '5px'}}>Severity:</span>{severityString(props.vuln.severity)}</p></Grid>
                            <Grid item sm={3}><p><span style={{fontWeight: "bold", marginRight: '5px'}}>Status:</span>{statusString(props.vuln.status)}</p></Grid>
                            <Grid item sm={6}><p><span style={{fontWeight: "bold", marginRight: '5px'}}>Timestamp:</span>{props.audit.audit.friendly_timestamp}</p></Grid>
                        </Grid>
                    </div>
                </div>
                <div className="vuln-description-content-wrapper">
                    <div style={{paddingBottom:"15px"}}>
                        <h4>Rule Title: </h4> <p>{props.vuln.rule_title}</p>
                    </div>
                    <div style={{paddingBottom:"15px"}}>
                        <h4>Discussion: </h4>
                        <p>
                            {props.vuln.vuln_discuss.split("\n").map(function(item,idx){
                                return (<span key={idx}>{item}<br/></span>)
                            })}
                        </p>
                    </div>
                    <div style={{paddingBottom:"15px"}}>
                        <h4>How to Check: </h4>
                        <p>
                            {props.vuln.check_content.split("\n").map(function(item,idx){
                                return (<span key={idx}>{item}<br/></span>)
                            })}
                        </p>
                    </div>
                    <div style={{paddingBottom:"15px"}}>
                        <h4>How to Fix: </h4>
                        <p>
                            {props.vuln.fix_text.split("\n").map(function(item,idx){
                                return (<span key={idx}>{item}<br/></span>)
                            })}
                        </p>
                    </div>
                    {/* <div style={{paddingBottom:"15px"}}>
                        <h4>Finding Details: </h4>
                        <p>
                            {props.vuln.finding_details.split("\n").map(function(item,idx){
                                return (<span key={idx}>{item}<br/></span>)
                            })}
                        </p>
                    </div>
                    <div style={{paddingBottom:"15px"}}>
                        <h4>Comments: </h4>
                        <p>
                            {props.vuln.comments.split("\n").map(function(item,idx){
                                return (<span key={idx}>{item}<br/></span>)
                            })}
                        </p>
                    </div> */}
                    <div style={{paddingBottom:"15px"}}>
                    <h4>CCI: </h4>
                        <p>
                            {
                                props.vuln.cci_ids != null ?
                                props.vuln.cci_ids.map(function(item,idx){
                                    return (<span key={idx}>{item}<br/></span>)
                                }) :
                                <span>N/A<br/></span>
                            }
                        </p>
                    </div>
                </div>
                <div className="vuln-description-content-comment-wrapper">
                    <h4>Finding Details: </h4>
                    <p style={{maxHeight: '100px', overflowY: "auto", marginRight: "10px"}}>
                        {props.vuln.finding_details.split("\n").map(function(item,idx){
                            return (<span key={idx}>{item}<br/></span>)
                        })}
                    </p>
                </div>
                <div className="vuln-description-content-comment-wrapper">
                    <h4>Comments: </h4>
                    <p style={{maxHeight: '100px', overflowY: "auto", marginRight: "10px"}}>
                    {props.vuln.comments.split("\n").map(function(item,idx){
                            return (<span key={idx}>{item}<br/></span>)
                    })}
                    </p>
                </div>
            </>
        )
    }
  }

  return (
    <div className="vuln-description-container" style={{backgroundColor: props.theme ? "#242424" : "#FFF", border: props.theme ? "1px solid #000" : "1px solid #EBEBEB", color: props.theme ? "#fff" : "#000"}}>
        <div className="grid-item-header" style={{background: props.theme ? "#202020" : ""}}>
            <h4>Vulnerability Description</h4>
        </div>
        {
            handleContent()    
        }
  </div>
  )
}

export default VulnDescription