import React, {useContext, useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';
import { UserContext } from '../../../App';

//CSS
import "./Audits.css";
import "react-datepicker/dist/react-datepicker.css";

//Components
import AuditsTable from "./AuditsTable";
import AuditWidget from "./AuditWidget";
import Pagination from './../Pagination';
import AuditExport from './AuditExport';
import AuditCompare from "./AuditCompare";
import PopupPage from "../PopupPage";
import AuditPopup from './AuditPopup';
import AuditFailure from "./AuditFailure";

//Icons
import RefreshIcon from '@mui/icons-material/Refresh';

const Audits = (props) => {
    const { user, FetchCatch, theme } = useContext(UserContext);
    let location = useLocation();
    const [audit,setAudit] = useState([]);
    const [sort,setSort] = useState([]);
    const [filtered, setFiltered] = useState(null);
    const [mostrecent, setMostrecent] = useState(true);
    const [width, setWidth]   = useState(window.innerWidth);
    const [compare, setCompare] = useState([null, null]);
    const [compareMode, setCompareMode] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [currentPosts, setCurrentPosts] = useState([]);
    
    const [exportMode, setExportMode] = useState(false);
    const [popupagepagecheck, setPopuppagecheck] = useState(false);

    const exportHandler = (audits, combine) => {
        if(combine){
            let diff_device_check = audits.filter(x => x.device_id != audits[0].device_id);
            if(diff_device_check.length > 0){
                alert("Can't combine audits with different devices.");
                return null;
            }
            let list_tmp = [];
            let url_export = "/api/combine-ckl?audit_ids=";
            audits.sort((a,b) => a.time_finished > b.time_finished ? -1 : 1);
            for(let i in audits){
                let index = list_tmp.findIndex(a => a.playbook_id == audits[i].playbook_id);
                if(index == -1){
                    if(i != 0){
                        url_export += "&audit_ids=";
                    }
                    list_tmp.push(audits[i]);
                    url_export += audits[i].id.toString();
                }
            }

            let filename = "";

            fetch(url_export, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
            .then(res=>{
                if(res.ok){
                    const header = res.headers.get('content-disposition');
                    if(header === null || header === undefined){
                        filename = prompt("Please enter a file name");
                    }else{
                        const parts = header.split(';');
                        filename = parts[1].split('=')[1];
                        filename = filename.substring(1, filename.length - 1);
                        let new_name = prompt("Please enter a file name.", filename)
                        if(new_name == null) return null;
                        if(new_name.slice(-4) !== ".ckl"){
                            new_name += ".ckl";
                        }
                        filename = new_name;
                    }
                    return res.blob();
                }else{
                    if(res.status == 404){
                        console.log('File Not Found.');
                        return null;
                    }
                    throw res;
                }
            })
            .then(data =>{
                if(data == null) return;
                let a = document.createElement('a');
                a.href = window.URL.createObjectURL(data);
                a.download = filename;
                a.click();
                a.remove();
            }).catch(async error => {
                return await FetchCatch(error, exportHandler, [audits, combine]);
            });

        }else{
            for(let i in audits){
                const url_export = "/api/audits/checklist/" + audits[i].id;
                let filename = "";
                fetch(url_export, {
                    headers: {
                        'Authorization': `Bearer ${user.token}`
                    }
                })
                .then(res=>{
                    if(res.ok){
                        const header = res.headers.get('content-disposition');
                        if(header === null || header === undefined){
                            filename = prompt("Please enter a file name");
                        }else{
                            const parts = header.split(';');
                            filename = parts[1].split('=')[1];
                            filename = filename.substring(1, filename.length - 1);
                        }
                        return res.blob();
                    }else{
                        if(res.status == 404){
                            console.log('File Not Found.');
                            return null;
                        }
                        throw res;
                    }
                })
                .then(data =>{
                    if(data == null) return;
                    let a = document.createElement('a');
                    a.href = window.URL.createObjectURL(data);
                    a.download = filename;
                    a.click();
                    a.remove();
                }).catch(async error => {
                    return await FetchCatch(error, exportHandler, [audits, combine]);
                });
            }
        }
        setExportMode(false);
    }

    useEffect(async () => {
        if(props.deviceList == null) return;
        props.refresh();
    }, [props.deviceList])

    useEffect(() => {
        getPosts(currentPage, filtered);
    }, [props.totalAuditList])

    const getPosts = async (page, filter = filtered) => {
        if(props.totalAuditList == null){
            setCurrentPosts([]);
            return;
        }
        setCurrentPage(page);

        let index = -1;
        if(filter != filtered){
            setFiltered(filter);
        }else{
            index = props.totalAuditList.findIndex(x => x.page === page);
        }

        let tmp = [];
        let tmp_results = [];
        if(index !== -1){
            tmp = props.totalAuditList[index].results;
        }else{
            tmp = await props.getAuditPage(page, filter);
        }

        for(let i in tmp){
            tmp_results.push({audit: tmp[i], device: props.deviceList[props.deviceList.findIndex(x => x.device_id === tmp[i].device_id)], playbook: props.playbookList[props.playbookList.findIndex(x => x.id === tmp[i].playbook_id)]})
        }

        if(tmp_results.length > 0)
            setAudit([tmp_results[0]]);
        else
            setAudit([]);

        setCurrentPosts(tmp_results);
    }

    const updateDimensions = () => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    useEffect(() => {
        if(location.state == null){
        }else if(location.state.host != null){
            if(location.state.host.length > 0){
                setTimeout(() => {
                    getPosts(1, {device_id: location.state.host[0].device_id});
                }, 500)
            }
        }
    },[location.state]);

    const auditSelectHandler = async (audit_select, ctrlKey, shiftKey) => {
        let index = audit.findIndex(x => x.audit.id == audit_select.audit.id);
        if(ctrlKey){
            let tmp = [...audit];
            if(index === -1){
                tmp.push(audit_select);
            }else{
                tmp.splice(index, 1);
            }
            setAudit(tmp);
            if(tmp.length == 0){
                setMostrecent(true);
            }
        }else if(shiftKey){
            if(audit.length == 0){
                setAudit(audit_select);
                setMostrecent(false);
            }else{
                let firstIndex = currentPosts.findIndex(x => x.audit.id == audit[audit.length - 1].audit.id);
                let secondIndex = currentPosts.findIndex(x => x.audit.id == audit_select.audit.id);
                let tmp = [];
                if(firstIndex < secondIndex){
                    tmp = currentPosts.slice(firstIndex + 1, secondIndex + 1);
                    tmp.push(currentPosts[firstIndex]);
                }else{
                    tmp = currentPosts.slice(secondIndex, firstIndex + 1);
                }
                setAudit(tmp);
            }
        }else{
            if(index === -1){
                setAudit([audit_select]);
                setMostrecent(false);
            }else{
                if(audit.length > 1){
                    setAudit([audit_select]);
                    setMostrecent(false);
                }else{
                    if(mostrecent){
                        setMostrecent(false);
                    }else{
                        setAudit([]);
                        setMostrecent(true);
                    }
                }
            }
        }
    }

    const API_get_checklist = async (audit_id) => {
        const url_vuln = "/api/vulnerabilities?audit_id=" + audit_id;
        let data = await fetch(url_vuln ,{
            headers:{
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${user.token}`
            }
        })
        .then(res=>{
            if(res.ok) return res.json();
            if(res.status == 404){ 
                alert("Vulnerability was not found for audit's id: " + audit_id);
                return null;
            }
            throw res;
        })
        .then(data =>{
            if(data == null) return null;
            return data;
        }).catch(async error => {
            return await FetchCatch(error, API_get_checklist, [audit_id]);
        })

        return data;
    }

    const auditSortHandler = (field) => {
        let tmp = [...currentPosts];
        if(field === 'device'){
            tmp.sort((a,b) => a.device.name > b.device.name ? 1 : -1);
        }else if(field === 'timestarted'){
            tmp.sort((a,b) => a.audit.time_started > b.audit.time_started ? 1 : -1);
        }else if(field === 'timefinished'){
            tmp.sort((a,b) => a.audit.time_finished > b.audit.time_finished ? 1 : -1);
        }else if(field === 'stig'){
            tmp.sort((a,b) => a.playbook.friendly_name > b.playbook.friendly_name ? 1 : -1);
        }else if(field === 'opens'){
            tmp.sort((a,b) => a.audit.metadata.Open.Total > b.audit.metadata.Open.Total ? 1 : -1);
        }else if(field === 'status'){
            tmp.sort((a,b) => 
            a.audit.status > b.audit.status ? 1 : 
            a.audit.status < b.audit.status  ? -1 : 
            a.audit.time_started > b.audit.time_started ? 1 : -1);
        }else if(field === "remediation"){
            tmp.sort((a,b) => a.audit.remediation === true && b.audit.remediation === true ? a.audit.time_started > b.audit.time_started ? 1 : -1 : a.audit.remediation === true && b.audit.remediation === false ? 1 : b.audit.remediation === true && a.audit.remediation === false ? -1 : a.audit.remediation === false && b.audit.remediation === false ? a.audit.time_started > b.audit.time_started ? 1 : -1 : -1);
        }

        let index = sort.indexOf(field);
        let tmp2 = [...sort];
        if(index === -1){
            setCurrentPosts(tmp);
            tmp2.push(field);
        }else{
            tmp.reverse();
            setCurrentPosts(tmp);
            tmp2.splice(index,1);
        }
        setSort(tmp2);
    }

    const undoCompare = () => {
        setCompare(null);
        setCompareMode(false);
    }

    const deleteAuditsHandler = () => {
        if(window.confirm("Are you sure you want to delete the audit(s)?")){
            let tmp = [];
            for(let i in audit){
                tmp.push(audit[i].audit);
            }
            props.deleteAudit(tmp, currentPage);
            setAudit([]);
            setMostrecent(true);
        }
    }

    const cancelAuditsHandler = () => {
        if(window.confirm("Are you sure you want to cancel the in-progress audit(s)?")){
            let tmp = [];
            for(let i in audit){
                if(audit[i].audit.status === "in-progress"){
                    tmp.push(audit[i].audit);
                }
            }
            props.cancelAudits(tmp, currentPage);
            setAudit([]);
            setMostrecent(true);
        }
    }
    
    const refreshAuditHandler = () => {
        props.refresh(filtered);
        setAudit([]);
        setMostrecent(true);
    }

    const rerunAuditHandler = () => {
        let tmp = [];
        for(let i in audit){
            tmp.push(audit[i].audit);
        }
        props.rerunAudits(tmp);
        setAudit([]);
        setMostrecent(true);
        props.refresh();
    }

    const compareHandler = () => {
        if(audit[0].audit == null || audit[1].audit == null || audit[0].audit.playbook_id != audit[1].audit.playbook_id || audit[0].audit.status != "Complete" || audit[1].audit.status != "Complete"){
            alert("Both audits must be completed and have the same STIG selected.");
            return;
        }

        let playbook = props.playbookList.find(x => x.id == audit[0].audit.playbook_id);
        if(playbook.friendly_name.includes(process.env.REACT_APP_VM_STIG_NAME)){
            alert("Can't compare " + process.env.REACT_APP_VM_STIG_NAME);
            return;
        }

        setCompare(audit[0].audit.id > audit[1].audit.id ? [audit[1], audit[0]] : [audit[0], audit[1]]);
        setCompareMode(true);
    }

    const simplifiedAudit = (audits) => {
        let tmp = [];
        for(let i in audits){
            tmp.push(audits[i].audit);
        }
        return tmp;
    }

    const filterOptionSubmit = async (filter) => {
        setPopuppagecheck(false);
        getPosts(1, filter);
    }

    const UndoFilter = () => {
        getPosts(1, null);
    }

    const viewinViewerHandler = () => {
        let tmp = [];
        for(let i in audit){
            tmp.push(audit[i].audit);
        }
        props.viewinViewer(tmp);
    }

    return (
        <div className="inventory-content">
            {
                compareMode ?
                    <AuditCompare audit={compare[0]} select={compare[1]} undoCompare={undoCompare} deviceList={props.deviceList} playbookList={props.playbookList} viewinViewer={props.viewinViewer} />
                :
                <>
            <div className="inventory-content-container" style={{alignItems: "stretch", color: theme ? "#FFF" : "#000"}}>
                <div className="inventory-content-header" style={{display: 'flex', flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <h1>Audits</h1>
                        <div title="Refresh Audits"  style={{display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: "pointer", marginLeft: "10px"}} onClick={() => refreshAuditHandler()}>
                            <RefreshIcon style={{height: "20px", width: "20px", paddingTop: "5px"}}/>
                        </div>
                    </div>
                    <div >
                        <div style={{display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
                            <input className="widget-button" type="button" value="View" onClick={viewinViewerHandler} style={{marginRight: "0px"}}/>
                            {
                                filtered !== null ?
                                <input className="widget-button" type="button" value="Clear" onClick={() => UndoFilter()} style={{marginRight: "0px"}}/>
                                :
                                <input className="widget-button" type="button" value="Filter" onClick={() => setPopuppagecheck(true)} style={{marginRight: "0px"}}/>
                            }
                        </div>
                        <div style={{ display: "flex", alignItems:"center", justifyContent:"end"}}>
                            <input className={audit.length == 2 ? "widget-button" : "widget-button-disable"} disabled={audit.length == 2 ? false : true} type="button" value="Compare" onClick={compareHandler} />
                            <input className={audit.length > 0 && !mostrecent ? "widget-button" : "widget-button-disable"} disabled={audit.length > 0 && !mostrecent ? false : true} type="button" value="Export" onClick={() => setExportMode(true)} />
                            <input className={audit.length > 0 && !mostrecent ? "widget-button" : "widget-button-disable"} disabled={audit.length > 0 && !mostrecent ? false : true} type="button" value="Re-run" onClick={() => rerunAuditHandler()} />
                            <input className={audit.length > 0 && !mostrecent ? "widget-button" : "widget-button-disable"} disabled={audit.length > 0 && !mostrecent ? false : true} type="button" value="Delete" onClick={() => deleteAuditsHandler()} style={{marginRight: "0px"}}/>
                            <input className={audit.length > 0 && !mostrecent ? "widget-button" : "widget-button-disable"} disabled={audit.length > 0 && !mostrecent ? false : true} type="button" value="Cancel" onClick={() => cancelAuditsHandler()} style={{marginRight: "0px"}}/>
                        </div>
                    </div>
                </div>
                {
                    width <= 1280 &&
                    <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                        <AuditWidget audit={audit.length > 0 ? audit[0] : currentPosts.length > 0 ? currentPosts[0] : null} moreDetail={() => props.auditSelect([audit[0]])} mostrecent={mostrecent} />
                        {
                            (audit.length > 0 && audit[0].audit.status == "Failed") ?
                            <AuditFailure audit={audit[0].audit} /> : 
                            (audit.length === 0 && currentPosts.length > 0 && currentPosts[0].audit.status == "Failed") &&
                            <AuditFailure audit={currentPosts[0].audit} />  
                        }
                    </div>
                }
                <div className="inventory-content-wrapper">
                    <AuditsTable groupRole={props.groupRole} audit={audit} mostrecent={mostrecent} auditList={currentPosts} select={auditSelectHandler} sort={auditSortHandler} inProgressQueue={props.inProgressQueue} refresh={refreshAuditHandler}/>
                    <div style={{overflow: "auto", display: 'flex', alignItems: 'center' ,justifyContent: 'end', marginRight: "16px", height: "70px"}}>
                        <Pagination postsPerPage={props.resultsPerPage} totalPosts={props.auditData} paginate={getPosts} page={currentPage} theme={theme} />
                    </div>
                </div>
            </div>
            {
                width > 1280 &&
                <div className="inventory-content-container" style={{marginRight: "20px"}}>
                    <AuditWidget audit={audit.length > 0 ? audit[0] : currentPosts.length > 0 ? currentPosts[0] : null} moreDetail={() => props.auditSelect([audit[0]])} mostrecent={mostrecent} />
                    {
                        (audit.length > 0 && audit[0].audit.status == "Failed") ?
                        <AuditFailure audit={audit[0].audit} /> : 
                        (audit.length === 0 && currentPosts.length > 0 && currentPosts[0].audit.status == "Failed") &&
                        <AuditFailure audit={currentPosts[0].audit} />  
                    }
                </div>
            }
            {
                exportMode &&
                <AuditExport audits={simplifiedAudit(audit)} deviceList={props.deviceList} playbookList={props.playbookList} export={exportHandler} setExportMode={setExportMode} />
            }
            </>
            }
            {
                popupagepagecheck &&
                <PopupPage close={() => setPopuppagecheck(false)} title="Audits" content={<AuditPopup filterSave={filterOptionSubmit} deviceList={props.deviceList} OS={props.OS} playbookList={props.playbookList}/>}/>
            }
        </div>
    )
}

export default Audits;