import React from 'react'
import CloseIcon from '@mui/icons-material/Close';
import BadgeIcon from '@mui/icons-material/Badge';


const CACPopup = (props) => {
  return (
    <div style={{width: "100vw",height: "100vh", position:"absolute", zIndex: "1",top: 0, left: 0, display: "flex", overflowY: "hidden", justifyContent: 'center', alignItems: 'center', backgroundColor: "rgba(25,25,25,0.85)"}} onClick={() => props.close()}>
        <div style={{width: "400px", height: "400px", backgroundColor: "#ffffff", borderRadius: "10px", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", display: 'flex', alignItems:"center", flexDirection:"column", justifyContent:"space-between", zIndex: "100", border: "1px solid #d5d5d5", opacity: '1'}} onClick={(e) => e.stopPropagation()}>
            <div style={{width: "90%", display: 'flex', alignItems: 'center', justifyContent: 'space-between', color: "#000", fontSize: "20px", fontWeight: "bold", marginTop: "20px" }}>
                CAC Authentication<CloseIcon style={{color: "#252525", cursor: "pointer"}} onClick={() => props.close()}/>
            </div>
            <div style={{fontSize: "120px", color: props.bannerConfig.color, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <BadgeIcon fontSize='inherit'/>
            <div style={{fontSize: "26px", color: "black"}}>WIP</div>
            </div>
            <div style={{display: 'flex', justifyContent:'center', alignItems:"center", marginBottom: "20px"}}>
                <input type="button" value="Cancel" className="widget-button" style={{fontWeight: "bold"}} onClick={() => props.close()}/>
                <input type="button" value="Login" className="widget-button" style={{fontWeight: "bold",backgroundColor: props.bannerConfig.color}} onClick={(e) => props.submit(e)}/>
            </div>
      </div>
    </div>
  )
}

export default CACPopup