
export const InputValidator = (input, type) => {
    // validate input to certain measures.
    if(input == "") return false;
    let errors = [];
    if(type === "password"){
        if(input.length > 60){
            errors.push("Input is greater than 60 characters.")
        }
        if (input.length < 15) {
            errors.push("Input must be at least 15 characters long."); 
        }
        let char_length = input.length - input.replace(/[A-Za-z]/g, '').length;
        if(char_length < 8){
          errors.push("Input must contain at least 8 letters.");
        }
        if (input.search(/[0-9]/) < 0) {
            errors.push("Input must contain at least one digit."); 
        }
        if (input.search(/[a-z]/) < 0) {                         
          errors.push("Input must contain at least one lowercase letter.");                   
        }                       
        if (input.search(/[A-Z]/) < 0) {                         
          errors.push("Input must contain at least one uppercase letter.");                   
        }
        if (input.search(/[!@#$%^&*_-]/) < 0){
          errors.push("Input must contain at least one special character.");
        }

    }else if(type === "username"){
        if(input.length > 60){
            errors.push("Input is greater than 60 characters.")
        }

    }else if(type === "number"){
        if(isNaN(parseInt(input))){
            errors.push("Input must be numbers only.");
        }
    }else if(type === "string"){
        if(input.length > 60){
            errors.push("Input is greater than 60 characters.");
        }
        if(input.search(/[+;=?|'<>\]\[(){}]/).toString() != "-1"){
            errors.push("Input has one or more not allowed special characters.");
        }
    }

    if (errors.length > 0) {
        return false;
    }
    return true;
}