import React, {useState, useEffect, useContext} from 'react'
import { UserContext } from '../../../App';

// Libraries
import { PieChart, Pie, ResponsiveContainer, Legend, Tooltip} from 'recharts';

// Icons
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { CircularProgress } from '@mui/material';

const AuditWidget = (props) => {
    const { theme, FormatTimestamp, user, FetchCatch } = useContext(UserContext);
    const [templateList, setTemplateList] = useState([]);
    const [pieMode, setPieMode] = useState("All");

    const [statusData, setStatusData] = useState(      
        [
          {status: 'Not A Finding', total: 0, fill: '#75C626'},
          {status: 'Not Reviewed', total: 0, fill: '#ffba08'},
          {status: 'Open', total: 0, fill: '#DD462B'},
          {status: 'Not Applicable', total: 0}
        ]
    );

    useEffect(() => {
        if(props.audit === null || props.audit.audit === null || props.audit.audit.remediation || props.audit.audit.status !== "Complete") return;
        setPieMode("All");
        setStatusData(        
            [
            {status: 'Not A Finding', total:  props.audit.audit.metadata.NotAFinding.Total, fill: '#75C626'},
            {status: 'Not Reviewed', total: props.audit.audit.metadata.Not_Reviewed.Total, fill: '#ffba08'},
            {status: 'Open', total: props.audit.audit.metadata.Open.Total, fill: '#DD462B'},
            {status: 'Not Applicable', total: props.audit.audit.metadata.Not_Applicable.Total}
          ])
    },[props.audit]);

    useEffect(async () => {
        if(props.audit != null && props.audit.audit != null && props.audit.audit.group_id != null){
            let data = await API_get_group_templates(props.audit.audit.group_id);
            if(data != null && Array.isArray(data)){
                setTemplateList(data);
            }else{
                setTemplateList([]);
            }
        }
    }, [props.audit])

    const API_get_group_templates = async (group_id) => {
        const url_groups_template = "/api/stigs/templates/group/" + group_id;
        let data = await fetch(url_groups_template,{
            method: 'GET',
            headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${user.token}` },
        })
        .then(res=>{
            if(res.ok) return res.json();
            throw res;
        })
        .then(data =>{
            return data;
        }).catch(async error => {
            return await FetchCatch(error, API_get_group_templates, [group_id]);
        });
    
        return data;
      }

    const onChangeRadio = (type) => {
        let audit = props.audit;
        setPieMode(type);
        if(type === "All"){
            setStatusData(        
                [
                {status: 'Not A Finding', total:  audit.audit.metadata.NotAFinding.Total, fill: '#75C626'},
                {status: 'Not Reviewed', total: audit.audit.metadata.Not_Reviewed.Total, fill: '#ffba08'},
                {status: 'Open', total: audit.audit.metadata.Open.Total, fill: '#DD462B'},
                {status: 'Not Applicable', total: audit.audit.metadata.Not_Applicable.Total}
              ])
        }else if(type === "high"){
            setStatusData(        
                [
                {status: 'Not A Finding', total:  audit.audit.metadata.NotAFinding.cat_1, fill: '#75C626'},
                {status: 'Not Reviewed', total: audit.audit.metadata.Not_Reviewed.cat_1, fill: '#ffba08'},
                {status: 'Open', total: audit.audit.metadata.Open.cat_1, fill: '#DD462B'},
                {status: 'Not Applicable', total: audit.audit.metadata.Not_Applicable.cat_1}
              ])  
        }else if(type === "medium"){
            setStatusData(        
                [
                {status: 'Not A Finding', total:  audit.audit.metadata.NotAFinding.cat_2, fill: '#75C626'},
                {status: 'Not Reviewed', total: audit.audit.metadata.Not_Reviewed.cat_2, fill: '#ffba08'},
                {status: 'Open', total: audit.audit.metadata.Open.cat_2, fill: '#DD462B'},
                {status: 'Not Applicable', total: audit.audit.metadata.Not_Applicable.cat_2}
              ])         
        }
        else if(type === "low"){
            setStatusData(        
                [
                {status: 'Not A Finding', total:  audit.audit.metadata.NotAFinding.cat_3, fill: '#75C626'},
                {status: 'Not Reviewed', total: audit.audit.metadata.Not_Reviewed.cat_3, fill: '#ffba08'},
                {status: 'Open', total: audit.audit.metadata.Open.cat_3, fill: '#DD462B'},
                {status: 'Not Applicable', total: audit.audit.metadata.Not_Applicable.cat_3}
              ])         
        }
    }

    const getDeviceIP = (device) => {
        if(device === null || device === undefined) return;
        for(let i in device.device_vars){
          if(device.device_vars[i].var_name === "ansible_host"){
            return device.device_vars[i].var_value;
          }
        }
        return "n/a"
    }

    const getTemplateName = (template_id) => {
        if(template_id == null || templateList.length === 0) return "n/a";
        let template = templateList.find(x => x.id === template_id);
        if(template){
            return template.name;
        }else{
            return "n/a";
        }
    }

    const audit_inprogress = () => {
        return (
            <div className="widget-content">
                <div className="widget-text">
                    <span>Device: </span>
                    <span>{props.audit.device == null || props.audit.device == undefined ? "" : props.audit.device.name}</span>
                </div>
                <div className="widget-text">
                    <span>STIG: </span>
                    <span>{props.audit.playbook == null || props.audit.playbook.friendly_name == null ? "n/a" : props.audit.playbook.friendly_name}</span>
                </div> 
                <div className="widget-text">
                    <span>Template: </span>
                    <span>{props.audit.audit == null ? "n/a" : getTemplateName(props.audit.audit.template_id)}</span>
                </div>
                <div className="widget-text">
                    <span>IP/Port: </span>
                    <span>{props.audit.device === null ? "" : getDeviceIP(props.audit.device)}</span>
                </div>
                <div style={{width: "100%", display: "flex", alignItems:"center", justifyContent:"center",height: "200px"}}><CircularProgress /></div>
            </div>
        )
    }

    const audit_complete = () => {
        return(
            <div className="widget-content">
                <div className="widget-text">
                    <span>Device: </span>
                    <span>{props.audit.device === null || props.audit.device === undefined ? "n/a" : props.audit.device.name}</span>
                </div>
                <div className="widget-text">
                    <span>STIG: </span>
                    <span>{props.audit.playbook == null || props.audit.playbook.friendly_name == null ? "n/a" : props.audit.playbook.friendly_name}</span>
                </div>
                <div className="widget-text">
                    <span>Template: </span>
                    <span>{props.audit.audit == null ? "n/a" : getTemplateName(props.audit.audit.template_id)}</span>
                </div>
                <div className="widget-text">
                    <span>IP/Port: </span>
                    <span>{props.audit.device === null || props.audit.device === undefined ? "n/a" : getDeviceIP(props.audit.device)}</span>
                </div>
                <div className="widget-text">
                    <span>Time Started: </span>
                    <span>{FormatTimestamp(props.audit.audit.time_started)}</span>
                </div>
                <div className="widget-text">
                    <span>Time Finished: </span>
                    <span>{FormatTimestamp(props.audit.audit.time_finished)}</span>
                </div>
                <form>
                    {
                        (props.audit.playbook != null && !props.audit.playbook.friendly_name.includes(process.env.REACT_APP_VM_STIG_NAME)) &&
                        <div style={{display: 'grid', gridTemplateColumns: "1fr 1fr 1fr 1fr", padding: "5px 0px", width: "100%"}}>
                            <div>
                                <input type="radio" value="All" name={"audit" + props.audit.audit.id} onChange={(e) => onChangeRadio(e.target.value)} checked={pieMode === 'All' ? true : false}/> All
                            </div>
                            <div>
                                <input type="radio" value="high" name={"audit" + props.audit.audit.id} onChange={(e) => onChangeRadio(e.target.value)} checked={pieMode === 'high' ? true : false}/> CAT I
                            </div>
                            <div>
                                <input type="radio" value="medium" name={"audit" + props.audit.audit.id} onChange={(e) => onChangeRadio(e.target.value)} checked={pieMode === 'medium' ? true : false}/> CAT II
                            </div>
                            <div>
                                <input type="radio" value="low" name={"audit" + props.audit.audit.id} onChange={(e) => onChangeRadio(e.target.value)} checked={pieMode === 'low' ? true : false}/> CAT III
                            </div>
                        </div>
                    }
                </form>
                {
                    (props.audit.playbook != null && !props.audit.playbook.friendly_name.includes(process.env.REACT_APP_VM_STIG_NAME)) &&
                    <>
                        <div style={{display: 'grid', gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr", padding: "5px 0px", width: "100%"}}>
                            <div style={{border: "1px solid #d5d5d5", textAlign: "center", whiteSpace: "nowrap"}}>
                                <div style={{width: "100%", height: "16px", backgroundColor: theme ? "#444444" : "#D5D5D5", padding: '3px 0px' }} title="Not a Finding">NF</div>
                                <div style={{padding: '5px 0px'}}>{statusData[0].total}</div>
                            </div>
                            <div style={{border: "1px solid #d5d5d5", textAlign: "center", whiteSpace: "nowrap"}}>
                                <div style={{width: "100%", height: "16px", backgroundColor: theme ? "#444444" : "#D5D5D5", padding: '3px 0px'}} title="Not Reviewed">NR</div>
                                <div style={{padding: '5px 0px'}}>{statusData[1].total}</div>
                            </div>
                            <div style={{border: "1px solid #d5d5d5", textAlign: "center", whiteSpace: "nowrap"}}>
                                <div style={{width: "100%", height: "16px", backgroundColor: theme ? "#444444" : "#D5D5D5", padding: '3px 0px'}}>Open</div>
                                <div style={{padding: '5px 0px'}}>{statusData[2].total}</div>
                            </div>
                            <div style={{border: "1px solid #d5d5d5", textAlign: "center", whiteSpace: "nowrap"}}>
                                <div style={{width: "100%", height: "16px", backgroundColor: theme ? "#444444" : "#D5D5D5", padding: '3px 0px'}} title="Not Applicable">NA</div>
                                <div style={{padding: '5px 0px'}}>{statusData[3].total}</div>
                            </div>
                            <div style={{border: "1px solid #d5d5d5", textAlign: "center", whiteSpace: "nowrap"}}>
                                <div style={{width: "100%", height: "16px", backgroundColor: theme ? "#444444" : "#D5D5D5", padding: '3px 0px'}}>Total</div>
                                <div style={{padding: '5px 0px'}}>{statusData[0].total + statusData[1].total + statusData[2].total + statusData[3].total}</div>
                            </div>
                        </div>
                        <ResponsiveContainer width="100%" height={200}>
                            <PieChart>
                            <Pie data={statusData} dataKey="total" nameKey="status" outerRadius={50}  />
                            <Legend verticalAlign="bottom" align="center" iconSize={5} fontStyle={{size: "5px"}} style={{border: "1px solid black"}}/>
                            <Tooltip/>
                            </PieChart>
                        </ResponsiveContainer>
                        </>
                }
            </div>
        )
    }

    const audit_failed = () => {
        return(
        <div className="widget-content">
            <div className="widget-text">
                <span>Device: </span>
                <span>{props.audit.device === null || props.audit.device === undefined ? "n/a" : props.audit.device.name}</span>
            </div>
            <div className="widget-text">
                <span>STIG: </span>
                <span>{props.audit.playbook == null || props.audit.playbook.friendly_name == null ? "n/a" : props.audit.playbook.friendly_name}</span>
            </div>
            <div className="widget-text">
                <span>Template: </span>
                <span>{props.audit.audit == null ? "n/a" : getTemplateName(props.audit.audit.template_id)}</span>
            </div>
            <div className="widget-text">
                <span>IP/Port: </span>
                <span>{props.audit.device === null || props.audit.device === undefined ? "n/a" : getDeviceIP(props.audit.device)}</span>
            </div>
            <div className="widget-text">
                <span>Time Started: </span>
                <span>{FormatTimestamp(props.audit.audit.time_started)}</span>
            </div>
            <div className="widget-text">
                <span>Time Finished: </span>
                <span>{FormatTimestamp(props.audit.audit.time_finished)}</span>
            </div>
        </div>
        )
    }

    return (
        <div className="widget-container" style={{backgroundColor: theme ? "#242424" : "#FFF", border: theme ? "1px solid #000" : "1px solid #EBEBEB", color: theme ? "#fff" : "#000", borderRadius: "10px", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", width: "90%", margin: "10px 0px", height: "auto"}}>
            <h3 className="widget-header">
            <span>{props.title !== undefined ? props.title : props.mostrecent ? "Most Recent Audit #" + (props.audit != null ? props.audit.audit.id : "N/A") : "Audit #" + (props.audit != null ? props.audit.audit.id : "N/A")}</span>
            <span style={{display: props.audit === null || props.audit.audit === null || props.audit.audit.remediation === true || props.audit.audit.status !== "Complete" || props.compare === true || props.audit.playbook?.friendly_name.includes(process.env.REACT_APP_VM_STIG_NAME) ? "none" : 'flex', alignItems: "center", cursor: "pointer", color: "#0088FF",fontWeight: "normal", fontSize: "16px"}} onClick={() => props.moreDetail()}>
                More Detail <ArrowForwardIcon sx={{width: "16px"}}/>
            </span>
            </h3>
            {
                props.audit === null || props.audit.audit === null ? "" : props.audit.audit.status === "Complete" ? audit_complete() : props.audit.audit.status === "in-progress" ? audit_inprogress() : audit_failed()
            }
        </div>
    )
}

export default AuditWidget;