import React from 'react'

import AgileLogo from "./../../styles/imgs/AgileDefenseLogoWht.png";
import DuroSuiteWhite from "./../../styles/imgs/DuroSuiteHorizontalWhite.png";

const LoginBanner = (props) => {
  return (
    <>
        <div className="login-branding-container" style={{backgroundColor: props.config.color, top: props.banner ? "40px" : "0px"}}>
            <img src={AgileLogo} alt="Agile Defense Logo" className="login-branding-logo" title="Agile Defense" style={{height: "40px", paddingLeft: '20px'}}/>
            <img src={DuroSuiteWhite} alt="DuroSuite White Logo" className="login-branding-logo" title="Agile Defense" style={{height: "40px", paddingRight: '20px'}}/>
        </div>
    </>
  )
}

export default LoginBanner;
