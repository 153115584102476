import React, { useState, useEffect, useContext} from 'react';
import { UserContext } from '../../../App';

// Icons
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import InfoIcon from '@mui/icons-material/Info';

const ScheduledTasks = (props) => {
    const { user, FetchCatch, FormatTimestamp, theme } = useContext(UserContext);
    const [taskList, setTaskList] = useState([]);
    const [totalTaskList, setTotalTaskList] = useState([]);
    const [totalTaskListCnt, setTotalTaskListCnt] = useState(0);

    const [filterGroup, setFilterGroup] = useState(null);
    const [filterDevice, setFilterDevice] = useState(null);
    const [filterPlaybook, setFilterPlaybook] = useState(null);
    const [filtered, setFiltered] = useState(false);    

    const [filterTaskType, setFilterTaskType] = useState('All');

    const repeat_types = ["Single", "Daily", "Weekly", "Monthly"];

    const [scheduleInfoAwait, setScheduleInfoAwait] = useState(false);
    const [downloadScheduleInfoAwait, setDownloadScheduleInfoAwait] = useState(false)
    const [emptyScheduleInfoAwait, setEmptyScheduleInfoAwait] = useState(false)

    const darkTheme = createTheme({
        palette: {
          mode: 'dark',
        },
      });

    const lightTheme = createTheme({
    palette: {
        mode: 'light',
    },
    });

    // Single = DateTime
    // Daily = Time fom DateTime
    // Weekly = Weekday (i.e Monday) and Time from DateTime
    // Monthly = Weekday (i.e 5th of October) and Time from DateTime

    useEffect(async () => {
        await refresh();
    }, [])

    const refresh = async () =>{
        let data = await API_get_scheduled_tasks();
        if(data){
            setTaskList(data);
            setTotalTaskList(data);
            setTotalTaskListCnt(data.length);
        }
    }

    const filterGroupHandler = async (group) => {
        setFilterGroup(group);
    }

    const filterDeviceHandler = async (device) => {
        setFilterDevice(device);
    }

    const filterPlaybookHandler = async (pb) => {
        setFilterPlaybook(pb);
    }

    const deleteScheduledTaskHandler = async (scheduled_task_id) => {
        let confirmed = window.confirm("Are you sure you want to delete this scheduled task?");
        if(!confirmed) return;

        let data = await API_delete_scheduled_task(scheduled_task_id);
        if(data){
            let scheduled_task_list = await API_get_scheduled_tasks();
            setTaskList(scheduled_task_list == null ? [] : scheduled_task_list);
        }
    }

    const API_get_scheduled_tasks = async (group_id, device_id, repeat_type) => {
        const url_get_schedule = "/api/schedule" + (group_id != null ? "?group_id=" + group_id : "") + (device_id != null ? "?device_id=" + device_id : "") + (repeat_type != null && repeat_type != "All" ? "?repeat_type=" + repeat_type : "");
        let data = await fetch(url_get_schedule ,{
          headers:{
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${user.token}`,
          },  
        })
        .then(res=>{
          if(res.ok) return res.json();
          throw res
        })
        .then(data =>{
          return data;
        }).catch(async error => {
          return await FetchCatch(error, API_get_scheduled_tasks, [group_id, device_id]);
        })
        
        return data;
    }

    const API_delete_scheduled_task = async (scheduled_task_id) => {
        const url_delete_schedule = "/api/schedule/" + scheduled_task_id;
        let data = await fetch(url_delete_schedule ,{
          method: "DELETE",
          headers:{
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${user.token}`,
          },  
        })
        .then(res=>{
          if(res.ok) return true;
          throw res
        })
        .then(data =>{
          return data;
        }).catch(async error => {
          return await FetchCatch(error, API_delete_scheduled_task, [scheduled_task_id]);
        })
        
        return data;
    }

    const API_get_schedule_info = async () => {
        const url_get_schedule_info = "/api/schedule/info";
        let data = await fetch(url_get_schedule_info ,{
          method: "GET",
          headers:{
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${user.token}`,
          },  
        })
        .then(res=>{
          if(res.ok) return res.json();
          throw res
        })
        .then(data =>{
          return data;
        }).catch(async error => {
          return await FetchCatch(error, API_get_schedule_info, []);
        })
        
        return data;
    }

    const API_download_schedule_info = async () => {
        const url_download_schedule_info = "/api/schedule/info/download";
        let filename = "";
        fetch(url_download_schedule_info, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
        .then(res=>{
            if(res.ok){
                const header = res.headers.get('content-disposition');
                if(header === null || header === undefined){
                    filename = prompt("Please enter a file name");
                }else{
                    const parts = header.split(';');
                    filename = parts[1].split('=')[1];
                    filename = filename.substring(1, filename.length - 1);
                }
                return res.blob();
            }else{
                if(res.status == 404){
                    console.log('File Not Found.');
                    return null;
                }
                throw res;
            }
        })
        .then(data =>{
            if(data == null) return;
            let a = document.createElement('a');
            a.href = window.URL.createObjectURL(data);
            a.download = filename;
            a.click();
            a.remove();
        }).catch(async error => {
            return await FetchCatch(error, API_download_schedule_info, []);
        });
    }

    const API_empty_schedule_queue = async () => {
        const url_empty_schedule_queue = "/api/schedule/empty";
        let data = await fetch(url_empty_schedule_queue ,{
          method: "POST",
          headers:{
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${user.token}`,
          },  
        })
        .then(res=>{
          if(res.ok) return true;
          throw res
        })
        .then(data =>{
          return data;
        }).catch(async error => {
          return await FetchCatch(error, API_empty_schedule_queue, []);
        })
        
        return data;
    }

    const FormatDateTime = (datetime, type) => {
        const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        const date = new Date(datetime * 1000);

        if(type === "Single"){
            return FormatTimestamp(datetime);
        }else if(type === "Daily"){
            return (date).toLocaleTimeString("en-US", {
                hour: '2-digit',
                minute: '2-digit',
            });
        }else if(type === "Weekly"){
            return weekdays[date.getDay()] + " " + (date).toLocaleTimeString("en-US", {
                hour: '2-digit',
                minute: '2-digit',
            });
        }else if(type === "Monthly"){
            return ordinal_suffix_of(date.getDate()) + " " + (date).toLocaleTimeString("en-US", {
                hour: '2-digit',
                minute: '2-digit',
            });
        }
    }

    function ordinal_suffix_of(i) {
        var j = i % 10,
            k = i % 100;
        if (j == 1 && k != 11) {
            return i + "st";
        }
        if (j == 2 && k != 12) {
            return i + "nd";
        }
        if (j == 3 && k != 13) {
            return i + "rd";
        }
        return i + "th";
    }

    const format_device_group_name = (task) => {
        if(task.device_id == null){
            return props.groupList.find(x => x.group_id == task.group_id)?.name;
        }else{
            return props.deviceList.find(x => x.device_id == task.device_id)?.name;
        }
    }

    const clearFilterHandler = (refreshList = true) => {
        setFilterDevice(null);
        setFilterGroup(null);
        setFilterPlaybook(null);
        setFiltered(false);

        if(refreshList){
            setTaskList(totalTaskList);
            setFilterTaskType('All');
        }
    }

    const handleFilterHandler = () => {
        let tasklist_tmp = [...totalTaskList];
        let filtered_tmp = false;
        if(filterGroup != null){
            tasklist_tmp = totalTaskList.filter(x => x.group_id == filterGroup.group_id);
            filtered_tmp = true;
        }

        if(filterDevice != null){
            tasklist_tmp = tasklist_tmp.filter(x => x.device_id == filterDevice.device_id);
            filtered_tmp = true;
        }

        if(filterPlaybook != null){
            tasklist_tmp = tasklist_tmp.filter(x => x.playbook_id == filterPlaybook.id);
            filtered_tmp = true;
        }

        setFiltered(filtered_tmp);
        setTaskList(tasklist_tmp);
    }

    const filterTaskTypeHandler = async (type) => {
        if(type === "All"){
            setTaskList(totalTaskList);
        }else if(type === "Groups"){
            setTaskList(totalTaskList.filter(x => x.device_id == null));
        }else{
            setTaskList(totalTaskList.filter(x => x.device_id != null));
        }

        setFilterTaskType(type);
        clearFilterHandler(false);
    }

    const scheduleInfoHandler = async () => {
        setScheduleInfoAwait(true);
        let data = await API_get_schedule_info();
        console.log(data);
        window.alert(data);
        setScheduleInfoAwait(false);
    }

    
    const downloadScheduleInfoHandler = async () => {
        setDownloadScheduleInfoAwait(true);
        let data = await API_download_schedule_info();
        setDownloadScheduleInfoAwait(false);
    }
    const emptyScheduleQueueHandler = async () => {
        let status = window.confirm("Do you want to empty the scheduled queue lists?\n\n*It will also delete all scheduled task records.")
        if(!status) return;
        setEmptyScheduleInfoAwait(true);
        let data = await API_empty_schedule_queue();
        setEmptyScheduleInfoAwait(false);
        if(data){
            window.alert("Successful");
            refresh();
        }else{
            window.alert("Unsuccessful");
        }
    }

    return (
    // <div style={{display: 'grid', gridTemplateColumns: "1fr 350px", width: "100%"}}>
    <div style={{width: "calc(100% - 240px)", color: theme ? "white" : "black"}}>
        <div style={{display: 'grid', gridTemplateColumns: "300px 1fr"}}>
        <div style={{padding: "10px", display: 'flex', flexDirection: 'column', gap: "10px"}}>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <h1>
                <PendingActionsIcon />
                Scheduled Tasks
                </h1>
            </div>
            <h3>Filter Options:</h3>
            <div>
                <label>Group:</label>
                <select value={filterGroup == null ? -1 : filterGroup.group_id} onChange={(e) => filterGroupHandler(props.groupList.find(x => x.group_id == e.target.value))}>
                    <option value={-1}>All Groups</option>
                    {
                        props.groupList?.map((group, index) => (
                            <option key={group.group_id} value={group.group_id}>{group.name}</option>
                        ))
                    }
                </select>
            </div>
            <div>
                <label>Device:</label>
                <select value={filterDevice == null ? -1 : filterDevice.device_id} onChange={(e) => filterDeviceHandler(props.deviceList.find(x => x.device_id == e.target.value))}>
                    <option value={-1}>All Devices</option>
                    {
                        props.deviceList?.map((device, index) => (
                            <option key={device.device_id} value={device.device_id}>{device.name}</option>
                        ))
                    }
                </select>
            </div>
            <div>
                <label>STIG:</label>
                <select style={{maxWidth: "100%"}} value={filterPlaybook == null ? -1 : filterPlaybook.id} onChange={(e) => filterPlaybookHandler(props.playbookList.find(x => x.id == e.target.value))}>
                    <option value={-1}>All STIGs</option>
                    {
                        props.playbookList?.map((pb, index) => (
                            <option key={pb.id} value={pb.id}>{pb.friendly_name}</option>
                        ))
                    }
                </select>
            </div>
            <div>
                <input type="button" value="Clear" className="widget-button" style={{width: "fit-content", padding: "0px 10px"}} onClick={() => clearFilterHandler()}/>
                <input type="button" value="Filter" className="widget-button" style={{width: "fit-content", padding: "0px 10px"}} onClick={() => handleFilterHandler()}/>
            </div>
        </div>
        <div style={{display: "flex", flexDirection: "column", gap: "5px",height: "calc(100vh - 60px)", borderRadius: "10px", padding: "5px"}}>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '15px 0px'}}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{marginRight: '5px'}}>
                        Number of Scheduled Tasks: {totalTaskListCnt} | Total Showing: {taskList.length}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: "center", fontSize: "16px"}}>
                        {
                            <div className="inventory-content-header-button"  onClick={() => scheduleInfoHandler()}>
                                <InfoIcon style={{fontSize: "20px"}} />
                                {/* Get Scheduled Info */}
                                Scheduled Info
                            </div>
                        }
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: "center", fontSize: "16px"}}>
                        {
                            <div className="inventory-content-header-button"  onClick={() => downloadScheduleInfoHandler()}>
                                <DownloadIcon style={{fontSize: "20px"}} />
                                {/* Download Scheduled Info */}
                                Download
                            </div>
                        }
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: "center", fontSize: "16px"}}>
                        {
                            <div className="inventory-content-header-button"  onClick={() => emptyScheduleQueueHandler()}>
                                <DeleteForeverIcon style={{fontSize: "20px"}} />
                                {/* Empty Scheduled Lists*/}
                                Empty Schedule
                            </div>
                        }
                    </div>
                </div>
                <div style={{marginRight: "10px"}}>
                    <ThemeProvider theme={theme ?  darkTheme : lightTheme}>
                    <ToggleButtonGroup
                    value={filterTaskType}
                    exclusive
                    onChange={(e) => filterTaskTypeHandler(e.target.value)}
                    aria-label="Platform"
                    >
                    <ToggleButton value={"All"}>All</ToggleButton>
                    <ToggleButton value={"Groups"}>Group(s)</ToggleButton>
                    <ToggleButton value={"Device"}>Device(s)</ToggleButton>
                    </ToggleButtonGroup>
                    </ThemeProvider>
                </div>
            </div>
            <div style={{overflowY: "auto", overflowX: "hidden", display: 'flex', flexDirection: 'column', gap: '5px'}}>
            {
                taskList.map((task, index) => (
                    <div key={task.id} style={{display: "flex", width: "calc(100% - 30px)", alignItems: 'center', justifyContent: "space-between", border: "1px solid gray", padding: "5px 10px", borderRadius: "15px" }}>
                        <div>
                            <div style={{display: 'flex', alignItems: 'center', marginBottom: "5px"}}>
                                #{task.id + " "} 
                                {" " + format_device_group_name(task)}
                                <div style={{margin: "0px 10px",  backgroundColor: 'blue', color: "white", borderRadius: "10px", padding: '5px 10px', fontSize: "10px"}}>
                                    {task.device_id != null ? "Device" : "Group"}
                                </div>
                                <div style={{backgroundColor: 'green', color: "white", borderRadius: "10px", padding: '5px 10px', fontSize: "10px"}}>
                                    {task.repeat_type}
                                </div>
                            </div>
                            <div>
                            { props.playbookList.find(x => x.id == task.playbook_id)?.friendly_name + " " }
                            |
                            {" " + FormatDateTime(task.datetime, task.repeat_type) + " "}
                            |
                            {task.remediation ? " Remediation " : " Audit "}
                            </div>
                        </div>
                        <div><DeleteForeverIcon style={{color: "red", cursor: "pointer"}} onClick={() => deleteScheduledTaskHandler(task.id)}/></div>
                    </div>
                ))
            }
            </div>
        </div>
    </div>
    </div>
    )
}

export default ScheduledTasks;